import React, { ReactNode } from 'react';
import { TextStyle, ViewStyle } from 'react-native';
// eslint-disable-next-line import/no-named-as-default
import Overlay from '../Overlay/Overlay';
import { Modal } from './Modal';

export const ConfirmFn =
  (...params: Parameters<typeof Confirm>) =>
  () =>
    Confirm(...params);

export const Confirm = (
  func: () => void,
  content?: string | ReactNode,
  options?: {
    cancelTitle?: string;
    confirmTitle?: string;
    onCancel?: () => void;
    renderButton?: ({ close }: { close: () => void }) => ReactNode;

    renderContent?: ({ close }: { close: () => void }) => ReactNode;
    hasContentMargin?: boolean;

    buttonDirection?: 'vertical' | 'horizontal';
    buttons?: {
      label: string | ReactNode;
      type?: 'primary' | 'ghost';
      style?: ViewStyle;
      labelStyle?: TextStyle;
      onPress?: ({ close }: { close: () => void }) => void;
      color?: string;
    }[];

    title?: string | ReactNode;
    showClose?: boolean;
    showBckImg?: boolean;
  },
) => {
  const {
    showClose = true,
    title,
    buttonDirection,
    buttons,
    renderButton,
    renderContent,
    cancelTitle,
    onCancel,
    confirmTitle,
    hasContentMargin,
  } = options ?? {};

  // eslint-disable-next-line prefer-const
  let key: any;

  const onClose = () => {
    Overlay.hide(key, true);
  };

  const overlayView = (
    <Overlay.PopView
      modal
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Modal
        content={content}
        title={title ?? 'Tips'}
        onClose={() => {
          onClose();
        }}
        hasContentMargin={hasContentMargin}
        renderContent={renderContent}
        showClose={showClose}
        buttonDirection={buttonDirection}
        buttons={
          buttons ?? [
            {
              label: cancelTitle ?? 'Cancel',
              onPress: () => {
                onClose();
                onCancel?.();
              },
              type: 'ghost',
            },
            {
              label: confirmTitle ?? 'Confirm',
              onPress: () => {
                onClose();
                func?.();
              },
            },
          ]
        }
        renderButton={renderButton}
      />
    </Overlay.PopView>
  );
  // eslint-disable-next-line prefer-const
  key = Overlay.show(overlayView);
};

export const Tips = (
  content: string | ReactNode,
  func = () => {},

  options?: {
    title?: string | ReactNode;
    buttons?: {
      label: string | React.ReactNode;
      type?: 'primary' | 'ghost';
      style?: ViewStyle;
      labelStyle?: TextStyle;
      onPress?: () => void;
    }[];
    buttonDirection?: 'vertical' | 'horizontal';
    renderButton?: ({ close }: { close: () => void }) => ReactNode;

    hasContentMargin?: boolean;
    renderContent?: ({ close }: { close: () => void }) => ReactNode;

    showClose?: boolean;
    confirmTitle?: string;
  },
) => {
  const {
    showClose = true,
    title,
    renderContent,
    buttons,
    confirmTitle,
    buttonDirection,
    hasContentMargin,
  } = options ?? {};
  // eslint-disable-next-line prefer-const
  let key: any;
  const overlayView = (
    <Overlay.PopView
      modal
      style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
    >
      <Modal
        content={content}
        title={title ?? 'Notice'}
        onClose={() => {
          Overlay.hide(key, true);
          func?.();
        }}
        hasContentMargin={hasContentMargin}
        renderContent={renderContent}
        showClose={showClose}
        buttonDirection={buttonDirection}
        buttons={
          buttons ?? [
            {
              label: confirmTitle ?? 'Ok',
              onPress: () => {
                Overlay.hide(key, true);
                func?.();
              },
              type: 'primary',
            },
          ]
        }
      />
    </Overlay.PopView>
  );
  // eslint-disable-next-line prefer-const
  key = Overlay.show(overlayView);
  return {
    close: () => {
      Overlay.hide(key, true);
    },
  };
};
