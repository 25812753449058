import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import { isString } from 'lodash';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  KeyboardTypeOptions,
  StyleProp,
  TextInput,
  TextStyle,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import { BaseViewProps, CenterRow } from '../layout';

export type InputProps = {
  inputStyle?: StyleProp<TextStyle>;
  style?: ViewStyle;
  disabled?: boolean;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  onChangeText: (str: string) => void;
  value: string;
  focused?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  keyboardType?: KeyboardTypeOptions;
} & BaseViewProps;

const {
  colorText,
  colorTextSecondary,
  colorTextTertiary,
  colorInteractive,
} = colorConfig;
export const Input: React.FC<InputProps> = props => {
  const {
    inputStyle,
    disabled,
    leftComponent = null,
    rightComponent = null,
    onChangeText,
    value,
    placeholder = '',
    focused,
    autoFocus,
    style,
    keyboardType,
  } = props;
  const { mode } = useThemeMode();

  const [isActive, setIsActive] = useState(false);

  let txtColor = colorText[mode];

  if (isActive) {
    txtColor = colorText[mode];
  }
  if (disabled) {
    txtColor = colorTextSecondary[mode];
  }

  const inputRef = useRef<TextInput | null>(null);
  useEffect(() => {
    if (focused) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 250);
    }
  }, [focused]);

  return (
    <Container
      {...props}
      active={isActive}
      disabled={disabled}
      style={style}
    >
      {leftComponent}
      <TextInput
        ref={inputRef}
        onFocus={() => {
          setIsActive(true);
        }}
        autoFocus={autoFocus}
        editable={!disabled}
        onBlur={() => setIsActive(false)}
        placeholder={placeholder}
        placeholderTextColor={
          disabled ? colorTextSecondary[mode] : colorTextTertiary[mode]
        }
        keyboardType={keyboardType}
        value={value}
        onChangeText={t => {
          if (isString(t)) {
            onChangeText?.(t);
          }
        }}
        style={[
          {
            color: txtColor,
            flex: 1,
            margin: 0,
          },
          inputStyle,
          { outline: 'none' } as any,
        ]}
        selectionColor={colorInteractive[mode]}
      />
      {rightComponent}
    </Container>
  );
};

const { padding, borderRadiusLG } = globalToken;
const {
  colorInputBg,
  colorInputBgDisabled,
  colorInputBgActive,
  colorInputBorderActive,
  colorInputBorder,
} = globalToken.Input;
const Container = styled(CenterRow)<{
  active: boolean;
  disabled?: boolean;
}>`
  padding-left: ${padding};
  padding-right: ${padding};
  height: 48px;
  border-width: 1px;

  background-color: ${p =>
    p.disabled
      ? colorInputBgDisabled
      : p.active
      ? colorInputBgActive
      : colorInputBg};

  border-radius: ${borderRadiusLG};
  border-color: ${p =>
    p.active && !p.disabled ? colorInputBorderActive : colorInputBorder};
  input {
    :focus-visible {
      outline: none !important;
    }
  }
`;
