import { TxnResult } from '@aries-markets/create-sdk';
import { Notify, Toast } from '@aries/mobile-core/Smoothy';
import { getTFunction } from '@aries/shared/locale';
import { shortenAddress } from '@aries/shared/utils';
import { getWalletAdapter } from '@aries/solana-web-wallet';
import { get, isNumber, isString } from 'lodash';

export const isDev = () => process.env.NODE_ENV === 'development';

export function withSendTxNotify<T extends any[]>(
  fn: (...params: T) => Promise<TxnResult>,
  options?: {
    sendingTitle?: string;
    sendSuccessTitle?: string;
    sendSuccessContent?: string;
  },
): (...params: T) => Promise<boolean> {
  return async (...params: T) => {
    const t = getTFunction();
    const { hide: hideProgress } = Toast.progress(
      options?.sendingTitle ?? t`Sending Transaction`,
    );

    try {
      const wallet = getWalletAdapter();
      if (!wallet) throw new Error(t`Please connect your wallet.`);

      const res = await fn(...params);
      if (res.success) {
        hideProgress();
        Notify.info(
          options?.sendSuccessTitle ?? t`Send Transaction Successful`,
          options?.sendSuccessContent ??
            `Txn: ${shortenAddress(res.txId, 5)}`,
        );
      } else {
        throw new Error(res.message);
      }

      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Send Tx Failed]', err);
      const maybeReqErrorCode = get(err, 'response.data.code');
      let errMsg = t`Unknown Error`;

      // Aptos Request Error
      if (isNumber(maybeReqErrorCode) && maybeReqErrorCode !== 200) {
        errMsg = get(err, 'response.data.message') ?? t`Unknown Error`;
      } else {
        // Client Error
        errMsg =
          err instanceof Error
            ? err.message
            : isString(err)
            ? err
            : t`Unknown Error`;
      }
      hideProgress();
      Notify.error(t`Send Transaction Failed`, errMsg);
      return false;
    }
  };
}

export async function withLoadingNotify<T>(
  promise: Promise<T>,
  options?: {
    sendingTitle?: string;
    sendSuccessTitle?: string;
    sendSuccessContent?: string;
  },
): Promise<T | null> {
  const t = getTFunction();
  const { hide: hideProgress } = Toast.progress(
    options?.sendingTitle ?? t`Sending`,
  );

  try {
    const res = await promise;
    hideProgress();
    Notify.info(options?.sendSuccessTitle ?? t`Success`, '');

    return res;
  } catch (err) {
    const errMsg = (err as any)?.message ?? t`Unknown Error`;
    hideProgress();
    Notify.error(t`Send Transaction Failed ${errMsg}`, '');

    return null;
  }
}
