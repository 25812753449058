import { ReactNode } from 'react';
import styled from 'styled-components/native';
import { Button, ButtonTypeProps } from '../button';
import { CenterCol, Col } from '../layout';

import { globalToken } from '@aries/ui-theming';
import { ViewStyle } from 'react-native';

type ActionSheetItem = {
  title: ReactNode;
  onPress: () => void;
  color?: string;
  type?: ButtonTypeProps;
};
export const ActionSheet = ({
  close,
  closeOuter,
  btns,
  cancelText = 'Cancel',
  style,
}: {
  close?: () => void;
  closeOuter?: () => void;
  btns: ActionSheetItem[];
  cancelText?: ReactNode;
  style?: ViewStyle;
}) => {
  return (
    <CenterCol pt={4} pb={6}>
      <Divider mb={12} />
      <Col style={{ width: '100%', ...style }}>
        {btns.map(({ title, type = 'ghost', onPress, color }, index) => {
          return (
            <Button
              key={`${title}__${index}`}
              type={type}
              title={title}
              titleStyle={{ color }}
              active
              onPress={() => {
                onPress();
                close?.();
                closeOuter?.();
              }}
              mb={10}
            />
          );
        })}
        <Button
          key="Cancel"
          title={cancelText}
          onPress={() => {
            close?.();
            closeOuter?.();
          }}
          type="text"
          mb={6}
          mt={10}
        />
      </Col>
    </CenterCol>
  );
};

const { colorBgQuaternary } = globalToken;
export const Divider = styled(Col)`
  background-color: ${colorBgQuaternary};
  border-radius: 4;
  width: 40;
  height: 4;
  align-self: center;
`;
