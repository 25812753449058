// @ts-nocheck
/* eslint-disable  */
/* eslint-disable consistent-return */
// ModalIndicator.js

import { Txt } from '@aries/mobile-core/components';
import React from 'react';
import Overlay from '../Overlay/Overlay';
import ModalIndicatorView from './ModalIndicatorView';

let miKey: any = null;
let miOverlay: any = null;

export class ModalIndicator extends Overlay {
  static IndicatorView = ModalIndicatorView;

  static show(text: string) {
    if (miOverlay) {
      miOverlay.text = text;
      return;
    }
    miKey = super.show(
      <this.IndicatorView
        text={text}
        ref={v => {
          miOverlay = v;
        }}
      />,
    );

    return miKey;
  }

  static progress() {
    let setProgress: any;
    const C = () => {
      const [p, setP] = React.useState(0);
      setProgress = setP;

      return (
        <Txt c1 h3>
          更新中{p}%，请勿关闭应用
        </Txt>
      );
    };

    ModalIndicator.show((<C />) as any);

    return {
      setProgress: (p: number) => {
        setProgress?.(p);
      },
    };
  }

  static hide() {
    if (miKey) {
      super.hide(miKey);
      miKey = null;
      miOverlay = null;
    }
  }
}
