import { Asset, Balance } from '@aries/defi-toolkit/types';
import {
  CenterBetweenRow,
  CenterCol,
  CenterRow,
  Col,
  ScrollView,
  Txt,
} from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { useSearchList } from '@aries/shared/hooks';
import React, { FC } from 'react';
import { Search, TokenIcon } from '~/components';
import { Item } from './Item';

import { Trans, useTranslation } from '@aries/shared/locale';
import { globalToken } from '@aries/ui-theming';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

const { colorBgElevated } = globalToken;

type SwapProps = {
  onClose: () => void;
  onSelect: (asset: Asset) => void;
  list: (Asset & {
    wallet?: Balance;
    deposit?: Balance;
    isCommon?: boolean;
  })[];
  showDeposit?: boolean;
};

export const openSelectToken = (
  onSelect: (v: Asset) => void,
  options: Pick<SwapProps, 'list' | 'showDeposit'>,
) => {
  openModal({
    title: (
      <Txt h4>
        <Trans i18nkey="Select Token" />
      </Txt>
    ),
    renderContent: ({ close }) => (
      <Detail
        onClose={close}
        list={options.list ?? []}
        onSelect={onSelect}
        showDeposit={options.showDeposit}
      />
    ),
  });
  options;
};

const Detail: FC<SwapProps> = ({
  onClose,
  onSelect,
  list,
  showDeposit,
}) => {
  const { t } = useTranslation();
  const { search, setSearch, filteredList } = useSearchList(list, a => [
    a.name,
    a.symbol,
  ]);

  const commonOptions = list.filter(v => v.isCommon);

  return (
    <ScrollView style={{ marginTop: 12 }}>
      <CenterCol>
        {showDeposit && (
          <Txt h1 c2 mb={12}>
            {t`Only partial tokens support borrow now.`}
          </Txt>
        )}
      </CenterCol>
      {commonOptions.length > 0 && (
        <CenterRow mb={12} style={{ justifyContent: 'space-around' }}>
          {commonOptions.map(v => {
            return (
              <AssetQuickButton
                key={v.id}
                onPress={() => {
                  onSelect(v);
                  onClose();
                }}
              >
                <TokenIcon asset={v} size={20} />
                <Txt ml={3} h2>
                  {v.symbol}
                </Txt>
              </AssetQuickButton>
            );
          })}
        </CenterRow>
      )}

      <Search
        value={search}
        onChange={v => {
          setSearch(v);
        }}
      />
      <CenterBetweenRow style={{ width: '100%' }} pd={3} pt={0}>
        <Txt c2 ml={16}>
          {t`Token`}
        </Txt>
        <Txt c2 mr={16}>
          {showDeposit ? t`Deposited` : t`Balance`}
        </Txt>
      </CenterBetweenRow>
      <Col>
        {filteredList.map(item => {
          return (
            <Item
              asset={item}
              key={item.id}
              onPress={() => {
                onSelect(item);
                onClose();
              }}
            />
          );
        })}
      </Col>
    </ScrollView>
  );
};

const AssetQuickButton = styled(TouchableOpacity)`
  padding: 5px 8px;
  border-radius: 5px;
  &:hover {
    background-color: ${colorBgElevated};
    cursor: pointer;
  }
  flex-direction: row;
`;
