import {
  Button,
  CenterRow,
  Col,
  Row,
  SegmentControl,
  Txt,
} from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import { globalToken } from '@aries/ui-theming';
import { animated, useSpring } from '@react-spring/native';
import React, { useState } from 'react';
import styled from 'styled-components/native';
import { InputNumber } from '~/components';

export const TransactionSettings: React.FC<{
  currentValue: number;
  onChange: (value: number) => void;
}> = ({ currentValue, onChange }) => {
  const { t } = useTranslation();
  const [slippage, setSlippage] = useState(currentValue);
  return (
    <Col pd={16} pt={8}>
      <Txt h2 c2 mb={8}>
        {t`Slippage Tolerance`}
      </Txt>
      <Row mb={10}>
        <SegmentControl
          options={[0.5, 1, 2]}
          renderOption={v => `${v}%`}
          value={slippage}
          onChange={v => setSlippage(v)}
        />
      </Row>
      <InputNumber
        valueType="number"
        value={slippage}
        onChange={setSlippage}
        placeholder={t`Custom`}
        mb={10}
      />
      <Button
        type="primary"
        onPress={() => {
          onChange(slippage);
        }}
        title={t`Confirm`}
      />
    </Col>
  );
};

const {
  marginSM,
  borderRadiusLG,
  colorBgContainer,
  colorBgElevated,
  colorBgBase,
  colorBgSpotlight,
  colorInteractive,
  borderRadiusSM,
} = globalToken;

export const SwapCard = styled(Col)`
  padding: ${marginSM}px;
  margin-bottom: ${marginSM}px;
  border-radius: ${borderRadiusLG}px;
  background-color: ${colorBgContainer};
  border-width: 1px;
  border-style: solid;
  border-color: ${colorBgElevated};
`;

export const SwapIconBox = styled.TouchableOpacity`
  width: 48px;
  height: 48px;
  background-color: ${colorBgBase};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: -${marginSM};
  align-self: center;
`;

export const SwapIcon = styled.View`
  width: 40px;
  height: 40px;
  background-color: ${colorBgSpotlight};
  border-width: 1px;
  border-style: solid;
  border-color: ${colorBgElevated};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const ResultCard: React.FC<{
  isVisible: boolean;
  height?: number;
}> = ({ isVisible, children, height }) => {
  const styles = useSpring({
    opacity: isVisible ? 1 : 0,
    padding: isVisible ? 16 : 0,
    marginBottom: isVisible ? 12 : 0,
    height: isVisible ? height ?? 'auto' : 0,
  });

  return (
    <AnimatedContainer style={styles}>
      {isVisible ? children : null}
    </AnimatedContainer>
  );
};

export const AnimatedContainer = styled(animated.View)`
  border-radius: 8px;
  display: flex;
  background-color: ${colorBgContainer};
  flex-direction: column;
  border: 1px solid ${colorBgElevated};
`;

export const RouteCard = styled(CenterRow)<{ isActive?: boolean }>`
  border-radius: ${borderRadiusSM}px;
  background-color: ${colorBgSpotlight};
  padding: 12px;
  margin-bottom: 8px;
  border-width: 1px;
  border-color: ${p => (p.isActive ? colorInteractive : colorBgSpotlight)};
  position: relative;
`;
