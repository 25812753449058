import {
  CenterRow,
  createIcon,
  Input,
} from '@aries/mobile-core/components';
import React from 'react';

const SearchIcon = createIcon(require('./search.png'));

export const Search: React.FunctionComponent<{
  value: string;
  onChange: (v: string) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder }) => {
  return (
    <Input
      leftComponent={
        <CenterRow mr={8}>
          <SearchIcon size={23} />
        </CenterRow>
      }
      value={value}
      onChangeText={onChange}
      placeholder={placeholder ?? 'Search Token'}
      mb={12}
    />
  );
};
