import { ScrollView } from '@aries/mobile-core/components';
import React from 'react';
import { FirstPageWrapper } from '~/components';
import { UserSummary } from './UserSummary';

export const Account: React.FunctionComponent = () => {
  return (
    <FirstPageWrapper>
      <ScrollView>
        <UserSummary />
      </ScrollView>
    </FirstPageWrapper>
  );
};
