import { useBorrow } from '@aries/lending-fe-hooks';
import {
  CenterRow,
  Col,
  ScrollView,
  Txt,
  useTabViewCtx,
} from '@aries/mobile-core/components';
import { NotificationBase } from '@aries/mobile-core/Smoothy';
import { useTranslation } from '@aries/shared/locale';
import Big from 'big.js';
import React, { FC, ReactNode } from 'react';
import { TokenInput } from '~/components';
import {
  ConfirmButton,
  Content,
  ExtraConfig,
  MaxValueRow,
} from './common';

export const BorrowPanel: FC<{
  reserveId: string;
  onClose: () => void;
  footerComponents: ReactNode;
}> = ({ onClose, reserveId, footerComponents }) => {
  const { t } = useTranslation();
  const borrow = useBorrow(reserveId);
  const { curPage } = useTabViewCtx();

  if (!borrow) return null;

  const {
    reserve,
    amount,
    shouldWithdrawFirst,
    max,
    loading,
    disableInput,
    disableConfirm,
    onChange,
    onConfirm,
  } = borrow;

  return (
    <Content>
      <ScrollView>
        <Col pb={12}>
          {shouldWithdrawFirst && <WithdrawNotification />}

          <TokenInput
            tokenInfo={reserve.asset}
            value={amount}
            onAmountChange={v => onChange(v)}
            maxAmount={Big(max)}
          >
            <CenterRow>
              <TokenInput.Input
                focused={curPage === 2}
                disabled={disableInput}
                style={{ width: '100%' }}
              />
            </CenterRow>

            <MaxValueRow
              maxTitle={t`Available`}
              max={max}
              onSetValue={onChange}
              asset={reserve.asset}
            />

            <TokenInput.Slider />
          </TokenInput>

          <ExtraConfig
            reserveId={reserve.id}
            items={[
              {
                label: t`APY`,
                value: (
                  <Txt
                    h3
                    color={
                      reserve.borrow.totalApy.isPositive
                        ? 'Text/Critical'
                        : 'Text/Success'
                    }
                  >
                    {reserve.borrow.totalApy.base}
                  </Txt>
                ),
              },
              {
                label: t`Your Margin`,
                value: (
                  <Txt h3>
                    {reserve.summary.ofUser?.totalMarginValueUSD ?? '-'}
                  </Txt>
                ),
              },
              {
                label: t`LTV`,
                value: <Txt h3>{reserve.meta.loanToValue}</Txt>,
              },
              {
                label: t`Liquidation Threshold`,
                value: <Txt h3>{reserve.meta.liquidationThreshold}</Txt>,
              },
            ]}
          />
          {footerComponents}
        </Col>
      </ScrollView>
      <ConfirmButton
        onClick={async () => {
          const success = await onConfirm();
          success && onClose();
        }}
        loading={loading}
        disabled={disableConfirm}
        reason={
          shouldWithdrawFirst
            ? t`Please withdraw you deposit first`
            : t`Please input a valid number`
        }
        title={t`Borrow`}
      />
    </Content>
  );
};

const WithdrawNotification = () => {
  const { t } = useTranslation();
  const { changeTab } = useTabViewCtx();

  return (
    <NotificationBase
      type="warning"
      content={
        <Txt h1>
          {t`You should`}{' '}
          <Txt
            h1
            onPress={() => {
              changeTab(1);
            }}
            style={{ textDecorationLine: 'underline' }}
          >
            {t`withdraw all your deposit`}
          </Txt>{' '}
          {t`to borrow`}
        </Txt>
      }
      style={{ marginBottom: 12 }}
    />
  );
};
