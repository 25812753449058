import { colorConfig, useThemeMode } from '@aries/ui-theming';
import { useState } from 'react';
import DropDownPickerImpl, {
  ThemeNameType,
  ValueType,
  type DropDownPickerProps,
} from 'react-native-dropdown-picker';

export const DropDownPicker = <T extends ValueType>({
  value,
  setValue,
  items,
  setItems,
  multiple,
  ...rest
}: Omit<DropDownPickerProps<T>, 'open' | 'setOpen'>) => {
  const [open, setOpen] = useState(false);
  const { mode } = useThemeMode();

  return (
    // @ts-ignore
    <DropDownPickerImpl
      open={open}
      setOpen={setOpen}
      value={value}
      items={items}
      setValue={setValue}
      setItems={setItems}
      multiple={multiple}
      theme={mode.toUpperCase() as ThemeNameType}
      containerStyle={{
        marginTop: 16,
      }}
      style={{
        borderColor: open
          ? colorConfig.Input?.colorInputBorderActive?.[mode]
          : 'transparent',
        backgroundColor: colorConfig.Input?.colorInputBg?.[mode],
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      }}
      labelStyle={{
        color: colorConfig.colorText[mode],
      }}
      dropDownContainerStyle={{ top: 56 }}
      listItemContainerStyle={{
        backgroundColor: colorConfig.colorBgBase?.[mode],
      }}
      listItemLabelStyle={{
        color: colorConfig.colorText[mode],
      }}
      selectedItemContainerStyle={{
        backgroundColor: colorConfig?.Input?.colorInputBg?.[mode],
      }}
      disableBorderRadius={false}
      {...rest}
    />
  );
};
