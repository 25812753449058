/* eslint-disable  */
// PopoverPicker.js

import { RefObject } from 'react';
import { View } from 'react-native';

import Overlay from '../Overlay/Overlay';
import PopoverPickerView from './PopoverPickerView';

export interface PopoverPickerItem {
  title: string;
  onPress: () => void;
}

// @ts-ignore
export class PopoverPicker extends Overlay {
  static PopoverPickerView = PopoverPickerView;

  // fromBounds shape: x, y, width, height
  // items: array of string
  static show(
    fromView: RefObject<View>,
    items: PopoverPickerItem[],
    options = {},
  ) {
    const onSelected = (item: string) => {
      const index = items.findIndex(i => i.title === item);
      if (index > -1) {
        items[index].onPress?.();
      }
    };

    fromView.current?.measureInWindow((x, y, width, height) => {
      return super.show(
        <this.PopoverPickerView
          fromBounds={{ x, y, width, height }}
          items={items.map(item => item.title)}
          selectedIndex={undefined}
          onSelected={onSelected}
          {...options}
        />,
      );
    });
  }
}
