import { Input, InputProps } from '@aries/mobile-core/components';
import { useParseNumberInput, type NumberType } from '@aries/shared/hooks';
import Big from 'big.js';
import React from 'react';

export type InputNumberProps<T extends NumberType> = {
  value: T | undefined;
  onChange: (input: T) => void;
  min?: T;
  max?: T;
  valueType: 'number' | 'big';
} & Omit<InputProps, 'value' | 'onChangeText'>;

/** Limitation: only supports non-negative number for now */
export function InputNumber<T extends number | Big>({
  value,
  onChange: handleChange,
  max,
  min,
  valueType,
  ...inputProps
}: InputNumberProps<T>) {
  const { value: strValue, onChange: onStrChange } = useParseNumberInput({
    value,
    onChange: handleChange,
    min,
    max,
    valueType,
  } as any);

  return (
    <Input value={strValue} onChangeText={onStrChange} {...inputProps} />
  );
}
