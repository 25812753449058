// eslint-disable-next-line import/no-unresolved
import '@aries/mobile-core/initialize';
import { registerRootComponent } from 'expo';
import App from './src/App.tsx';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

const SHOW_STORYBOOK = false;

if (__DEV__ && SHOW_STORYBOOK) {
  const { StorybookUIRoot } = require('./storybook');
  registerRootComponent(StorybookUIRoot);
} else {
  registerRootComponent(App);
}
