import { getProviderHub } from '@aries/solana-defi/common';
import * as BufferLayout from '@solana/buffer-layout';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { LendingInstructionKey, toAccessKeys } from './config';

export const initProfileIx = ({
  marketId,
  profileId,
  walletId,
}: {
  marketId: PublicKey;
  profileId: PublicKey;
  walletId: PublicKey;
}) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
  ]);
  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    { instruction: LendingInstructionKey.InitObligation },
    data,
  );

  return new TransactionInstruction({
    keys: toAccessKeys([
      { key: profileId, type: 'write' },
      { key: marketId, type: 'read' },
      { key: walletId, type: 'signer' },
      { key: SYSVAR_CLOCK_PUBKEY, type: 'read' },
      { key: SYSVAR_RENT_PUBKEY, type: 'read' },
      { key: TOKEN_PROGRAM_ID, type: 'read' },
    ]),
    data,
    programId: getProviderHub()!.currentNetwork.programId,
  });
};
