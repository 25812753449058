// ActionPopover.js
/* eslint-disable  */
import { RefObject } from 'react';
import { Vibration, View } from 'react-native';

import Overlay from '../Overlay/Overlay';
import ActionPopoverView from './ActionPopoverView';

export interface ActionPopoverItem {
  title: string;
  onPress: () => void;
  disabled?: boolean;
}

// @ts-ignore
export class ActionPopover extends Overlay {
  static ActionPopoverView = ActionPopoverView;

  // fromBounds shape: x, y, width, height
  // items shape
  //   title: PropTypes.string.isRequired,
  //   onPress: PropTypes.func,
  static show(
    fromView: RefObject<View>,
    items: ActionPopoverItem[],
    options = {},
  ) {
    if (!fromView.current) {
      // eslint-disable-next-line no-console
      console.error('ActionPopover：实例不存在');
    }
    fromView.current?.measure((_x, _y, width, height, Px, Py) => {
      Vibration.vibrate(200);
      super.show(
        <this.ActionPopoverView
          fromBounds={{ x: Px, y: Py, width, height }}
          items={items}
          {...options}
        />,
      );
    });
  }
}
