import { Txt } from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import { isString } from 'lodash';
import React from 'react';
import { ViewStyle } from 'react-native';
import styled from 'styled-components/native';

const { borderRadiusLG, colorBgContainer, colorBgSpotlight } = globalToken;
export const GhostTag: React.FC<{
  style?: ViewStyle;
  label: string | React.ReactNode;
  color?: string;
}> = ({ style, label, color }) => {
  return (
    <ItemContent style={style} color={color}>
      {isString(label) ? <Txt color={color}>{label}</Txt> : label}
    </ItemContent>
  );
};

const ItemContent = styled.View<{ color?: string }>`
  padding: 8px 12px;
  border-radius: ${borderRadiusLG};
  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.color ?? colorBgSpotlight};
`;

export const Tag: React.FC<{
  style?: ViewStyle;
  label: string | React.ReactNode;
  color?: string;
  bckColor?: string;
}> = ({ style, label, color, bckColor }) => {
  return (
    <GhostContent style={style} bckColor={bckColor}>
      {isString(label) ? <Txt color={color}>{label}</Txt> : label}
    </GhostContent>
  );
};

const GhostContent = styled.View<{ bckColor?: string }>`
  padding: 4px 8px;
  border-radius: 100px;
  background-color: ${p => p.bckColor ?? colorBgContainer};
`;
