/* eslint-disable @typescript-eslint/no-unused-vars */
import { getMax, getMin } from '@aries/defi-toolkit/utils';
import { createGlobalStore } from '@aries/shared/deps';
import { ReserveInfo } from '@port.finance/port-sdk';
import Big from 'big.js';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useReservesSubscription } from './sub';
import { ReserveNotifyMap } from '@aries/solana-defi/config';
import { useLendingMarkets } from '../../market';

export * from './sub';

const U64_MAX = Big(2).pow(64).sub(1);

export const [useReserves, getReserves] = createGlobalStore(() => {
  const { activeMarketId } = useLendingMarkets();
  const { results: reserves, isLoading: loading } =
    useReservesSubscription();

  return useMemo(() => {
    const reserveDetails = reserves.map(({ value, pubkey }) => ({
      coinAddress: value.getAssetMintId().toString(),
      rawReserve: value,
      marketId: value.marketId.toBase58(),
      reserveId: pubkey.toString(),
      lpCoinAddress: value.getShareMintId().toBase58(),
      notify: ReserveNotifyMap[activeMarketId]?.[pubkey.toString()],
      ...getReserveDetail(value),
    }));

    const reserveMap = keyBy(reserveDetails, r => r.reserveId);

    const getReserveSDK = (reserveId: string): ReserveInfo | undefined => {
      return reserveMap[reserveId]?.rawReserve;
    };

    return {
      reserves: reserveDetails,
      reserveMap,
      getReserveSDK,
      refresh: () => {},
      loading,
    };
  }, [reserves, loading, activeMarketId]);
});

const getReserveDetail = (reserve: ReserveInfo) => {
  const totalIssuedToken = reserve.share
    .getIssuedShare()
    .getAmount()
    .getRaw();
  const totalCashAvailable = reserve.asset
    .getAvailableAsset()
    .getAmount()
    .getRaw();
  const totalBorrowedShare = reserve.asset
    .getBorrowedAsset()
    .getAmount()
    .getRaw();

  const loanToValue =
    reserve.params.loanToValueRatio.toHundredBasedNumber(6);
  const liquidationThreshold =
    reserve.params.liquidationThreshold.toHundredBasedNumber(6);
  const borrowFeePct = reserve.params.borrowFee.getRaw();
  const depositLimit = Big(0);
  const borrowLimit = Big(0);
  const liquidationBonus = reserve.params.liquidationPenalty
    .multiply(100)
    .toHundredBasedNumber(6);

  // Total
  const totalBorrowed = reserve.getBorrowedAsset().getAmount().getRaw();
  const totalDeposited = totalCashAvailable.add(totalBorrowed);
  const totalAsset = reserve
    .getMarketCap()
    .getAsset()
    .getAmount()
    .getRaw();

  const {
    maxBorrowRate,
    minBorrowRate,
    optimalBorrowRate,
    optimalUtilizationRatio,
  } = reserve.params;

  // Need to minus reserved profit of platform
  const availableLiquidity = totalCashAvailable.mul(0.97);

  // Ratio
  const loanToValueRatio = loanToValue / 100;
  const liquidationThresholdRatio = liquidationThreshold / 100;

  const shareToAssetExchangeRatio = totalIssuedToken.eq(0)
    ? Big(0)
    : totalAsset.div(totalIssuedToken);

  const shareToBorrowExchangeRatio = totalBorrowedShare.eq(0)
    ? Big(1)
    : totalBorrowed.div(totalBorrowedShare);

  // Some limit
  const maxDepositableLamports = getMax(
    Big(0),
    depositLimit.minus(totalDeposited),
  );

  const maxBorrowableLamports = getMin(
    getMax(
      Big(0),
      (borrowLimit.eq(0) ? U64_MAX : borrowLimit).minus(totalBorrowed),
    ),
    availableLiquidity,
  );

  const maxWithdrableLamports = reserve
    .getAvailableAsset()
    .getAmount()
    .getRaw();

  const shareToBorrow = (share: Big) => {
    return share.mul(shareToBorrowExchangeRatio);
  };

  const shareToDeposit = (share: Big) => {
    return share.mul(shareToAssetExchangeRatio);
  };

  const depositToShare = (deposit: Big) => {
    return shareToAssetExchangeRatio.eq(0)
      ? deposit
      : deposit.div(shareToAssetExchangeRatio);
  };

  const depositToMargin = (depositedValueUSD: number) => {
    return depositedValueUSD * loanToValueRatio;
  };

  const depositToCollateralThreshold = (depositedValueUSD: number) => {
    return depositedValueUSD * liquidationThresholdRatio;
  };

  return {
    borrowApyPct:
      reserve.getBorrowApy().getPct()?.getRaw().mul(100) ?? Big(0),
    supplyApyPct:
      reserve.getSupplyApy().getPct()?.getRaw().mul(100) ?? Big(0),
    borrowFeePct,
    // Todo
    flashLoanFeePct: Big(0)
      .div(10 ** 4)
      .toNumber(),
    shareToDeposit,
    shareToBorrow,
    depositToMargin,
    depositToCollateralThreshold,
    liquidationThresholdRatio,
    loanToValueRatio,
    marketCap: totalAsset,
    totalBorrowed,
    totalDeposited,
    reserveConfig: {
      borrowFeeHundredthBips: Big(0),
      liquidationBonusBips: Big(liquidationBonus),
      liquidationThreshold,
      borrowLimit,
      depositLimit,
      allowCollateral: true,
      allowRedeem: true,
      loanToValue,
    },
    maxDepositableLamports,
    maxBorrowableLamports,
    maxWithdrableLamports,
    availableLiquidity,
    liquidationBonusPct: liquidationBonus,
    optimalInterestRatePct:
      optimalBorrowRate.getPct()?.toHundredBasedNumber(6) ?? 0,
    optimalUtilizationPct:
      optimalUtilizationRatio.getPct()?.toHundredBasedNumber(6) ?? 0,
    maxInterestRatePct:
      maxBorrowRate.getPct()?.toHundredBasedNumber(6) ?? 0,
    minInterestRatePct:
      minBorrowRate.getPct()?.toHundredBasedNumber(6) ?? 0,
    depositToShare,
    utilization:
      reserve
        .getUtilizationRatio()
        .getPct()
        ?.getRaw()
        .mul(100)
        .toNumber() ?? 0,
  };
};

export type Reserve = ReturnType<typeof useReserves>['reserves'][number];
