import { keyBy } from 'lodash';
import useSWR from 'swr';
import { BaseAsset, EMPTY_ASSET } from '../types';

export const useTokenBaseInfo = (fetcher: () => Promise<BaseAsset[]>) => {
  const { data: map = {}, mutate } = useSWR(
    ['TokenBaseInfoMap'],
    async () => {
      const list = await fetcher();
      return keyBy(list, a => a.id);
    },
  );

  const refresh = () => {
    mutate(v => v, true);
  };

  return {
    tokenMap: new Proxy(map, {
      get: (target, key) => {
        return target[key as string] ?? EMPTY_ASSET;
      },
    }),
    emptyableTokenMap: map,
    refresh,
  };
};
