import { useCallback, useState } from 'react';
import { SelectableProps } from './types';

export const getDefaultDataKey = (data: unknown) => String(data);

export function useSelectableKey<T extends any[]>({
  value,
  defaultValue,
  keyExtractor = getDefaultDataKey,
  onChange: handleChange,
}: SelectableProps<T>): [string, (data: T, key?: string) => void] {
  const selectedKeyProp = value && keyExtractor(value);
  const defaultKey = defaultValue && keyExtractor(defaultValue);
  const [selectedKeyState, setKey] = useState<string>(defaultKey ?? '');
  const selectedKey = selectedKeyProp ?? selectedKeyState;

  const onChange = useCallback(
    (data: T, key?: string) => {
      if (handleChange && selectedKey !== key) {
        handleChange(data);
      }
      setKey(key ?? keyExtractor(data));
    },
    [handleChange, keyExtractor, selectedKey],
  );

  return [selectedKey, onChange];
}
