import { createGlobalStore } from '@aries/shared/deps';
import { OrderBookAppDefine } from '@aries/trading-fe-hooks/adapter';
import { useProviderHub } from '../common/index';
import {
  SwapProvider,
  useSwapByInitial,
  useSwapContext,
} from '../swap/index';
import { useOpenbookTrading } from './providers/openbook/index';

/**
 * Orderbook app impl on aptos blockchain
 */
const useSolanaTradingAppImpl: () => OrderBookAppDefine = () => {
  const trading = useOpenbookTrading();
  const { env } = useProviderHub();

  return {
    ...trading,
    useSwapByInitial,
    useSwapContext,
    SwapProvider,
    env,
  };
};

export const [useSolanaTradingApp, getSolanaTradingApp] =
  createGlobalStore(useSolanaTradingAppImpl);
