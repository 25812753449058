import {
  EMPTY_TOKEN,
  useBalanceHub,
  useTokenInfoHub,
} from '@aries/solana-defi/common';
import { TradingProvider } from '../type';
import { getMarketActions } from './actions';
import { EMPTY_BALANCE } from '@aries/solana-defi/../defi-toolkit/types';
import { EMPTY_MARKET } from '@aries/trading-fe-hooks';
import Big from 'big.js';
import { compact, flatten, noop, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useAllOrderBook, useUserAccounts } from './data';
import { IconOpenbook } from '@aries/solana-defi/common/assets/dex-icons';

export const useOpenbookTrading = (): TradingProvider => {
  const { tokenMap } = useTokenInfoHub();
  const { balanceMap } = useBalanceHub();

  const {
    orderbookList,
    currentMarketId,
    changeMarket,
    refreshCurrentMarket,
    marketLoading,
  } = useAllOrderBook();

  const {
    accountByMarket,
    getAccountByMarket,
    loading: accountLoading,
    refresh: refreshUserAccount,
  } = useUserAccounts();

  const markets = useMemo(() => {
    return compact(
      orderbookList.map(
        ({
          marketId,
          baseCoinAddress,
          quoteCoinAddress,
          programId,
          value,
        }) => {
          const baseAsset = tokenMap[baseCoinAddress] ?? EMPTY_TOKEN;
          const quoteAsset = tokenMap[quoteCoinAddress] ?? EMPTY_TOKEN;
          const marketInfo = {
            baseCoinType: baseCoinAddress,
            quoteCoinType: quoteCoinAddress,
            marketId,
          };
          if (!value) {
            return baseAsset.id && quoteAsset.id
              ? {
                  ...EMPTY_MARKET,
                  id: marketId,
                  marketInfo,
                  platformLogo: IconOpenbook,
                  baseAsset,
                  quoteAsset,
                }
              : null;
          }
          const { asks, bids, tradings, maxBidLamports, minAskLamports } =
            value;

          const baseAssetFromWallet =
            balanceMap[baseCoinAddress]?.lamports ?? Big(0);
          const quoteAssetFromWallet =
            balanceMap[quoteCoinAddress]?.lamports ?? Big(0);

          const actions = getMarketActions({
            marketId,
            programId: programId.toString(),
            baseMint: value.rawMarket.baseMintAddress,
            quoteMint: value.rawMarket.quoteMintAddress,
          });
          const maybeAccount = getAccountByMarket(marketInfo);

          const toBaseBalance = (amount: Big) =>
            baseAsset.lamportsToBalance(amount);
          const toQuoteBalance = (amount: Big) =>
            quoteAsset.lamportsToBalance(amount);

          const userAccount = {
            hasWithdrawableFunds: maybeAccount?.canWithdraw ?? false,
            baseCollateral: {
              total: toBaseBalance(maybeAccount?.baseTotal ?? Big(0)),
              withdrawable: toBaseBalance(
                maybeAccount?.baseAvailable ?? Big(0),
              ),
              available: toBaseBalance(
                baseAssetFromWallet.plus(
                  maybeAccount?.baseAvailable ?? Big(0),
                ),
              ),
            },
            quoteCollateral: {
              total: toQuoteBalance(maybeAccount?.quoteTotal ?? Big(0)),
              withdrawable: toQuoteBalance(
                maybeAccount?.quoteAvailable ?? Big(0),
              ),
              available: toQuoteBalance(
                quoteAssetFromWallet.plus(
                  maybeAccount?.quoteAvailable ?? Big(0),
                ),
              ),
            },
            orders: [
              ...compact(
                maybeAccount?.asks.map(v => {
                  const { price: priceLamports, size: lamports } = v;
                  if (priceLamports === 0) {
                    return null;
                  }
                  const amount = Big(lamports);
                  const price = Big(priceLamports);

                  return {
                    id: v.orderId.toString(),
                    type: 'ask' as const,
                    amount: baseAsset.formatAmount(lamports),
                    price: `${price.toNumber()} ${quoteAsset.symbol}`,
                    total: `${quoteAsset.formatAmount(
                      price.mul(amount).toNumber(),
                    )}`,
                    cancel: async () => {
                      return actions.cancelLimitOrder(v);
                    },
                  };
                }),
              ),
              ...compact(
                maybeAccount?.bids.map(v => {
                  const { price: priceLamports, size: lamports } = v;
                  if (priceLamports === 0) {
                    return null;
                  }
                  const amount = Big(lamports);
                  const price = Big(priceLamports);

                  return {
                    id: v.orderId.toString(),
                    type: 'bid' as const,
                    amount: baseAsset.formatAmount(lamports),
                    price: `${price.toNumber()} ${quoteAsset.symbol}`,
                    total: `${quoteAsset.formatAmount(
                      price.mul(amount).toNumber(),
                    )}`,
                    cancel: async () => {
                      return actions.cancelLimitOrder(v);
                    },
                  };
                }),
              ),
            ],
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            addBaseColateral: (amount: number) => Promise.resolve(true),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            withdrawBaseColateral: (amount: number) =>
              actions.withdrawCollateral(),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            addQuoteColateral: (amount: number) => Promise.resolve(true),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            withdrawQuoteColateral: (amount: number) =>
              actions.withdrawCollateral(),
            withdrawAllFunds: async () =>
              maybeAccount?.canWithdraw
                ? actions.withdrawAllAsset()
                : true,
          };

          return {
            id: marketId,
            marketInfo,
            platformLogo: IconOpenbook,
            baseAsset: {
              ...baseAsset,
              id: baseCoinAddress,
              priceUSD: `$${baseAsset.price.toFixed(6)}`,
              priceUSDNum: baseAsset.price.toNumber(),
            },
            quoteAsset: {
              ...quoteAsset,
              priceUSD: `$${quoteAsset.price.toFixed(6)}`,
              id: quoteCoinAddress,
              priceUSDNum: quoteAsset.price.toNumber(),
            },
            baseFromWallet: toBaseBalance(baseAssetFromWallet),
            quoteFromWallet: toQuoteBalance(quoteAssetFromWallet),
            userAccount,
            hasWithdrawableFunds: !!userAccount?.hasWithdrawableFunds,
            orderbook: {
              marketId,
              bids: bids.map(bid => {
                return {
                  amount: bid.lamports.toNumber(),
                  total: bid.totalLamports.toNumber(),
                  depthPct: bid.depthPct,
                  price:
                    quoteAsset
                      .formatAmount(bid.priceNum)
                      .split(' ')?.[0] ?? '',
                  priceNum: bid.priceNum,
                };
              }),
              asks: asks.map(ask => {
                return {
                  amount: ask.lamports.toNumber(),
                  total: ask.totalLamports.toNumber(),
                  depthPct: ask.depthPct,
                  price:
                    quoteAsset
                      .formatAmount(ask.priceNum)
                      .split(' ')?.[0] ?? '',
                  priceNum: ask.priceNum,
                };
              }),
              maxBid: maxBidLamports.toString(),
              maxBidNum: maxBidLamports.toNumber(),
              minAsk: minAskLamports.toString(),
              minAskNum: minAskLamports.toNumber(),
            },
            marketTradings: tradings.map(t => ({
              id: t.id,
              price: t.priceLamports.toNumber().toFixed(2),
              amount: t.lamports.toNumber().toFixed(2),
              isBid: t.isBid,
            })),
            registerMarketAccount: () =>
              Promise.resolve(true) as Promise<boolean>,
            actions,
            placeLimitOrder: (
              direction: 'buy' | 'sell',
              amount: number,
              price: number,
            ) => {
              return actions.placeLimitOrder(
                direction === 'buy' ? 'bid' : 'ask',
                Number(amount.toFixed(baseAsset.decimals)),
                Number(price.toFixed(quoteAsset.decimals)),
              );
            },
            fillMarketOrder: () =>
              Promise.resolve(true) as Promise<boolean>,
          };
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderbookList, tokenMap, accountByMarket, balanceMap]);

  const currentMarket = useMemo(
    () =>
      markets.find(
        m => m.marketInfo.marketId.toString() === currentMarketId,
      ),
    [currentMarketId, markets],
  );

  const totalOrders = useMemo(() => {
    return sortBy(
      flatten(
        compact(
          Object.values(markets).map(m =>
            m.userAccount?.orders.map(o => ({
              ...o,
              baseAsset: m.baseAsset,
              quoteAsset: m.quoteAsset,
            })),
          ),
        ),
      ),
      a =>
        a.baseAsset.symbol === currentMarket?.baseAsset.symbol &&
        a.quoteAsset.symbol === currentMarket?.quoteAsset.symbol
          ? -1
          : 1,
    );
  }, [
    currentMarket?.baseAsset.symbol,
    currentMarket?.quoteAsset.symbol,
    markets,
  ]);

  return {
    // @ts-ignore
    markets,
    // @ts-ignore
    currentMarket,
    currentMarketId,
    changeMarket,
    refreshCurrentMarket,
    totalOrders,
    accountLoading,
    setInitialTradingPair: noop,
    // @ts-ignore
    refreshUserAccount,
    tradingTokens: currentMarket
      ? [
          {
            asset: currentMarket.baseAsset,
            collateral: currentMarket.userAccount.baseCollateral,
            addColateral: currentMarket.userAccount.addBaseColateral,
            withdrawColateral:
              currentMarket.userAccount.withdrawBaseColateral,
            wallet: currentMarket.baseFromWallet ?? EMPTY_BALANCE,
            withdrawable:
              currentMarket.userAccount.baseCollateral.withdrawable,
            depositable: currentMarket.baseFromWallet ?? EMPTY_BALANCE,
          },
          {
            asset: currentMarket.quoteAsset,
            collateral: currentMarket.userAccount.quoteCollateral,
            addColateral: currentMarket.userAccount.addQuoteColateral,
            withdrawColateral:
              currentMarket.userAccount.withdrawQuoteColateral,
            wallet: currentMarket.quoteFromWallet ?? EMPTY_BALANCE,
            withdrawable:
              currentMarket.userAccount.quoteCollateral.withdrawable,
            depositable: currentMarket.quoteFromWallet ?? EMPTY_BALANCE,
          },
        ]
      : [],
    marketLoading,
    marketEmpty: !marketLoading && !orderbookList.length,
    setEnableTradingRefresh: noop,
  };
};
