import Big from 'big.js';
import { get, isArray, isNil } from 'lodash';

export * from './log';

const U128_MAX = Big(2).pow(128).sub(1);
export const getMax = (...params: Big[]) => {
  let max = Big(U128_MAX.mul(-1));
  params.forEach(num => {
    if (num.gt(max)) {
      max = num;
    }
  });

  return max;
};

export const getMin = (...params: Big[]) => {
  let min = Big(U128_MAX);
  params.forEach(num => {
    if (num.lt(min)) {
      min = num;
    }
  });

  return min;
};

export const isBig = (n: any): n is Big =>
  !isNil(get(n, 's')) && !isNil(get(n, 'e')) && isArray(get(n, 'c'));
