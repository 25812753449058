import { useDeferredState } from '@aries/shared/utils/index';
import { useProviderHub } from '@aries/solana-defi/common/provider';
import { Cluster, Connection, PublicKey } from '@solana/web3.js';
import {
  AggregatorState,
  SwitchboardAccountType,
} from '@switchboard-xyz/switchboard-api';
import {
  AggregatorAccount,
  loadSwitchboardProgram,
  SwitchboardProgram,
} from '@switchboard-xyz/switchboard-v2';
import { useCallback, useEffect, useMemo } from 'react';

export const useSwitchBoardPrices = (watchedSbPubkeys: PublicKey[]) => {
  const { provider, currentNetwork } = useProviderHub();
  const conn = provider.connection;

  const keyDiff = useMemo(
    () => watchedSbPubkeys.map(r => r.toBase58()).join(),
    [watchedSbPubkeys],
  );

  // AssetMintId -> oracle price
  const [sbPrices, setSbPrices] = useDeferredState<Record<string, number>>(
    {},
    15000,
  );

  // Sync with sbv1 & v2 prices, will request to the network
  const refreshSbPrices = useCallback(async () => {
    const { cluster } = currentNetwork;

    // Do not support testnet
    if (cluster === 'testnet') return;

    const sbv1Addr = new PublicKey(
      clusterToSwitchboardProgramKey[cluster],
    );

    const sbv2Addr = new PublicKey(
      clusterToSwitchboardProgramV2Key[cluster],
    );

    const sbv2Program = await loadSwitchboardProgram(cluster, conn);

    const prices = await Promise.all(
      watchedSbPubkeys.map(async oracleKey => {
        const addressOwner = (await conn.getParsedAccountInfo(oracleKey))
          .value?.owner;

        let price = 0;

        if (addressOwner?.equals(sbv1Addr)) {
          price = (await fetchAndParseSbv1Price(conn, oracleKey)) ?? 0;
        }

        if (addressOwner?.equals(sbv2Addr)) {
          price = (await fetchSbv2Price(sbv2Program, oracleKey)) ?? 0;
        }

        return [oracleKey.toString(), price] as const;
      }),
    );

    const values = Object.fromEntries(prices);
    setSbPrices(v => ({ ...v, ...values }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conn, keyDiff, currentNetwork.cluster]);

  useEffect(() => {
    refreshSbPrices();

    const timer = setInterval(refreshSbPrices, 15 * 1000);
    return () => clearInterval(timer);
  }, [refreshSbPrices]);

  return sbPrices;
};

/** Mapping from solana clusters to the public key of the Switch program. */
const clusterToSwitchboardProgramKey: Record<Cluster, string> = {
  'mainnet-beta': 'DtmE9D2CSB4L5D6A15mraeEjrGMm6auWVzgaD8hK2tZM',
  devnet: '7azgmy1pFXHikv36q1zZASvFq5vFa39TT9NweVugKKTU',
  testnet: '8tfDNiaEyrV6Q1U4DEXrEigs9DoDtkugzFbybENEbCDz',
};

/** Mapping from solana clusters to the public key of the Switch program. */
const clusterToSwitchboardProgramV2Key: Record<Cluster, string> = {
  'mainnet-beta': 'SW1TCH7qEPTdLsDHRgPuMQjbQxKdH2aBStViMFnt64f',
  devnet: '2TfB33aLaneQb5TNVwyDz3jSZXS6jdW2ARw1Dgf84XCG',
  testnet: '8tfDNiaEyrV6Q1U4DEXrEigs9DoDtkugzFbybENEbCDz',
};

const fetchAndParseSbv1Price = async (
  conn: Connection,
  addr: PublicKey,
) => {
  const accountInfo = await conn.getAccountInfo(addr);
  if (accountInfo === null) {
    throw Error(`cannot fetch account data at ${addr}`);
  }
  switch (accountInfo.data.at(0)) {
    case SwitchboardAccountType.TYPE_AGGREGATOR:
      return AggregatorState.decodeDelimited(accountInfo.data.slice(1))
        .lastRoundResult?.result;
    case SwitchboardAccountType.TYPE_AGGREGATOR_RESULT_PARSE_OPTIMIZED:
      // See: https://docs.rs/switchboard-utils/0.2.1/switchboard_utils/struct.FastRoundResultAccountData.html
      return accountInfo.data.readDoubleLE(1 + 32 + 4 + 4);
    default:
      throw Error('cannot match to any kind of Switchboard result');
  }
};

const fetchSbv2Price = async (
  program: SwitchboardProgram,
  aggregatorAddr: PublicKey,
) => {
  const aggAccount = new AggregatorAccount({
    program,
    publicKey: aggregatorAddr,
  });
  return (await aggAccount.getLatestValue())?.toNumber();
};
