import { NotifyType, useReserves } from '@aries/lending-fe-hooks';
import {
  CenterBetweenRow,
  CenterRow,
  Col,
  createIcon,
  EndCol,
  Input,
  Txt,
  View,
} from '@aries/mobile-core/components';
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
} from '@aries/mobile-core/Smoothy/components/notification/Icon';
import { useSearchList } from '@aries/shared/hooks';
import { useTranslation } from '@aries/shared/locale';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import { sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components/native';
import { IconSecondSearch, InfoItem, TokenIcon } from '~/components';
import { openReserveAction } from '~/services';
import { Card } from './Card';

const { marginXXS, marginXS, marginSM } = globalToken;
export const WarningIcon = createIcon(require('./assets/warning.svg'));

export const Assets: React.FC<{}> = () => {
  const { colorTextCritical, colorTextSuccess } = colorConfig;
  const { t } = useTranslation();
  const { reserves } = useReserves();
  const {
    search,
    setSearch,
    filteredList: renderList,
  } = useSearchList(reserves, item => [
    item.asset.name,
    item.asset.symbol,
  ]);
  const { mode } = useThemeMode();
  const getNotificationIcon = (type: NotifyType) => {
    switch (type) {
      case 'info':
        return InfoIcon;
      case 'success':
        return SuccessIcon;
      case 'error':
        return ErrorIcon;
      case 'warning':
        return WarningIcon;
      default:
        return () => null;
    }
  };

  const data = sortBy(renderList, a =>
    a.notify?.type === 'warning' ? 1 : -a.meta.loanToValuePct,
  ).map(detail => {
    return {
      id: detail.id,
      onPress: () => {
        openReserveAction(detail.asset.id);
      },
      icon: <TokenIcon asset={detail.asset} size={29} />,
      title: (
        <CenterBetweenRow style={{ width: '100%' }}>
          <Txt>{detail.asset.symbol}</Txt>
          {detail.notify &&
            (() => {
              const Icon = getNotificationIcon(detail.notify.type);
              return <Icon />;
            })()}
        </CenterBetweenRow>
      ),
      subTitle: detail.asset.priceUSD,
      tags: [],
      info: [
        { title: t`LTV:`, subTitle: detail.meta.loanToValue },
        {
          title: t`Deposit APY:`,
          subTitle: (
            <EndCol>
              <Txt
                h3
                color={
                  detail.deposit.totalApy.isPositive
                    ? 'colorTextSuccess'
                    : 'colorTextCritical'
                }
              >
                {detail.deposit.totalApy.base}
              </Txt>
              {detail.deposit.rewardApy && (
                <EndCol mt={8}>
                  <CenterRow gap={3}>
                    <Txt c2>{detail.deposit.baseApy.base}</Txt>
                    <Txt c1>+</Txt>
                    <Txt c2>{detail.deposit.rewardApy.base}</Txt>
                    <CenterRow gap={1}>
                      {detail.deposit.rewardAssets.map(a => (
                        <TokenIcon asset={a} size={20} key={a.id} />
                      ))}
                    </CenterRow>
                  </CenterRow>
                </EndCol>
              )}
            </EndCol>
          ),
          color: !detail.borrow.totalApy.isPositive
            ? colorTextCritical[mode]
            : colorTextSuccess[mode],
        },
        {
          title: t`Market Size:`,
          subTitle: detail.deposit.balance.valueUSD,
        },

        {
          title: t`Borrow APY:`,
          subTitle: detail.borrow.totalApy.base,
          color: detail.borrow.totalApy.isPositive
            ? colorTextCritical[mode]
            : colorTextSuccess[mode],
        },
        {
          title: t`Total Borrowed:`,
          subTitle: detail.borrow.balance.valueUSD,
        },
        {
          title: t`Your Deposits:`,
          subTitle: (
            <EndCol>
              <Txt h3>
                {`${detail.deposit.ofUser?.deposited?.amountNum?.toFixed(
                  2,
                )} ${detail.asset.symbol}`}
              </Txt>
              <Txt h2 c2>
                {detail.deposit.ofUser?.deposited?.valueUSD}
              </Txt>
            </EndCol>
          ),
          hidden: !detail.deposit.ofUser?.deposited?.amountNum,
        },
        {
          title: t`Your Loans:`,
          subTitle: (
            <EndCol>
              <Txt h3>
                {`${detail.borrow.ofUser?.borrowed?.amountNum?.toFixed(
                  2,
                )} ${detail.asset.symbol}`}
              </Txt>
              <Txt h2 c2>
                {detail.borrow.ofUser?.borrowed?.valueUSD}
              </Txt>
            </EndCol>
          ),
          hidden: !detail.borrow.ofUser?.borrowed?.amountNum,
        },
      ],
    };
  });

  return (
    <View>
      <Container>
        <Input
          leftComponent={
            <IconSecondSearch style={{ marginRight: marginXS }} />
          }
          value={search}
          onChangeText={v => {
            setSearch(v);
          }}
          placeholder={t`Search`}
          style={{ marginBottom: marginSM }}
        />
        {/* <Tags
          tags={[
            { label: '#Borrow', color: '#D571FF' },
            { label: '#Collateral', color: '#9271FF' },
          ]}
        /> */}
        {data.map(item => {
          const { title, subTitle, icon, tags, info, id } = item;
          return (
            <Card
              key={id}
              title={title}
              subTitle={subTitle}
              titleIcon={icon}
              titleTags={tags}
              onPress={item.onPress}
            >
              {info
                .filter(item => !item.hidden)
                .map((item, index) => {
                  return (
                    <InfoItem
                      key={item.title + index}
                      title={item.title}
                      subTitle={
                        <Txt color={item.color} bold>
                          {item.subTitle}
                        </Txt>
                      }
                    />
                  );
                })}
            </Card>
          );
        })}
      </Container>
    </View>
  );
};

const Container = styled(Col)`
  flex: 1;
  padding-top: ${marginXXS}px;
`;
