/* eslint-disable  */
/* eslint-disable */
// OverlayView.js

import { globalToken } from '@aries/ui-theming';
import { Component } from 'react';
import {
  Animated,
  PanResponder,
  Platform,
  StyleSheet,
  View,
} from 'react-native';

export default class OverlayView extends Component {
  static defaultProps = {
    modal: false,
    animated: false,
    overlayPointerEvents: 'auto',
    autoKeyboardInsets: false,
    closeOnHardwareBackPress: true,
  };

  constructor(props) {
    super(props);
    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (e, gestureState) => true,
      onPanResponderGrant: (e, gestureState) =>
        (this.touchStateID = gestureState.stateID),
      onPanResponderRelease: (e, gestureState) =>
        this.touchStateID == gestureState.stateID
          ? this.closeRequest()
          : null,
    });
    this.state = {
      overlayOpacity: new Animated.Value(0),
    };
  }

  UNSAFE_componentWillMount() {
    if (Platform.OS === 'android') {
      const BackHandler = BackHandler;
      this.backListener = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          if (this.props.closeOnHardwareBackPress) {
            this.closeRequest();
            return true;
          }
          return false;
        },
      );
    }
  }

  componentDidMount() {
    this.appearAfterMount && this.appear();
  }

  componentWillUnmount() {
    this.removeBackListener();
  }

  removeBackListener() {
    if (this.backListener) {
      this.backListener.remove();
      this.backListener = null;
    }
  }

  get overlayOpacity() {
    const { Overlay: overlayStyle } = globalToken;

    const { overlayOpacity } = this.props;
    return overlayOpacity || overlayOpacity === 0
      ? overlayOpacity
      : overlayStyle.overlayOpacity;
  }

  get appearAnimates() {
    const duration = 200;
    const animates = [
      Animated.timing(this.state.overlayOpacity, {
        toValue: this.overlayOpacity,
        duration,
        useNativeDriver: true,
      }),
    ];
    return animates;
  }

  get disappearAnimates() {
    const duration = 200;
    const animates = [
      Animated.timing(this.state.overlayOpacity, {
        toValue: 0,
        duration,
        useNativeDriver: true,
      }),
    ];
    return animates;
  }

  get appearAfterMount() {
    return true;
  }

  get overlayPointerEvents() {
    // override in Toast
    return this.props.overlayPointerEvents;
  }

  appear(animated = this.props.animated, additionAnimates = null) {
    if (animated) {
      this.state.overlayOpacity.setValue(0);
      Animated.parallel(
        this.appearAnimates.concat(additionAnimates),
      ).start(e => this.appearCompleted());
    } else {
      this.state.overlayOpacity.setValue(this.overlayOpacity);
      this.appearCompleted();
    }
  }

  disappear(animated = this.props.animated, additionAnimates = null) {
    if (animated) {
      Animated.parallel(
        this.disappearAnimates.concat(additionAnimates),
      ).start(e => this.disappearCompleted());
      this.state.overlayOpacity.addListener(e => {
        if (e.value < 0.01) {
          this.state.overlayOpacity.stopAnimation();
          this.state.overlayOpacity.removeAllListeners();
        }
      });
    } else {
      this.disappearCompleted();
    }
  }

  appearCompleted() {
    const { onAppearCompleted } = this.props;
    onAppearCompleted && onAppearCompleted();
  }

  disappearCompleted() {
    const { onDisappearCompleted } = this.props;
    onDisappearCompleted && onDisappearCompleted();
  }

  close(animated = this.props.animated) {
    if (this.closed) return true;
    this.closed = true;
    this.removeBackListener();
    this.disappear(animated);
    return true;
  }

  closeRequest() {
    const { modal, onCloseRequest } = this.props;
    if (onCloseRequest) onCloseRequest(this);
    else if (!modal) this.close();
  }

  buildStyle() {
    let { style } = this.props;
    style = [{ backgroundColor: 'rgba(0, 0, 0, 0)', flex: 1 }].concat(
      style,
    );
    return style;
  }

  renderContent() {
    return this.props.children;
  }

  render() {
    const { autoKeyboardInsets } = this.props;
    return (
      <View
        style={styles.screen}
        pointerEvents={this.overlayPointerEvents}
      >
        <Animated.View
          style={[
            styles.screen,
            {
              backgroundColor: '#000',
              opacity: this.state.overlayOpacity,
            },
          ]}
          {...this.panResponder.panHandlers}
        />
        <View style={this.buildStyle()} pointerEvents="box-none">
          {this.renderContent()}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  screen: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});
