import { Asset, Balance } from '@aries/defi-toolkit/types';
import {
  CenterRow,
  Col,
  EndCol,
  Txt,
} from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import React, { FC } from 'react';
import styled from 'styled-components/native';
import { TokenIcon } from '~/components';

export const Item: FC<{
  asset: Asset & {
    wallet?: Balance | undefined;
    deposit?: Balance | undefined;
    isCommon?: boolean | undefined;
  };
  onPress: () => void;
}> = ({ onPress, asset }) => {
  return (
    <Container onPress={onPress}>
      <CenterRow>
        <TokenIcon asset={asset} size={35} />
        <Col ml={12}>
          <Txt>{asset.symbol}</Txt>
          <Txt h1 c2>
            {asset.name}
          </Txt>
        </Col>
      </CenterRow>
      <EndCol>
        <Txt>{asset.wallet?.amountNum ?? '-'}</Txt>
      </EndCol>
    </Container>
  );
};

const { padding, colorBgSpotlight, borderRadiusLG, marginSM } =
  globalToken;
const Container = styled.TouchableOpacity`
  padding: ${padding}px;
  background-color: ${colorBgSpotlight};
  align-items: center;
  border-radius: ${borderRadiusLG}px;
  flex-direction: row;
  margin-bottom: ${marginSM}px;
  justify-content: space-between;
`;
