import { debounce, throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/**
 * create a function that always get latest closure variables.
 * @returns
 */
export const useStableCallback = <T extends (...params: any) => any>(
  fn: T,
) => {
  const stableFnRef = useRef(fn);
  stableFnRef.current = fn;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    ((...params): ReturnType<T> => stableFnRef.current(...params)) as T,
    [],
  );
};

export const useDebouncedValue = <T>(
  v: T,
  opt: { initValue?: T; timeout?: number },
) => {
  const [innerV, setV] = useState(opt?.initValue ?? v);

  const change = useMemo(
    () => debounce((newV: T) => setV(newV), opt?.timeout ?? 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    change(v);
  }, [v, change]);

  return innerV;
};

export const usePrevious = <T>(v: T) => {
  const lastVRef = useRef(v);
  useEffect(() => {
    lastVRef.current = v;
  }, [v]);

  return lastVRef.current;
};

export const useNow = (interval = 1000) => {
  const [ts, setTs] = useState(Date.now());

  useEffect(() => {
    const i = setInterval(() => {
      setTs(Date.now);
    }, interval);

    return () => clearInterval(i);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ts;
};

export function useMemoizedObject<T extends Record<string, any>>(obj: T) {
  const objItems = Object.values(obj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => obj, objItems);
}

export function useOverridableState<T>(
  initState: T,
  value?: T,
  setValue?: (v: T) => void,
): [T, (v: T) => void] {
  const [localValue, setLocalValue] = useState(initState);
  const overridenValue = value ?? localValue;
  const overridenSetValue = useCallback((v: T) => {
    if (setValue) {
      setValue(v);
    }
    setLocalValue(v);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [overridenValue, overridenSetValue];
}

export const useLatest = <T>(val: T) => {
  const ref = useRef(val);
  ref.current = val;

  return ref;
};

export function useDeferredState<T>(
  initState: T,
  minUpdateMillis: number,
) {
  type Upd = (oldState: T) => T;
  const [state, setStateInner] = useState<T>(initState);

  const pendingUpdates = useRef<Array<Upd>>([]);
  const applyUpdates = useStableCallback(
    (updates: typeof pendingUpdates['current']) => {
      setStateInner(current =>
        updates.reduce((acc, upd) => upd(acc), current),
      );
      while (updates.length) {
        updates.pop();
      }
    },
  );
  const throttledApplyUpdates = useMemo(
    () => throttle(applyUpdates, minUpdateMillis),
    [applyUpdates, minUpdateMillis],
  );

  const pushUpdate = useStableCallback((updateFn: (oldState: T) => T) => {
    pendingUpdates.current.push(updateFn);
    throttledApplyUpdates(pendingUpdates.current);
  });

  return [state, pushUpdate] as const;
}
