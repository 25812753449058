import { getProviderHub } from '@aries/solana-defi/common';
import * as BufferLayout from '@solana/buffer-layout';
import {
  PublicKey,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { StakingInstructionKey, toAccessKeys } from './config';

export const newStakingAccountIx = ({
  stakeAccountId,
  stakingPoolId,
  walletId,
}: {
  stakeAccountId: PublicKey;
  stakingPoolId: PublicKey;
  walletId: PublicKey;
}) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    { instruction: StakingInstructionKey.CreateStakeAccount },
    data,
  );

  return new TransactionInstruction({
    keys: toAccessKeys([
      { key: stakeAccountId, type: 'write' },
      { key: stakingPoolId, type: 'read' },
      { key: walletId, type: 'read' },
      { key: SYSVAR_RENT_PUBKEY, type: 'read' },
    ]),
    data,
    programId: getProviderHub()!.currentNetwork.stakingProgramId,
  });
};
