import { Asset, Big } from '@aries/defi-toolkit/types';
import { useProfile, useWallet } from '@aries/lending-fe-hooks';
import {
  Button,
  CenterBetweenRow,
  CenterRow,
  Col,
  styled,
  Txt,
} from '@aries/mobile-core/components';
import {
  NotificationBase as NotificationBar,
  NotificationType,
  Toast,
} from '@aries/mobile-core/Smoothy';
import { ScreenInset } from '@aries/mobile-core/utils';
import { useTranslation } from '@aries/shared/locale';
import { shortenAddress } from '@aries/shared/utils';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import React, { useState } from 'react';
import { Clipboard, ViewStyle } from 'react-native';
import { IconSecondDown } from '~/components';
import { useCommonMeta } from './commonMeta';

const { colorBgElevated } = globalToken;
const { colorInteractive } = colorConfig;
export const Notification: React.FC<{
  content: React.ReactNode;
  type: NotificationType;
}> = ({ content, type = 'info' }) => {
  return (
    <NotificationBar
      type={type}
      content={content}
      style={{ marginTop: -8, marginBottom: 16 }}
    />
  );
};

export const MaxValueRow: React.FC<{
  max: number;
  maxTitle?: string;
  asset: Asset;
  onSetValue: (val: Big, isMax: boolean) => void;
}> = ({ max, maxTitle, asset, onSetValue }) => {
  const { t } = useTranslation();
  return (
    <CenterBetweenRow mb={9}>
      <Txt c3 h2>
        {maxTitle ?? t`Max`}: {max ?? '-'} {asset.symbol ?? '-'}
      </Txt>
      <CenterRow mt={8} mb={8}>
        <CenterRow>
          <Button
            title={t`half`}
            type="ghost"
            active
            onPress={() => {
              onSetValue(Big(max / 2), false);
            }}
            h={25}
            w={50}
            titleStyle={{ fontSize: 13 }}
          />
          <Button
            title={t`max`}
            type="ghost"
            active
            onPress={() => {
              onSetValue(Big(max), true);
            }}
            ml={10}
            h={25}
            w={50}
            titleStyle={{ fontSize: 13 }}
          />
        </CenterRow>
      </CenterRow>
    </CenterBetweenRow>
  );
};

export const ConfirmButton = ({
  onClick,
  disabled,
  loading,
  reason,
  title,
}: {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  reason?: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const { walletAddress, connect } = useWallet();
  const [localLoading, setLoading] = useState(false);

  const { initProfile, initialized } = useProfile();

  return (
    <Button
      type="primary"
      onPress={
        !walletAddress
          ? connect
          : !initialized
          ? async () => {
              setLoading(true);
              await initProfile('Main Account');
              setLoading(false);
            }
          : onClick
      }
      loading={localLoading || loading}
      disabled={!!(walletAddress && initialized && disabled)}
      title={
        !walletAddress
          ? t`Connect Wallet`
          : !initialized
          ? t`Create Profile to ${title}!`
          : disabled
          ? reason ?? t`Please input a valid number`
          : title
      }
    />
  );
};

export function CopiableAddress(props: { address: string }) {
  const { mode } = useThemeMode();
  const { address } = props;
  return (
    <Txt
      onPress={() => {
        Clipboard.setString(address);
        Toast.success('Copy To The Clipboard!');
      }}
      color={colorInteractive[mode]}
    >
      {shortenAddress(address, 6)}
    </Txt>
  );
}

export const ExtraConfig: React.FC<{
  items: { label: string; value: React.ReactNode }[];
  // eslint-disable-next-line react/no-unused-prop-types
  reserveId: string;
}> = ({ items }) => {
  const metas = useCommonMeta();
  return (
    <Col pd={5} mt={10}>
      {[...items, ...metas].map(({ label, value }) => (
        <CenterBetweenRow mb={10} key={label}>
          <Txt h2 c2>
            {label}
          </Txt>
          <Txt h2>{value}</Txt>
        </CenterBetweenRow>
      ))}
    </Col>
  );
};

export const FAQs: React.FC<{
  data: {
    question: string;
    answer: React.ReactNode;
  }[];
  direction?: 'vertical' | 'horizontal';
  title?: string;
  style?: ViewStyle;
}> = ({ data, direction, title = 'FAQs', style }) => {
  const [isFold, setIsFold] = useState(false);

  return (
    <Col style={style}>
      <CenterRow mt={0}>
        <Line />
        <CenterRow ml={10} mr={10}>
          <Txt h2 bold mr={4} onPress={() => setIsFold(v => !v)} c3>
            {title}
          </Txt>
          <IconSecondDown
            size={10}
            style={!isFold ? { transform: [{ rotateZ: '180deg' }] } : {}}
          />
        </CenterRow>
        <Line />
      </CenterRow>
      <FaqContent isFold={isFold}>
        {data.map(({ question, answer }, index) => (
          <React.Fragment key={question}>
            {direction === 'horizontal' ? (
              <CenterBetweenRow mt={12}>
                <Txt c2 h2>
                  {question}
                </Txt>
                <Txt h2>{answer}</Txt>
              </CenterBetweenRow>
            ) : (
              <Col mt={15}>
                <Txt c2 h2>
                  {index + 1}.{question}
                </Txt>
                <Txt h2>{answer}</Txt>
              </Col>
            )}
          </React.Fragment>
        ))}
      </FaqContent>
    </Col>
  );
};

const FaqContent = styled(Col)<{ isFold: boolean }>`
  ${p => (p.isFold ? 'height:0px' : undefined)}
  overflow-y: hidden;
`;

const Line = styled.View`
  flex: 1;
  background-color: ${colorBgElevated};
  height: 1px;
`;

const { padding, paddingSM } = globalToken;
export const Content = styled.View`
  padding-top: ${paddingSM};
  padding-bottom: ${paddingSM};
  flex: 1;
  padding-left: ${padding};
  padding-right: ${padding};
  margin-bottom: ${ScreenInset.bottom};
`;
