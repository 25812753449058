import { ScrollView } from '@aries/mobile-core/components';
import React from 'react';
import { FirstPageWrapper } from '~/components';
import { BaseInfo } from './BaseInfo';
import { List } from './List';

export const Wallet = () => {
  return (
    <FirstPageWrapper>
      <ScrollView contentContainerStyle={{ paddingBottom: 12 }}>
        <BaseInfo />
        <List />
      </ScrollView>
    </FirstPageWrapper>
  );
};
