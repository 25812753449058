import { setTheme } from '@aries/ui-theming';

const colorBgMdIndicator = {
  dark: '#fff',
  light: '#eee',
};
const colorTextMdIndicator = {
  dark: '#fff',
  light: '#eee',
};

export const modalIndicatorToken = {
  fontSizeMdIndicator: 15,
  paddingTopMdIndicatorTxt: 12,
  paddingLeftMdIndicator: 40,
  paddingRightMdIndicator: 40,
  paddingTopMdIndicator: 100,
  paddingBottomMdIndicator: 80,
  colorBgMdIndicator: setTheme(colorBgMdIndicator),
  colorTextMdIndicator: setTheme(colorTextMdIndicator),
};

export const modalIndicatorConfig = {
  colorBgMdIndicator,
  colorTextMdIndicator,
};
