import { getTokenInfoHub } from '@aries/solana-defi/common/index';
import {
  AdditionalReward,
  Apy,
  AssetPrice,
  MintId,
  StakingPoolId,
  ReserveInfo,
} from '@port.finance/port-sdk';
import type { TokenInfo } from '@solana/spl-token-registry';
import { getSlot } from './slot';
import { getStakingPools } from '../staking-pool';

export const getAdditionalRewards = (
  reserve: ReserveInfo,
): AdditionalReward[] => {
  const [rewardMint, altRewardMint] = getRewardMints(
    reserve.getStakingPoolId(),
  );
  const portRewardApy = getRewardApy(reserve, rewardMint);
  const altRewardApy = getSubRewardApy(reserve, altRewardMint);

  const reserveId = reserve.getReserveId();
  const result: AdditionalReward[] = [];
  if (!portRewardApy.isTrivial() && rewardMint) {
    result.push(
      new AdditionalReward(reserveId, portRewardApy, true, rewardMint),
    );
  }

  if (altRewardMint && altRewardApy && !altRewardApy.isTrivial()) {
    // add any secondary reward from third parties
    result.push(
      new AdditionalReward(reserveId, altRewardApy, true, altRewardMint),
    );
  }

  return result;
};

const getRewardMints = (
  stakingPoolId: StakingPoolId | undefined,
): MintId[] => {
  const rewardMints: MintId[] = [];
  const rewardTokenSupply =
    getStakingPools()?.poolToRewardAccounts?.[
      stakingPoolId?.toString() ?? ''
    ];
  if (rewardTokenSupply) {
    rewardMints.push(rewardTokenSupply.primaryRewardAccount.getMintId());
    if (rewardTokenSupply.secondaryRewardAccount) {
      rewardMints.push(
        rewardTokenSupply.secondaryRewardAccount.getMintId(),
      );
    }
  }

  return rewardMints;
};

const getRewardApy = (
  reserve: ReserveInfo,
  tokenMint: MintId | undefined,
): Apy => {
  const stakingPoolId = reserve.getStakingPoolId();
  if (!stakingPoolId || !tokenMint) {
    return Apy.na();
  }
  const pool = getStakingPools()?.getStakingPool?.(stakingPoolId)?.value;
  const tokenMap = getTokenInfoHub()?.emptyableTokenMap;
  const currentSlot = getSlot();

  const rewardTokenInfo = tokenMap?.[tokenMint.toString()];
  const price = tokenMap?.[tokenMint.toBase58()]?.price;
  if (
    !pool ||
    !price?.toNumber() ||
    !stakingPoolId ||
    !currentSlot ||
    pool.isPoolEnd(currentSlot) ||
    !tokenMint ||
    !rewardTokenInfo
  ) {
    return Apy.na();
  }

  return pool.getRewardApy(
    reserve,
    AssetPrice.of(tokenMint, price),
    rewardTokenInfo as unknown as TokenInfo,
  );
};

const getSubRewardApy = (
  reserve: ReserveInfo,
  tokenMint: MintId | undefined,
): Apy => {
  const stakingPoolId = reserve.getStakingPoolId();
  if (!stakingPoolId || !tokenMint) {
    return Apy.na();
  }
  const pool = getStakingPools()?.getStakingPool?.(stakingPoolId)?.value;
  const tokenMap = getTokenInfoHub()?.emptyableTokenMap;
  const currentSlot = getSlot();

  const rewardTokenInfo = tokenMap?.[tokenMint.toString()];
  const price = tokenMap?.[tokenMint.toBase58()]?.price;
  if (
    !pool ||
    !price?.toNumber() ||
    !stakingPoolId ||
    !currentSlot ||
    pool.isPoolEnd(currentSlot) ||
    !tokenMint ||
    !rewardTokenInfo
  ) {
    return Apy.na();
  }

  return pool.getSubRewardApy(
    reserve,
    AssetPrice.of(tokenMint, price),
    rewardTokenInfo as unknown as TokenInfo,
  );
};
