import { CenterRow, Col, Txt } from '@aries/mobile-core/components';
import {
  colorConfig,
  globalToken,
  ModeProps,
  useThemeMode,
} from '@aries/ui-theming';
import { isNumber, noop } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import {
  ErrorIcon,
  IconThirdClose,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from './Icon';

export type NotificationType = 'info' | 'success' | 'warning' | 'error';

export const NotificationBase: React.FC<{
  title?: string | ReactNode;
  content?: string | ReactNode;
  style?: ViewStyle;
  closeable?: boolean;
  type: NotificationType;
  onManage?: () => void;
  onClose?: () => void;
  timestamp?: number | string;

  renderClose?: (options: { close: () => void }) => React.ReactNode;
}> = ({
  title,
  content,
  renderClose,
  onClose,
  closeable,
  style,
  onManage,
  timestamp,
  type,
}) => {
  const { mode } = useThemeMode();
  const { colorInteractive } = colorConfig;
  const closeIcon = renderClose ? (
    renderClose({ close: onClose ?? noop })
  ) : (
    <IconThirdClose size={10} />
  );

  const HeadingIcon = useMemo(() => getNotificationIcon(type), [type]);
  const borderColor = useMemo(
    () => getNotificationBorderColor(type, mode),
    [type, mode],
  );

  return (
    <Container borderColor={borderColor} style={style}>
      {type ? <CenterRow mr={8}>{HeadingIcon}</CenterRow> : null}
      <Col flex={1}>
        {title ? (
          <Txt bold h4>
            {title}
          </Txt>
        ) : null}
        {content ? <Txt>{content}</Txt> : null}

        {onManage ? (
          <TouchableOpacity style={{ marginTop: 10 }}>
            <Txt color={colorInteractive[mode]}>Manage</Txt>
          </TouchableOpacity>
        ) : null}

        {timestamp ? (
          <Txt c2 h1 mt={3} style={{ textAlign: 'right' }}>
            {isNumber(timestamp)
              ? new Date(timestamp).toLocaleDateString()
              : timestamp}
          </Txt>
        ) : null}
      </Col>

      {closeable ? <CloseBox>{closeIcon}</CloseBox> : null}
    </Container>
  );
};

const getNotificationIcon = (type: NotificationType) => {
  switch (type) {
    case 'info':
      return <InfoIcon size={22} />;
    case 'success':
      return <SuccessIcon size={22} />;
    case 'error':
      return <ErrorIcon size={22} />;
    case 'warning':
      return <WarningIcon size={22} />;
    default:
      return () => null;
  }
};

const getNotificationBorderColor = (
  type: NotificationType,
  mode: ModeProps,
) => {
  const {
    colorInfoBorder,
    colorWarningBorder,
    colorSuccessBorder,
    colorErrorBorder,
  } = colorConfig;
  switch (type) {
    case 'info':
      return colorInfoBorder[mode];
    case 'success':
      return colorSuccessBorder[mode];
    case 'error':
      return colorErrorBorder[mode];
    case 'warning':
      return colorWarningBorder[mode];
    default:
      return null;
  }
};

const { padding, borderRadiusLG, colorBgBase } = globalToken;
const Container = styled(CenterRow)<{ borderColor: string | null }>`
  padding: ${padding}px;
  border-radius: ${borderRadiusLG}px;
  background-color: ${colorBgBase};
  border-color: ${p => p.borderColor};
  border-width: 1px;
  border-style: solid;
`;

const CloseBox = styled.TouchableOpacity`
  position: absolute;
  right: 12px;
  top: 12px;
`;
