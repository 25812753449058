/* eslint-disable  */
// PopoverPickerView.js

'use strict';
import PropTypes from 'prop-types';
import { ScrollView } from 'react-native';

import { colorConfig, globalToken } from '@aries/ui-theming';
import { ThemeContext } from 'styled-components/native';
import Overlay from '../Overlay/Overlay';
import PopoverPickerItem from './PopoverPickerItem';

export default class PopoverPickerView extends Overlay.PopoverView {
  static propTypes = {
    ...Overlay.PopoverView.propTypes,
    items: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number,
    getItemText: PropTypes.func, // (item, index) return display text of item, item=items[index], use item when it's null
    shadow: PropTypes.bool,
    onSelected: PropTypes.func, // (item, index)
  };

  static defaultProps = {
    ...Overlay.PopoverView.defaultProps,
    direction: 'down',
    align: 'center',
    showArrow: false,
    shadow: true,
  };

  static Item = PopoverPickerItem;

  onItemPress(itemIndex) {
    const { items, onSelected } = this.props;
    this.close(false);
    onSelected && onSelected(items[itemIndex], itemIndex);
  }

  buildPopoverStyle() {
    const { shadow } = this.props;
    const mode = this.context?.mode ?? 'dark';
    const { colorPopPicker, colorPopPickerShadow } =
      colorConfig.PopoverPicker;
    const {
      minWidthPopPicker,
      maxWidthPopPicker,
      minHeightPopPicker,
      maxHeightPopPicker,
      directionInsetsPopPicker,
    } = globalToken.PopoverPicker;

    const pickerStyle = {
      backgroundColor: colorPopPicker[mode],
      minWidth: minWidthPopPicker,
      maxWidth: maxWidthPopPicker,
      minHeight: minHeightPopPicker,
      maxHeight: maxHeightPopPicker,
    };
    if (shadow) {
      Object.assign(pickerStyle, {
        shadowColor: colorPopPickerShadow[mode],
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
      });
    }

    this.defaultDirectionInsets = directionInsetsPopPicker;
    let { popoverStyle, arrow } = super.buildPopoverStyle();
    popoverStyle = [pickerStyle].concat(popoverStyle);
    return { popoverStyle, arrow };
  }

  renderContent() {
    const { items, selectedIndex, getItemText } = this.props;
    return super.renderContent(
      <ScrollView>
        {items &&
          items.map((item, index) => (
            <this.constructor.Item
              key={'item' + index}
              title={getItemText ? getItemText(item, index) : item}
              selected={index === selectedIndex}
              onPress={() => this.onItemPress(index)}
            />
          ))}
      </ScrollView>,
    );
  }
}

PopoverPickerView.contextType = ThemeContext;
