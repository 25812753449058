import { useDeposit } from '@aries/lending-fe-hooks';
import {
  CenterRow,
  Col,
  ScrollView,
  Txt,
  useTabViewCtx,
} from '@aries/mobile-core/components';
import { NotificationBase } from '@aries/mobile-core/Smoothy';
import { useTranslation } from '@aries/shared/locale';
import React, { FC, ReactNode } from 'react';
import { TokenInput } from '~/components';
import {
  ConfirmButton,
  Content,
  ExtraConfig,
  MaxValueRow,
} from './common';

export const DepositPanel: FC<{
  onClose: () => void;
  reserveId: string;
  footerComponents: ReactNode;
}> = ({ onClose, reserveId, footerComponents }) => {
  const { t } = useTranslation();
  const deposit = useDeposit(reserveId);
  const { curPage } = useTabViewCtx();

  if (!deposit) return null;

  const {
    max,
    amount,
    reserve,
    loading,
    depositApy,
    disableInput,
    isApyPositive,
    disableConfirm,
    depositedAmount,
    shouldRepayFirst,
    onChange,
    onConfirm,
  } = deposit;

  return (
    <Content>
      <ScrollView>
        <Col pb={12}>
          {shouldRepayFirst && <RepayNotification />}

          <TokenInput
            tokenInfo={reserve.asset}
            value={amount}
            onAmountChange={v => onChange(v)}
            maxAmount={max}
          >
            <CenterRow>
              <TokenInput.Input
                focused={curPage === 0}
                disabled={disableInput}
                style={{ width: '100%' }}
              />
            </CenterRow>
            <MaxValueRow
              asset={reserve.asset}
              onSetValue={onChange}
              max={max}
            />
            <TokenInput.Slider />
          </TokenInput>
          <ExtraConfig
            reserveId={reserve.id}
            items={[
              {
                label: t`Deposited`,
                value: <Txt h2>{depositedAmount}</Txt>,
              },
              {
                label: t`APY`,
                value: (
                  <Txt
                    h2
                    color={
                      isApyPositive ? 'Text/Success' : 'Text/Critical'
                    }
                  >
                    {depositApy}
                  </Txt>
                ),
              },
            ]}
          />

          {footerComponents}
        </Col>
      </ScrollView>

      <ConfirmButton
        onClick={async () => {
          const success = await onConfirm();
          success && onClose();
        }}
        loading={loading}
        title={t`Deposit`}
        reason={
          shouldRepayFirst
            ? t`Please repay your debt first`
            : t`Please input a valid number`
        }
        disabled={disableConfirm}
      />
    </Content>
  );
};

const RepayNotification = () => {
  const { t } = useTranslation();
  const { changeTab } = useTabViewCtx();

  return (
    <NotificationBase
      type="warning"
      content={
        <Txt h1>
          {t`You have to`}{' '}
          <Txt
            onPress={() => {
              changeTab(3);
            }}
            style={{ textDecorationLine: 'underline' }}
            h1
          >
            {t`repay your debt`}
          </Txt>{' '}
          {t`to enable deposit.`}
        </Txt>
      }
      style={{ marginBottom: 16 }}
    />
  );
};
