/* eslint-disable @typescript-eslint/no-unused-vars */
import { createGlobalStore } from '@aries/shared/deps';
import { useProviderHub } from '@aries/solana-defi/common';
import { useWallet } from '@aries/solana-defi/wallet';
import {
  StakeAccount,
  STAKE_ACCOUNT_DATA_SIZE,
} from '@port.finance/port-sdk';
import {
  Keypair,
  PublicKey,
  SystemProgram,
  TransactionInstruction,
} from '@solana/web3.js';
import { newStakingAccountIx } from '../actions/instructions/new-staking-account';
import { createSeed } from '../actions/utils';
import { useProgramSubscription } from './util';

export const [useStakingAccounts, getStakingAccounts] = createGlobalStore(
  () => {
    const { currentNetwork, provider } = useProviderHub();
    const { walletAddress } = useWallet();
    const { results: accounts, isLoading: loading } =
      useProgramSubscription(
        currentNetwork.stakingProgramId.toBase58(),
        [
          {
            dataSize: STAKE_ACCOUNT_DATA_SIZE,
          },
          {
            memcmp: {
              offset: 1 + 16,
              bytes: walletAddress!,
            },
          },
        ],
        StakeAccount.fromRaw,
        {
          deps: [walletAddress, currentNetwork.name],
          disableFetch: !walletAddress,
          delayLoad: 3000,
        },
      );

    const getOrCreateStakingAccount = async (stakingPoolId: PublicKey) => {
      const account = accounts.find(a =>
        a.value.getStakingPoolId().equals(stakingPoolId),
      );

      if (account) {
        return {
          address: new PublicKey(account.pubkey),
          ixs: [] as TransactionInstruction[],
          signers: [] as Keypair[],
          stakingPoolId,
          programId: currentNetwork.stakingProgramId,
        };
      }

      const wallet = new PublicKey(walletAddress!);
      const seed = await createSeed(
        wallet,
        stakingPoolId,
        currentNetwork.stakingProgramId,
      );
      const pair = Keypair.fromSeed(seed);

      const size = STAKE_ACCOUNT_DATA_SIZE;
      const minBalance =
        await provider.connection.getMinimumBalanceForRentExemption(size);

      return {
        address: pair.publicKey,
        ixs: [
          SystemProgram.createAccount({
            fromPubkey: wallet,
            newAccountPubkey: pair.publicKey,
            lamports: minBalance,
            space: size,
            programId: currentNetwork.stakingProgramId,
          }),
          newStakingAccountIx({
            stakeAccountId: pair.publicKey,
            stakingPoolId,
            walletId: wallet,
          }),
        ],
        signers: [pair],
        stakingPoolId,
        programId: currentNetwork.stakingProgramId,
      };
    };

    return {
      accounts,
      refresh: () => {},
      loading,
      getOrCreateStakingAccount,
    };
  },
);
