// ActionSheetView.js
/* eslint-disable  */

import { colorConfig, ModeProps } from '@aries/ui-theming';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { Theme } from '../../theme';
import Overlay from '../Overlay/Overlay';
import ActionSheetItem from './ActionSheetItem';

//@ts-ignore
export default class ActionSheetView extends Overlay.PullView {
  static Item = ActionSheetItem;

  pressItem: any;

  disappearCompleted() {
    super.disappearCompleted();
    this.pressItem && this.pressItem.onPress && this.pressItem.onPress();
  }

  onItemPress(item: any) {
    if (item && item.disabled) return;
    this.pressItem = item;
    this.close(false);
  }

  onCancelItemPress() {
    const { cancelItem } = this.props;
    if (cancelItem && cancelItem.disabled) return;
    this.pressItem = cancelItem;
    this.close(true);
  }

  renderContent() {
    const { colorBgAcSheetItem, colorAcSheetCancelItem } =
      colorConfig.ActionSheet!;
    const { items, cancelItem } = this.props;
    const { mode }: { mode: ModeProps } = this.context;

    const list: React.ReactNode[] = [];
    for (let i = 0; items && i < items.length; ++i) {
      const item = items[i];
      list.push(
        // @ts-ignore
        <this.constructor.Item
          key={`item${i}`}
          title={item.title}
          topSeparator={i === 0 ? 'none' : 'full'}
          disabled={item.disabled}
          onPress={() => this.onItemPress(item)}
          style={{
            borderTopRightRadius: i === 0 ? 12 : 0,
            borderTopLeftRadius: i === 0 ? 12 : 0,
          }}
        />,
      );
    }
    if (cancelItem) {
      list.push(
        // @ts-ignore
        <this.constructor.Item
          key="cancelItem"
          type="cancel"
          title={cancelItem.title}
          topSeparator="full"
          disabled={cancelItem.disabled}
          onPress={() => this.onCancelItemPress()}
        />,
      );
    }
    list.push(
      <View
        style={{
          backgroundColor: cancelItem
            ? colorAcSheetCancelItem[mode]
            : colorBgAcSheetItem[mode],
          height: Theme.screenInset.bottom,
        }}
        key="bottomSpace"
      />,
    );

    // @ts-ignore
    return super.renderContent(list);
  }
}

ActionSheetView.contextType = ThemeContext;
