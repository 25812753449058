import { createIcon } from '../../../ImageIcon';

export const IconTextPlaceholder = createIcon(
  require('./text.png'),
  40 / 41,
);

export const IconActiveTextPlaceholder = createIcon(
  require('./activeText.png'),
  40 / 41,
);

export const IconPrimaryPlaceholder = createIcon(
  require('./primary.png'),
  40 / 41,
);
