/* eslint-disable prettier/prettier */
// Theme.js
import { isIphoneX } from '@aries/mobile-core/utils';
import { Dimensions, PixelRatio, Platform, StatusBar } from 'react-native';

const PAD_WIDTH = 768;

const { width: D_WIDTH, height: D_HEIGHT } = Dimensions.get('window');

const isIPhoneX = isIphoneX();

const isIPad = (() => {
  if (Platform.OS !== 'ios' || isIPhoneX) return false;

  // if portrait and width is smaller than iPad width
  if (D_HEIGHT > D_WIDTH && D_WIDTH < PAD_WIDTH) {
    return false;
  }

  // if landscape and height is smaller that iPad height
  if (D_WIDTH > D_HEIGHT && D_HEIGHT < PAD_WIDTH) {
    return false;
  }

  return true;
})();

// pixel size
const pixelSize = (() => {
  const pixelRatio = PixelRatio.get();
  if (pixelRatio >= 3) return 0.3333333333333333;
  if (pixelRatio >= 2) return 0.5;
  return 1;
})();

const isLandscape = (function isLandscape() {
  return Dimensions.get('window').width > Dimensions.get('window').height;
})();

const fitIPhoneX = true;

const statusBarHeight = (function statusBarHeight() {
  if (Platform.OS === 'ios') {
    if (isIPhoneX) return isLandscape ? 0 : fitIPhoneX ? 44 : 20;
    if (isIPad) return 20;
  } else if (Platform.OS === 'android') {
    if (Platform.Version > 20) return StatusBar.currentHeight; // translucent StatusBar is required
    return 0;
  }
  return isLandscape ? 0 : 20;
})();

const screenInset = {
  left: isLandscape && isIPhoneX && fitIPhoneX ? 44 : 0,
  right: isLandscape && isIPhoneX && fitIPhoneX ? 44 : 0,
  top: statusBarHeight,
  bottom: isIPhoneX && fitIPhoneX ? (isLandscape ? 24 : 34) : 0,
};

export const Theme = {
  hairLineWidth: pixelSize,
  isPad: isIPad,
  isIPhoneX,
  fitIPhoneX,
  isLandscape,
  statusBarHeight,
  screenInset,
  screenWidth: D_WIDTH,
  screenHeight: D_HEIGHT,
  pixelSize,
};
