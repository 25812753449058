import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import { merge } from 'lodash';
import * as React from 'react';
import {
  Animated,
  Easing,
  StyleSheet,
  TouchableWithoutFeedback,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import { LinearView } from '../linearView';
import { SwitchProps } from './switch.props';

// animation
const DURATION = 250;

const enhance = (
  style?: ViewStyle[] | ViewStyle,
  newStyles?: ViewStyle[] | ViewStyle,
): any => {
  return merge(style, newStyles);
};

const makeAnimatedValue = (switchOn?: boolean) =>
  new Animated.Value(switchOn ? 1 : 0);

export const Switch: React.FunctionComponent<
  SwitchProps & {
    disabled?: boolean;
    size?: number;
    width?: number;
  }
> = React.memo(props => {
  const [timer] = React.useState<Animated.Value>(
    makeAnimatedValue(props.value),
  );

  const startAnimation = React.useMemo(
    () => (newValue: boolean) => {
      const toValue = newValue ? 1 : 0;
      const easing = Easing.out(Easing.circle);
      Animated.timing(timer, {
        toValue,
        duration: DURATION,
        easing,
        useNativeDriver: true,
      }).start();
    },
    [timer],
  );

  const [previousValue, setPreviousValue] = React.useState<boolean>(
    !!props.value,
  );
  React.useEffect(() => {
    if (props.value !== previousValue) {
      startAnimation(!!props.value);
      setPreviousValue(!!props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handlePress = React.useMemo(
    () => () => props.onToggle && props.onToggle(!props.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onToggle, props.value],
  );
  const { mode } = useThemeMode();

  if (!timer) {
    return null;
  }

  const { disabled, size, width: defaultWidth } = props;

  const thumbSize = size ?? 24;

  const width = defaultWidth ?? thumbSize * 1.7;
  const offPosition = -0.5;
  const onPosition = width - thumbSize;
  const borderRadius = (thumbSize * 3) / 4;

  const translateX: any = timer.interpolate({
    inputRange: [0, 1],
    outputRange: [offPosition, onPosition],
  });

  const style = enhance({}, props.style);

  let trackStyle = {
    height: thumbSize,
    width,
    borderRadius,
  };

  trackStyle = enhance(
    trackStyle,
    props.value ? props.trackOnStyle : props.trackOffStyle,
  );

  let thumbStyle: ViewStyle = {
    position: 'absolute',
    width: thumbSize,
    height: thumbSize,
    borderRadius: thumbSize / 2,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    overflow: 'hidden',
  };

  thumbStyle = enhance(thumbStyle, {
    transform: [{ translateX }],
  });

  thumbStyle = enhance(
    thumbStyle,
    props.value ? props.thumbOnStyle : props.thumbOffStyle,
  );

  const {
    colorSwitchBg,
    colorSwitchBgChecked,
    colorSwitchBgCheckedDisabled,
  } = colorConfig.Switch;
  return (
    <TouchableWithoutFeedback
      onPress={handlePress}
      disabled={disabled}
      style={style}
    >
      <Animated.View style={trackStyle}>
        {/* border */}
        {props.value ? null : (
          <BorderView
            active={props.value}
            style={StyleSheet.absoluteFillObject}
            borderRadius={borderRadius}
          />
        )}

        {/* background */}
        <LinearView
          color={
            props.value
              ? disabled
                ? colorSwitchBgCheckedDisabled[mode]
                : colorSwitchBgChecked[mode]
              : disabled
              ? colorSwitchBg[mode]
              : colorSwitchBg[mode]
          }
          style={[
            StyleSheet.absoluteFillObject,
            { borderRadius: thumbSize },
          ]}
        />

        <Animated.View style={thumbStyle}>
          <Thumb active={props.value} disabled={disabled} />
        </Animated.View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
});

const {
  colorSwitchBorder,
  colorSwitchCheckedThumb,
  colorSwitchThumb,
  colorSwitchThumbCheckedDisabled,
  colorSwitchThumbDisabled,
} = globalToken.Switch;
const BorderView = styled.View<{
  active?: boolean;
  borderRadius: number;
}>`
  border-width: 1px;
  border-radius: ${p => p.borderRadius}px;
  border-color: ${colorSwitchBorder};
`;

const Thumb = styled.View<{ active?: boolean; disabled?: boolean }>`
  background-color: ${p => {
    if (p.disabled) {
      return p.active
        ? colorSwitchThumbCheckedDisabled
        : colorSwitchThumbDisabled;
    }
    return p.active ? colorSwitchCheckedThumb : colorSwitchThumb;
  }};
  flex: 1;
  margin: 4px;
  border-radius: 50%;
`;
