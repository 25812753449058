/* eslint-disable  */
// ToastView.js

'use strict';
import { colorConfig, globalToken } from '@aries/ui-theming';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import Overlay from '../Overlay/Overlay';

export default class ToastView extends Overlay.View {
  static propTypes = {
    ...Overlay.View.propTypes,
    text: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.number,
    ]),
    icon: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.shape({ uri: PropTypes.string }), // {uri: 'http://...'}
      PropTypes.number, // require('path/to/image')
      PropTypes.oneOf([
        'none',
        'success',
        'fail',
        'smile',
        'sad',
        'info',
        'stop',
      ]),
    ]),
    position: PropTypes.oneOf(['top', 'bottom', 'center']),
  };

  static defaultProps = {
    ...Overlay.View.defaultProps,
    overlayOpacity: 0,
    overlayPointerEvents: 'none',
    closeOnHardwareBackPress: false,
    position: 'center',
  };

  get overlayPointerEvents() {
    const { overlayPointerEvents, modal } = this.props;
    return modal ? 'auto' : overlayPointerEvents;
  }

  buildStyle() {
    const {
      paddingLeftToastScreen,
      paddingRightToastScreen,
      paddingTopToastScreen,
      paddingBottomToastScreen,
    } = globalToken.Toast;

    let { style, position } = this.props;
    style = [
      {
        paddingLeft: paddingLeftToastScreen,
        paddingRight: paddingRightToastScreen,
        paddingTop: paddingTopToastScreen,
        paddingBottom: paddingBottomToastScreen,
        justifyContent:
          position === 'top'
            ? 'flex-start'
            : position === 'bottom'
            ? 'flex-end'
            : 'center',
        alignItems: 'center',
      },
    ].concat(super.buildStyle());
    return style;
  }

  renderIcon() {
    const { paddingTopToastIcon, paddingBottomToastIcon } =
      globalToken.Toast;
    const { icon } = this.props;
    if (!icon && icon !== 0) return null;
    let image = icon;
    return (
      <View
        style={{
          paddingTop: paddingTopToastIcon,
          paddingBottom: paddingBottomToastIcon,
        }}
      >
        {image}
      </View>
    );
  }

  renderText() {
    const mode = this.context?.mode ?? 'dark';
    const { colorTextToast } = colorConfig.Toast;
    const { fontSizeToast } = globalToken.Toast;
    let { text } = this.props;

    if (typeof text === 'string' || typeof text === 'number') {
      text = (
        <Text
          style={{
            color: colorTextToast[mode],
            fontSize: fontSizeToast,
          }}
        >
          {text}
        </Text>
      );
    }
    return text;
  }

  renderContent() {
    const mode = this.context?.mode ?? 'dark';
    const { colorBgToast } = colorConfig.Toast;
    const {
      paddingLeftToast,
      paddingTopToast,
      paddingRightToast,
      paddingBottomToast,
      borderRadiusToast,
      widthToast,
      heightToast,
    } = globalToken.Toast;

    const contentStyle = {
      backgroundColor: colorBgToast[mode],
      paddingLeft: paddingLeftToast,
      paddingRight: paddingRightToast,
      paddingTop: paddingTopToast,
      paddingBottom: paddingBottomToast,
      borderRadius: borderRadiusToast,
      minWidth: widthToast,
      minHeight: heightToast,
      alignItems: 'center',
      justifyContent: 'center',
    };
    if (!this.props.icon) {
      contentStyle.paddingLeft = 12;
      contentStyle.paddingRight = 12;
      contentStyle.paddingTop = 10;
      contentStyle.paddingBottom = 10;
      contentStyle.minWidth = undefined;
      contentStyle.minHeight = undefined;
    }

    return (
      <View style={contentStyle}>
        {this.renderIcon()}
        {this.renderText()}
      </View>
    );
  }
}

ToastView.contextType = ThemeContext;
