import { globalToken } from '@aries/ui-theming';
import { isString } from 'lodash';
import React, { FC, ReactNode } from 'react';
import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { LinearDefaultColorProps, LinearView } from '../linearView';
import { IconLoading, IconPrimaryLoading } from './icons';

const HEIGHT = 48;

type BaseProps = {
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;

  w?: number | string;
  h?: number | string;
};

export type ButtonTypeProps = 'primary' | 'ghost' | 'text';

export const Button: React.FC<
  BaseProps &
    LinearDefaultColorProps & {
      onPress: (() => void) | (() => Promise<unknown>);
      color?: string;
      title?: string | ReactNode;
      hasPlaceholder?: boolean;
      type?: ButtonTypeProps;
      style?: ViewStyle;
      titleStyle?: TextStyle;
      round?: boolean;
    }
> = props => {
  const {
    title,
    type = 'primary',
    style,
    loading,
    disabled,
    active,
    hasPlaceholder,
    round,
    titleStyle,
  } = props;

  const isPrimary = type === 'primary';

  const status = {
    loading,
    active,
    disabled,
  };

  const placeholder = hasPlaceholder ? (
    <Placeholder type={type} {...status} />
  ) : null;

  const loadingContent = isPrimary ? (
    <IconPrimaryLoading size={40} style={{ paddingHorizontal: 10 }} />
  ) : (
    <IconLoading size={40} style={{ paddingHorizontal: 10 }} />
  );

  return (
    <Container
      {...props}
      disabled={disabled}
      type={type}
      active={active}
      style={[style]}
      activeOpacity={0.7}
      round={round}
    >
      <LinearBox
        {...(isPrimary ? status : {})}
        color={isPrimary ? undefined : 'transparent'}
      >
        {placeholder}

        {loading ? (
          loadingContent
        ) : isString(title) ? (
          <Text type={type} active={active} style={titleStyle}>
            {title}
          </Text>
        ) : (
          title
        )}

        {placeholder}
      </LinearBox>

      {type === 'ghost' ? (
        <BorderView
          active={active}
          style={StyleSheet.absoluteFillObject}
        />
      ) : null}
    </Container>
  );
};

const Placeholder: FC<
  { type: ButtonTypeProps } & LinearDefaultColorProps
> = ({ active, type, loading }) => {
  if (loading) return <NullPlaceholder />;

  if (type === 'primary') {
    return <NullPlaceholder />;
  }

  if (active) {
    return <NullPlaceholder />;
  }

  return <NullPlaceholder />;
};

const NullPlaceholder = styled.View`
  width: ${HEIGHT}px;
  height: ${HEIGHT}px;
  justify-content: center;
  align-items: center;
`;

const {
  colorButtonPrimaryText,
  colorButtonTextActive,
  colorButtonText,
  colorButtonGhostBorderActive,
  colorButtonGhostBorder,
} = globalToken.Button;
const { borderRadiusLG, colorBorder, colorInteractive } = globalToken;

const Text = styled.Text<{
  type: ButtonTypeProps;
  active?: boolean;
}>`
  color: ${p =>
    p.type === 'primary'
      ? colorButtonPrimaryText
      : p.active
      ? colorButtonTextActive
      : colorButtonText};
  font-family: 'PoppinsBold';
  font-size: 17px;
`;

const BorderView = styled.View<{
  active?: boolean;
}>`
  border-width: 1px;
  border-radius: ${borderRadiusLG};
  border-color: ${p =>
    p.active ? colorButtonGhostBorderActive : colorButtonGhostBorder};
`;

const Container = styled.TouchableOpacity<{
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;
  active?: boolean;
  type: ButtonTypeProps;
  w?: number | string;
  h?: number | string;
  round?: boolean;
}>`
  overflow: hidden;
  /* align-items: center; */
  justify-content: center;
  height: ${HEIGHT}px;
  border-radius: ${p => (p.round ? 30 : borderRadiusLG)}px;
  border-color: ${p => (p.active ? colorInteractive : colorBorder)};
  ${p => (p.mg ? `margin:${p.mg}px` : '')};
  ${p => (p.ml ? `margin-left:${p.ml}px` : '')};
  ${p => (p.mr ? `margin-right:${p.mr}px` : '')};
  ${p => (p.mt ? `margin-top:${p.mt}px` : '')};
  ${p => (p.mb ? `margin-bottom:${p.mb}px` : '')};

  ${p => (p.w ? `width:${p.w}px` : '')};
  ${p => (p.h ? `height:${p.h}px` : '')};
`;

const LinearBox = styled(LinearView)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex: 1;
  height: 100%;
`;
