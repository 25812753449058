import {
  BrandColorMapConfig,
  BrandColorMapToken,
  setTheme,
} from '@aries/ui-theming';

const colorPrimaryBg = {
  dark: '#A98AF4,#A98AF4,#543CEE',
  light: '#98DBFE,#91D9FE,#7FD2FE,#6BCBFD',
};
const colorPrimaryBgDisabled = {
  dark: '#7A728D,#7A728D,#7A728D,#7A728D,#3B2D93',
  light: '#6BCBFD8C,#7FD2FE8C,#91D9FE8C,#98DBFE8C',
};
const colorPrimaryBgActive = {
  dark: '#543CEE,#A98AF4,#543CEE',
  light: '#4ABDFC,#38B0F0,#219EE2,#0388CF',
};
const colorPrimaryBorder = {
  dark: '',
  light: '',
};
const colorSuccessBg = {
  dark: '#186F39',
  light: '#186F39',
};
const colorSuccessBorder = {
  dark: 'rgba(102, 255, 164, 0.2)',
  light: 'rgba(105, 255, 165, 1)',
};
const colorWarningBg = {
  dark: '',
  light: '',
};
const colorWarningBorder = {
  dark: 'rgba(255, 157, 105, 1)',
  light: 'rgba(255, 157, 105, 1)',
};
const colorErrorBg = {
  dark: '#9C3A3A',
  light: '#9C3A3A',
};
const colorErrorBorder = {
  dark: 'rgba(255, 105, 102, 0.2)',
  light: 'rgba(255, 107, 105, 1)',
};
const colorInfoBg = {
  dark: '#2B6DDD',
  light: '#2B6DDD',
};
const colorInfoBorder = {
  dark: 'rgba(105, 202, 255, 1)',
  light: 'rgba(105, 202, 255, 1)',
};

export const brandColorMapConfig: BrandColorMapConfig = {
  colorPrimaryBg,
  colorPrimaryBgDisabled,
  colorPrimaryBgActive,
  colorPrimaryBorder,
  colorSuccessBg,
  colorSuccessBorder,
  colorWarningBg,
  colorWarningBorder,
  colorErrorBg,
  colorErrorBorder,
  colorInfoBg,
  colorInfoBorder,
};

export const brandColorMapTokens: BrandColorMapToken = {
  colorPrimaryBg: setTheme(colorPrimaryBg),
  colorPrimaryBgActive: setTheme(colorPrimaryBgActive),
  colorPrimaryBgDisabled: setTheme(colorPrimaryBgDisabled),
  colorPrimaryBorder: setTheme(colorPrimaryBorder),
  colorSuccessBg: setTheme(colorSuccessBg),
  colorSuccessBorder: setTheme(colorSuccessBorder),
  colorWarningBg: setTheme(colorWarningBg),
  colorWarningBorder: setTheme(colorWarningBorder),
  colorErrorBg: setTheme(colorErrorBg),
  colorErrorBorder: setTheme(colorErrorBorder),
  colorInfoBg: setTheme(colorInfoBg),
  colorInfoBorder: setTheme(colorInfoBorder),
};
