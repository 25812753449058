import { setModalHeight } from '@aries/mobile-core/Smoothy';
import { ScreenHeight, ScreenInset } from '@aries/mobile-core/utils';
import { ModeProps } from '@aries/ui-theming';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { ThemeProvider } from 'styled-components/native';
import { PAGE_HEADER_HEIGHT } from './components';
import {
  Account,
  Browser,
  Lending,
  SplashScreen,
  Swap,
  TabBar,
  Wallet,
} from './screen';
import { Bridge } from './screen/Bridge';

setModalHeight(ScreenHeight - ScreenInset.top - PAGE_HEADER_HEIGHT);

const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  const [loading, setLoaing] = useState(true);
  const [fontLoaded] = useFonts({
    Lato: require('%/assets/fonts/Lato-Regular.ttf'),
    LatoBold: require('%/assets/fonts/Lato-Bold.ttf'),
  });

  useEffect(() => {
    setTimeout(() => {
      setLoaing(false);
    }, 1000);
  }, []);

  if (loading || !fontLoaded) {
    return <SplashScreen />;
  }

  return (
    <Tab.Navigator
      initialRouteName="Lending"
      screenOptions={{
        header: () => {
          return null;
        },
        headerShown: false,
        tabBarShowLabel: false,
      }}
      tabBar={v => {
        return <TabBar {...v} />;
      }}
    >
      <Tab.Screen name="Lending" component={Lending} />
      <Tab.Screen name="Account" component={Account} />
      <Tab.Screen name="Swap" component={Swap} />
      <Tab.Screen name="Bridge" component={Bridge} />
      {/* <Tab.Screen name="Trading" component={Trading} /> */}
      {Platform.OS !== 'web' && (
        <Tab.Screen name="Wallet" component={Wallet} />
      )}
      {Platform.OS !== 'web' && (
        <Tab.Screen name="Browser" component={Browser} />
      )}
    </Tab.Navigator>
  );
};

export function AppRoutes() {
  const [themeMode, setThemeMode] = useState<ModeProps>('dark');

  return (
    <ThemeProvider
      theme={{
        mode: themeMode,
        changeTheme: (v: ModeProps) => {
          setThemeMode(v);
        },
      }}
    >
      <NavigationContainer
        documentTitle={{
          formatter: () => `Port Finance `,
        }}
      >
        <TabNavigator />
      </NavigationContainer>
    </ThemeProvider>
  );
}
