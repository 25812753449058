import { getMax } from '@aries/defi-toolkit/utils/index';
import {
  getBalanceHub,
  getProviderHub,
  hasEnoughGasOrThrow,
} from '@aries/solana-defi/common';
import { getProfileHub } from '@aries/solana-defi/lending/data/profile';
import { withSendTxNotify } from '@aries/solana-defi/utils';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Keypair, Transaction } from '@solana/web3.js';
import Big from 'big.js';
import {
  redeemCollateralIx,
  withdrawCollateralIx,
} from './instructions/withdraw';
import { getReserveCtx } from './utils';

export const withdrawCollateral = withSendTxNotify(
  async (reserveKey: string, lamports: Big) => {
    hasEnoughGasOrThrow();

    const { provider } = getProviderHub()!;

    const {
      shareMintId,
      assetMintId,
      reserveSDK,
      refreshProfileIxs,
      refreshReservesIxs,
      walletPubkey,
      marketPubkey,
      profilePubkey,
      reserve,
      stakeAccount,
      marketAuthority,
      reserveId,
    } = await getReserveCtx(reserveKey);

    const tx = new Transaction();

    const shareAmount = reserve.depositToShare(lamports);

    const srcShareSplAccount = await getBalanceHub()!.tryGetSplAccount(
      shareMintId,
    );

    const availableLPToken = srcShareSplAccount.balance;
    const LPNeedToUncollateralize = getMax(
      shareAmount.sub(availableLPToken),
      Big(0),
    );

    const destAssetSplAccount = await getBalanceHub()!.tryGetSplAccount(
      assetMintId,
    );

    const transferAuthorityKeypair = Keypair.generate();

    tx.add(
      ...srcShareSplAccount.preIxns,
      ...destAssetSplAccount.preIxns,
      ...refreshReservesIxs,
      ...refreshProfileIxs,
      ...(LPNeedToUncollateralize.gt(0)
        ? [
            withdrawCollateralIx({
              reserveShareSplAccount: reserveSDK.getShareBalanceId(),
              destShareSplAccount: srcShareSplAccount.address,
              reserveId,
              marketAuthorityId: marketAuthority,
              marketId: marketPubkey,
              walletId: walletPubkey,
              amount: LPNeedToUncollateralize,
              profileId: profilePubkey,
              stakeAccount,
            }),
          ]
        : []),
      // Approve to transfer LP tokens
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        srcShareSplAccount.address,
        transferAuthorityKeypair.publicKey,
        walletPubkey,
        [],
        shareAmount.toNumber(),
      ),
      ...(stakeAccount?.ixns ?? []),
      redeemCollateralIx({
        shareAmount,
        srcShareSplAccount: srcShareSplAccount.address,
        destAssetSplAccount: destAssetSplAccount.address,
        reserveId,
        marketAuthorityId: marketAuthority,
        transferAuthority: transferAuthorityKeypair.publicKey,
        reserveAssetSplAccount: reserveSDK.asset.getSplAccountId(),
        shareMintId,
        marketId: marketPubkey,
        stakeAccount,
      }),
      Token.createRevokeInstruction(
        TOKEN_PROGRAM_ID,
        srcShareSplAccount.address,
        walletPubkey,
        [],
      ),
      Token.createRevokeInstruction(
        TOKEN_PROGRAM_ID,
        destAssetSplAccount.address,
        walletPubkey,
        [],
      ),
    );

    const res = await provider.signAndSendTx(tx, [
      transferAuthorityKeypair,
      ...(stakeAccount?.signers ?? []),
    ]);

    getProfileHub()?.refresh();
    getBalanceHub()?.refresh();

    return res;
  },
);
