import { createIcon } from '@aries/mobile-core/components';

export const WarningIcon = createIcon(require('./warning.png'));
export const SuccessIcon = createIcon(require('./check.png'));
export const InfoIcon = createIcon(require('./info.png'));
export const ErrorIcon = createIcon(require('./triangle.png'));
export const IconThirdClose = createIcon(require('./close.png'));

export const WarningIconSource = require('./warning.png');
export const SuccessIconSource = require('./check.png');
export const InfoIconSource = require('./info.png');
export const ErrorIconSource = require('./triangle.png');
