import { Asset, Big } from '@aries/defi-toolkit/types';
import { useLatest } from '@aries/shared/hooks/helper';
import {
  useProviderHub,
  useTokenInfoHub,
} from '@aries/solana-defi/common';
import { withSendTxNotify } from '@aries/solana-defi/utils/notify';
import { getWalletCtx } from '@aries/solana-defi/wallet';
import { useJupiter } from '@jup-ag/react-hook';
import { PublicKey } from '@solana/web3.js';
import { BigSource } from 'big.js';
import JSBI from 'jsbi';
import { compact } from 'lodash';
import { useEffect, useMemo } from 'react';
import { SwapMarket } from '../../interface';

export const useJupSwap = (
  fromAssetId: string,
  toAssetId: string,
  slippage: number,
  amount: BigSource,
): SwapMarket => {
  const { provider } = useProviderHub();
  const { tokenMap } = useTokenInfoHub();

  const {
    allTokenMints,
    routeMap,
    exchange,
    refresh,
    loading,
    routes: jupRoutes,
  } = useJupiter({
    amount: JSBI.BigInt(amount.toString()),
    inputMint: new PublicKey(fromAssetId),
    outputMint: new PublicKey(toAssetId),
    slippageBps: slippage,
    debounceTime: 250,
  });

  const refreshRef = useLatest(refresh);
  useEffect(() => {
    const timer = setInterval(() => {
      refreshRef.current();
    }, 30000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    totalAssetList: useMemo(() => {
      return compact(
        allTokenMints.map(mint => {
          const asset = tokenMap[mint];
          if (
            !asset ||
            asset.decimals <= 2 ||
            asset.decimals > 20 ||
            !asset.logoURI
          ) {
            return null;
          }
          return asset;
        }),
      );
    }, [tokenMap, allTokenMints]),
    routes: useMemo(() => {
      return (
        jupRoutes?.map((r, index) => {
          const fromAsset = tokenMap[fromAssetId];
          const toAsset = tokenMap[toAssetId];
          const recieve = toAsset.toAmount(Big(r.outAmount.toString()));
          const avgPrice = fromAsset
            .toAmount(Big(r.inAmount.toString()))
            .eq(0)
            ? 0
            : recieve
                .div(fromAsset.toAmount(Big(r.inAmount.toString())))
                .toNumber();

          return {
            id: `${index}`,
            name: r.marketInfos.map(m => m.label).join('x'),
            dexesPath: r.marketInfos.map(m => m.label).join(' x '),
            desc: `${fromAsset?.symbol}→${r.marketInfos
              .map(m => `${tokenMap[m.outputMint.toString()]?.symbol}`)
              .join('→')}`,
            slippagePct: r.slippageBps,
            receive: recieve.toNumber(),
            minReceive: toAsset
              .toAmount(Big(r.otherAmountThreshold.toString()))
              .toNumber(),
            receiveValueUSD: toAsset.lamportsToBalance(
              Big(r.outAmount.toString()),
            ).valueUSD,
            cost: r.fees?.totalFeeAndDeposits ?? 0,
            exchangeRate: avgPrice,
            swap: withSendTxNotify(async () => {
              const res = await exchange({
                wallet: {
                  signAllTransactions: getWalletCtx()
                    ?.signAllTransactions as <T>(
                    transactions: T[],
                  ) => Promise<T[]>,
                  signTransaction: getWalletCtx()?.signTransaction as <T>(
                    transaction: T,
                  ) => Promise<T>,
                },
                routeInfo: r,
              });

              const success = !(res as any)?.error;

              if (success) {
                const txId = (res as any).txid;
                const tx = await provider.connection.getTransaction(txId, {
                  maxSupportedTransactionVersion: 0,
                });
                return {
                  success: true as const,
                  rawTx: tx,
                  txId,
                  payload: tx ?? {},
                };
              }
              return {
                success: false as const,
                rawTx: '',
                txId: '',
                payload: {},
                message: (res as any)?.error?.message,
              };
            }),
          };
        }) ?? []
      );
    }, [
      exchange,
      fromAssetId,
      jupRoutes,
      tokenMap,
      provider.connection,
      toAssetId,
    ]),
    loading,
    getHasRoutesByPair: (
      fromAsset?: Pick<Asset, 'id'>,
      toAsset?: Pick<Asset, 'id'>,
    ) => {
      if (!fromAsset?.id || !toAsset?.id) {
        return false;
      }

      return routeMap.get(fromAsset.id)?.includes(toAsset.id) ?? false;
    },
  };
};
