/* eslint-disable  */
// PopoverPickerItem.js

'use strict';

import { colorConfig, globalToken } from '@aries/ui-theming';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import { Theme } from '../../theme';

export default class PopoverPickerItem extends Component {
  static propTypes = {
    ...TouchableOpacity.propTypes,
    title: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.number,
    ]),
    selected: PropTypes.bool,
  };

  buildStyle() {
    let { style } = this.props;
    const mode = this.context?.mode ?? 'dark';
    const { colorPopPicker, colorPopPickerItemSeparator } =
      colorConfig.PopoverPicker;
    const {
      paddingBottomPopPickerItem,
      paddingLeftPopPickerItem,
      paddingRightPopPickerItem,
      paddingTopPopPickerItem,
    } = globalToken.PopoverPicker;

    style = [
      {
        backgroundColor: colorPopPicker[mode],
        paddingLeft: paddingLeftPopPickerItem,
        paddingRight: paddingRightPopPickerItem,
        paddingTop: paddingTopPopPickerItem,
        paddingBottom: paddingBottomPopPickerItem,
        borderColor: colorPopPickerItemSeparator[mode],
        borderBottomWidth: Theme.pixelSize,
        flexDirection: 'row',
        alignItems: 'center',
      },
    ].concat(style);
    return style;
  }

  renderTitle() {
    let { title } = this.props;
    const mode = this.context?.mode ?? 'dark';
    const { colorPopPickerItemTitle } = colorConfig.PopoverPicker;
    const { fontSizePopPickerItem } = globalToken.PopoverPicker;

    if (typeof title === 'string' || typeof title === 'number') {
      const titleStyle = {
        color: colorPopPickerItemTitle[mode],
        fontSize: fontSizePopPickerItem,
        overflow: 'hidden',
        flexGrow: 1,
        flexShrink: 1,
      };
      title = (
        <Text style={titleStyle} numberOfLines={1}>
          {title}
        </Text>
      );
    }
    return title;
  }

  renderAccessory() {
    const {
      widthPopPickerAccessory,
      heightPopPickerAccessory,
      paddingLeftPopPickerAccessory,
    } = globalToken.PopoverPicker;
    const mode = this.context?.mode ?? 'dark';
    const { colorPopPickerAccessoryCheck } = colorConfig.PopoverPicker;

    const imageStyle = {
      width: widthPopPickerAccessory,
      height: heightPopPickerAccessory,
      tintColor: colorPopPickerAccessoryCheck[mode],
    };
    return (
      <View style={{ paddingLeft: paddingLeftPopPickerAccessory }}>
        <Image style={imageStyle} source={null} />
      </View>
    );
  }

  render() {
    const { style, children, title, selected, ...others } = this.props;
    return (
      <TouchableOpacity style={this.buildStyle()} {...others}>
        {this.renderTitle()}
        {this.renderAccessory()}
      </TouchableOpacity>
    );
  }
}

PopoverPickerItem.contextType = ThemeContext;
