import {
  Button,
  CenterCol,
  CenterRow,
  Txt,
} from '@aries/mobile-core/components';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import React from 'react';
import { Tag } from '~/components';

const { margin } = globalToken;
const { colorSuccessBg } = colorConfig;
export const BaseInfo = () => {
  const { mode } = useThemeMode();

  return (
    <>
      <CenterCol mt={28} mb={28}>
        <Txt size={35} bold>
          $199999.888
        </Txt>
        <CenterRow mt={5}>
          <Txt success mr={10} bold>
            +$999.88
          </Txt>
          <Tag
            label={
              <Txt success bold>
                +68.3%
              </Txt>
            }
            bckColor={colorSuccessBg[mode]}
          />
        </CenterRow>
      </CenterCol>
      <CenterRow>
        <Button
          onPress={() => {}}
          title="Deposit"
          round
          style={{ flex: 1, marginRight: margin }}
        />
        <Button
          onPress={() => {}}
          title="Send"
          round
          style={{ flex: 1 }}
        />
      </CenterRow>
    </>
  );
};
