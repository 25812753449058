import {
  CenterBetweenRow,
  Col,
  styled,
  Txt,
  View,
} from '@aries/mobile-core/components';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import { isString } from 'lodash';
import React from 'react';
import { ViewStyle } from 'react-native';

export const Section: React.FC<{
  title: React.ReactNode;
  style?: ViewStyle;
  headerRight?: React.ReactNode;
  containerStyle?: ViewStyle;
}> = ({ title, children, style, headerRight, containerStyle }) => {
  const { mode } = useThemeMode();

  return (
    <SectionCard style={containerStyle}>
      <Title>
        <CenterBetweenRow>
          {isString(title) ? (
            <Txt h3 bold>
              {title}
            </Txt>
          ) : (
            title
          )}
          <Txt h3 bold>
            {headerRight}
          </Txt>
        </CenterBetweenRow>
      </Title>
      <Col
        style={{
          padding: '16px',
          backgroundColor: colorConfig.colorBgContainer[mode],
          ...style,
        }}
      >
        {children}
      </Col>
    </SectionCard>
  );
};

const { marginSM, colorBgSpotlight, margin, borderRadiusLG } = globalToken;
const SectionCard = styled.View`
  margin-top: ${marginSM}px;
  border-color: ${colorBgSpotlight};
  border-width: 1px;
  border-style: solid;
  border-radius: ${borderRadiusLG};
`;

const Title = styled(View)`
  padding: ${marginSM}px ${margin}px;
  background-color: ${globalToken.colorBgContainer};
`;
