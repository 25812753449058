import {
  CenterBetweenRow,
  CenterCol,
  CenterRow,
  LinearView,
  Txt,
} from '@aries/mobile-core/components';
import { ScreenInset } from '@aries/mobile-core/utils';
import { useTranslation } from '@aries/shared/locale';
import { globalToken } from '@aries/ui-theming';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/native';
import {
  IconTabBarBridge,
  IconTabBarBridgeActive,
  IconTabBarBrowser,
  IconTabBarBrowserActive,
  IconTabBarLending,
  IconTabBarLendingActive,
  IconTabBarSwap,
  IconTabBarSwapActive,
  IconTabBarTrading,
  IconTabBarTradingActive,
  IconTabBarWallet,
  IconTabBarWalletActive,
} from '~/components';

export type TabBarProp =
  | 'Lending'
  | 'Trading'
  | 'Swap'
  | 'Wallet'
  | 'Browser'
  | 'Account'
  | 'Bridge';

export const TabBarHeight = 65;

const TabInfo: {
  active: ReactNode;
  notActive: ReactNode;
  name: TabBarProp;
}[] = [
  {
    active: <IconTabBarLendingActive size={25} />,
    notActive: <IconTabBarLending size={25} />,
    name: 'Lending',
  },
  {
    active: <IconTabBarWalletActive size={25} />,
    notActive: <IconTabBarWallet size={25} />,
    name: 'Account',
  },
  {
    active: <IconTabBarSwapActive size={25} />,
    notActive: <IconTabBarSwap size={25} />,
    name: 'Swap',
  },
  {
    active: <IconTabBarBridgeActive size={25} />,
    notActive: <IconTabBarBridge size={25} />,
    name: 'Bridge',
  },
  {
    active: <IconTabBarWalletActive size={25} />,
    notActive: <IconTabBarWallet size={25} />,
    name: 'Wallet',
  },
  {
    active: <IconTabBarBrowserActive size={25} />,
    notActive: <IconTabBarBrowser size={25} />,
    name: 'Browser',
  },
  {
    active: <IconTabBarTradingActive size={25} />,
    notActive: <IconTabBarTrading size={25} />,
    name: 'Trading',
  },
];

export const TabBar: FC<BottomTabBarProps> = ({
  state: { index, routeNames },
  navigation,
}) => {
  const { t } = useTranslation();
  const nameTransMap: Record<TabBarProp, string> = {
    Lending: t`Lending`,
    Trading: t`Trading`,
    Account: t`Account`,
    Swap: t`Swap`,
    Wallet: t`Wallet`,
    Browser: t`Browser`,
    Bridge: t`Bridge`,
  };
  return (
    <TabBox>
      {(routeNames as TabBarProp[]).map((name, i) => {
        const isActive = index === i;

        const icon = TabInfo.find(p => p.name === name);

        return (
          <TabItemBox
            onPress={() => {
              navigation.navigate(name);
            }}
            key={name}
          >
            {isActive ? (
              <LinearView style={{ height: 2, width: '100%' }} />
            ) : null}
            <CenterCol flex={1} style={{ justifyContent: 'center' }}>
              <CenterRow
                w={30}
                h={30}
                style={{ justifyContent: 'center' }}
              >
                {(isActive ? icon?.active : icon?.notActive) ?? null}
              </CenterRow>
              <Txt h2 c2={!isActive}>
                {nameTransMap[name]}
              </Txt>
            </CenterCol>
          </TabItemBox>
        );
      })}
    </TabBox>
  );
};

const TabItemBox = styled.TouchableOpacity`
  flex: 1;
  height: ${TabBarHeight}px;
`;

const { colorBgContainer } = globalToken;
const TabBox = styled(CenterBetweenRow)`
  background-color: ${colorBgContainer};
  padding-bottom: ${ScreenInset.bottom}px;
`;
