/* eslint-disable import/no-mutable-exports */
import { Transaction } from '@solana/web3.js';

export type Adapter = {
  walletAddress: string | undefined;
  walletIcon: string | undefined;
  signTransaction: (tx: Transaction) => Promise<Transaction>;
  signAllTransactions: (txnx: Transaction[]) => Promise<Transaction[]>;
  getAddressWithNotify: () => string | string;
  getAddress: () => string | undefined;
  connect: () => void;
  disconnect: () => void;
};

export let useWallet: () => Adapter;

export let getWalletCtx: () => Adapter | undefined;

export const initWalletCtx = (adapter: {
  hook: typeof useWallet;
  getter: typeof getWalletCtx;
}) => {
  useWallet = adapter.hook;
  getWalletCtx = adapter.getter;
};
