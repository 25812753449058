import './i18n';
import './styles';
// Enable dark mode by default
import { initNotifyUtil } from '@aries/solana-defi/utils/notify';
import { withLoadingNotify, withSendTxNotify } from './utils';
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-duplicates */
import { Platform } from 'react-native';

if (Platform.OS !== 'web') {
  // React-Native don't have Buffer natively, so patch it to global
  // @ts-ignore
  const { Buffer } = require('@craftzdog/react-native-buffer');
  global.Buffer = Buffer;
  // @ts-ignore
  // eslint-disable-next-line no-console
  (console as any).disableYellowBox = true;
}

initNotifyUtil(withSendTxNotify, withLoadingNotify as any);
