import { Col } from '@aries/mobile-core/components';
import { noop } from 'lodash';
import React, { FC } from 'react';
import { IconLogo } from '~/components';
import { openSelectToken } from '~/services';
import { Item, Props } from './Item';

const data: Props[] = [
  {
    image: <IconLogo size={30} />,
    name: 'Solana',
    subTitle: '11O SOL',
    count: '$1988.33',
    recentAmount: '+$777.44',
    id: '1',
  },
  {
    image: <IconLogo size={30} />,
    name: 'Solana',
    subTitle: '11O SOL',
    count: '$1988.33',
    recentAmount: '+$777.44',
    id: '2',
  },
  {
    image: <IconLogo size={30} />,
    name: 'Solana',
    subTitle: '11O SOL',
    count: '$1988.33',
    recentAmount: '+$777.44',
    id: '3',
  },
  {
    image: <IconLogo size={30} />,
    name: 'Solana',
    subTitle: '11O SOL',
    count: '$1988.33',
    recentAmount: '+$777.44',
    id: '4',
  },
];

export const List: FC<{}> = () => {
  const onPress = () => {
    openSelectToken(noop, {
      list: data.map(v => {
        return { ...v, logoURI: '', symbol: v.subTitle };
      }) as any,
      showDeposit: false,
    });
  };

  return (
    <Col mt={25}>
      {data.map(item => {
        return <Item data={item} key={item.id} onPress={onPress} />;
      })}
    </Col>
  );
};
