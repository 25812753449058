/* eslint-disable import/no-mutable-exports */
import { type DepsFn } from '@aries/shared/deps';
import { DashboardDefine, LendingAppDefine } from './type';

type LendingApp = (depsFn?: DepsFn<LendingAppDefine>) => LendingAppDefine;
// Singleton lending app state store
export let useLendingApp: LendingApp;
export let getLendingAppData: () => LendingAppDefine | undefined;

export const initLendingAppImpl = (adapter: {
  hook: typeof useLendingApp;
  getter: typeof getLendingAppData;
}) => {
  useLendingApp = adapter.hook;
  getLendingAppData = adapter.getter;
};

type Dashboard = (walletAddress: string | undefined) => DashboardDefine;
// Singleton dashboard state store
export let useDashboard: Dashboard;

export const initDashboardImpl = (adapter: {
  hook: typeof useDashboard;
}) => {
  useDashboard = adapter.hook;
};

export { type DashboardDefine, type LendingAppDefine } from './type';
