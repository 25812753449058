import { BigSource } from 'big.js';
import { SwapMarket } from '../interface';
import { useJupSwap } from './jupiter/index';

export const useSwapMarket: (
  fromAssetId: string,
  toAssetId: string,
  slippage: number,
  amount: BigSource,
) => SwapMarket = useJupSwap;
