import {
  getBalanceHub,
  getProviderHub,
  hasEnoughGasOrThrow,
} from '@aries/solana-defi/common/index';
import { withSendTxNotify } from '@aries/solana-defi/utils/notify';
import {
  liquidateObligationInstruction,
  PortProfile,
  PORT_LENDING,
  PORT_STAKING,
  refreshObligationInstruction,
  Lamport,
  refreshReserveInstruction,
} from '@port.finance/port-sdk';
import { AccountLayout, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  AccountInfo,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
} from '@solana/web3.js';
import Big from 'big.js';
import { compact, flatMap } from 'lodash';
import { getProfileHub } from '../data/profile';
import { getReserves } from '../data/reserves';
import { getLendingMarkets } from '../market';
import { getReserveCtx } from './utils';

async function fetchStakingAccounts(
  connection: Connection,
  owner: PublicKey,
  stakingPool: PublicKey | null,
): Promise<
  Array<{
    pubkey: PublicKey;
    account: AccountInfo<Buffer>;
  }>
> {
  if (!stakingPool) {
    return [];
  }
  return connection.getProgramAccounts(PORT_STAKING, {
    filters: [
      {
        dataSize: 233,
      },
      {
        memcmp: {
          offset: 1 + 16,
          bytes: owner.toBase58(),
        },
      },
      {
        memcmp: {
          offset: 1 + 16 + 32,
          bytes: stakingPool.toBase58(),
        },
      },
    ],
  });
}

export const liquidateByPayingToken = withSendTxNotify(
  async (
    lamports: Big,
    repayReserveId: string,
    withdrawReserveId: string,
    obligation: PortProfile,
  ) => {
    hasEnoughGasOrThrow();
    const { provider } = getProviderHub()!;

    const {
      assetMintId: repayAssetMintId,
      reserveSDK: repayReserveSDK,
      walletPubkey,
    } = await getReserveCtx(repayReserveId);

    const withdrawReserve = getReserves()?.reserveMap[withdrawReserveId];
    if (!withdrawReserve) {
      throw new Error('Reserve not found');
    }
    const withdrawReserveSDK = withdrawReserve.rawReserve;

    const refreshReservesIxs = flatMap(
      [obligation.getLoans(), obligation.getCollaterals()].map(arr =>
        compact(
          arr.map(v => {
            const reserve =
              getReserves()?.reserveMap?.[v.getReserveId().toBase58()]
                ?.rawReserve;
            if (!reserve) {
              return null;
            }
            return refreshReserveInstruction(
              reserve.getReserveId(),
              reserve.getOracleId(),
            );
          }),
        ),
      ),
    );

    const wrappedSOLTokenAccount = new Keypair();
    const tx = new Transaction();
    const isNative = repayReserveSDK.getAssetMintId().isNative();
    const nativeCreateAccTxs = isNative
      ? [
          SystemProgram.createAccount({
            fromPubkey: walletPubkey,
            newAccountPubkey: wrappedSOLTokenAccount.publicKey,
            lamports: lamports.toNumber(),
            space: AccountLayout.span,
            programId: new PublicKey(TOKEN_PROGRAM_ID),
          }),
          Token.createInitAccountInstruction(
            new PublicKey(TOKEN_PROGRAM_ID),
            repayReserveSDK.getAssetMintId(),
            wrappedSOLTokenAccount.publicKey,
            walletPubkey,
          ),
        ]
      : [];
    const nativeCreateCloseAccTxs = isNative
      ? [
          Token.createCloseAccountInstruction(
            TOKEN_PROGRAM_ID,
            wrappedSOLTokenAccount.publicKey,
            walletPubkey,
            walletPubkey,
            [],
          ),
        ]
      : [];

    const repayWallet = await getBalanceHub()?.tryGetSplAccount(
      repayAssetMintId,
      lamports,
    );
    const withdrawWallet = await getBalanceHub()?.tryGetSplAccount(
      withdrawReserveSDK.getShareMintId().toString(),
    );
    const stakeAccounts = await fetchStakingAccounts(
      provider.connection,
      obligation.getOwner()!,
      withdrawReserveSDK.getStakingPoolId()!,
    );
    tx.add(
      ...refreshReservesIxs,
      refreshObligationInstruction(
        obligation.getProfileId(),
        obligation.getCollateralReserveIds(),
        obligation.getLoanReserveIds(),
      ),
      ...nativeCreateAccTxs,
      ...repayWallet!.preIxns,
      ...withdrawWallet!.preIxns,
      liquidateObligationInstruction(
        Lamport.of(lamports).toU64(),
        isNative ? wrappedSOLTokenAccount.publicKey : repayWallet!.address,
        withdrawWallet!.address,
        repayReserveSDK.getReserveId(),
        repayReserveSDK.getAssetBalanceId(),
        withdrawReserveSDK.getReserveId(),
        withdrawReserveSDK.getShareBalanceId(),
        obligation.getProfileId(),
        new PublicKey(getLendingMarkets()?.activeMarketId ?? ''),
        getLendingMarkets()?.getMarketAuthId()!,
        walletPubkey,
        PORT_LENDING,
        withdrawReserveSDK.getStakingPoolId()
          ? withdrawReserveSDK.getStakingPoolId()
          : undefined,
        withdrawReserveSDK.getStakingPoolId()
          ? stakeAccounts[0].pubkey
          : undefined,
      ),
      ...nativeCreateCloseAccTxs,
    );

    const res = await provider.signAndSendTx(
      tx,
      isNative ? [wrappedSOLTokenAccount] : [],
    );

    getProfileHub()?.refresh();
    getBalanceHub()?.refresh();

    return res;
  },
);
