/* eslint-disable import/no-mutable-exports */
import { colorConfig, useThemeMode } from '@aries/ui-theming';
import { noop } from 'lodash';
import React from 'react';
import DropdownAlert from 'react-native-dropdownalert';
import {
  ErrorIconSource,
  InfoIconSource,
  SuccessIconSource,
  WarningIconSource,
} from './Icon';

// dropdown singleton
export let curDropdown: DropdownAlert;
export const DropdownProvider = ({
  children = null,
}: {
  children: React.ReactNode;
}) => {
  const { mode } = useThemeMode();
  const { colorInfoBg } = colorConfig;
  const styleSheet = createStyleSheet();
  return (
    <>
      {children}
      <DropdownAlert
        ref={el => {
          if (el) {
            curDropdown = el;
          }
        }}
        defaultContainer={{ padding: 8, paddingTop: 0 }}
        infoImageSrc={InfoIconSource}
        infoColor={colorInfoBg[mode]}
        warnImageSrc={WarningIconSource}
        successImageSrc={SuccessIconSource}
        errorImageSrc={ErrorIconSource}
        zIndex={1000}
        wrapperStyle={styleSheet.wrapperStyle}
        titleStyle={styleSheet.titleStyle}
        imageStyle={styleSheet.imageStyle}
        messageStyle={styleSheet.messageStyle}
      />
    </>
  );
};

interface NotificationHandle {
  close: () => void;
}

type NotificationFn = (
  title: string,
  message?: string,
) => NotificationHandle;

type NotificationArgs = Parameters<NotificationFn>;

interface NotificationApi {
  info: NotificationFn;
  warn: NotificationFn;
  error: NotificationFn;
  success: NotificationFn;
}

export const Notify: NotificationApi = {
  info: (...args: NotificationArgs) => {
    const [title, message = ''] = args;
    curDropdown?.alertWithType('info', title, message);
    return { close: curDropdown?.clearQueue ?? noop };
  },
  warn: (...args: NotificationArgs) => {
    const [title, message = ''] = args;
    curDropdown?.alertWithType('warn', title, message);
    return { close: curDropdown?.clearQueue ?? noop };
  },
  error: (...args: NotificationArgs) => {
    const [title, message = ''] = args;
    curDropdown?.alertWithType('error', title, message);
    return { close: curDropdown?.clearQueue ?? noop };
  },
  success: (...args: NotificationArgs) => {
    const [title, message = ''] = args;
    curDropdown?.alertWithType('error', title, message);
    return { close: curDropdown?.clearQueue ?? noop };
  },
};

const createStyleSheet = () => ({
  wrapperStyle: {
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  titleStyle: {
    lineHeight: 30,
    color: 'white',
    fontWeight: 'bold',
  },
  imageStyle: {
    marginRight: 10,
    alignSelf: 'center',
    width: 32,
    height: 32,
  },
  messageStyle: { fontSize: 12, color: 'white', lineHeight: 16 },
});
