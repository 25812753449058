// ActionSheet.js
/* eslint-disable  */
// @ts-nocheck
import { ViewStyle } from 'react-native';
import Overlay from '../Overlay/Overlay';
import ActionSheetView from './ActionSheetView';

export interface ActionSheetItem {
  title: string;
  onPress: () => void;
  disabled?: boolean;
  style?: ViewStyle;
}

export class ActionSheet extends Overlay {
  static ActionSheetView = ActionSheetView;

  static show(
    items: ActionSheetItem[],
    cancelItem: ActionSheetItem = { title: 'Cancel', onPress: () => {} },
    options = {},
  ) {
    return super.show(
      <this.ActionSheetView
        items={items}
        cancelItem={cancelItem}
        {...options}
      />,
    );
  }
}
