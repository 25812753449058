import { useProviderHub } from '@aries/solana-defi/common/index';
import { useWallet } from '@aries/solana-defi/wallet/index';
import { Provider as AnchorProvider } from '@project-serum/anchor';
import { Keypair, PublicKey, Transaction } from '@solana/web3.js';
import { createGlobalStore } from 'hox';
import { useMemo } from 'react';

export const [useAnchorProvider, getAnchorProvider] = createGlobalStore(
  () => {
    const {
      provider: { connection },
    } = useProviderHub();
    const { walletAddress, signTransaction, signAllTransactions } =
      useWallet();

    const solanaProvider = useMemo(
      () =>
        new AnchorProvider(
          connection,
          walletAddress
            ? {
                publicKey: new PublicKey(walletAddress),
                signTransaction,
                signAllTransactions,
              }
            : new Wallet(Keypair.generate()),
          AnchorProvider.defaultOptions(),
        ),
      [connection, signAllTransactions, signTransaction, walletAddress],
    );

    return solanaProvider;
  },
);

/**
 * Allow creating a wallet using `Keypair`.
 */
export class Wallet {
  constructor(readonly payer: Keypair) {}

  async signTransaction(tx: Transaction): Promise<Transaction> {
    tx.partialSign(this.payer);
    return tx;
  }

  async signAllTransactions(txs: Transaction[]): Promise<Transaction[]> {
    return txs.map(t => {
      t.partialSign(this.payer);
      return t;
    });
  }

  get publicKey(): PublicKey {
    return this.payer.publicKey;
  }
}
