import { useMemo } from 'react';
import { useLendingApp } from '../adapter';

export const useReserves = () => {
  const { reserves, reserveLoading, reserveEmpty } = useLendingApp(
    data => [data.reserves, data.reserveLoading, data.reserveEmpty],
  );

  return {
    reserves,
    reserveEmpty,
    reserveLoading,
  };
};

export const useReserveById = (id: string) => {
  const { reserves, reserveLoading, reserveEmpty } = useReserves();

  return useMemo(
    () => ({
      reserve: reserves.find(reserve => reserve.id === id),
      reserveLoading,
      reserveEmpty,
    }),
    [id, reserves, reserveLoading, reserveEmpty],
  );
};

export const useObligations = (
  mode: 'unhealthy' | 'insolvent' = 'unhealthy',
) => {
  const { unhealthyProfiles, obligationLoading, obligationEmpty } =
    useLendingApp(data => [
      data.unhealthyProfiles,
      data.obligationLoading,
      data.obligationEmpty,
    ]);

  const list = useMemo(
    () =>
      unhealthyProfiles.filter(p =>
        mode === 'unhealthy'
          ? p.riskPct > 0
          : p.depositedValueUSD < p.borrowedValueUSD,
      ),
    [unhealthyProfiles, mode],
  );

  return {
    unhealthyProfiles: list,
    obligationLoading,
    obligationEmpty,
  };
};

export const useMarketSummary = () => {
  const { summary } = useLendingApp(data => [data.summary]);

  return {
    totalMarketCapUSD: summary.marketSize,
    totalBorrowedAssetValueUSD: summary.borrowed,
    borrowedRatio: summary.lentOut,
  };
};

export const useProfile = () =>
  useLendingApp(data => [data.profile]).profile;

export const useFaucet = () => ({
  list: useLendingApp(data => [data.faucets]).faucets,
  refresh: useLendingApp().triggerRefreshBalance,
});

export const useWallet = () => useLendingApp(data => [data.wallet]).wallet;

export const useEnv = () => useLendingApp(data => [data.env]).env;
