import { createGlobalStore } from '@aries/shared/deps';
import { MarketId } from '@port.finance/port-sdk';
import { PublicKey } from '@solana/web3.js';
import { getProviderHub } from '@aries/solana-defi/common';
import { useState } from 'react';
import portLogo from './assets/port.png';

export const PORT_MARKET_ID = MarketId.fromBase58(
  '6T4XxKerq744sSuj3jaoV6QiZ8acirf4TrPwQzHAoSy5',
);
export const HUBBLE_MARKET_ID = MarketId.fromBase58(
  '3VGB8mkJ7Po4DMhquiwZ5TY6T8si2oEzkTMLpke4aT3o',
);
export const UXD_MARKET_ID = MarketId.fromBase58(
  '9H2dexZJqrErvjaVvN8ZN3krA3sWS6WDXYx1xLQyzMqv',
);
export const HEDGE_MARKET_ID = MarketId.fromBase58(
  'HUYp9prDMdnQMfBYp9KQANTqCHW3R7fzQfjm89eeRpft',
);

export const [useLendingMarkets, getLendingMarkets] = createGlobalStore(
  () => {
    const [markets] = useState([
      {
        id: PORT_MARKET_ID.toBase58(),
        name: 'Port Main Market',
        description: 'Lending Pool officially supported by Port Finance.',
        logo: portLogo,
      }
    ]);

    const [activeMarketId, setActiveMarket] = useState(markets[0].id);

    const getMarketAuthId = () => {
      const [authorityKey] = PublicKey.findProgramAddressSync(
        [new PublicKey(activeMarketId).toBuffer()],
        new PublicKey(getProviderHub()!.currentNetwork.programId),
      );

      return authorityKey;
    };

    return {
      markets,
      activeMarketId,
      setActiveMarket,
      getMarketAuthId,
    };
  },
);
