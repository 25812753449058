import { useMarketSummary } from '@aries/lending-fe-hooks';
import { CenterBetweenRow } from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import { globalToken } from '@aries/ui-theming';
import { SmallCard } from '~/components';

const { margin } = globalToken;
export const AssetsCount: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const { totalMarketCapUSD, totalBorrowedAssetValueUSD, borrowedRatio } =
    useMarketSummary();

  const data = [
    { title: t`Market Size`, subTitle: totalMarketCapUSD },
    { title: t`Borrowed`, subTitle: totalBorrowedAssetValueUSD },
    { title: t`Lent Out`, subTitle: borrowedRatio },
  ];
  return (
    <CenterBetweenRow mt={margin}>
      {data.map((item, index) => {
        return (
          <SmallCard
            key={item.title}
            title={item.title}
            subTitle={item.subTitle}
            hasMarginLeft={index > 0}
          />
        );
      })}
    </CenterBetweenRow>
  );
};
