import { ScrollView } from '@aries/mobile-core/components';
import { DropDownPicker } from '@aries/mobile-core/Smoothy';
import { useLendingMarkets } from '@aries/solana-defi/lending/market';
import { noop } from 'lodash';
import React from 'react';
import { FirstPageWrapper } from '~/components';
import { Assets } from './Assets';
import { AssetsCount } from './AssetsCount';

export const Lending: React.FunctionComponent = () => {
  return (
    <FirstPageWrapper>
      <ScrollView>
        <MarketSelect />
        <AssetsCount />
        <Assets />
      </ScrollView>
    </FirstPageWrapper>
  );
};

const MarketSelect = () => {
  const { markets, activeMarketId, setActiveMarket } = useLendingMarkets();

  return (
    <DropDownPicker
      value={activeMarketId}
      items={markets.map(m => ({
        value: m.id,
        label: m.name,
        icon: () => <img alt={m.name} src={m.logo} height={24} />,
      }))}
      setValue={val => setActiveMarket(val)}
      setItems={noop}
      multiple={false}
    />
  );
};
