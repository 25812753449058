/* eslint-disable @typescript-eslint/no-unused-vars */
import Big from 'big.js';
import { Reserve } from '../data/reserves';
import { borrowAsset } from './borrow';
import { depositAndAddCollateral } from './deposit';
import { repayAsset } from './repay';
import { withdrawCollateral } from './withdraw';

export { addProfile } from './add-profile';

export const getReservesActions = ({
  coinAddress,
  marketId,
  reserveId,
}: Reserve) => {
  const deposit = async (lamports: Big) => {
    const res = await depositAndAddCollateral(reserveId, lamports);

    return res;
  };

  const withdraw = async (lamports: Big, isMax?: boolean) => {
    const res = await withdrawCollateral(reserveId, lamports);

    return res;
  };
  const borrow = async (lamports: Big) => {
    const res = await borrowAsset(reserveId, lamports);

    return res;
  };

  const repay = async (lamports: Big, isMax?: boolean) => {
    const res = await repayAsset(reserveId, lamports, !!isMax);

    return res;
  };
  // const addCollateral = withSendTxNotify(async (amount?: number) => {
  //   const maxAmount =
  //     getBalanceHub()?.balanceMap[
  //       getLPTokenAddress(coinAddress)
  //     ].amount?.toNumber();

  //   const v = amount ?? maxAmount;

  //   if (!v) {
  //     throw new Error('Should input a valid number.');
  //   }

  //   const res = await c().addCollateral(
  //     { amount: Big(v), profile_name: getCurrentProfile().name },
  //     { Coin0: coinAddress },
  //   );

  //   refresh();

  //   return res;
  // });

  // const removeCollateral = withSendTxNotify(async (amount?: number) => {
  //   const currentProfile = getCurrentProfile();
  //   const maxAmount =
  //     currentProfile?.depositByCoin[coinAddress]?.lamports.toNumber();

  //   const v = amount ?? maxAmount;

  //   if (!v) {
  //     throw new Error('Should input a valid number.');
  //   }

  //   const res = await c().removeCollateral(
  //     { amount: Big(v), profile_name: currentProfile.name },
  //     { Coin0: coinAddress },
  //   );

  //   refresh();

  //   return res;
  // });

  return {
    deposit,
    withdraw,
    borrow,
    repay,
  };
};

export type ReserveActions = ReturnType<typeof getReservesActions>;
