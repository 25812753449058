import { useProviderHub } from '@aries/solana-defi/common/index';
import { Slot } from '@port.finance/port-sdk';
import { SlotInfo } from '@solana/web3.js';
import { createGlobalStore } from 'hox';
import { useEffect, useRef, useState } from 'react';

export const [useSlot, getSlot] = createGlobalStore(
  (): Slot | undefined => {
    const [slot, setSlot] = useState<Slot>();
    const {
      provider: { connection },
    } = useProviderHub();

    const slotRef = useRef(slot);

    useEffect(() => {
      const id = connection.onSlotChange((slotInfo: SlotInfo) => {
        const v = Slot.of(slotInfo.slot);
        if (!slotRef.current) {
          setSlot(v);
        }
        slotRef.current = v;
      });

      const interval = setInterval(() => {
        setSlot(slotRef.current);
      }, 5000);

      return () => {
        connection.removeSlotChangeListener(id).then(() => {
          // eslint-disable-next-line no-console
          console.log('Slot subscription terminated.');
        });
        clearInterval(interval);
      };
    }, [connection]);

    return slot;
  },
);
