import { globalToken } from '@aries/ui-theming';
import { isString } from 'lodash';
import React, { memo } from 'react';
import {
  Dimensions,
  ScrollView,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import { Button, CenterRow, Txt } from '../../../components';
import { IconClose } from '../common';

const { width } = Dimensions.get('window');

interface Props {
  buttons?: {
    label: string | React.ReactNode;
    type?: 'primary' | 'ghost';
    style?: ViewStyle;
    labelStyle?: TextStyle;
    onPress?: (v: { close: () => void }) => void;
    timeout?: number;
    color?: string;
  }[];
  buttonDirection?: 'vertical' | 'horizontal';
  buttonContainerStyle?: ViewStyle;
  renderButton?: (v: { close: () => void }) => React.ReactNode;

  content: string | React.ReactNode;
  renderContent?: (v: { close: () => void }) => React.ReactNode;
  hasContentMargin?: boolean;
  scrollable?: boolean;

  showClose?: boolean;
  title?: string | React.ReactNode;
  titleStyle?: TextStyle;
  titleContainerStyle?: ViewStyle;
  renderTitle?: (v: { close: () => void }) => React.ReactNode;

  containerStyle?: ViewStyle;
  onClose: () => void;
}

export const Modal: React.FunctionComponent<Props> = memo(props => {
  const {
    buttons,
    buttonDirection = 'horizontal',
    buttonContainerStyle,
    renderButton,

    title,
    titleStyle,
    titleContainerStyle,
    showClose,
    renderTitle,

    content,
    renderContent,
    scrollable,
    hasContentMargin = true,

    containerStyle,
    onClose,
  } = props;

  const Container = scrollable ? ScrollView : React.Fragment;

  return (
    <ModalBox style={containerStyle}>
      <CenterRow>
        {renderTitle ? renderTitle({ close: onClose }) : null}
        {renderTitle ? null : title ? (
          isString(title) ? (
            <TitleBox style={titleContainerStyle}>
              <Txt bold h2 style={titleStyle}>
                {title}
              </Txt>
            </TitleBox>
          ) : (
            title
          )
        ) : null}

        {showClose ? (
          <TouchableOpacity
            onPress={onClose}
            style={{
              right: 0,
              top: -5,
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconClose size={24} />
          </TouchableOpacity>
        ) : null}
      </CenterRow>

      {renderContent ? renderContent({ close: onClose }) : null}

      {renderContent ? null : isString(content) ? (
        <ContentBox
          style={{
            padding: hasContentMargin ? 16 : 0,
          }}
        >
          <Txt
            style={{
              width: '100%',
              textAlign: 'center',
            }}
            h4
            lh={19}
          >
            {content}
          </Txt>
        </ContentBox>
      ) : (
        <Container
          showsVerticalScrollIndicator={false}
          style={{
            flex: 1,
          }}
        >
          {content}
        </Container>
      )}

      {renderButton ? renderButton({ close: onClose }) : null}
      {buttons && (
        <ButtonsBox
          buttonDirection={buttonDirection}
          style={buttonContainerStyle}
        >
          {buttons.map(({ label, type, style, onPress, color }, index) => {
            if (isString(label) && onPress) {
              const isVertical = buttonDirection === 'vertical';
              return (
                <Button
                  key={label}
                  type={type ?? 'primary'}
                  onPress={() => {
                    onPress({ close: onClose });
                  }}
                  color={color}
                  title={label}
                  style={{
                    marginTop: index !== 0 && isVertical ? 16 : 0,
                    marginLeft: isVertical ? 0 : index !== 0 ? 16 : 0,
                    ...(isVertical ? {} : { flex: 1 }),
                    ...style,
                  }}
                />
              );
            }

            return label;
          })}
        </ButtonsBox>
      )}
    </ModalBox>
  );
});

const { padding, borderRadiusLG, colorBgSpotlight } = globalToken;

const ModalBox = styled.View`
  border-radius: ${borderRadiusLG}px;
  background-color: ${colorBgSpotlight};
  width: ${0.72 * width}px;
  padding: ${padding}px;
`;

const TitleBox = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ContentBox = styled.View<{ hasContentMargin?: boolean }>`
  ${p => (p.hasContentMargin ? `padding-left: ${padding}px;` : '')}
  ${p => (p.hasContentMargin ? `padding-right: ${padding}px;` : '')}
`;

const ButtonsBox = styled.View<{
  buttonDirection?: 'vertical' | 'horizontal';
}>`
  ${p =>
    p.buttonDirection
      ? `flex-direction: row;
  margin-top: 5px;
  justify-content: space-between;
  width: 100%;`
      : ''}
`;
