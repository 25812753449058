import {
  Profile,
  useLendingApp,
  useProfile,
  useWallet,
} from '@aries/lending-fe-hooks';
import {
  Button,
  CenterBetweenRow,
  CenterRow,
  Col,
  ScrollView,
  Txt,
} from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { ScreenInset } from '@aries/mobile-core/utils';
import { Trans, useTranslation } from '@aries/shared/locale';
import React, { FC } from 'react';
import {
  Image,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { ProgressBar } from '~/components';
import { AvatarBox, Card } from './common';
import { WalletSelect } from './WalletConnect';

export const ProfileSelect = ({
  style,
}: {
  style?: StyleProp<ViewStyle>;
}) => {
  const { t } = useTranslation();
  const walletCtx = useWallet();
  const { currentProfile, initialized } = useProfile();

  if (!walletCtx.walletAddress) {
    return (
      <Button
        onPress={() => {
          walletCtx.connect();
        }}
        type="primary"
        title={t`Connect Wallet`}
        w={115}
        h={35}
        titleStyle={{ fontSize: 12 }}
      />
    );
  }

  return (
    <AvatarBox
      onPress={() => {
        openAccountCenter();
      }}
      style={style}
    >
      {walletCtx.walletIcon ? (
        <Image
          source={{ uri: walletCtx.walletIcon }}
          style={{ height: 16, width: 16, borderRadius: 5 }}
        />
      ) : null}
      <Txt h2 c2 ml={4}>
        {`${initialized ? currentProfile?.name : t`No Account`}`}
      </Txt>
    </AvatarBox>
  );
};

export const openAccountCenter = () => {
  openModal({
    renderHeader: () => (
      <CenterRow>
        <Txt h4>
          <Trans i18nkey="Account Center" />
        </Txt>
      </CenterRow>
    ),

    renderContent: ({ close }) => <AccountCenter onHide={close} />,
  });
};

const AccountCenter: React.FC<{
  onHide: () => void;
}> = ({ onHide }) => {
  const { t } = useTranslation();
  const { profile } = useLendingApp();

  const onConfirm = async () => {
    const success = await profile.addProfile('-');
    if (success) {
      onHide();
    }
  };

  return (
    <Col pb={ScreenInset.bottom + 10} flex={1}>
      <ScrollView style={{ flex: 1, paddingTop: 12 }}>
        <Txt lh={21} c2 mb={16}>
          {t`Multiple accounts can help you spread risk. Click to change the current account.`}
        </Txt>
        <Txt lh={21} mb={10} bold>
          {t`Wallet`}
        </Txt>
        <Col mb={10}>
          <WalletSelect close={onHide} />
        </Col>
        <Txt lh={21} mb={10} bold>
          {t`Accounts`}
        </Txt>
        {profile.list.length === 0 ? (
          <>
            <Txt mb={3} c2>
              {t`You have no account on Aries now,`}
            </Txt>
            <Txt mb={10} c2>
              {t`click to create your first account!`}
            </Txt>
            <Button
              type="primary"
              onPress={() => profile.initProfile('Main Account')}
              title={t`Initialize Account`}
            />
          </>
        ) : (
          <>
            {profile.list.map(item => {
              return (
                <TouchableOpacity
                  key={item.name}
                  activeOpacity={0.7}
                  onPress={() => {
                    profile.changeProfile(item.id);
                    onHide();
                  }}
                >
                  <AccountCard
                    active={profile.currentProfile?.name === item.name}
                    profile={item}
                  />
                </TouchableOpacity>
              );
            })}
          </>
        )}
      </ScrollView>
      {profile.list.length > 0 && (
        <Button onPress={onConfirm} title={t`Add Account`} />
      )}
    </Col>
  );
};

const AccountCard: FC<{ active?: boolean; profile: Profile | null }> = ({
  active,
  profile,
}) => {
  const { t } = useTranslation();
  const summary = profile?.summary ?? {
    apy: 0,
    depositedValueUSD: '-',
    borrowedValueUSD: '-',
    availableMarginUSD: '-',
    borrowingPowerPct: 0,
    deposits: [],
    loans: [],
  };

  return (
    <Card active={active}>
      <Txt bold h4>
        {profile?.name}
      </Txt>
      <CenterBetweenRow mt={15} mb={15}>
        {[
          { title: t`Deposited`, value: summary.depositedValueUSD },
          {
            title: t`APY`,
            value: `${summary.apy.toFixed(2)}%`,
            isCritical: summary.apy < 0,
          },
          { title: t`Borrowed`, value: summary.borrowedValueUSD },
        ].map(({ title, value, isCritical }, index) => {
          return (
            <Col key={index} style={toColStyle(index)}>
              <Txt c2 h2 mb={5}>
                {title}
              </Txt>
              <Txt h4 bold critical={isCritical}>
                {value}
              </Txt>
            </Col>
          );
        })}
      </CenterBetweenRow>

      <CenterBetweenRow mb={8}>
        <Txt c2 h2>
          {t`Borrowing Power`}
        </Txt>
        <Txt c2 h2>
          {t`Power Used`}
        </Txt>
      </CenterBetweenRow>
      {summary.borrowingPowerPct ? (
        <ProgressBar
          pct={summary.borrowingPowerPct}
          style={{ borderRadius: 4, marginBottom: 12 }}
        />
      ) : null}
      <CenterBetweenRow>
        <Txt c2 h2 mb={5}>
          {summary.borrowingPowerPct.toFixed(2)}%
        </Txt>
        <Txt c2 h2>
          {(100 - summary.borrowingPowerPct).toFixed(2)}%
        </Txt>
      </CenterBetweenRow>
    </Card>
  );
};

const toColStyle = (index: number): ViewStyle => ({
  flex: 1,
  alignItems:
    index === 0 ? 'flex-start' : index === 2 ? 'flex-end' : 'center',
});
