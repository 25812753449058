import Big from 'big.js';

export type NumberType = number | Big;

interface NumberTypeHelper<T extends NumberType> {
  decode: (s: string) => T;
  encode: (value: T) => string;
  lt: (value: T, otherValue: T) => boolean;
  gt: (value: T, otherValue: T) => boolean;
}

const encodeNumber = (number: NumberType) => number.toString();

const numberHelper: NumberTypeHelper<number> = {
  decode: (s: string) => Number(s),
  encode: encodeNumber,
  lt: (a: number, b: number) => a < b,
  gt: (a: number, b: number) => a > b,
};

const BigHelper: NumberTypeHelper<Big> = {
  decode: (s: string) => new Big(s),
  encode: (num: Big) => num.toFixed(),
  lt: (a: Big, b: Big) => a.lt(b),
  gt: (a: Big, b: Big) => a.gt(b),
};

export const getNumberTypeHelper = <T extends 'number' | 'big'>(
  type: T,
): any => {
  if (type === 'big') {
    return BigHelper;
  }
  return numberHelper;
};
