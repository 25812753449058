import { createGlobalStore } from 'hox';
import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import md5 from 'md5';
import {
  initReactI18next,
  useTranslation as useTranslationInnter,
} from 'react-i18next';

export const [useTranslation, getTranslation] = createGlobalStore(() => {
  const { t, i18n } = useTranslationInnter();

  const hashT = (keys: TemplateStringsArray, ...args: string[]) => {
    const translations = keys.map(str => (str ? t(md5(str)) : ''));
    return defaultTaggedFunction(translations, ...args);
  };

  return { t: hashT, i18n };
});

export const Trans = ({ i18nkey }: { i18nkey: string }) => {
  const { t } = useTranslationInnter();
  return <>{t(md5(i18nkey))}</>;
};

const defaultTaggedFunction = (
  keys: TemplateStringsArray | string[],
  ...args: string[]
) => {
  let res = keys[0] ?? '';
  for (let i = 0, len = args.length; i < len; i++) {
    res += args[i] + keys[i + 1];
  }
  return res;
};

export const getTFunction = () =>
  getTranslation()?.t ?? defaultTaggedFunction;

interface Resources extends Resource {
  en: {
    translations: Record<string, string>;
  };
  zh: {
    translations: Record<string, string>;
  };
}

export const LANGUAGE_LIST: {
  code: 'zh' | 'en';
  title: string;
}[] = [
  { code: 'en', title: 'English' },
  { code: 'zh', title: '中文（繁體）' },
];

export const initI18n = (resources: Resources) => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      // we init with resources
      resources,
      fallbackLng: ['en'],
      debug: true,

      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: false, // we use content as keys

      interpolation: {
        escapeValue: false,
      },
    });
};
