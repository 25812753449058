/* eslint-disable @typescript-eslint/no-unused-vars */
import { getDecimalCount } from '@aries/shared/utils/index';
import { getBalanceHub, getProviderHub } from '@aries/solana-defi/common';
import {
  USDC_REFERRAL_FEES_ADDRESS,
  USDT_REFERRAL_FEES_ADDRESS,
} from '@aries/solana-defi/config';
import { withSendTxNotify } from '@aries/solana-defi/utils/notify';
import { getWalletCtx } from '@aries/solana-defi/wallet';
import { Market, OpenOrders, TOKEN_MINTS } from '@openbook-dex/openbook';
import { type Order } from '@openbook-dex/openbook/lib/market';
import { Account, PublicKey, Transaction } from '@solana/web3.js';
import Big from 'big.js';
import { compact, flatMap, uniqBy } from 'lodash';
import { getAllOrderBook, getUserAccounts } from './data';

type ActionSide = 'bid' | 'ask';

export const getMarketActions = ({
  marketId,
  programId,
  baseMint,
  quoteMint,
}: {
  marketId: string;
  programId: string;
  baseMint: PublicKey;
  quoteMint: PublicKey;
}) => {
  const refreshBalance = () => getBalanceHub()?.refresh();
  const refreshOrderBook = () => getAllOrderBook()?.refresh();
  const refreshCurrentMarket = () =>
    getAllOrderBook()?.refreshCurrentMarket();
  const refreshUserAccount = () => getUserAccounts()?.refresh();

  const withdrawCollateral = withSendTxNotify(async () => {
    const walletAddress = getWalletCtx()?.walletAddress;
    if (!walletAddress) {
      throw new Error('Please Connect your wallet first');
    }

    const connection = getProviderHub()?.provider?.connection!;
    const openOrders = getUserAccounts()?.getAccountByMarket({
      marketId,
    })?.openOrders!;
    const market =
      getAllOrderBook()?.orderbookList.find(
        v => v.address.toString() === marketId,
      )?.value?.rawMarket ??
      (await Market.load(
        connection,
        new PublicKey(marketId),
        {},
        new PublicKey(programId),
      ));

    const tx = new Transaction();

    const baseSplAccount = await getBalanceHub()!.tryGetSplAccount(
      baseMint,
    );
    const quoteSplAccount = await getBalanceHub()!.tryGetSplAccount(
      quoteMint,
    );
    const baseCurrencyAccountPubkey = baseSplAccount.address;
    const quoteCurrencyAccountPubkey = quoteSplAccount.address;

    let referrerQuoteWallet: PublicKey | null = null;
    if (market.supportsReferralFees) {
      const usdt = TOKEN_MINTS.find(({ name }) => name === 'USDT');
      const usdc = TOKEN_MINTS.find(({ name }) => name === 'USDC');
      if (
        USDT_REFERRAL_FEES_ADDRESS &&
        usdt &&
        market.quoteMintAddress.equals(usdt.address)
      ) {
        referrerQuoteWallet = new PublicKey(USDT_REFERRAL_FEES_ADDRESS);
      } else if (
        USDC_REFERRAL_FEES_ADDRESS &&
        usdc &&
        market.quoteMintAddress.equals(usdc.address)
      ) {
        referrerQuoteWallet = new PublicKey(USDC_REFERRAL_FEES_ADDRESS);
      }
    }

    const {
      transaction: settleFundsTransaction,
      signers: settleFundsSigners,
    } = await market.makeSettleFundsTransaction(
      connection,
      openOrders,
      baseCurrencyAccountPubkey,
      quoteCurrencyAccountPubkey,
      referrerQuoteWallet,
    );

    tx.add(
      ...baseSplAccount.preIxns,
      ...quoteSplAccount.preIxns,
      settleFundsTransaction,
    );

    const res = await getProviderHub()?.provider.signAndSendTx(tx, [
      ...settleFundsSigners,
    ])!;

    await Promise.all([refreshUserAccount(), refreshBalance()]);
    return res;
  });

  const placeLimitOrder = withSendTxNotify(
    async (side: ActionSide, size: number, price: number) => {
      const walletAddress = getWalletCtx()?.walletAddress;
      if (!walletAddress) {
        throw new Error('Please Connect your wallet first');
      }

      const connection = getProviderHub()?.provider?.connection!;
      const market =
        getAllOrderBook()?.orderbookList.find(
          v => v.address.toString() === marketId,
        )?.value?.rawMarket ??
        (await Market.load(
          connection,
          new PublicKey(marketId),
          {},
          new PublicKey(programId),
        ));

      const formattedMinOrderSize =
        market?.minOrderSize?.toFixed(
          getDecimalCount(market.minOrderSize),
        ) || market?.minOrderSize;
      const formattedTickSize =
        market?.tickSize?.toFixed(getDecimalCount(market.tickSize)) ||
        market?.tickSize;
      const isIncrement = (num: number, step: number) =>
        Math.abs((num / step) % 1) < 1e-5 ||
        Math.abs(((num / step) % 1) - 1) < 1e-5;

      if (!isIncrement(size, market.minOrderSize)) {
        throw new Error(
          `Size must be an increment of ${formattedMinOrderSize}`,
        );
      }
      if (size < market.minOrderSize) {
        throw new Error(`Size too small`);
      }
      if (!isIncrement(price, market.tickSize)) {
        throw new Error(
          `Price must be an increment of ${formattedTickSize}`,
        );
      }
      if (price < market.tickSize) {
        throw new Error(`Price under tick size`);
      }

      const owner = new PublicKey(getWalletCtx()?.walletAddress!);
      const tx = new Transaction();

      const signers: Account[] = [];
      const baseSplAccount = await getBalanceHub()!.tryGetSplAccount(
        baseMint,
      );
      const quoteSplAccount = await getBalanceHub()!.tryGetSplAccount(
        quoteMint,
      );
      const payer =
        side === 'ask' ? baseSplAccount.address : quoteSplAccount.address;
      if (!payer) {
        throw new Error(`Need an SPL token account for cost currency`);
      }
      const feeDiscountKey = (
        await market.findFeeDiscountKeys(connection, owner)
      )?.[0]?.pubkey;
      const params = {
        owner,
        payer,
        side: side === 'bid' ? ('buy' as const) : ('sell' as const),
        price,
        size,
        orderType: 'limit' as const,
        feeDiscountPubkey: feeDiscountKey || null,
      };

      const { transaction: placeOrderTx, signers: placeOrderSigners } =
        await market.makePlaceOrderTransaction(
          connection,
          params,
          120_000,
          120_000,
        );
      tx.add(
        ...baseSplAccount.preIxns,
        ...quoteSplAccount.preIxns,
        market.makeMatchOrdersTransaction(5),
        placeOrderTx,
        market.makeMatchOrdersTransaction(5),
      );
      signers.push(...placeOrderSigners);

      const res = await getProviderHub()?.provider.signAndSendTx(
        tx,
        signers,
      )!;

      await Promise.all([
        refreshOrderBook(),
        refreshUserAccount(),
        refreshCurrentMarket(),
      ]);

      return res;
    },
  );

  const withdrawAllAsset = async () => {
    const walletAddress = getWalletCtx()?.walletAddress;
    if (!walletAddress) {
      throw new Error('Please Connect your wallet first');
    }

    const connection = getProviderHub()?.provider?.connection!;
    const markets = compact(
      getAllOrderBook()?.orderbookList.map(m => m.value?.rawMarket),
    );
    const programIds = uniqBy(compact(markets.map(m => m.programId)), id =>
      id.toString(),
    );
    const tx = new Transaction();
    const getOpenOrdersAccountsForProgramId = async (
      programId: PublicKey,
    ) => {
      const openOrdersAccounts = await OpenOrders.findForOwner(
        connection,
        new PublicKey(walletAddress),
        programId,
      );
      return openOrdersAccounts.filter(
        openOrders =>
          openOrders.baseTokenFree.toNumber() ||
          openOrders.quoteTokenFree.toNumber(),
      );
    };
    const openOrdersAccounts = flatMap(
      await Promise.all(
        programIds.map(programId =>
          getOpenOrdersAccountsForProgramId(programId),
        ),
      ),
      v => v,
    );

    if (!openOrdersAccounts.length) {
      return true;
    }

    return withSendTxNotify(async () => {
      const settleTransactions = (
        await Promise.all(
          openOrdersAccounts.map(async openOrdersAccount => {
            const market = markets.find(m =>
              m.decoded?.ownAddress?.equals(openOrdersAccount.market),
            );
            if (
              openOrdersAccount.baseTokenFree.isZero() &&
              openOrdersAccount.quoteTokenFree.isZero()
            ) {
              // nothing to settle for this market.
              return null;
            }
            const baseMint = market?.baseMintAddress;
            const quoteMint = market?.quoteMintAddress;

            if (!baseMint || !quoteMint) {
              return null;
            }
            const baseSplAccount = await getBalanceHub()!.tryGetSplAccount(
              baseMint,
            );
            const quoteSplAccount =
              await getBalanceHub()!.tryGetSplAccount(quoteMint);
            if (
              baseSplAccount.preIxns.length ||
              quoteSplAccount.preIxns.length
            ) {
              tx.add(
                ...baseSplAccount.preIxns,
                ...quoteSplAccount.preIxns,
              );
            }
            return (
              market &&
              market.makeSettleFundsTransaction(
                connection,
                openOrdersAccount,
                baseSplAccount.address,
                quoteSplAccount.address,
              )
            );
          }),
        )
      ).filter(
        (
          x,
        ): x is {
          signers: Account[];
          transaction: Transaction;
          payer: PublicKey;
        } => !!x,
      );
      if (!settleTransactions || settleTransactions.length === 0) {
        throw new Error('No open orders to settle');
      }

      const transactions = settleTransactions
        .slice(0, 4)
        .map(t => t.transaction);
      const signers: Array<Account> = [];
      settleTransactions
        .reduce(
          (cumulative: Array<Account>, t) => cumulative.concat(t.signers),
          [],
        )
        .forEach(signer => {
          if (!signers.find(s => s.publicKey.equals(signer.publicKey))) {
            signers.push(signer);
          }
        });

      tx.add(...transactions);
      const res = await getProviderHub()?.provider.signAndSendTx(tx, [
        ...signers,
      ])!;

      await Promise.all([refreshUserAccount()]);

      return res;
    })();
  };

  const cancelLimitOrder = withSendTxNotify(async (order: Order) => {
    const walletAddress = getWalletCtx()?.walletAddress;
    if (!walletAddress) {
      throw new Error('Please Connect your wallet first');
    }

    const connection = getProviderHub()?.provider?.connection!;
    const market =
      getAllOrderBook()?.orderbookList.find(
        v => v.address.toString() === marketId,
      )?.value?.rawMarket ??
      (await Market.load(
        connection,
        new PublicKey(marketId),
        {},
        new PublicKey(programId),
      ));

    const tx = new Transaction();
    const publicKey = new PublicKey(walletAddress);
    tx.add(
      market.makeMatchOrdersTransaction(5),
      market.makeCancelOrderInstruction(connection, publicKey, order),
      market.makeMatchOrdersTransaction(5),
    );

    const res = await getProviderHub()?.provider.signAndSendTx(tx, [])!;
    await Promise.all([
      refreshOrderBook(),
      refreshUserAccount(),
      refreshCurrentMarket(),
    ]);

    return res;
  });

  return {
    placeLimitOrder,
    cancelLimitOrder,
    withdrawCollateral,
    withdrawAllAsset,
  };
};
