import { getProviderHub } from '@aries/solana-defi/common';
import { uint64 } from '@port.finance/port-sdk';
import * as BufferLayout from '@solana/buffer-layout';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import Big from 'big.js';
import { compact } from 'lodash';
import { bigToU64, LendingInstructionKey, toAccessKeys } from './config';

/// Combines DepositReserveLiquidity and DepositObligationCollateral
///
/// Accounts expected by this instruction:
///
///   0. `[writable]` Source liquidity token account.
///                     $authority can transfer $liquidity_amount.
///   1. `[writable]` Destination collateral token account.
///   2. `[writable]` Reserve account.
///   3. `[writable]` Reserve liquidity supply SPL Token account.
///   4. `[writable]` Reserve collateral SPL Token mint.
///   5. `[]` Lending market account.
///   6. `[]` Derived lending market authority.
///   7. `[writable]` Destination deposit reserve collateral supply SPL Token account.
///   8. `[writable]` Obligation account.
///   9. `[signer]` Obligation owner.
///   10 `[signer]` User transfer authority ($authority).
///   11 `[]` Clock sysvar.
///   12 `[]` Token program id.
export const withdrawCollateralIx = ({
  reserveShareSplAccount,
  destShareSplAccount,
  reserveId,
  marketAuthorityId,
  amount,
  marketId,
  profileId,
  walletId,
  stakeAccount,
}: {
  reserveShareSplAccount: PublicKey;
  destShareSplAccount: PublicKey;
  reserveId: PublicKey;
  marketAuthorityId: PublicKey;
  profileId: PublicKey;
  marketId: PublicKey;
  walletId: PublicKey;
  amount: Big;
  stakeAccount?: {
    stakingAccountId: PublicKey;
    stakingPoolId: PublicKey;
    stakingProgramId: PublicKey;
  };
}) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
    uint64('collateralAmount'),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: LendingInstructionKey.WithdrawObligationCollateral,
      collateralAmount: bigToU64(amount),
    },
    data,
  );

  return new TransactionInstruction({
    data,
    programId: getProviderHub()!.currentNetwork.programId,
    keys: toAccessKeys(
      compact([
        { key: reserveShareSplAccount, type: 'write' },
        { key: destShareSplAccount, type: 'write' },
        { key: reserveId, type: 'write' },
        { key: profileId, type: 'write' },
        { key: marketId, type: 'read' },
        { key: marketAuthorityId, type: 'read' },
        { key: walletId, type: 'signer' },
        { key: SYSVAR_CLOCK_PUBKEY, type: 'read' },
        { key: TOKEN_PROGRAM_ID, type: 'read' },
        ...(stakeAccount
          ? ([
              { key: stakeAccount.stakingAccountId, type: 'write' },
              { key: stakeAccount.stakingPoolId, type: 'write' },
              { key: stakeAccount.stakingProgramId, type: 'read' },
            ] as const)
          : []),
      ]),
    ),
  });
};

export const redeemCollateralIx = ({
  shareAmount,
  srcShareSplAccount,
  stakeAccount,
  transferAuthority,
  marketId,
  reserveAssetSplAccount,
  shareMintId,
  marketAuthorityId,
  reserveId,
  destAssetSplAccount,
}: {
  shareAmount: Big;
  srcShareSplAccount: PublicKey;
  destAssetSplAccount: PublicKey;
  shareMintId: PublicKey;
  reserveId: PublicKey;
  marketId: PublicKey;
  reserveAssetSplAccount: PublicKey;
  marketAuthorityId: PublicKey;
  transferAuthority: PublicKey;
  stakeAccount?: {
    stakingAccountId: PublicKey;
    stakingPoolId: PublicKey;
    stakingProgramId: PublicKey;
  };
}) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
    uint64('collateralAmount'),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: LendingInstructionKey.RedeemReserveCollateral,
      collateralAmount: bigToU64(shareAmount),
    },
    data,
  );

  return new TransactionInstruction({
    data,
    programId: getProviderHub()!.currentNetwork.programId,
    keys: toAccessKeys(
      compact([
        { key: srcShareSplAccount, type: 'write' },
        { key: destAssetSplAccount, type: 'write' },
        { key: reserveId, type: 'write' },
        { key: shareMintId, type: 'write' },
        { key: reserveAssetSplAccount, type: 'write' },
        { key: marketId, type: 'read' },
        { key: marketAuthorityId, type: 'read' },
        { key: transferAuthority, type: 'signer' },
        { key: SYSVAR_CLOCK_PUBKEY, type: 'read' },
        { key: TOKEN_PROGRAM_ID, type: 'read' },
        ...(stakeAccount
          ? ([
              { key: stakeAccount.stakingAccountId, type: 'write' },
              { key: stakeAccount.stakingPoolId, type: 'write' },
              { key: stakeAccount.stakingProgramId, type: 'read' },
            ] as const)
          : []),
      ]),
    ),
  });
};
