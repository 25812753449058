/* eslint-disable  */
/* eslint-disable react/static-property-placement */
// Popover.js

import { colorConfig, globalToken } from '@aries/ui-theming';
import { Component } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import { Theme } from '../../theme';

const ScreenWidth = Dimensions.get('window').width;
export class Popover extends Component {
  static defaultProps = {
    ...View.defaultProps,
    arrow: 'none',
  };

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  filterPopoverStyle(fs, includeRadius) {
    const {
      flexDirection,
      alignItems,
      justifyContent,
      margin,
      marginBottom,
      marginHorizontal,
      marginLeft,
      marginRight,
      marginTop,
      marginVertical,
      padding,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingVertical,
      backgroundColor,
      borderBottomColor,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderBottomWidth,
      borderColor,
      borderLeftColor,
      borderLeftWidth,
      borderRadius,
      borderRightColor,
      borderRightWidth,
      borderStyle,
      borderTopColor,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderTopWidth,
      borderWidth,
      ...others
    } = fs;
    const style = includeRadius
      ? {
          borderBottomLeftRadius,
          borderBottomRightRadius,
          borderRadius,
          borderTopLeftRadius,
          borderTopRightRadius,
          ...others,
        }
      : { ...others };
    for (const key in style) {
      if (style[key] === undefined) {
        delete style[key];
      }
    }
    return style;
  }

  filterContentStyle(fs) {
    const {
      flexDirection,
      alignItems,
      justifyContent,
      margin,
      marginBottom,
      marginHorizontal,
      marginLeft,
      marginRight,
      marginTop,
      marginVertical,
      padding,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingVertical,
      backgroundColor,
      borderBottomColor,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderBottomWidth,
      borderColor,
      borderLeftColor,
      borderLeftWidth,
      borderRadius,
      borderRightColor,
      borderRightWidth,
      borderStyle,
      borderTopColor,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderTopWidth,
      borderWidth,
      ...others
    } = fs;
    const style = {
      flexDirection,
      alignItems,
      justifyContent,
      margin,
      marginBottom,
      marginHorizontal,
      marginLeft,
      marginRight,
      marginTop,
      marginVertical,
      padding,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingVertical,
      backgroundColor,
      borderBottomColor,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderBottomWidth,
      borderColor,
      borderLeftColor,
      borderLeftWidth,
      borderRadius,
      borderRightColor,
      borderRightWidth,
      borderStyle,
      borderTopColor,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderTopWidth,
      borderWidth,
    };
    for (const key in style) {
      if (style[key] === undefined) {
        delete style[key];
      }
    }
    return style;
  }

  buildStyle() {
    const { borderRadiusLG, paddingXS } = globalToken;
    const { colorBgElevatedContent, colorBgElevated } = colorConfig;
    const mode = this.context?.mode ?? 'dark';

    let {
      style,
      arrow,
      paddingCorner,
      headerStyle,
      arrowStyle,
      contentStyle,
      popoverStyle,
    } = this.props;

    style = [
      {
        backgroundColor: colorBgElevatedContent[mode],
        borderColor: colorBgElevated[mode],
        borderRadius: borderRadiusLG,
        borderWidth: Theme.pixelSize,
      },
    ].concat(style);

    const fs = StyleSheet.flatten(style);
    const { backgroundColor, borderColor, borderRadius, borderWidth } = fs;

    const arrowSize = 7; // Square side length
    let halfSquareSize = Math.sqrt(arrowSize * arrowSize * 2) / 2; // The half-length of the square diagonal: sqrt(7^2 + 7^2) / 2 = 4.95
    halfSquareSize =
      Math.ceil(halfSquareSize / Theme.pixelSize) * Theme.pixelSize;
    const headerSize = halfSquareSize + borderWidth;
    const headerPadding = headerSize - arrowSize / 2; // Let the center of square on the edge: 5 - (7 / 2) = 1.5
    const headerPaddingCorner = paddingCorner || paddingXS;
    const contentPadding = halfSquareSize;

    const headerLayouts = {
      none: {},
      topLeft: {
        top: 0,
        left: 0,
        right: 0,
        height: headerSize,
        paddingTop: headerPadding,
        alignItems: 'flex-start',
        paddingLeft: headerPaddingCorner,
      },
      top: {
        top: 0,
        left: 0,
        right: 0,
        height: headerSize,
        paddingTop: headerPadding,
        alignItems: 'center',
      },
      topRight: {
        top: 0,
        left: 0,
        right: 0,
        height: headerSize,
        paddingTop: headerPadding,
        alignItems: 'flex-end',
        paddingRight: headerPaddingCorner,
      },
      rightTop: {
        top: 0,
        bottom: 0,
        right: 0,
        width: headerSize,
        paddingRight: headerPadding,
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        paddingTop: headerPaddingCorner,
      },
      right: {
        top: 0,
        bottom: 0,
        right: 0,
        width: headerSize,
        paddingRight: headerPadding,
        alignItems: 'flex-end',
        justifyContent: 'center',
      },
      rightBottom: {
        top: 0,
        bottom: 0,
        right: 0,
        width: headerSize,
        paddingRight: headerPadding,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingBottom: headerPaddingCorner,
      },
      bottomRight: {
        bottom: 0,
        left: 0,
        right: 0,
        height: headerSize,
        paddingBottom: headerPadding,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingRight: headerPaddingCorner,
      },
      bottom: {
        bottom: 0,
        left: 0,
        right: 0,
        height: headerSize,
        paddingBottom: headerPadding,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      bottomLeft: {
        bottom: 0,
        left: 0,
        right: 0,
        height: headerSize,
        paddingBottom: headerPadding,
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingLeft: headerPaddingCorner,
      },
      leftBottom: {
        top: 0,
        bottom: 0,
        left: 0,
        width: headerSize,
        paddingLeft: headerPadding,
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: headerPaddingCorner,
      },
      left: {
        top: 0,
        bottom: 0,
        left: 0,
        width: headerSize,
        paddingLeft: headerPadding,
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      leftTop: {
        top: 0,
        bottom: 0,
        left: 0,
        width: headerSize,
        paddingLeft: headerPadding,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingTop: headerPaddingCorner,
      },
    };
    const arrowLayouts = {
      none: {},
      topLeft: { transform: [{ rotate: '45deg' }] },
      top: { transform: [{ rotate: '45deg' }] },
      topRight: { transform: [{ rotate: '45deg' }] },
      rightTop: { transform: [{ rotate: '135deg' }] },
      right: { transform: [{ rotate: '135deg' }] },
      rightBottom: { transform: [{ rotate: '135deg' }] },
      bottomRight: { transform: [{ rotate: '225deg' }] },
      bottom: { transform: [{ rotate: '225deg' }] },
      bottomLeft: { transform: [{ rotate: '225deg' }] },
      leftBottom: { transform: [{ rotate: '315deg' }] },
      left: { transform: [{ rotate: '315deg' }] },
      leftTop: { transform: [{ rotate: '315deg' }] },
    };
    const popoverLayouts = {
      none: {},
      topLeft: { paddingTop: contentPadding },
      top: { paddingTop: contentPadding },
      topRight: { paddingTop: contentPadding },
      rightTop: { paddingRight: contentPadding },
      right: { paddingRight: contentPadding },
      rightBottom: { paddingRight: contentPadding },
      bottomRight: { paddingBottom: contentPadding },
      bottom: { paddingBottom: contentPadding },
      bottomLeft: { paddingBottom: contentPadding },
      leftBottom: { paddingLeft: contentPadding },
      left: { paddingLeft: contentPadding },
      leftTop: { paddingLeft: contentPadding },
    };

    if (!arrow) arrow = 'none';
    let useArrow = arrow;
    switch (arrow) {
      case 'topLeft':
      case 'topRight':
        if (headerPaddingCorner + contentPadding > this.state.width / 2)
          useArrow = 'top';
        break;
      case 'rightTop':
      case 'rightBottom':
        if (headerPaddingCorner + contentPadding > this.state.height / 2)
          useArrow = 'right';
        break;
      case 'bottomRight':
      case 'bottomLeft':
        if (headerPaddingCorner + contentPadding > this.state.width / 2)
          useArrow = 'bottom';
        break;
      case 'leftBottom':
      case 'leftTop':
        if (headerPaddingCorner + contentPadding > this.state.height / 2)
          useArrow = 'left';
        break;
    }

    arrowStyle = {
      backgroundColor,
      width: arrowSize,
      height: arrowSize,
      borderColor,
      borderTopWidth: borderWidth,
      borderLeftWidth: borderWidth,
      ...arrowLayouts[useArrow],
    };
    contentStyle = this.filterContentStyle(fs);
    popoverStyle = [
      this.filterPopoverStyle(fs, useArrow === 'none'),
      {
        backgroundColor:
          useArrow === 'none'
            ? colorBgElevatedContent[mode]
            : 'rgba(0, 0, 0, 0)', // Transparent background will cause a warning at debug mode
      },
    ].concat(popoverLayouts[useArrow]);
    headerStyle = {
      position: 'absolute',
      overflow: 'hidden',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      ...headerLayouts[useArrow],
    };

    return { popoverStyle, contentStyle, headerStyle, arrowStyle };
  }

  onLayout(e) {
    const { width, height } = e.nativeEvent.layout;
    if (width != this.state.width || height != this.state.height) {
      this.setState({ width, height });
    }
    this.props.onLayout && this.props.onLayout(e);
  }

  render() {
    const { style, children, arrow, paddingCorner, onLayout, ...others } =
      this.props;
    let { popoverStyle, contentStyle, headerStyle, arrowStyle } =
      this.buildStyle();

    popoverStyle = StyleSheet.flatten(popoverStyle);

    const rawLeft = popoverStyle.left;
    const rawWidth = popoverStyle.width;

    let offset = 0;

    if (rawLeft + rawWidth > ScreenWidth) {
      offset = rawLeft + rawWidth - ScreenWidth + 5;
    }

    if (rawLeft < 0) {
      offset = rawLeft - 5;
    }

    return (
      <View
        style={[popoverStyle, { left: rawLeft - offset }]}
        onLayout={e => this.onLayout(e)}
        {...others}
      >
        <View style={contentStyle}>{children}</View>
        {!arrow || arrow === 'none' ? null : (
          <View style={headerStyle}>
            <View style={[arrowStyle, { left: offset }]} />
          </View>
        )}
      </View>
    );
  }
}

Popover.contextType = ThemeContext;
