import styled from 'styled-components/native';

export type BaseViewProps = {
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;

  pd?: number;
  pt?: number;
  pr?: number;
  pl?: number;
  pb?: number;
  gap?: number;

  flex?: number;

  w?: number | string;
  h?: number | string;

  bck?: string;
};

export const View = styled.View<BaseViewProps>`
  ${p => (p.mg ? `margin:${p.mg}px` : '')};
  ${p => (p.gap ? `gap:${p.gap}px` : '')};

  ${p => (p.ml ? `margin-left:${p.ml}px` : '')};
  ${p => (p.mr ? `margin-right:${p.mr}px` : '')};
  ${p => (p.mt ? `margin-top:${p.mt}px` : '')};
  ${p => (p.mb ? `margin-bottom:${p.mb}px` : '')};

  ${p => (p.pd ? `padding:${p.pd}px` : '')};
  ${p => (p.pl ? `padding-right:${p.pl}px` : '')};
  ${p => (p.pr ? `padding-right:${p.pr}px` : '')};
  ${p => (p.pt ? `padding-top:${p.pt}px` : '')};
  ${p => (p.pb ? `padding-bottom:${p.pb}px` : '')};

  ${p => (p.flex ? `flex:${p.flex}` : '')};
  ${p => (p.w ? `width:${p.w}px` : '')};
  ${p => (p.h ? `height:${p.h}px` : '')};
  ${p => (p.bck ? `backgroundColor:${p.bck}` : '')};
`;

export const Row: typeof View = styled(View)`
  flex-direction: row;
`;

export const Col: typeof View = styled(View)`
  flex-direction: column;
`;

export const EndCol: typeof View = styled(View)`
  align-items: flex-end;
`;

export const BetweenCol: typeof View = styled(View)`
  justify-content: space-between;
`;

export const CenterCol = styled(View)<{
  fullWidth?: boolean;
}>`
  align-items: center;
  width: ${p => (p.fullWidth ? '100%' : 'auto')};
`;

export const CenterRow: typeof View = styled(View)`
  flex-direction: row;
  align-items: center;
`;

export const CenterBetweenRow: typeof View = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BetweenRow: typeof View = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const EndRow: typeof View = styled(View)`
  flex-direction: row;
  align-items: flex-end;
`;

export const EndStartRow: typeof View = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const EndBetweenRow: typeof View = styled(View)`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
