import { MARKETS, TOKEN_MINTS } from '@openbook-dex/openbook';
import { noop } from 'lodash';
import { MarketInfo } from '../types';

// Used in debugging, should be false in production
const IGNORE_DEPRECATED = false;

export const useMarkets: MarketInfo[] = IGNORE_DEPRECATED
  ? MARKETS.map(m => ({ ...m, deprecated: false }))
  : MARKETS;

export const useOrderbookList = () => {
  const orderbookList = useMarkets
    .filter(
      ({ name, deprecated }) =>
        !deprecated &&
        !process.env.REACT_APP_EXCLUDE_MARKETS?.includes(name),
    )
    .map(m => {
      const [baseSymbol, quoteSymbol] = m.name.split('/');
      return {
        ...m,
        marketId: m.address.toBase58(),
        baseCoinAddress: TOKEN_MINTS.find(
          token => token.name === baseSymbol,
        )?.address?.toBase58()!,
        quoteCoinAddress: TOKEN_MINTS.find(
          token => token.name === quoteSymbol,
        )?.address?.toBase58()!,
      };
    })
    .filter(v => v.baseCoinAddress && v.quoteCoinAddress);

  return { refresh: noop, orderbookList, loading: false };
};
