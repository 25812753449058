/* eslint-disable import/no-mutable-exports */
import { OrderBookAppDefine } from './type';

// Singleton lending app state store
export let useOrderbookApp: () => OrderBookAppDefine;

export const initTradingAppImpl = (adapter: {
  hook: typeof useOrderbookApp;
}) => {
  useOrderbookApp = adapter.hook;
};

export { type OrderBookAppDefine } from './type';
