/* eslint-disable no-underscore-dangle */
import { Big, useDeferredState } from '@aries/shared/utils';
import { getProviderHub } from '@aries/solana-defi/common';
import { Market, Orderbook } from '@openbook-dex/openbook';
import { sortBy, uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useAccountInfoHub } from '../account-info';
import { MarketInfo } from '../types';

export const useOrderbookDetailHub = () => {
  type OrderbookData = { value: OrderbookDetail | null; loading: boolean };
  const [orderbookMap, requestUpdateOrderbookMap] = useDeferredState<
    Record<string, OrderbookData>
  >({}, 100);
  const { getAccountInfo, subscribeAccountInfo, accountInfoMap } =
    useAccountInfoHub();

  useEffect(() => {
    Object.entries(orderbookMap).forEach(([marketId, info]) => {
      const updateOrderbook = (
        updateFn: (oldValue: OrderbookData) => OrderbookData,
      ) =>
        requestUpdateOrderbookMap(currentMap => ({
          ...currentMap,
          [`${marketId}`]: updateFn(
            currentMap[`${marketId}`] ?? {
              loading: true,
              value: null,
            },
          ),
        }));

      const market = info.value?.rawMarket;
      if (market) {
        const bidsData = getAccountInfo(market.bidsAddress);
        const asksData = getAccountInfo(market.asksAddress);
        if (bidsData) {
          const bids = groupOrders(
            Orderbook.decode(market, bidsData)
              .getL2(100)
              .map(([price, size]) => ({ price, size }))
              .filter(v => v.size !== 0),
            true,
          );
          const maxBidLamports = (() => {
            let maxPrice = Big(bids[0]?.price ?? 0);
            for (const o of bids) {
              if (maxPrice.lt(o.price)) {
                maxPrice = Big(o.price);
              }
            }
            return maxPrice;
          })();
          updateOrderbook(({ value: oldValue }) => ({
            loading: false,
            value: {
              tradings: [],
              asks: [],
              minAskLamports: Big(0),
              rawMarket: market,
              ...oldValue,
              bids,
              maxBidLamports,
            },
          }));
        }
        if (asksData) {
          const asks = groupOrders(
            Orderbook.decode(market, asksData)
              .getL2(100)
              .map(([price, size]) => ({ price, size }))
              .filter(v => v.size !== 0),
            false,
          );
          const minAskLamports = (() => {
            let minPrice = Big(asks[0]?.price ?? 0);
            for (const o of asks) {
              if (minPrice.gt(o.price)) {
                minPrice = Big(o.price);
              }
            }
            return minPrice;
          })();
          updateOrderbook(({ value: oldValue }) => ({
            loading: false,
            value: {
              tradings: [],
              bids: [],
              maxBidLamports: Big(0),
              rawMarket: market,
              ...oldValue,
              asks,
              minAskLamports,
            },
          }));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfoMap, orderbookMap, requestUpdateOrderbookMap]);

  const triggerLoadOrderbookDetail = async (marketInfo: MarketInfo) => {
    const { address: marketId } = marketInfo;
    const updateOrderbook = (
      updateFn: (oldValue: OrderbookData) => OrderbookData,
    ) =>
      requestUpdateOrderbookMap(currentMap => ({
        ...currentMap,
        [`${marketId.toBase58()}`]: updateFn(
          currentMap[`${marketId.toBase58()}`] ?? {
            loading: true,
            value: null,
          },
        ),
      }));

    updateOrderbook(({ value }) => ({ loading: true, value }));
    try {
      const market = await Market.load(
        getProviderHub()?.provider?.connection!,
        marketInfo.address,
        {},
        marketInfo.programId,
      );
      subscribeAccountInfo(market.bidsAddress);
      subscribeAccountInfo(market.asksAddress);
      const orderbookDetail = await getOrderbookDetail(market);
      updateOrderbook(({ value: oldValue }) => ({
        loading: false,
        value: {
          tradings: [],
          rawMarket: market,
          ...oldValue,
          ...orderbookDetail,
        },
      }));

      const tradings = await getMarketTradings(market);
      updateOrderbook(({ value }) => ({
        loading: false,
        value: value ? { ...value, tradings } : null,
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `err: fetch econia trading market ${marketId.toBase58()}`,
      );
      updateOrderbook(() => ({ loading: false, value: null }));
    }
  };

  return {
    orderbookMap,
    triggerLoadOrderbookDetail,
  };
};

const getOrderbookDetail = async (market: Market) => {
  const connection = getProviderHub()?.provider?.connection!;
  if (!market)
    return {
      asks: [],
      bids: [],
      maxBidLamports: Big(0),
      minAskLamports: Big(0),
    };
  const bidOrdersL2 = (await market.loadBids(connection))
    .getL2(100)
    .map(([price, size]) => ({ price, size }))
    .filter(v => v.size !== 0);
  const askOrdersL2 = (await market.loadAsks(connection))
    .getL2(100)
    .map(([price, size]) => ({ price, size }))
    .filter(v => v.size !== 0);

  const bids = groupOrders(bidOrdersL2, true);
  const asks = groupOrders(askOrdersL2, false);

  const maxBidLamports = (() => {
    let maxPrice = Big(bids[0]?.price ?? 0);
    for (const o of bids) {
      if (maxPrice.lt(o.price)) {
        maxPrice = Big(o.price);
      }
    }
    return maxPrice;
  })();

  const minAskLamports = (() => {
    let minPrice = Big(asks[0]?.price ?? 0);
    for (const o of asks) {
      if (minPrice.gt(o.price)) {
        minPrice = Big(o.price);
      }
    }
    return minPrice;
  })();

  return {
    asks,
    bids,
    maxBidLamports,
    minAskLamports,
  };
};

const groupOrders = (
  arr: { size: number; price: number }[],
  isBid: boolean,
) => {
  const sorted = sortBy(
    arr.map(({ size, price }) => {
      // Considering lot size
      return {
        price: `${price}`,
        priceNum: price,
        lamports: Big(size),
      };
    }),
    v => (isBid ? -v.priceNum : v.priceNum),
  );

  let cumulativeTotal = Big(0);

  const totalLamports = sorted.reduce(
    (sum, cur) => sum.add(cur.lamports),
    Big(0),
  );

  return sorted.map(v => {
    cumulativeTotal = cumulativeTotal.add(v.lamports);
    return {
      ...v,
      totalLamports: cumulativeTotal,
      depthPct:
        (totalLamports.eq(0)
          ? 0
          : cumulativeTotal.div(totalLamports).toNumber()) * 100,
    };
  });
};

const getMarketTradings = async (
  market: Market,
): Promise<OrderbookDetail['tradings']> => {
  const connection = getProviderHub()?.provider?.connection!;
  if (!market) return [];
  return (
    market.loadFills(connection, 10000).then(fills =>
      uniqBy(
        fills
          .filter(({ eventFlags }) => eventFlags.maker)
          .map(event => {
            const { clientOrderId, orderId, price, size, side } = event;
            return {
              id: `${orderId.toString()}-${clientOrderId.toString()}`,
              priceLamports: Big(price),
              isBid: side === 'buy',
              lamports: Big(size),
            };
          }),
        v => v.id,
      ),
    ) ?? []
  );
};

type OrderbookDetail = {
  rawMarket: Market;
  asks: {
    totalLamports: Big;
    depthPct: number;
    price: string;
    priceNum: number;
    lamports: Big;
  }[];
  bids: {
    totalLamports: Big;
    depthPct: number;
    price: string;
    priceNum: number;
    lamports: Big;
  }[];
  tradings: {
    id: string;
    priceLamports: Big;
    lamports: Big;
    isBid: boolean;
  }[];
  maxBidLamports: Big;
  minAskLamports: Big;
};
