import {
  CenterRow,
  Row,
  SkypeIndicator,
  Txt,
  View,
} from '@aries/mobile-core/components';
import { Drawer } from '@aries/mobile-core/Smoothy';
import {
  ScreenHeight,
  ScreenInset,
  ScreenWidth,
} from '@aries/mobile-core/utils';
import {
  useCurrentMarket,
  useMarkets,
  useOrderbookApp,
} from '@aries/trading-fe-hooks';
import { globalToken } from '@aries/ui-theming';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import { FirstPageWrapper, Search, TokenIcon } from '~/components';
import { Orderbook } from './OrderbookView';
import { Orders } from './Orders';
import { PlaceOrder } from './PlaceOrder';

export const Trading = () => {
  const [currentMarketId, setMarketId] = useState<string>();

  const data = useOrderbookApp();

  const market = useCurrentMarket();

  useEffect(() => {
    if (data.markets.length > 0 && !currentMarketId) {
      setMarketId(data.markets[0].id);
    }
  }, [data, currentMarketId]);

  if (
    (data.marketEmpty && data.markets.length === 0) ||
    !market ||
    !currentMarketId
  ) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          display: 'flex',
        }}
      >
        <SkypeIndicator size={40} />
      </View>
    );
  }

  return (
    <FirstPageWrapper
      headerLeft={
        <CenterRow flex={1}>
          <MarketSelect
            onPress={() => {
              openMarketSelect(id => {
                setMarketId(id);
              });
            }}
          >
            <TokenIcon asset={market.baseAsset} size={20} />
            <TokenIcon
              asset={market.quoteAsset}
              size={20}
              style={{ marginLeft: -10 }}
            />
            <Txt bold ml={5}>
              {market.baseAsset.symbol}/{market.quoteAsset.symbol}
            </Txt>
          </MarketSelect>
        </CenterRow>
      }
    >
      <Row mt={16}>
        <Orderbook marketId={currentMarketId} />
        <PlaceOrder marketId={currentMarketId} />
      </Row>

      <Orders marketId={currentMarketId} />
    </FirstPageWrapper>
  );
};

const openMarketSelect = (onSelect: (marketId: string) => void) => {
  const res = Drawer.open(
    <MarketListPanel
      onSelect={marketId => {
        onSelect(marketId);
        res.close();
      }}
    />,
    'left',
  );
};

const MarketListPanel: React.FC<{
  onSelect: (marketId: string) => void;
}> = ({ onSelect }) => {
  const markets = useMarkets();
  const [search, setSearch] = useState('');
  const filteredMarkets = useMemo(() => {
    if (search === '') return markets;
    return markets.filter(market => {
      const { baseAsset, quoteAsset } = market;

      return [
        baseAsset.name,
        baseAsset.symbol,
        quoteAsset.name,
        quoteAsset.symbol,
      ].some(v => v.toLowerCase().includes(search.toLowerCase()));
    });
  }, [markets, search]);

  return (
    <MarketList>
      <Txt size={20} bold mb={14}>
        Markets
      </Txt>
      <Search
        placeholder="Search Markets"
        value={search}
        onChange={setSearch}
      />
      {filteredMarkets.map(market => {
        return (
          <MarketItem
            onPress={() => {
              onSelect(market.id);
            }}
            key={market.id}
          >
            <CenterRow flex={1}>
              <TokenIcon asset={market.baseAsset} size={28} />
              <TokenIcon
                asset={market.quoteAsset}
                size={28}
                style={{ marginLeft: -16 }}
              />
              <Txt bold ml={5} h3>
                {market.baseAsset.symbol}
                <Txt h2 c2 bold>
                  /{market.quoteAsset.symbol}
                </Txt>
              </Txt>
            </CenterRow>
            <Txt colorRank="success" bold>
              {/* ${market.orderbook.minAsk} */}
            </Txt>
          </MarketItem>
        );
      })}
    </MarketList>
  );
};

const { colorBgContainer, margin, colorBgSpotlight, borderRadiusLG } =
  globalToken;
const MarketList = styled.ScrollView`
  width: ${ScreenWidth * 0.75};
  height: ${ScreenHeight};
  background-color: ${colorBgContainer};
  padding-top: ${ScreenInset.top + 30};
  padding-left: ${margin};
  padding-right: ${margin};
`;

const MarketItem = styled.TouchableOpacity`
  background-color: ${colorBgSpotlight};
  border-radius: ${borderRadiusLG};
  padding: 12px 12px;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const MarketSelect = styled.TouchableOpacity`
  background-color: ${colorBgSpotlight};
  border-radius: ${borderRadiusLG};
  padding: 0px 12px;
  flex-direction: row;
  align-items: center;
  height: 35px;
`;
