import { useEffect, useState } from 'react';
import { getMintInfo } from '@project-serum/common';
import {
  QuarryWrapper,
  QuarrySDK,
  RewarderWrapper,
} from '@quarryprotocol/quarry-sdk';
import { ChainId, Token } from '@saberhq/token-utils';
import { PublicKey } from '@solana/web3.js';
import {
  useProviderHub,
  getTokenInfoHub,
} from '@aries/solana-defi/common/index';
import { createGlobalStore } from 'hox';
import { useAnchorProvider } from './anchor-provider';
import { useReservesSubscription } from '../reserves/index';
import { useLatest } from '@aries/solana-defi/../shared/hooks/helper';

import {
  AdditionalReward,
  Apy,
  MintId,
  ReserveInfo,
} from '@port.finance/port-sdk';
import Big from 'big.js';

export const [useReservesQuarryApys, getReservesQuarryApys] =
  createGlobalStore(() => {
    const { results: reserves } = useReservesSubscription();
    const rewardSDK = usePortRewardWrapper();
    const provider = useAnchorProvider();

    const [reserveQuarryWrapperMap, setReserveQuarryWrapperMap] = useState<
      Record<string, QuarryWrapper>
    >({});
    const reserveQuarryWrapperMapRef = useLatest(reserveQuarryWrapperMap);

    useEffect(() => {
      reserves.map(async ({ value: reserve, pubkey: reserveId }) => {
        const quarryMintId = reserve.getShareMintId();
        if (!reserveQuarryWrapperMapRef.current[reserveId]) {
          try {
            const quarryMintInfo = await getMintInfo(
              provider,
              quarryMintId,
            );

            const quarryToken = Token.fromMint(
              quarryMintId,
              quarryMintInfo?.decimals ?? 6,
              {
                chainId: ChainId.MainnetBeta,
              },
            );

            if (quarryToken) {
              const wrapper = await rewardSDK?.getQuarry(quarryToken);
              if (wrapper) {
                setReserveQuarryWrapperMap(prev => ({
                  ...prev,
                  [reserveId]: wrapper,
                }));
              }
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('[Load Quarry]', 'load quarry data failed');
          }
        }
      });
    }, [
      provider,
      reserveQuarryWrapperMapRef,
      reserves,
      rewardSDK,
      setReserveQuarryWrapperMap,
    ]);

    return { reserveQuarryWrapperMap };
  });

const PORT_QUARRY_ADDRESS = new PublicKey(
  'GuHrjvzqDvLTB27ebd9iFKwceCxKvSswzTByDQUTsvdm',
);

// calculate apy on Quarry, just port token apy now
const HIDE_QUARRY_APY = ['mSOL', 'USDC'];
const PORT_MINT = 'PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y';
export const getReserveQuarryReward = (reserve: ReserveInfo) => {
  const reserveId = reserve.getReserveId().toString();
  const quarryWrapper =
    getReservesQuarryApys()?.reserveQuarryWrapperMap?.[reserveId];
  const tokenMap = getTokenInfoHub()?.emptyableTokenMap;

  if (
    !quarryWrapper?.quarryData ||
    HIDE_QUARRY_APY.includes(
      tokenMap?.[reserve.getAssetMintId().toString()]?.symbol ?? '',
    )
  )
    return null;

  const portAsset = tokenMap?.[PORT_MINT];
  const portPrice = portAsset?.price ?? Big(0);
  const priceValue =
    tokenMap?.[reserve.getAssetMintId().toString()]?.price ?? Big(0);

  const { annualRewardsRate, totalTokensDeposited, tokenMintDecimals } =
    quarryWrapper.quarryData;
  if (totalTokensDeposited.isZero() || priceValue.eq(0)) {
    return null;
  }

  const quarryApy = Apy.of(
    new Big(annualRewardsRate.toString())
      .mul(new Big(10).pow(-(portAsset?.decimals ?? 6)))
      .mul(portPrice)
      .div(
        new Big(totalTokensDeposited.toString())
          .mul(new Big(10).pow(-tokenMintDecimals))
          .mul(priceValue),
      ),
  );

  return new AdditionalReward(
    reserve.getReserveId(),
    quarryApy,
    true,
    MintId.fromBase58(PORT_MINT),
  );
};

// REMIND: only available on mainnet
const usePortRewardWrapper = () => {
  const [sdk, setSdk] = useState<RewarderWrapper>();
  const { provider: solanaProvider } = useProviderHub();

  useEffect(() => {
    const initSdk = () => {
      const SDK = QuarrySDK.load({
        provider: solanaProvider,
      });

      SDK.mine
        .loadRewarderWrapper(PORT_QUARRY_ADDRESS)
        .then(wrapper => {
          setSdk(wrapper);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('[QUARRY_SDK]', 'load rewarder wrapper failed', e);
        });
    };

    initSdk();
  }, [solanaProvider]);

  return sdk;
};
