/* eslint-disable  */
// ModalIndicatorView.js

import PropTypes from 'prop-types';
import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import { colorConfig, globalToken } from '@aries/ui-theming';
import { ThemeContext } from 'styled-components/native';
import Overlay from '../Overlay/Overlay';

export default class ModalIndicatorView extends Overlay.View {
  static propTypes = {
    ...Overlay.View.propTypes,
    text: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.number,
    ]),
    position: PropTypes.oneOf(['top', 'bottom', 'center']),
    size: PropTypes.oneOfType([
      PropTypes.oneOf(['small', 'large']),
      PropTypes.number,
    ]),
    color: PropTypes.string,
  };

  static defaultProps = {
    ...Overlay.View.defaultProps,
    modal: true,
    position: 'center',
    size: 'large',
  };

  constructor(props) {
    super(props);
    Object.assign(this.state, {
      text: props.text,
    });
  }

  get text() {
    return this.state.text;
  }

  set text(value) {
    this.setState({ text: value });
  }

  buildStyle() {
    let { style, position } = this.props;
    const {
      paddingLeftMdIndicator,
      paddingRightMdIndicator,
      paddingTopMdIndicator,
      paddingBottomMdIndicator,
    } = globalToken.ModalIndicator;

    style = [
      {
        paddingLeft: paddingLeftMdIndicator,
        paddingRight: paddingRightMdIndicator,
        paddingTop: paddingTopMdIndicator,
        paddingBottom: paddingBottomMdIndicator,
        justifyContent:
          position === 'top'
            ? 'flex-start'
            : position === 'bottom'
            ? 'flex-end'
            : 'center',
        alignItems: 'center',
      },
    ].concat(super.buildStyle());
    return style;
  }

  renderContent() {
    const { size, color } = this.props;
    const { text } = this.state;
    const mode = this.context?.mode ?? 'dark';
    const { colorTextMdIndicator, colorBgMdIndicator } =
      colorConfig.ModalIndicator;
    const { fontSizeMdIndicator, paddingTopMdIndicatorTxt } =
      globalToken.ModalIndicator;

    return (
      <View style={{ alignItems: 'center' }}>
        <ActivityIndicator
          size={size}
          color={color || colorBgMdIndicator[mode]}
        />
        {React.isValidElement(text) ? (
          text
        ) : (
          <Text
            style={{
              color: colorTextMdIndicator[mode],
              fontSize: fontSizeMdIndicator,
              paddingTop: paddingTopMdIndicatorTxt,
            }}
          >
            {text}
          </Text>
        )}
      </View>
    );
  }
}

ModalIndicatorView.contextType = ThemeContext;
