import { compact, flatMap, keyBy } from 'lodash';
import { useReservesSubscription } from '../reserves';
import { useMemo } from 'react';
import { AdditionalReward, ReserveInfo } from '@port.finance/port-sdk';
import Big from 'big.js';

export type GetRewardsByReservePlugin = (
  reserve: ReserveInfo,
) => AdditionalReward | AdditionalReward[] | null;

export const useReserveRewards = (
  getRewardsPlgugins: GetRewardsByReservePlugin[],
  deps = [] as any[],
) => {
  const { results: reserves } = useReservesSubscription();

  const rewards = useMemo(() => {
    return reserves.map(({ value: reserve, pubkey }) => {
      const rewards = compact(
        flatMap(getRewardsPlgugins, p => p(reserve)),
      ).filter(r => !r.getApy().isTrivial());

      const apys = rewards.map(r => ({
        apy: r.getApy().getPct()?.toOneBasedNumber(6) ?? 0,
        mintId: r.getMintId() ?? reserve.getAssetMintId(),
      }));

      const totalApy = Big(apys.reduce((s, v) => s + v.apy, 0) * 100);

      return {
        reserveId: pubkey,
        apys,
        totalApy,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserves, ...deps]);

  const rewardMap = useMemo(
    () => keyBy(rewards, r => r.reserveId),
    [rewards],
  );

  return { rewardMap, rewards };
};
