import { CenterCol, Txt } from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import portLogo from '@aries/solana-mobile/assets/logo/logo.png';
import React from 'react';
import { Image } from 'react-native';
import { FirstPageWrapper } from '~/components';

export const Bridge = () => {
  const { t } = useTranslation();
  return (
    <FirstPageWrapper>
      <CenterCol
        style={{ height: '100%', justifyContent: 'center' }}
        gap={20}
      >
        <Image
          source={{ uri: portLogo }}
          style={{ height: 150, width: 150 }}
        />
        <CenterCol fullWidth>
          <Txt
            h4
            bold
            style={{
              width: '80%',
              textAlign: 'center',
            }}
          >
            {t`Use Port Finance at desktop to bridge tokens between blockchains easily`}
          </Txt>
        </CenterCol>
      </CenterCol>
    </FirstPageWrapper>
  );
};
