import { setTheme } from '@aries/ui-theming';

const colorBgAcSheetItem = {
  dark: 'rgba(255, 255, 255, 1)',
  light: 'rgba(0, 0, 0, 1)',
};
const colorTitleAcSheetItem = {
  dark: '#000',
  light: '#fff',
};
const colorAcSheetCancelItem = {
  dark: 'rgba(255, 255, 255, 1)',
  light: 'rgba(0, 0, 0, 1)',
};
const colorSeparatorAcSheetItem = {
  dark: 'dee1ec',
  light: 'dee1ec',
};
const colorTitleAcSheetCancelItem = {
  dark: '#FF6666',
  light: '#FF6666',
};
const colorSeparatorAcSheetCancelItem = {
  dark: 'dee1ec',
  light: 'dee1ec',
};

export const actionSheetToken = {
  opacityAcSheetItemDisabled: 0.65,
  minHeightAcSheetItem: 53,
  paddingLeftAcSheetItem: 12,
  paddingRightAcSheetItem: 12,
  paddingTopAcSheetItem: 8,
  paddingBottomAcSheetItem: 8,
  FontSizeAcSheetItem: 19,
  lineWidthSeparatorAcSheetCancelItem: 7,
  fontSizeAcSheetCancelItem: 19,
  colorBgAcSheetItem: setTheme(colorBgAcSheetItem),
  colorTitleAcSheetItem: setTheme(colorTitleAcSheetItem),
  colorAcSheetCancelItem: setTheme(colorAcSheetCancelItem),
  colorSeparatorAcSheetItem: setTheme(colorSeparatorAcSheetItem),
  colorTitleAcSheetCancelItem: setTheme(colorTitleAcSheetCancelItem),
  colorSeparatorAcSheetCancelItem: setTheme(
    colorSeparatorAcSheetCancelItem,
  ),
};

export const actionSheetConfig = {
  colorBgAcSheetItem,
  colorTitleAcSheetItem,
  colorAcSheetCancelItem,
  colorSeparatorAcSheetItem,
  colorTitleAcSheetCancelItem,
  colorSeparatorAcSheetCancelItem,
};
