import {
  getBalanceHub,
  getProviderHub,
  hasEnoughGasOrThrow,
} from '@aries/solana-defi/common';
import { getProfileHub } from '@aries/solana-defi/lending/data/profile';
import { withSendTxNotify } from '@aries/solana-defi/utils';
import { Lamport } from '@port.finance/port-sdk';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Keypair, Transaction } from '@solana/web3.js';
import Big from 'big.js';
import { depositAndAddCollateralIx } from './instructions/deposit';
import { refreshReserveIx } from './instructions/refresh-reserve';
import { getReserveCtx } from './utils';

export const depositAndAddCollateral = withSendTxNotify(
  async (reserveKey: string, lamports: Big) => {
    hasEnoughGasOrThrow();
    const { provider } = getProviderHub()!;

    const {
      walletPubkey,
      marketPubkey,
      profilePubkey,
      reserveId,
      assetMintId,
      shareMintId,
      stakeAccount,
      marketAuthority,
      reserveSDK,
    } = await getReserveCtx(reserveKey);

    const tx = new Transaction();

    const srcSplAccount = await getBalanceHub()!.tryGetSplAccount(
      assetMintId,
      lamports,
    );

    const destSplAccount = await getBalanceHub()!.tryGetSplAccount(
      shareMintId,
    );

    const transferAuthorityKeypair = Keypair.generate();

    tx.add(
      ...srcSplAccount.preIxns,
      ...destSplAccount.preIxns,
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        srcSplAccount.address,
        transferAuthorityKeypair.publicKey,
        walletPubkey,
        [],
        lamports.toNumber(),
      ),
      refreshReserveIx(reserveSDK),
      ...(stakeAccount?.ixns ?? []),
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        destSplAccount.address,
        transferAuthorityKeypair.publicKey,
        walletPubkey,
        [],
        Lamport.max().toU64(),
      ),
      depositAndAddCollateralIx({
        srcSplAccount: srcSplAccount.address,
        destSplAccount: destSplAccount.address,
        reserveId,
        marketAuthorityId: marketAuthority,
        transferAuthority: transferAuthorityKeypair.publicKey,
        reserveSplAccount: reserveSDK.asset.getSplAccountId(),
        reserveShareSplAccount: reserveSDK.getShareBalanceId(),
        amount: lamports,
        shareMintId,
        marketId: marketPubkey,
        stakeAccount,
        walletId: walletPubkey,
        profileId: profilePubkey,
      }),
      Token.createRevokeInstruction(
        TOKEN_PROGRAM_ID,
        srcSplAccount.address,
        walletPubkey,
        [],
      ),
      Token.createRevokeInstruction(
        TOKEN_PROGRAM_ID,
        destSplAccount.address,
        walletPubkey,
        [],
      ),
    );

    const res = await provider.signAndSendTx(tx, [
      transferAuthorityKeypair,
      ...(stakeAccount?.signers ?? []),
    ]);

    getProfileHub()?.refresh();
    getBalanceHub()?.refresh();

    return res;
  },
);
