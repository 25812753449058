import { Big } from '@aries/shared/utils';
import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { useProfile, useReserveById } from './base';

const useDisableInput = () => {
  const { currentProfile } = useProfile();
  return !currentProfile;
};

export const useDeposit = (reserveId: string) => {
  const { reserve } = useReserveById(reserveId);
  const [amount, setAmount] = useState(Big(-1));
  const [loading, setLoading] = useState(false);
  const disableInput = useDisableInput();

  if (!reserve) {
    return null;
  }

  const onConfirm = async () => {
    setLoading(true);

    const success = await reserve!.actions.deposit(amount.toNumber());
    setLoading(false);
    setAmount(Big(0));

    return success;
  };

  const depositable = reserve.deposit.ofUser?.depositable;
  const maxVal = depositable?.amountNum ?? 0;

  const disableConfirm = amount.lte(0);

  const shouldRepayFirst =
    (reserve.borrow.ofUser?.borrowed?.amountNum ?? 0) > 0;
  const depositedAmount = reserve.deposit.ofUser?.deposited.amount ?? '-';

  const reachedDepositLimit =
    !shouldRepayFirst && !!reserve.deposit.reachedDepositLimit;

  const depositApy = reserve.deposit.totalApy.base;
  const isApyPositive = reserve.deposit.totalApy.isPositive;

  return {
    amount,
    max: maxVal,
    loading,
    disableConfirm,
    reserve,
    reachedDepositLimit,
    shouldRepayFirst,
    depositedAmount,
    depositApy,
    isApyPositive,
    onConfirm,
    onChange: setAmount,
    disableInput,
  };
};

export const useWithdraw = (reserveId: string) => {
  const { reserve } = useReserveById(reserveId);

  const [amount, setAmount] = useState(Big(-1));
  const [loading, setLoading] = useState(false);
  const isMaxRef = useRef(false);
  const disableInput = useDisableInput();

  if (!reserve) return null;

  const { ofUser } = reserve.deposit;
  const canWithdrawAll =
    !isNil(ofUser) &&
    ofUser.withdrawable
      .mul(100)
      .div(ofUser.deposited.amountNum || 1)
      .gt(99.9);
  const onConfirm = async () => {
    setLoading(true);
    const success = await reserve!.actions.withdraw(
      amount.toNumber(),
      canWithdrawAll && isMaxRef.current,
    );
    setLoading(false);
    setAmount(Big(0));
    return success;
  };

  const max = ofUser?.withdrawable ?? Big(0);

  const disableConfirm = amount.lte(0);

  return {
    amount,
    reserve,
    loading,
    max,
    isMaxRef,
    onConfirm,
    onChange: setAmount,
    disableConfirm,
    disableInput,
  };
};

export const useBorrow = (reserveId: string) => {
  const { reserve } = useReserveById(reserveId);
  const { exitEmode } = useProfile();
  const [amount, setAmount] = useState(Big(-1));
  const [loading, setLoading] = useState(false);
  const disableInput = useDisableInput();

  if (!reserve) return null;

  const onConfirm = async () => {
    setLoading(true);
    const success = await reserve!.actions.borrow(amount.toNumber());
    setLoading(false);
    setAmount(Big(0));
    return success;
  };

  const max = reserve.borrow.ofUser?.available.amountNum ?? 0;
  const hasDeposit = (reserve.deposit.ofUser?.withdrawable ?? 0) > 0;

  const disableConfirm = hasDeposit || amount.lte(0);

  const { shouldExitEmode } = reserve.borrow;
  return {
    amount,
    reserve,
    loading,
    max,
    onConfirm,
    onChange: setAmount,
    exitEmode,
    shouldWithdrawFirst: hasDeposit,
    disableConfirm,
    disableInput,
    shouldExitEmode,
  };
};

export const useRepay = (reserveId: string) => {
  const { reserve } = useReserveById(reserveId);
  const [amount, setAmount] = useState(Big(-1));
  const [loading, setLoading] = useState(false);
  const isMaxRef = useRef(false);
  const disableInput = useDisableInput();

  if (!reserve) {
    return null;
  }

  const onConfirm = async () => {
    setLoading(true);
    const success = await reserve!.actions.repay(
      amount.toNumber(),
      isMaxRef.current,
    );
    setLoading(false);
    setAmount(Big(0));

    return success;
  };

  const { ofUser } = reserve.borrow;
  const max = Math.min(
    ofUser?.borrowed.amountNum ?? 0,
    Math.max((reserve.wallet?.amountNum ?? 0) * 1, 0),
  );

  const borrowed = ofUser?.borrowed.amountNum ?? 0;

  const disableConfirm = amount.lte(0);

  const onChange = (amount: Big) => {
    setAmount(amount.gte(max) ? Big(max) : amount);
    if (amount.gt(max) || amount.lt(borrowed)) {
      isMaxRef.current = false;
    }
  };

  return {
    reserve,
    amount,
    loading,
    borrowed,
    max,
    isMaxRef,
    onChange,
    onConfirm,
    disableConfirm,
    disableInput,
  };
};
