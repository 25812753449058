import { createIcon } from '@aries/mobile-core/components';

export const IconTabBarLendingActive = createIcon(
  require('%/assets/bottomTab/lendingActive.png'),
);
export const IconTabBarLending = createIcon(
  require('%/assets/bottomTab/lending.png'),
);

export const IconTabBarTradingActive = createIcon(
  require('%/assets/bottomTab/tradingActive.png'),
);
export const IconTabBarTrading = createIcon(
  require('%/assets/bottomTab/trading.png'),
);

export const IconTabBarBrowser = createIcon(
  require('%/assets/bottomTab/browser.png'),
);
export const IconTabBarBrowserActive = createIcon(
  require('%/assets/bottomTab/browserActive.png'),
);

export const IconTabBarSwapActive = createIcon(
  require('%/assets/bottomTab/swapActive.png'),
);
export const IconTabBarSwap = createIcon(
  require('%/assets/bottomTab/swap.png'),
);

export const IconTabBarWalletActive = createIcon(
  require('%/assets/bottomTab/walletActive.png'),
);
export const IconTabBarWallet = createIcon(
  require('%/assets/bottomTab/wallet.png'),
);

export const IconTabBarBridgeActive = createIcon(
  require('%/assets/bottomTab/bridgeActive.png'),
);
export const IconTabBarBridge = createIcon(
  require('%/assets/bottomTab/bridge.png'),
);
