import { Asset, Big, EMPTY_BALANCE } from '@aries/defi-toolkit/types';
import { createStore } from '@aries/shared/deps';
import { Swap } from '@aries/trading-fe-hooks';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  getBinanceRatePlugin,
  getCoinGeckoRatePlugin,
  useCEXPrices,
} from './exchanges';
import { usePairSelectOptions } from './pair-select-options';
import { usePairState } from './pair-selection';
import { useSwapMarket } from './providers/index';
import { JupiterProvider as JupiterProviderImpl } from '@jup-ag/react-hook';
import { useBalanceHub, useProviderHub } from '@aries/solana-defi/common';
import { useSolanaLendingApp } from '@aries/solana-defi/lending';
import { noop } from 'lodash';
import { useWallet } from '@aries/solana-defi/wallet/index';
import { PublicKey } from '@solana/web3.js';

export const useSwapByInitial: (
  initialValue?: Partial<{
    fromAsset: Asset;
    toAsset: Asset;
    fromAmount: number;
  }>,
) => Swap = initialValue => {
  // Manage select state
  const {
    currentPair: { fromAsset, fromAmount, toAsset },
    changePair,
    changeFromAsset,
    changeFromAmount,
    changeToAsset,
    changeDirection,
    setInitialSwapPair,
  } = usePairState(initialValue);
  const [slippage, setSlippage] = useState(0.5);
  const [activeRoute, setActiveRoute] =
    useState<Swap['currentPair']['routes'][number]>();
  const [allowBorrow] = useState(false);

  // Fetch pair data
  const {
    totalAssetList,
    routes: rawRoutes,
    loading: routeLoading,
    getHasRoutesByPair,
  } = useSwapMarket(
    fromAsset?.id ?? 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    toAsset?.id ?? 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    1,
    fromAsset?.toLamports(fromAmount)?.gt(0)
      ? fromAsset?.toLamports(fromAmount)
      : Big(1000000),
  );

  const hasRoutes = useMemo(
    () => getHasRoutesByPair(fromAsset, toAsset),
    [fromAsset, toAsset, getHasRoutesByPair],
  );
  const prevRoutes = useRef<Swap['currentPair']['routes']>([]);
  const routes = useMemo(() => {
    if (rawRoutes.length) {
      const newRoutes = rawRoutes.map((r, i) => ({
        ...r,
        swap: () => r.swap(false),
        setActive: () => setActiveRoute(newRoutes[i]),
      }));
      setActiveRoute(newRoutes[0]);
      prevRoutes.current = newRoutes;
      return newRoutes;
    }
    return prevRoutes.current;
  }, [rawRoutes]);

  const CEXPrices = useCEXPrices(
    [getCoinGeckoRatePlugin, getBinanceRatePlugin],
    fromAsset,
    toAsset,
    activeRoute?.exchangeRate,
  );

  // Calculate select options by current selection
  const { fromList, toList } = usePairSelectOptions(totalAssetList, false);

  // Fetch asset wallet
  const { getBalance } = useBalanceHub();
  const { reserves } = useSolanaLendingApp();
  const fromAssetBalance =
    fromAsset?.lamportsToBalance(getBalance(fromAsset.id)) ??
    EMPTY_BALANCE;
  const { borrowableAmount: maxFromAmount, maxLeverage } = {
    borrowableAmount: fromAssetBalance.amountNum,
    maxLeverage: 0,
  };
  const borrowableFromAmount = maxFromAmount - fromAmount;
  const insufficientFunds =
    fromAmount > maxFromAmount || maxFromAmount === 0;
  const toAssetBalance =
    toAsset?.lamportsToBalance(getBalance(toAsset.id)) ?? EMPTY_BALANCE;
  const reserve = reserves.find(r => {
    return r?.id === fromAsset?.id;
  });
  const borrowMeta = {
    borrowApy: reserve?.borrow.baseApy.base ?? '-',
    borrowApyPositive: !!reserve?.borrow.baseApy.isPositive,
  };
  const willBorrowAmount = Number(
    Math.max(0, fromAmount - fromAssetBalance.amountNum).toFixed(
      fromAsset?.decimals,
    ),
  );

  useEffect(() => {
    if (fromAsset && !fromList.find(a => a.id === fromAsset.id)) {
      changeFromAsset(undefined);
    }

    if (toAsset && !toList.find(a => a.id === toAsset.id)) {
      changeToAsset(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromList, toList, fromAsset, toAsset]);

  return {
    fromList,
    toList,
    allowBorrow,
    slippage,
    currentPair: {
      fromAsset,
      fromAmount,
      fromAssetBalance,
      toAsset,
      toAmount: activeRoute?.receive ?? 0,
      toAssetBalance,
      maxFromAmount,
      borrow: {
        borrowableFromAmount,
        willBorrowAmount,
        maxLeverage,
        ...borrowMeta,
      },
      insufficientFunds,
      hasRoutes,
      routes,
      activeRoute,
      routeLoading: routeLoading && !routes.length,
      changeActiveRoute: setActiveRoute,
      CEXPrices,
    },
    setSlippage,
    changePair,
    changeFromAsset,
    changeFromAmount,
    changeToAsset,
    changeToAmount: noop,
    changeDirection,
    setAllowBorrow: noop,
    setInitialSwapPair,
  };
};

export const [useSwapContext, SwapProvider] = createStore(() =>
  useSwapByInitial(),
);

export const JupiterProvider = ({ children }: { children: ReactNode }) => {
  const { provider } = useProviderHub();
  const { walletAddress } = useWallet();

  const userPublicKey = useMemo(
    () => (walletAddress ? new PublicKey(walletAddress) : undefined),
    [walletAddress],
  );
  return (
    <JupiterProviderImpl
      connection={provider.connection}
      userPublicKey={userPublicKey}
    >
      {children}
    </JupiterProviderImpl>
  );
};
