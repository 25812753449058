import { ImageSourcePropType, ImageStyle, StyleProp } from 'react-native';
import styled from 'styled-components/native';
import { BaseViewProps } from './layout';

export { Image } from 'react-native';

export type ImageIconProps = {
  size?: number;
  style?: StyleProp<ImageStyle>;
  proportion?: number;
} & BaseViewProps;

export const ImageIcon = (
  props: {
    src: ImageSourcePropType;
    size?: number;
    style?: StyleProp<ImageStyle>;
    proportion?: number;
  } & BaseViewProps,
) => {
  const { src, style, size = 20, proportion = 1, ...rest } = props;

  return (
    <ImageBox
      {...rest}
      source={src}
      style={[{ height: size * proportion, width: size }, style]}
    />
  );
};

/**
 *
 * @param proportion  height/width
 * @returns <Image/>
 */
export const createIcon = (
  src: ImageSourcePropType,
  proportion?: number,
) => {
  return (props: ImageIconProps) => (
    <ImageIcon src={src} proportion={proportion} {...props} />
  );
};

export const ImageBox = styled.Image<BaseViewProps>`
  ${p => (p.mg ? `margin:${p.mg}px` : '')};

  ${p => (p.ml ? `margin-left:${p.ml}px` : '')};
  ${p => (p.mr ? `margin-right:${p.mr}px` : '')};
  ${p => (p.mt ? `margin-top:${p.mt}px` : '')};
  ${p => (p.mb ? `margin-bottom:${p.mb}px` : '')};

  ${p => (p.pd ? `padding:${p.pd}px` : '')};
  ${p => (p.pl ? `padding-right:${p.pl}px` : '')};
  ${p => (p.pr ? `padding-right:${p.pr}px` : '')};
  ${p => (p.pt ? `padding-top:${p.pt}px` : '')};
  ${p => (p.pb ? `padding-bottom:${p.pb}px` : '')};

  ${p => (p.flex ? `flex:${p.flex}` : '')};
  ${p => (p.w ? `width:${p.w}px` : '')};
  ${p => (p.h ? `height:${p.h}px` : '')};
  ${p => (p.bck ? `backgroundColor:${p.bck}` : '')};
`;
