import { useProviderHub } from '@aries/solana-defi/common';
import { useWallet } from '@aries/solana-defi/wallet';
import { createGlobalStore } from '@aries/shared/deps';
import Big from 'big.js';
import { keyBy, mapValues } from 'lodash';
import { useEffect } from 'react';
import useSWR from 'swr';
import { useOrderbookList } from './orderbook/list';
import { PublicKey } from '@solana/web3.js';
import { Market } from '@openbook-dex/openbook';

type MarketInfo = {
  marketId: string;
  baseCoinType: string;
  quoteCoinType: string;
};

export const NO_CUSTODIAN = Big(0);
export const [useUserAccounts, getUserAccounts] = createGlobalStore(() => {
  const { walletAddress } = useWallet();
  const {
    currentNetwork,
    provider: { connection },
    env,
  } = useProviderHub();
  const { orderbookList } = useOrderbookList();

  const {
    data: accountByMarketPair = {},
    mutate,
    isValidating: loading,
  } = useSWR(
    [
      'OpenbookUserOrderBook',
      walletAddress,
      currentNetwork,
      env,
      orderbookList.length,
    ],
    async () => {
      if (!walletAddress) return {};

      const accounts = await Promise.all(
        orderbookList.map(async m => {
          const publicKey = new PublicKey(walletAddress);
          const market = await Market.load(
            connection,
            m.address,
            undefined,
            m.programId,
          );
          const orders = await market.loadOrdersForOwner(
            connection,
            publicKey,
            30000,
          );

          const openbookAccounts =
            await market.findOpenOrdersAccountsForOwner(
              connection,
              publicKey,
            );
          return { orders, openbookAccounts, market: m };
        }),
      );

      return mapValues(
        keyBy(accounts, a => a.market.marketId),
        ({ orders, openbookAccounts }) => {
          const currentAccount = openbookAccounts?.[0];
          const baseAvailable = Big(
            currentAccount?.baseTokenFree?.toString() ?? 0,
          );
          const quoteAvailable = Big(
            currentAccount?.quoteTokenFree?.toString() ?? 0,
          );
          const baseTotal = Big(
            currentAccount?.baseTokenTotal?.toString() ?? 0,
          );
          const quoteTotal = Big(
            currentAccount?.quoteTokenTotal?.toString() ?? 0,
          );
          return {
            marketAccountId: currentAccount?.address?.toString(),
            openOrders: currentAccount,
            canWithdraw: baseAvailable?.gt(0) || quoteAvailable?.gt(0),
            baseTotal,
            quoteTotal,
            baseAvailable,
            quoteAvailable,
            asks: orders.filter(o => o.side === 'sell') ?? [],
            bids: orders.filter(o => o.side === 'buy') ?? [],
          };
        },
      );
    },
    { refreshInterval: 10 * 1000 },
  );

  const getAccountByMarket = (
    market: Pick<MarketInfo, 'marketId'>,
  ): typeof accountByMarketPair[string] | null => {
    return accountByMarketPair[market.marketId.toString()] ?? null;
  };

  const refresh = () => mutate(v => (v ? { ...v } : {}), true);
  useEffect(() => {
    if (walletAddress) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return {
    accountByMarket: accountByMarketPair,
    loading,
    getAccountByMarket,
    refresh,
  };
});
