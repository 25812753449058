import { LendingAppDefine } from '@aries/lending-fe-hooks/adapter/type';
import { createGlobalStore } from '@aries/shared/deps';
import { formatPercentage, formatQuoteValue } from '@aries/shared/utils';
import {
  EMPTY_TOKEN,
  useBalanceHub,
  useProviderHub,
  useTokenInfoHub,
} from '@aries/solana-defi/common';
import Big from 'big.js';
import { noop, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useWallet } from '../wallet/index';
import { addProfile, getReservesActions } from './actions';
import { useFaucet } from './data/faucet';
import { useUnhealthyProfiles } from './data/liquidate';
import { useProfileHub } from './data/profile';
import { useReserves } from './data/reserves';
import { getAdditionalRewards } from './data/rewards/additional-rewards';
import { useReserveRewards } from './data/rewards/index';
import {
  getReserveQuarryReward,
  useReservesQuarryApys,
} from './data/rewards/quarry-reward';
import { useStakingPools } from './data/staking-pool';

/**
 * Lending app impl on aptos blockchain
 */
const useSolanaLendingAppImpl: () => LendingAppDefine = () => {
  const walletCtx = useWallet();
  const { env } = useProviderHub();
  const { balanceMap } = useBalanceHub();
  const { tokenMap } = useTokenInfoHub();
  const { reserves: reserveMetas, loading } = useReserves();

  const { reserveQuarryWrapperMap } = useReservesQuarryApys();
  const { poolToRewardAccounts } = useStakingPools();
  const { rewardMap } = useReserveRewards(
    [getAdditionalRewards, getReserveQuarryReward],
    [reserveQuarryWrapperMap, poolToRewardAccounts],
  );

  const {
    hasInitialized,
    changeProfile,
    currentProfile,
    currentProfileKey,
    profileList,
    profileLoading,
  } = useProfileHub();

  const { faucets } = useFaucet();

  const reserves = useMemo(
    () =>
      reserveMetas.map(({ coinAddress, ...reserve }) => {
        const tokenInfo = tokenMap[coinAddress] ?? EMPTY_TOKEN;
        const {
          reserveConfig: {
            liquidationBonusBips,
            liquidationThreshold,
            borrowLimit,
            depositLimit,
            allowCollateral,
            allowRedeem,
            loanToValue,
          },
          notify,
          totalBorrowed,
          supplyApyPct,
          borrowApyPct,
          totalDeposited,
          marketCap,
          borrowFeePct,
          maxInterestRatePct,
          optimalInterestRatePct,
          optimalUtilizationPct,
          flashLoanFeePct,
          reserveId,
          lpCoinAddress,
          minInterestRatePct,
          utilization,
        } = reserve;

        const depositRewards = rewardMap[reserve.reserveId];
        const rewardAssets = depositRewards?.apys?.map(
          ({ mintId, apy }) => ({
            ...tokenMap[mintId.toString()],
            rewardApy: `${(apy * 100).toFixed(2)}%`,
          }),
        );

        const rawActions = getReservesActions({ ...reserve, coinAddress });

        const actions: LendingAppDefine['reserves'][number]['actions'] = {
          withdraw: (amount, isMax) =>
            rawActions.withdraw(tokenInfo.toLamports(amount), isMax),
          repay: (amount, isMax) =>
            rawActions.repay(tokenInfo.toLamports(amount), isMax),
          deposit: amount =>
            rawActions.deposit(tokenInfo.toLamports(amount)),
          borrow: amount =>
            rawActions.borrow(tokenInfo.toLamports(amount)),
        };

        const calcApyProps = (baseApyPct: Big, rewardApyPct: Big) => {
          const totalApyPct = baseApyPct.add(rewardApyPct);
          return {
            baseApy: {
              base: `${baseApyPct.toFixed(2)}%`,
              isPositive: baseApyPct.gte(0),
              num: baseApyPct.toNumber(),
            },
            rewardApy: !rewardApyPct.eq(0)
              ? {
                  // It might be very large
                  base: formatPercentage(rewardApyPct),
                  isPositive: rewardApyPct.gte(0),
                  num: rewardApyPct.toNumber(),
                }
              : undefined,
            totalApy: {
              base: formatPercentage(totalApyPct),
              isPositive: totalApyPct.gte(0),
              num: totalApyPct.toNumber(),
            },
          };
        };

        const supplyRewardApyPct = Big(0);
        const borrowRewardApyPct = Big(0);

        return {
          coinAddress,
          id: reserveId,
          rawReserve: reserve,
          meta: {
            loanToValue: `${loanToValue}%`,
            loanToValuePct: loanToValue,
            liquidationThreshold: `${liquidationThreshold}%`,
            liquidationBonus: `${liquidationBonusBips.toNumber() / 100}%`,
            allowCollateral,
            allowWithdraw: allowRedeem,
            lpCoinAddress,
            coinAddress,
            borrowLimit: tokenInfo.toAmountStr(borrowLimit),
            borrowLimitNum: tokenInfo.toAmount(borrowLimit).toNumber(),
            depositLimit: tokenInfo.toAmountStr(depositLimit),
            depositLimitNum: tokenInfo.toAmount(depositLimit).toNumber(),
            borrowFeePct: borrowFeePct.toNumber(),
            maxInterestRatePct,
            minInterestRatePct,
            optimalInterestRatePct,
            optimalUtilizationPct,
            flashLoanFeePct,
            supplyRewardApyPct,
            borrowRewardApyPct,
          },
          status: {
            marketCap: tokenInfo.lamportsToBalance(marketCap),
            utilization,
          },
          asset: tokenInfo,
          deposit: {
            ...calcApyProps(supplyApyPct, depositRewards.totalApy),
            rewardAssets,
            balance: tokenInfo.lamportsToBalance(totalDeposited),
          },
          borrow: {
            ...calcApyProps(borrowApyPct, Big(0).sub(borrowRewardApyPct)),
            rewardAssets: [],
            balance: tokenInfo.lamportsToBalance(totalBorrowed),
          },
          actions,
          notify,
        };
      }),

    [tokenMap, reserveMetas, rewardMap],
  );

  const reserveWithProfile: LendingAppDefine['reserves'] = useMemo(() => {
    const list = reserves.map(
      ({
        id: reserveId,
        coinAddress,
        status: reserveStatus,
        ...reserve
      }) => {
        const tokenInfo = reserve.asset;

        const walletAmount = tokenInfo.lamportsToBalance(
          balanceMap[coinAddress]?.lamports ?? Big(0),
        );

        const depositedAsset =
          currentProfile?.getDepositedLamports(reserveId) ?? Big(0);

        const borrowedLamports =
          currentProfile?.getBorrowedLamports(reserveId) ?? Big(0);

        const withdrawableAmount =
          currentProfile?.getWithdrawableAmount(reserveId) ?? Big(0);

        const borrowableAmount =
          currentProfile?.getBorrowableAmount(reserveId) ?? Big(0);

        const depositable =
          currentProfile?.getDepositableAmount(
            reserveId,
            walletAmount.lamports,
          ) ?? Big(0);

        return {
          id: reserveId,
          ...reserve,
          wallet: walletAmount,
          summary: {
            marketCap: reserveStatus.marketCap,
            utilization: reserveStatus.utilization,
            ofUser: {
              totalMarginValueUSD: `$${currentProfile?.totalDepositedUSD?.toFixed(
                3,
              )}`,
            },
          },
          deposit: {
            ...reserve.deposit,
            ofUser: {
              deposited: tokenInfo.lamportsToBalance(depositedAsset),
              withdrawable: tokenInfo.toAmount(withdrawableAmount),
              depositable: tokenInfo.toBalance(depositable),
            },
          },
          borrow: {
            ...reserve.borrow,
            ofUser: {
              available: tokenInfo.toBalance(borrowableAmount),
              borrowed: tokenInfo.lamportsToBalance(borrowedLamports),
            },
          },
        };
      },
    );

    return sortBy(list, v => -v.summary.marketCap.valueUSDNum);
  }, [reserves, balanceMap, currentProfile]);

  const summary = useMemo(() => {
    let marketSize = Big(0);
    let borrowed = Big(0);

    reserves.forEach(reserve => {
      marketSize = marketSize.add(reserve.deposit.balance.valueUSDNum);
      borrowed = borrowed.add(reserve.borrow.balance.valueUSDNum);
    });

    return {
      marketSize: formatQuoteValue(marketSize.toNumber()),
      borrowed: formatQuoteValue(borrowed.toNumber()),
      lentOut: marketSize.eq(0)
        ? '-'
        : `${borrowed.div(marketSize).mul(100).toFixed(1)}%`,
    };
  }, [reserves]);

  const profiles = profileList.map(
    ({
      id,
      name,
      availableMarginUSD,
      totalDepositedUSD,
      totalLoanUSD,
      networth,
      loanList,
      borrowPower,
      depositList,
      riskFactorPct,
      totalApyPct,
    }) => ({
      id,
      name,
      summary: {
        apy: totalApyPct,
        isApyPositive: totalApyPct > 0,
        depositedValueUSD: `$${totalDepositedUSD.toFixed(2)}`,
        borrowedValueUSD: `$${totalLoanUSD.toFixed(2)}`,
        availableMarginUSD: `$${
          availableMarginUSD.lt(0) ? 0 : availableMarginUSD.toFixed(2)
        }`,
        networth: `$${networth.toFixed(2)}`,
        borrowingPowerPct: borrowPower,
        loans: loanList,
        deposits: depositList,
        rewards: [],
        rewardValueUSD: '$0.00',
        riskFactorPct,
        claimRewards: noop,
      },
    }),
  );

  const { unhealthyProfiles, loading: profileListLoading } =
    useUnhealthyProfiles();
  return {
    reserves: reserveWithProfile,
    reserveLoading: loading,
    reserveEmpty: false,
    unhealthyProfiles,
    obligationEmpty: !profileListLoading && unhealthyProfiles.length === 0,
    obligationLoading:
      unhealthyProfiles.length === 0 && profileListLoading,
    triggerRefreshObligation: noop as any,
    profile: {
      initProfile: () => addProfile(true),
      addProfile: () => addProfile(false),
      changeProfile,
      initialized: hasInitialized,
      list: profiles,
      currentProfile: profiles.find(p => p.id === currentProfileKey),
      currentProfileId: currentProfileKey,
      currentProfileLoading: profileLoading,
    },
    summary,
    faucets,
    env,
    wallet: walletCtx,
  };
};

export const [useSolanaLendingApp, getSolanaLendingApp] =
  createGlobalStore(useSolanaLendingAppImpl);
