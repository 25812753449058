import { Col } from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import styled from 'styled-components/native';

const {
  paddingSM,
  marginSM,
  borderRadiusLG,
  colorBgElevated,
  colorBgSpotlight,
  colorInteractive,
} = globalToken;

export const Card = styled(Col)<{ active?: boolean }>`
  padding-top: ${marginSM};
  margin-bottom: ${marginSM};
  padding-bottom: ${paddingSM};
  padding-left: ${paddingSM};
  padding-right: ${paddingSM};

  border-radius: ${borderRadiusLG};

  background-color: ${p =>
    p.active ? colorBgElevated : colorBgSpotlight};

  border-width: ${p => (p.active ? '1px' : undefined)};
  border-style: solid;
  border-color: ${p => (p.active ? colorInteractive : undefined)};
`;

export const AvatarBox = styled.TouchableOpacity`
  border-radius: ${borderRadiusLG};
  padding: 0px 12px;
  flex-direction: row;
  align-items: center;
  height: 35px;
`;
