/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useBalanceHub,
  useProviderHub,
  useTokenInfoHub,
} from '@aries/solana-defi/common';
import { Asset, Balance, Big } from '@aries/defi-toolkit/types';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import useSWR from 'swr';
import { Port, PortProfile } from '@port.finance/port-sdk';
import { useLendingMarkets } from '../market';
import { PublicKey } from '@solana/web3.js';
import { shortenAddress } from '@aries/solana-defi/../shared/utils/index';
import { useReserves } from './reserves';
import { UnhealthyProfile } from '@aries/solana-defi/../lending-fe-hooks/index';
import { liquidateByPayingToken } from '../actions/liquidate';

export const useUnhealthyProfiles = () => {
  const { tokenMap } = useTokenInfoHub();
  const { balanceMap } = useBalanceHub();
  const { reserveMap, reserves } = useReserves();
  const { connection } = useProviderHub().provider;
  const { activeMarketId } = useLendingMarkets();

  const { data = [], isValidating: loading } = useSWR(
    ['Unhealthy Profiles', connection],
    async () => {
      const port = Port.forMainNet({
        connection,
        lendingMarket: new PublicKey(activeMarketId),
      });
      const allPortProfiles = await port.getAllPortProfiles();

      return allPortProfiles.filter(p =>
        [hasBorrow, (p: PortProfile) => !willNeverLiquidate(p)].reduce(
          (acc: boolean, filter) => acc && filter(p),
          true,
        ),
      );
    },
  );

  const unhealthyProfiles = useMemo(() => {
    const list = data.map(v => {
      return {
        id: `${v.getProfileId().toString()}`,
        profile: shortenAddress(v.getProfileId().toString()),
        liquidate: async (from: Asset, to: Asset, amount: number) => {
          const repayReserve = reserves.find(
            r => from.id === r.coinAddress,
          );
          const withdrawReserve = reserves.find(
            r => to.id === r.coinAddress,
          );
          if (!repayReserve || !withdrawReserve) {
            return false;
          }
          return liquidateByPayingToken(
            Big(amount),
            repayReserve.reserveId,
            withdrawReserve.reserveId,
            v,
          );
        },
        predictReceive: (from: Asset, to: Asset, amount: number) => {
          const receiveLamports =
            v
              .getCollaterals()
              .find(
                c =>
                  reserveMap[c.getReserveId().toString()]?.coinAddress ===
                  to.id,
              )
              ?.getRaw() ?? Big(0);

          return to.lamportsToBalance(receiveLamports);
        },
        getMaxInput: (from: Asset, to: Asset) => {
          const maxAmount = balanceMap[from.id]?.lamports ?? Big(0);
          return from.lamportsToBalance(maxAmount);
        },
        ...generateEnrichedObligation(v, reserveMap, tokenMap),
      };
    });

    return sortBy(list, v => -v.riskPct);
  }, [data, reserveMap, tokenMap, reserves, balanceMap]);

  return { unhealthyProfiles, loading };
};

const generateEnrichedObligation = (
  obligation: PortProfile,
  reserveMap: ReturnType<typeof useReserves>['reserveMap'],
  tokenMap: ReturnType<typeof useTokenInfoHub>['tokenMap'],
): Pick<
  UnhealthyProfile,
  | 'borrowedValueUSD'
  | 'borrowedAssets'
  | 'depositedAssets'
  | 'depositedValueUSD'
  | 'riskPct'
  | 'canLiquidate'
> => {
  let loanValue = new Big(0);
  const borrowedAssets: { balance: Balance; asset: Asset }[] = [];
  for (const borrow of obligation.getLoans()) {
    const reserve = reserveMap[borrow.getReserveId().toString()];
    const asset = tokenMap[reserve?.coinAddress];

    if (!asset || !reserve) {
      continue;
    }
    const balance = asset.lamportsToBalance(
      borrow.accrueInterest(borrow.getCumulativeBorrowRate()).getRaw(),
    );
    loanValue = loanValue.add(balance.valueUSDNum);
    borrowedAssets.push({ asset, balance });
  }

  let liquidationLoanValue: Big = new Big(0);
  let collateralValueWithoutThreshold = new Big(0);
  const depositedAssets: { balance: Balance; asset: Asset }[] = [];
  for (const deposit of obligation.getCollaterals()) {
    const reserve = reserveMap[deposit.getReserveId().toString()];
    const asset = tokenMap[reserve?.coinAddress];

    if (!asset || !reserve) {
      continue;
    }
    const balance = asset.lamportsToBalance(
      deposit
        .getRaw()
        .div(
          reserve.rawReserve.getExchangeRatio().getPct()?.getRaw() ??
            Big(1),
        ),
    );
    const totalLiquidatePrice = Big(balance.valueUSDNum).mul(
      reserve.liquidationThresholdRatio,
    );
    liquidationLoanValue = liquidationLoanValue.add(totalLiquidatePrice);
    collateralValueWithoutThreshold = collateralValueWithoutThreshold.add(
      balance.valueUSDNum,
    );
    depositedAssets.push({ asset, balance });
  }

  const riskPct: number =
    liquidationLoanValue.eq(0) || loanValue.eq(0)
      ? 0
      : loanValue.div(liquidationLoanValue).mul(100).round(2).toNumber();

  return {
    borrowedValueUSD: loanValue.round(2).toNumber(),
    depositedValueUSD: collateralValueWithoutThreshold.round(2).toNumber(),
    riskPct,
    borrowedAssets,
    depositedAssets,
    canLiquidate: riskPct > 100,
  };
};

const hasBorrow = (obligation: PortProfile): boolean => {
  return obligation.getLoans().length !== 0;
};

const willNeverLiquidate = (obligation: PortProfile): boolean => {
  const loans = obligation.getLoans();
  const collaterals = obligation.getCollaterals();
  return (
    loans.length === 1 &&
    collaterals.length === 1 &&
    loans[0].getReserveId().toString() ===
      collaterals[0].getReserveId().toString()
  );
};
