import {
  Asset,
  Balance,
  EMPTY_ASSET,
  EMPTY_BALANCE,
} from '@aries/defi-toolkit/types';

export type CurrentOrders = {
  id: string;
  type: 'bid' | 'ask';
  amount: string;
  price: string;
  total: string;
  cancel: () => Promise<boolean>;
}[];

export type UserMarketAccount = {
  baseCollateral: {
    total: Balance;
    available: Balance;
    withdrawable: Balance;
  };
  quoteCollateral: {
    total: Balance;
    available: Balance;
    withdrawable: Balance;
  };
  orders: CurrentOrders;
  addBaseColateral: (amount: number) => Promise<boolean>;
  withdrawBaseColateral: (amount: number) => Promise<boolean>;
  addQuoteColateral: (amount: number) => Promise<boolean>;
  withdrawQuoteColateral: (amount: number) => Promise<boolean>;
  withdrawAllFunds: () => Promise<boolean>;
  hasWithdrawableFunds: boolean;
};

export const EMPTY_MARKET_ACCOUNT: UserMarketAccount = {
  baseCollateral: {
    total: EMPTY_BALANCE,
    available: EMPTY_BALANCE,
    withdrawable: EMPTY_BALANCE,
  },
  quoteCollateral: {
    total: EMPTY_BALANCE,
    available: EMPTY_BALANCE,
    withdrawable: EMPTY_BALANCE,
  },
  orders: [],
  addBaseColateral: () => Promise.resolve(true),
  withdrawBaseColateral: () => Promise.resolve(true),
  addQuoteColateral: () => Promise.resolve(true),
  withdrawQuoteColateral: () => Promise.resolve(true),
  withdrawAllFunds: () => Promise.resolve(true),
  hasWithdrawableFunds: false,
};

export type OrderBook = {
  marketId: string;
  maxBid: string;
  maxBidNum: number;
  minAsk: string;
  minAskNum: number;
  bids: {
    total: number;
    depthPct: number;
    price: string;
    priceNum: number;
    amount: number;
  }[];
  asks: {
    total: number;
    depthPct: number;
    price: string;
    priceNum: number;
    amount: number;
  }[];
};

export const EMPTY_ORDERBOOK: OrderBook = {
  marketId: '',
  maxBid: '0',
  maxBidNum: 0,
  minAsk: '0',
  minAskNum: 0,
  bids: [],
  asks: [],
};

export type SwapRoute = {
  id: string;
  name: string;
  dexes?: (
    | { name: string; logo: (...params: any[]) => JSX.Element }
    | {
        name: string;
        logo: (...params: any[]) => JSX.Element;
        pct: number;
      }[]
  )[];
  dexesPath?: string;
  desc: string;
  minReceive: number;
  receive: number;
  receiveValueUSD: string;
  cost: number;
  slippagePct: number;
  exchangeRate: number;
  swap: () => Promise<boolean>;
  setActive: () => void;
};

export type Swap = {
  fromList: (Asset & {
    deposit?: Balance;
    wallet?: Balance;
    isCommon?: boolean;
  })[];
  toList: (Asset & {
    deposit?: Balance;
    wallet?: Balance;
    isCommon?: boolean;
  })[];
  allowBorrow: boolean;
  slippage: number;
  currentPair: {
    fromAsset?: Asset;
    toAsset?: Asset;
    fromAmount: number;
    toAmount: number;
    fromAssetBalance?: Balance;
    toAssetBalance?: Balance;
    maxFromAmount: number;
    insufficientFunds: boolean;
    borrow: {
      borrowableFromAmount: number;
      willBorrowAmount: number;
      borrowApy: string;
      borrowApyPositive: boolean;
      maxLeverage: number;
    };
    CEXPrices: {
      name: string;
      uri: string;
      comparePercentage: number;
      isPositive: boolean;
    }[];
    routes: SwapRoute[];
    activeRoute: SwapRoute | undefined;
    routeLoading: boolean;
    hasRoutes: boolean;
    changeActiveRoute: (route: SwapRoute) => void;
  };
  setSlippage: (v: number) => void;
  setInitialSwapPair: (address1: string, address2: string) => void;
  setAllowBorrow: (val: boolean) => void;
  changeFromAsset: (asset: Asset | undefined) => void;
  changeFromAmount: (v: number) => void;
  changeToAsset: (asset: Asset | undefined) => void;
  changeToAmount: (v: number) => void;
  changeDirection: () => void;
  changePair: (from: Asset | undefined, to: Asset | undefined) => void;
};

export interface TradingMarket {
  id: string;
  baseAsset: Asset;
  quoteAsset: Asset;
  platformLogo: string | undefined;
  baseFromWallet?: Balance;
  quoteFromWallet?: Balance;
  orderbook: OrderBook;
  userAccount: UserMarketAccount;
  marketTradings: {
    id: string;
    price: string;
    amount: string;
    isBid: boolean;
    version?: string;
  }[];
  registerMarketAccount: () => Promise<boolean>;
  placeLimitOrder: (
    direction: 'buy' | 'sell',
    price: number,
    amount: number,
  ) => Promise<boolean>;
  fillMarketOrder: (
    direction: 'buy' | 'sell',
    amount: number,
  ) => Promise<boolean>;
  hasWithdrawableFunds: boolean;
  lotSize?: number;
  tickSize?: number;
  isDeprecated?: boolean;
}

export const EMPTY_MARKET: TradingMarket = {
  id: '',
  baseAsset: EMPTY_ASSET,
  quoteAsset: EMPTY_ASSET,
  platformLogo: undefined,
  baseFromWallet: EMPTY_BALANCE,
  quoteFromWallet: EMPTY_BALANCE,
  orderbook: EMPTY_ORDERBOOK,
  userAccount: EMPTY_MARKET_ACCOUNT,
  marketTradings: [],
  registerMarketAccount: () => Promise.resolve(false),
  placeLimitOrder: () => Promise.resolve(false),
  fillMarketOrder: () => Promise.resolve(false),
  hasWithdrawableFunds: false,
};
