import { useOrderbookApp } from '@aries/trading-fe-hooks/adapter';
import { useRef } from 'react';
import { ScrollView } from 'react-native';
import { FirstPageWrapper } from '~/components';
import { Content } from './Content';
import { Tips } from './Tips';

export const Swap: React.FunctionComponent<{}> = () => {
  const { SwapProvider } = useOrderbookApp();
  const scrollviewRef = useRef<ScrollView>(null);

  const toBottom = () => {
    scrollviewRef.current?.scrollToEnd();
  };

  return (
    <FirstPageWrapper>
      <ScrollView
        ref={scrollviewRef}
        contentContainerStyle={{ paddingBottom: 12, flex: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <Tips />
        <SwapProvider>
          <Content scrollToBottom={toBottom} />
        </SwapProvider>
      </ScrollView>
      {/* <CenterCol
        style={{ height: '100%', justifyContent: 'center' }}
        gap={20}
      >
        <Image
          source={{ uri: portLogo }}
          style={{ height: 150, width: 150 }}
        />
        <CenterCol fullWidth>
          <Txt
            h4
            bold
          >{t`Leverage-enabled swap, quickly and safely.`}</Txt>
          <Txt h3 c2 mt={15}>
            {t`Coming soon...`}
          </Txt>
        </CenterCol>
      </CenterCol> */}
    </FirstPageWrapper>
  );
};
