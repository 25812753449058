/* eslint-disable  */
// ActionPopoverItem.js
'use strict';
import { colorConfig, globalToken } from '@aries/ui-theming';
import React, { Component } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { ThemeProvider } from 'styled-components/native';
import { Theme } from '../../theme';
export default class ActionPopoverItem extends Component {
  static defaultProps = {
    ...TouchableOpacity.defaultProps,
  };

  renderTitle() {
    const { colorTextAcPopItemTitle } = globalToken.ActionPopover;

    const { mode } = this.context;

    let { title } = this.props;

    if (
      (title || title === '' || title === 0) &&
      !React.isValidElement(title)
    ) {
      const textStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: colorTextAcPopItemTitle[mode],
        fontSize: 14,
      };
      title = (
        <Text style={textStyle} numberOfLines={1}>
          {title}
        </Text>
      );
    }

    return title;
  }

  render() {
    let { style, title, leftSeparator, rightSeparator, ...others } =
      this.props;

    const { paddingVerticalAcPopItem, paddingHorizontalAcPopItem } =
      globalToken.ActionPopover;

    const { colorSeparatorAcPop } = colorConfig.ActionPopover;
    const { mode } = this.context;

    style = [
      {
        paddingVertical: paddingVerticalAcPopItem,
        paddingHorizontal: paddingHorizontalAcPopItem,
        borderColor: colorSeparatorAcPop[mode],
        borderLeftWidth: leftSeparator ? Theme.pixelSize : 0,
        borderRightWidth: rightSeparator ? Theme.pixelSize : 0,
      },
    ].concat(style);

    return (
      <TouchableOpacity style={style} {...others}>
        {this.renderTitle()}
      </TouchableOpacity>
    );
  }
}

ActionPopoverItem.contextType = ThemeProvider;
