import { createTokenHub } from '@aries/defi-toolkit/token-hub';
import { BaseAsset, EMPTY_ASSET } from '@aries/defi-toolkit/types';
import { TOKEN_LIST_URL } from '@jup-ag/core';
import { NATIVE_MINT } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { mapKeys } from 'lodash';
import { useEffect } from 'react';
import { useSwitchBoardPrices } from './sb';

const coinMap = {
  MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K: 'mercurial',
  mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So: 'msol',
  [NATIVE_MINT.toBase58()]: 'solana',
  SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt: 'serum',
  '9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i': 'terrausd',
  EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: 'usd-coin',
  PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y: 'port-finance',
  Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS: 'parrot-usd',
  Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB: 'tether',
  '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj': 'lido-staked-sol',
  '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs': 'ethereum-wormhole',
  '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E': 'wrapped-bitcoin-sollet',
  Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1: 'saber',
  EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp: 'bonfida',
  '9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX': 'solana',
  '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf': 'usd-coin',
  USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX: 'usd-coin',
  '7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT': 'usd-coin',
  '9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6': 'usd-coin',
  HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p: 'lido-dao',
  MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey: 'marinade',
};

export const { useTokenInfoHub, getTokenInfoHub } = createTokenHub(
  async () => {
    const list: {
      chainId: number;
      address: string;
      symbol: string;
      name: string;
      decimals: number;
      logoURI: string;
      tags: string[];
    }[] = await fetch(TOKEN_LIST_URL['mainnet-beta']).then(response =>
      response.json(),
    );

    list.push({
      chainId: 101,
      address: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
      symbol: 'USDT-USDC',
      name: 'Saber USDT-USDC LP',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf/icon.png',
      tags: ['saber-stableswap-lp'],
    });

    const baseAssets: BaseAsset[] = list.map(i => {
      return {
        coingeckoId: coinMap[i.address] ?? 'usd-coin',
        name: i.name,
        symbol: i.symbol,
        decimals: i.decimals,
        id: i.address,
        logoURI: i.logoURI ?? '',
      };
    });

    return baseAssets;
  },
);

const watchedSbPubkeys: Record<string, string> = {
  // USDT-USDC
  MAb618HHPHaHtcSiobSe4EpTB4f45x9PY7jN8R5woV6:
    '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
};

export const useWatchPriceAndUpdate = () => {
  const sbPriceMap = useSwitchBoardPrices(
    Object.keys(watchedSbPubkeys).map(v => new PublicKey(v)),
  );

  useEffect(() => {
    getTokenInfoHub()?.setCustomizedPriceMap({
      ...mapKeys(sbPriceMap, (_, key) => watchedSbPubkeys[key]),
    });
  }, [sbPriceMap]);
};

export const EMPTY_TOKEN = {
  ...EMPTY_ASSET,
  address: '',
};
