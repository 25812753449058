import Big from 'big.js';
import { default as EventEmiiter } from 'eventemitter3';
import { get, isArray, isNil, throttle } from 'lodash';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export { default as Big } from 'big.js';
export { default as EventEmiiter } from 'eventemitter3';
export {
  formatPercentage,
  formatQuoteValue,
  formatQuoteValueStandard,
} from './quote-value';

export const isBig = (n: any): n is Big =>
  !isNil(get(n, 's')) && !isNil(get(n, 'e')) && isArray(get(n, 'c'));

export const bigMax = (a: Big, b: Big) => (a.gt(b) ? a : b);
export const bigMin = (a: Big, b: Big) => (a.lt(b) ? a : b);

export const delay = (time: number) =>
  new Promise(r => {
    setTimeout(r, time);
  });

export const useLocalStorageState = <T,>(
  key: string,
): [T | undefined, (newState: T) => void] => {
  const [state, setState] = useState<T>(() => {
    const storedState = localStorage.getItem(key);
    if (storedState) {
      return JSON.parse(storedState);
    }
    return undefined;
  });

  const setLocalStorageState = useCallback(
    (newState: T) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }
      setState(newState);
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newState));
      }
    },
    [state, key],
  );

  return [state, setLocalStorageState];
};

export const useLocalStorageRef = <T,>(
  key: string,
): [MutableRefObject<T | undefined>, (newState: T) => void] => {
  const storedRef = useRef<T>(
    (() => {
      const storedState = localStorage.getItem(key);
      if (storedState) {
        return JSON.parse(storedState);
      }
      return undefined;
    })(),
  );

  const setLocalStorageState = useCallback(
    (newState: T) => {
      const changed = storedRef.current !== newState;
      if (!changed) {
        return;
      }
      storedRef.current = newState;
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newState));
      }
    },
    [key],
  );

  return [storedRef, setLocalStorageState];
};

export const useWindowWidth = () => {
  const [clientWidth, setClientWidth] = useState<number>(
    window.document.body.clientWidth,
  );

  const setWidth = () => {
    setClientWidth(window.document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', setWidth);

    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);

  return clientWidth;
};

export const useIsMobile = () => useWindowWidth() < 1000;

export const createHumanizeUtil =
  (
    patterns: {
      regexp: RegExp;
      replace: (
        rawErrMsg: string,
        ...params: string[]
      ) => string | React.ReactNode;
    }[],
  ) =>
  (message: string): string | React.ReactNode => {
    for (const pattern of patterns) {
      const res = message.match(pattern.regexp);

      if (!res) continue;

      const replacedNodes: React.ReactNode[] = [];
      const [match, ...params] = Array.from(res);

      const [start, end] = message.split(match);
      const customReplace = pattern.replace(match, ...params);

      replacedNodes.push(start, customReplace, end);

      return replacedNodes;
    }

    return message;
  };

// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(
  address: string,
  chars = 4,
  dot = '.',
): string {
  return `${address.slice(0, chars)}${dot}${dot}${dot}${address.slice(
    -chars,
  )}`;
}

export const eventEmitter = new EventEmiiter();

export const tuple = <T, U>(t: T, u: U) => [t, u] as [T, U];

export const useStableCallback = <T extends (...params: any) => any>(
  fn: T,
) => {
  const stableFnRef = useRef(fn);
  stableFnRef.current = fn;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    ((...params): ReturnType<T> => {
      return stableFnRef.current(...params);
    }) as T,
    [],
  );
};

export function useDeferredState<T>(
  initState: T,
  minUpdateMillis: number,
) {
  type Upd = (oldState: T) => T;
  const [state, setStateInner] = useState<T>(initState);
  const pendingUpdates = useRef<Array<Upd>>([]);

  const requestFlushUpdates = useStableCallback(
    throttle(() => {
      const prevUpdates = pendingUpdates.current;
      setStateInner(oldState =>
        prevUpdates.reduce((acc, upd) => upd(acc), oldState),
      );
      pendingUpdates.current = [];
    }, minUpdateMillis),
  );

  const pushUpdate = useStableCallback((updateFn: Upd) => {
    pendingUpdates.current.push(updateFn);
    requestFlushUpdates();
  });

  return tuple(state, pushUpdate);
}

export const hexToUtf8 = (hexStr: string): string =>
  Buffer.from(
    hexStr.startsWith('0x') ? hexStr : hexStr.slice(2),
    'hex',
  ).toString('utf8');

export const getDecimalCount = (value: number): number => {
  if (
    !Number.isNaN(value) &&
    Math.floor(value) !== value &&
    value.toString().includes('.')
  ) {
    return value.toString().split('.')[1].length || 0;
  }
  if (
    !Number.isNaN(value) &&
    Math.floor(value) !== value &&
    value.toString().includes('e')
  ) {
    return parseInt(value.toString().split('e-')[1] || '0', 10);
  }
  return 0;
};
