/* eslint-disable @typescript-eslint/no-unused-vars */
import { createGlobalStore } from '@aries/shared/deps';
import { useLatest } from '@aries/solana-defi/../shared/hooks/helper';
import { useProviderHub } from '@aries/solana-defi/common';
import {
  StakingPool,
  STAKING_POOL_DATA_SIZE,
  TokenAccount,
} from '@port.finance/port-sdk';
import { PublicKey, PublicKeyInitData } from '@solana/web3.js';
import { useCallback, useEffect, useState } from 'react';
import { useProgramSubscription } from './util';

interface RewardTokenAccounts {
  primaryRewardAccount: TokenAccount;
  secondaryRewardAccount?: TokenAccount;
}

export const [useStakingPools, getStakingPools] = createGlobalStore(() => {
  const { currentNetwork } = useProviderHub();

  const {
    results: pools,
    isLoading: loading,
    reload,
  } = useProgramSubscription(
    currentNetwork.stakingProgramId.toBase58(),
    [
      {
        dataSize: STAKING_POOL_DATA_SIZE,
      },
    ],
    StakingPool.fromRaw,
    {
      deps: [currentNetwork.name],
      delayLoad: 3000,
    },
  );

  const getStakingPool = (stakingPoolId: PublicKeyInitData) => {
    const key = new PublicKey(stakingPoolId);
    return pools.find(p => p.value.getId().equals(key));
  };

  const {
    provider: { connection },
  } = useProviderHub();
  const [poolToRewardAccounts, setPoolToRewardAccounts] = useState<
    Record<string, RewardTokenAccounts>
  >({});
  const poolToRewardAccountsRef = useLatest(poolToRewardAccounts);

  useEffect(() => {
    (async () => {
      for (const { value: pool } of pools) {
        if (!poolToRewardAccountsRef.current[pool.getId().toString()]) {
          // eslint-disable-next-line no-await-in-loop
          const poolRewardAccount = await connection.getAccountInfo(
            pool.getRewardTokenPool(),
          );

          if (!poolRewardAccount) {
            continue;
          }

          const rewardTokenAccounts: RewardTokenAccounts = {
            primaryRewardAccount: TokenAccount.fromRaw({
              pubkey: pool.getRewardTokenPool(),
              account: poolRewardAccount,
            }),
          };

          const subRewardTokenPool = pool.getSubRewardTokenPool();
          if (subRewardTokenPool) {
            // eslint-disable-next-line no-await-in-loop
            const poolSubRewardAccount = await connection.getAccountInfo(
              subRewardTokenPool,
            );

            if (poolSubRewardAccount) {
              rewardTokenAccounts.secondaryRewardAccount =
                TokenAccount.fromRaw({
                  pubkey: subRewardTokenPool,
                  account: poolSubRewardAccount,
                });
            }
          }

          setPoolToRewardAccounts(prev => ({
            ...prev,
            [pool.getId().toString()]: rewardTokenAccounts,
          }));
        }
      }
    })();
  }, [connection, poolToRewardAccountsRef, pools]);

  return {
    pools,
    refresh: reload,
    loading,
    getStakingPool,
    poolToRewardAccounts,
  };
});
