import { CenterRow, Col, Txt } from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { ScreenInset } from '@aries/mobile-core/utils';
import { getTFunction, Trans } from '@aries/shared/locale';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { ProfileSelect } from '~/services';
import { IconLogo } from '../../icons';
import {
  IconDiscord,
  IconEarth,
  IconLink,
  IconMenu,
  IconNetwork,
  IconOtterSec,
  IconQeustion,
  IconTelegram,
  IconTwtter,
} from './Icons';
import { openLanguageSelect } from './LanguageSelect';
import { openNetworkSelect } from './NetworkSelect';

export const PAGE_HEADER_HEIGHT = 45;

export type HeaderProps = {
  headerRight?: string | React.ReactNode;
  headerLeft?: string | React.ReactNode;
  headerStyle?: ViewStyle;
  hasAccount?: boolean;
};

export const Header = React.memo((props: HeaderProps) => {
  const {
    headerRight,
    headerLeft,
    headerStyle,
    hasAccount = true,
  } = props;
  const { mode } = useThemeMode();
  const { colorBgSpotlight } = colorConfig;
  return (
    <Container style={headerStyle}>
      <Content>
        {!headerLeft ? (
          <LeftBox>
            <IconLogo size={25} />
            <Txt ml={8} h3 bold>
              Port Finance
            </Txt>
          </LeftBox>
        ) : (
          headerLeft
        )}
        {hasAccount ? (
          <ProfileSelect
            style={{
              backgroundColor: `${colorBgSpotlight[mode]}`,
            }}
          />
        ) : null}

        {!headerRight ? (
          <RightBox onPress={() => openMenuModal()}>
            <IconMenu style={{ width: 20, height: 14 }} />
          </RightBox>
        ) : (
          headerRight
        )}
      </Content>
    </Container>
  );
});

const openMenuModal = () => {
  const t = getTFunction();
  openModal({
    showHeader: true,
    renderHeader: () => (
      <CenterRow>
        <Txt h4>
          <Trans i18nkey="Menu" />
        </Txt>
      </CenterRow>
    ),
    renderContent: ({ close }) => (
      <>
        <Col
          style={[
            {
              width: '100%',
              justifyContent: 'center',
              marginLeft: 80,
              marginTop: 130,
              gap: 40,
            } as ViewStyle,
          ]}
        >
          <MenuContainer
            onPress={() => {
              window.open('https://v1.port.finance', '_blank');
            }}
          >
            <IconLink />
            <Txt c2 h4>
              <Trans i18nkey="Port Finance V1" />
            </Txt>
          </MenuContainer>

          <MenuContainer
            onPress={() => {
              close?.();
              openNetworkSelect();
            }}
          >
            <IconNetwork />
            <Txt h4 c2>
              {t`Network`}
            </Txt>
          </MenuContainer>

          <MenuContainer
            onPress={() => {
              close?.();
              openLanguageSelect();
            }}
          >
            <IconEarth />
            <Txt h4 c2>
              {t`Language`}
            </Txt>
          </MenuContainer>

          <MenuContainer
            onPress={() => {
              window.open('https://docs.port.finance/', '_blank');
            }}
          >
            <IconQeustion />
            <Txt h4 c2>{t`Docs`}</Txt>
          </MenuContainer>
        </Col>
        <Col gap={12} ml={80} mt={80}>
          <CenterRow
            gap={15}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <a
              href="https://discord.com/invite/nAMXAYhTb2"
              target="_blank"
              rel="noreferrer"
            >
              <IconDiscord style={{ width: 20, height: 20 }} />
            </a>
            <a
              href="https://t.me/port_finance"
              target="_blank"
              rel="noreferrer"
            >
              <IconTelegram style={{ width: 20, height: 20 }} />
            </a>
            <a
              href="https://twitter.com/port_finance"
              target="_blank"
              rel="noreferrer"
            >
              <IconTwtter style={{ width: 20, height: 20 }} />
            </a>
          </CenterRow>
          <Col>
            <CenterRow mb={12}>
              <Txt c3 h2 mr={8}>{t`Audited by:`}</Txt>
              <a href="https://osec.io/" target="_blank" rel="noreferrer">
                <IconOtterSec style={{ width: 19, height: 19 }} />
              </a>
            </CenterRow>
            <Txt c3 h2>{t`Copyright © 2023 Port Finance `}</Txt>
          </Col>
        </Col>
      </>
    ),
    showClose: true,
  });
};

const { colorBgContainer, colorBgSpotlight, padding } = globalToken;
const Container = styled(View)`
  background-color: ${colorBgContainer};
  padding-top: ${ScreenInset.top}px;
  padding-left: ${padding}px;
  padding-right: ${padding}px;
`;

const Content = styled(CenterRow)`
  height: ${PAGE_HEADER_HEIGHT}px;
`;

const LeftBox = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RightBox = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8;
  background-color: ${colorBgSpotlight};
`;

const MenuContainer = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
