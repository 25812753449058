/* eslint-disable */
// TopView.js

import { colorConfig } from '@aries/ui-theming';
import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import {
  Animated,
  DeviceEventEmitter,
  StyleSheet,
  View,
} from 'react-native';
import { ThemeContext } from 'styled-components/native';

let keyValue = 0;

export default class TopView extends Component {
  static add(element) {
    const key = ++keyValue;
    DeviceEventEmitter.emit('addOverlay', { key, element });
    return key;
  }

  static viewMap = {};

  static remove(key) {
    DeviceEventEmitter.emit('removeOverlay', { key });
  }

  static removeAll() {
    DeviceEventEmitter.emit('removeAllOverlay', {});
  }

  static transform(transform, animated, animatesOnly = null) {
    DeviceEventEmitter.emit('transformRoot', {
      transform,
      animated,
      animatesOnly,
    });
  }

  static restore(animated, animatesOnly = null) {
    DeviceEventEmitter.emit('restoreRoot', { animated, animatesOnly });
  }

  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      scaleX: new Animated.Value(1),
      scaleY: new Animated.Value(1),
    };
    this.handlers = [];
  }

  static contextTypes = {
    registerTopViewHandler: PropTypes.func,
    unregisterTopViewHandler: PropTypes.func,
  };

  static childContextTypes = {
    registerTopViewHandler: PropTypes.func,
    unregisterTopViewHandler: PropTypes.func,
  };

  getChildContext() {
    let { registerTopViewHandler, unregisterTopViewHandler } =
      this.context;
    if (!registerTopViewHandler) {
      registerTopViewHandler = handler => {
        this.handlers.push(handler);
      };
      unregisterTopViewHandler = handler => {
        for (let i = this.handlers.length - 1; i >= 0; --i) {
          if (this.handlers[i] === handler) {
            this.handlers.splice(i, 1);
            return true;
          }
        }
        return false;
      };
    }
    return { registerTopViewHandler, unregisterTopViewHandler };
  }

  get handler() {
    return this.handlers.length > 0
      ? this.handlers[this.handlers.length - 1]
      : this;
  }

  UNSAFE_componentWillMount() {
    const { registerTopViewHandler } = this.context;
    if (registerTopViewHandler) {
      registerTopViewHandler(this);
      return;
    }

    DeviceEventEmitter.addListener('addOverlay', e => this.handler.add(e));
    DeviceEventEmitter.addListener('removeOverlay', e =>
      this.handler.remove(e),
    );
    DeviceEventEmitter.addListener('removeAllOverlay', e =>
      this.handler.removeAll(e),
    );
    DeviceEventEmitter.addListener('transformRoot', e =>
      this.handler.transform(e),
    );
    DeviceEventEmitter.addListener('restoreRoot', e =>
      this.handler.restore(e),
    );
    DeviceEventEmitter.addListener('closeView', (key, animate) => {
      if (animate && TopView.viewMap[key] && TopView.viewMap[key].close) {
        TopView.viewMap[key].close();
      } else {
        TopView.remove(key);
      }
    });
  }

  componentWillUnmount() {
    const { unregisterTopViewHandler } = this.context;
    if (unregisterTopViewHandler) {
      unregisterTopViewHandler(this);
      return;
    }

    DeviceEventEmitter.removeAllListeners('addOverlay');
    DeviceEventEmitter.removeAllListeners('removeOverlay');
    DeviceEventEmitter.removeAllListeners('removeAllOverlay');
    DeviceEventEmitter.removeAllListeners('transformRoot');
    DeviceEventEmitter.removeAllListeners('restoreRoot');
  }

  add(e) {
    const { elements } = this.state;
    elements.push(e);
    this.setState({ elements });
  }

  remove(e) {
    const { elements } = this.state;
    for (let i = elements.length - 1; i >= 0; --i) {
      if (elements[i].key === e.key) {
        elements.splice(i, 1);
        delete TopView.viewMap[e.key];
        break;
      }
    }
    this.setState({ elements });
  }

  removeAll(e) {
    const { elements } = this.state;
    this.setState({ elements: [] });
  }

  transform(e) {
    const { translateX, translateY, scaleX, scaleY } = this.state;
    const { transform, animated, animatesOnly } = e;
    let tx = 0;
    let ty = 0;
    let sx = 1;
    let sy = 1;
    transform.map(item => {
      if (item && typeof item === 'object') {
        for (const name in item) {
          const value = item[name];
          switch (name) {
            case 'translateX':
              tx = value;
              break;
            case 'translateY':
              ty = value;
              break;
            case 'scaleX':
              sx = value;
              break;
            case 'scaleY':
              sy = value;
              break;
          }
        }
      }
    });
    if (animated) {
      const animates = [
        Animated.spring(translateX, {
          toValue: tx,
          friction: 9,
          useNativeDriver: true,
        }),
        Animated.spring(translateY, {
          toValue: ty,
          friction: 9,
          useNativeDriver: true,
        }),
        Animated.spring(scaleX, {
          toValue: sx,
          friction: 9,
          useNativeDriver: true,
        }),
        Animated.spring(scaleY, {
          toValue: sy,
          friction: 9,
          useNativeDriver: true,
        }),
      ];
      animatesOnly
        ? animatesOnly(animates)
        : Animated.parallel(animates).start();
    } else if (animatesOnly) {
      const animates = [
        Animated.timing(translateX, {
          toValue: tx,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: ty,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.timing(scaleX, {
          toValue: sx,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.timing(scaleY, {
          toValue: sy,
          duration: 1,
          useNativeDriver: true,
        }),
      ];
      animatesOnly(animates);
    } else {
      translateX.setValue(tx);
      translateY.setValue(ty);
      scaleX.setValue(sx);
      scaleY.setValue(sy);
    }
  }

  restore(e) {
    const { translateX, translateY, scaleX, scaleY } = this.state;
    const { animated, animatesOnly } = e;
    if (animated) {
      const animates = [
        Animated.spring(translateX, {
          toValue: 0,
          friction: 9,
          useNativeDriver: true,
        }),
        Animated.spring(translateY, {
          toValue: 0,
          friction: 9,
          useNativeDriver: true,
        }),
        Animated.spring(scaleX, {
          toValue: 1,
          friction: 9,
          useNativeDriver: true,
        }),
        Animated.spring(scaleY, {
          toValue: 1,
          friction: 9,
          useNativeDriver: true,
        }),
      ];
      animatesOnly
        ? animatesOnly(animates)
        : Animated.parallel(animates).start();
    } else if (animatesOnly) {
      const animates = [
        Animated.timing(translateX, {
          toValue: 0,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: 0,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.timing(scaleX, {
          toValue: 1,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.timing(scaleY, {
          toValue: 1,
          duration: 1,
          useNativeDriver: true,
        }),
      ];
      animatesOnly(animates);
    } else {
      translateX.setValue(0);
      translateY.setValue(0);
      scaleX.setValue(1);
      scaleY.setValue(1);
    }
  }

  render() {
    const { elements, translateX, translateY, scaleX, scaleY } =
      this.state;
    const transform = [
      { translateX },
      { translateY },
      { scaleX },
      { scaleY },
    ];
    const mode = this.context?.mode ?? 'dark';
    const { colorBgDefault } = colorConfig;

    return (
      <View style={{ backgroundColor: colorBgDefault[mode], flex: 1 }}>
        <Animated.View style={{ flex: 1, transform }}>
          <PureView>{this.props.children}</PureView>
        </Animated.View>
        {elements.map((item, index) => {
          return (
            <View
              key={`topView${item.key}`}
              style={styles.overlay}
              pointerEvents="box-none"
            >
              {React.cloneElement(item.element, {
                ref: res => {
                  TopView.viewMap[item.key] = res;
                },
              })}
            </View>
          );
        })}
      </View>
    );
  }
}

TopView.contextType = ThemeContext;

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

class PureView extends PureComponent {
  render() {
    return <View style={{ flex: 1 }}>{this.props.children}</View>;
  }
}
