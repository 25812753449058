import { TabView } from '@aries/mobile-core/components';
import { colorConfig, useThemeMode } from '@aries/ui-theming';

const { colorTextSuccess, colorTextCritical } = colorConfig;
export const PlaceOrder: React.FC<{
  marketId: string;
}> = ({ marketId }) => {
  const { mode } = useThemeMode();

  return (
    <TabView
      style={{ flex: 1.6, height: 300 }}
      pages={[
        {
          title: 'Buy',
          view: <Panel isBuy marketId={marketId} />,
          indicatorColor: colorTextSuccess[mode],
        },
        {
          title: 'Sell',
          view: <Panel marketId={marketId} />,
          indicatorColor: colorTextCritical[mode],
        },
      ]}
      tabStyle={{ height: 38, marginTop: -6 }}
    />
  );
};

const Panel: React.FC<{ marketId: string; isBuy?: boolean }> = () => {
  return null;
  // const market = useMarketById(marketId)!;
  // const { reserve } = useReserveById(
  //   isBuy ? market.quoteAsset.id : market.baseAsset.id,
  // );
  // const collateral = reserve?.deposit.ofUser?.withdrawable ?? Big(0);
  // const [nullableAmount, setAmount] = useState<number>();
  // const [nullablePrice, setPrice] = useState<number>();
  // const amount = nullableAmount ?? 0;
  // const price = nullablePrice ?? 0;
  // const [orderType, setOrderType] = useState<'Market' | 'Limit'>('Limit');
  // const useMarketPrice = orderType === 'Market';
  // const [maxParcels, setMaxParcels] = useState(0);
  // const [maxQuoteUnits, setMaxQuoteUnits] = useState(0);
  // const onConfirm = async () => {};
  // const total = amount * price;
  // const isCollateralEnough = isBuy
  //   ? collateral.gte(total)
  //   : collateral.gte(amount);
  // const shouldUseMarketPrice = isBuy
  //   ? market.orderbook.minAskNum !== 0 &&
  //     price > market.orderbook.minAskNum
  //   : market.orderbook.minAskNum !== 0 &&
  //     price < market.orderbook.maxBidNum;
  // // const baseSymbol = (
  // //   <CenterRow>
  // //     <TokenIcon asset={market.baseAsset} size={18} />
  // //     <Txt ml={5}>{market.baseAsset.symbol}</Txt>
  // //   </CenterRow>
  // // );
  // // const quoteSymbol = (
  // //   <CenterRow>
  // //     <TokenIcon asset={market.quoteAsset} size={18} />
  // //     <Txt ml={5}>{market.quoteAsset.symbol}</Txt>
  // //   </CenterRow>
  // // );
  // useEffect(() => {
  //   if (useMarketPrice) setPrice(0);
  // }, [useMarketPrice]);
  // return (
  //   <View flex={1}>
  //     <ScrollView style={{ flex: 1 }}>
  //       <Col mt={12} flex={1}>
  //         <Col mb={5}>
  //           <Col mb={5}>
  //             <Txt c2 h1 mb={5}>
  //               Order Type
  //             </Txt>
  //             <NumeralInput value={orderType} onChange={noop} />
  //           </Col>
  //           <CenterBetweenRow mb={2}>
  //             <Txt c2 h1>
  //               Price
  //             </Txt>
  //             <Txt c2 h1>
  //               {isBuy ? 'Min Ask' : 'Max Bid'}:{' '}
  //               <Txt h1 color={isBuy ? 'Text/Critical' : 'Text/Success'}>
  //                 {isBuy
  //                   ? market.orderbook.minAsk
  //                   : market.orderbook.maxBid}
  //               </Txt>
  //             </Txt>
  //           </CenterBetweenRow>
  //           {useMarketPrice ? (
  //             <NumeralInput value="Market" disabled onChange={noop} />
  //           ) : (
  //             <NumeralInput
  //               value={0}
  //               onChange={v => setPrice(Number(v))}
  //               placeholder="0"
  //               keyboardType="number-pad"
  //             />
  //           )}
  //         </Col>
  //         {useMarketPrice ? (
  //           <Col>
  //             <View mr={5}>
  //               <NumeralInput
  //                 label={`Max ${market.baseAsset.symbol} ${
  //                   isBuy ? 'Get' : 'Cost'
  //                 }`}
  //                 value={0}
  //                 onChange={v => setMaxParcels(Number(v))}
  //                 placeholder="0"
  //                 keyboardType="number-pad"
  //               />
  //             </View>
  //             <View>
  //               <NumeralInput
  //                 label={`Max ${market.quoteAsset.symbol} ${
  //                   isBuy ? 'Cost' : 'Get'
  //                 }`}
  //                 placeholder="0"
  //                 value={0}
  //                 onChange={v => setAmount(Number(v))}
  //                 keyboardType="number-pad"
  //               />
  //             </View>
  //           </Col>
  //         ) : (
  //           <View>
  //             <CenterBetweenRow mb={2}>
  //               <Txt c2 h1>
  //                 Amount
  //               </Txt>
  //               <Txt c2 h1>
  //                 Collateral Need:{' '}
  //                 <Txt
  //                   h1
  //                   color={
  //                     isCollateralEnough ? 'Text/Success' : 'Text/Critical'
  //                   }
  //                 >
  //                   {isBuy ? amount * price : amount}
  //                   {isBuy
  //                     ? ` ${market.quoteAsset.symbol}`
  //                     : ` ${market.baseAsset.symbol}`}
  //                 </Txt>
  //               </Txt>
  //             </CenterBetweenRow>
  //             <NumeralInput
  //               keyboardType="number-pad"
  //               value={0}
  //               onChange={v => setAmount(Number(v))}
  //               placeholder="0"
  //             />
  //             <Txt c3 h1 mt={5}>
  //               Avaiable:{' '}
  //               <Txt h1>
  //                 {collateral.toNumber()}
  //                 {/* <AnimatedNumber
  //                 value={collateral.toNumber()}
  //                 stepPrecision={0}
  //               /> */}
  //                 {isBuy
  //                   ? ` ${market.quoteAsset.symbol}`
  //                   : ` ${market.baseAsset.symbol}`}
  //               </Txt>
  //             </Txt>
  //           </View>
  //         )}
  //       </Col>
  //     </ScrollView>
  //     <Button
  //       type={isCollateralEnough ? 'primary' : 'ghost'}
  //       disabled={
  //         !market.userAccount ||
  //         (useMarketPrice
  //           ? !(maxParcels && maxQuoteUnits)
  //           : !(amount && price) || shouldUseMarketPrice) ||
  //         !isCollateralEnough
  //       }
  //       titleStyle={{ fontSize: 11 }}
  //       title="Coming Soon"
  //       onPress={onConfirm}
  //       h={40}
  //     />
  //   </View>
  // );
};
