import { getProfileHub } from '@aries/solana-defi/lending/data/profile';
import { getReserves } from '@aries/solana-defi/lending/data/reserves';
import { getStakingAccounts } from '@aries/solana-defi/lending/data/staking-account';
import { getWalletCtx } from '@aries/solana-defi/wallet';
import { PublicKey } from '@solana/web3.js';
import { sha256 } from 'crypto-hash';
import { compact, uniqBy } from 'lodash';
import { getLendingMarkets } from '../market';
import { refreshProfileIx } from './instructions/refresh-profile';
import { refreshReserveIx } from './instructions/refresh-reserve';

export const getReserveCtx = async (
  reserveKey: string,
  options?: { isBorrow: boolean },
) => {
  const marketId = getLendingMarkets()!.activeMarketId;
  const wallet = getWalletCtx()?.walletAddress;
  const profile = getProfileHub()!.currentProfileKey;
  const currentProfileSDK = getProfileHub()?.currentProfile?.raw;

  if (!marketId) {
    throw new Error('Please select a market');
  }

  if (!profile || !currentProfileSDK) {
    throw new Error('Please create your profile');
  }

  if (!wallet) {
    throw new Error('Please connect your wallet');
  }

  const walletPubkey = new PublicKey(wallet);
  const marketPubkey = new PublicKey(marketId);
  const profilePubkey = new PublicKey(profile);

  const reserve = getReserves()?.reserveMap[reserveKey];

  if (!reserve) {
    throw new Error('Reserve not found');
  }

  const reserveSDK = reserve.rawReserve;

  const [marketAuthority] = await reserveSDK.getMarketAuthority();
  const reserveId = reserveSDK.getReserveId();

  const assetMintId = new PublicKey(reserve.coinAddress);
  const shareMintId = new PublicKey(reserve.lpCoinAddress);
  const { getReserveSDK } = getReserves()!;

  const relativeReserves = uniqBy(
    compact([
      ...currentProfileSDK.getCollateralReserveIds(),
      ...currentProfileSDK.getLoanReserveIds(),
    ]),
    v => v.toBase58(),
  );

  const refreshReservesIxs = uniqBy(
    compact([...relativeReserves, options?.isBorrow && reserveId]),
    v => v.toBase58(),
  ).map(reserveId =>
    refreshReserveIx(getReserveSDK(reserveId.toString())!),
  );
  const refreshProfileIxs = [
    refreshProfileIx({
      profileId: profilePubkey,
      reserves: relativeReserves,
    }),
  ];

  const maybeStakingPoolId = reserveSDK.getStakingPoolId();
  const maybeStakingAccount = maybeStakingPoolId
    ? await getStakingAccounts()!.getOrCreateStakingAccount(
        maybeStakingPoolId,
      )
    : undefined;

  const stakeAccount = maybeStakingAccount
    ? {
        stakingAccountId: maybeStakingAccount.address,
        stakingPoolId: maybeStakingAccount.stakingPoolId,
        stakingProgramId: maybeStakingAccount.programId,
        ixns: maybeStakingAccount.ixs,
        signers: maybeStakingAccount.signers,
      }
    : undefined;

  return {
    reserveSDK,
    walletPubkey,
    marketPubkey,
    profilePubkey,
    assetMintId,
    shareMintId,
    reserve,
    currentProfileSDK,
    refreshProfileIxs,
    refreshReservesIxs,
    stakeAccount,
    marketAuthority,
    reserveId,
  };
};

export async function createSeed(...pubKey: PublicKey[]): Promise<Buffer> {
  let buffer = Buffer.alloc(0);
  pubKey.forEach(k => {
    buffer = Buffer.concat([buffer, k.toBuffer()]);
  });

  const array = await sha256(
    new Uint8Array(
      buffer.buffer.slice(
        buffer.byteOffset,
        buffer.byteOffset + buffer.byteLength,
      ),
    ),
    { outputFormat: 'buffer' },
  );
  return Buffer.from(array);
}
