import { useProfile } from '@aries/lending-fe-hooks';
import { CenterBetweenRow, Txt } from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import React from 'react';
import styled from 'styled-components/native';
import { ProgressBar } from '~/components';

const { marginSM } = globalToken;
const { colorTextCritical, colorTextSuccess } = colorConfig;
export const BorrowingPower: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { mode } = useThemeMode();
  const { currentProfile } = useProfile();
  const summary = currentProfile?.summary ?? {
    borrowedValueUSD: '-',
    availableMarginUSD: '-',
    borrowingPowerPct: 0,
    riskFactorPct: 0,
  };
  const borrowPowerStrong = summary.borrowingPowerPct > 50;
  return (
    <Container>
      <CenterBetweenRow mb={8}>
        <Txt h2 bold c1>
          {t`Borrowing Power`}
        </Txt>
        <Txt
          h2
          bold
          color={
            borrowPowerStrong
              ? colorTextSuccess[mode]
              : colorTextCritical[mode]
          }
        >
          {`${summary.borrowingPowerPct.toFixed(2)}%`}
        </Txt>
      </CenterBetweenRow>
      {summary.borrowingPowerPct ? (
        <ProgressBar
          pct={summary.borrowingPowerPct}
          style={{ borderRadius: 4, marginBottom: 16 }}
        />
      ) : null}
      <CenterBetweenRow mb={3}>
        <Txt h1>{summary.availableMarginUSD}</Txt>
        <Txt
          h1
          colorRank={summary.riskFactorPct > 50 ? 'critical' : 1}
        >{`${summary.riskFactorPct}%`}</Txt>
      </CenterBetweenRow>

      <CenterBetweenRow>
        <Txt h1 style={{ lineHeight: 12 }} c2>
          {t`Available`}
        </Txt>
        <Txt h1 style={{ lineHeight: 12 }} c2>
          {t`Risk Factor`}
        </Txt>
      </CenterBetweenRow>
    </Container>
  );
};

const Container = styled.View`
  margin-top: ${marginSM}px;
`;
