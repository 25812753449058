// @ts-nocheck
/* eslint-disable  */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Toast.js
import { getTFunction } from '@aries/shared/locale';
import { isFunction } from 'lodash';
import { ReactElement, ReactNode, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import Overlay from '../Overlay/Overlay';
import ToastView from './ToastView';

const Icon: any = () => {
  return null;
};

type IconType =
  | 'none'
  | 'success'
  | 'fail'
  | 'smile'
  | 'sad'
  | 'info'
  | 'stop';
type Position = 'top' | 'bottom' | 'center';
export interface ToastOptions {
  text: string | number | ReactElement;
  icon?: ReactElement | { uri: string } | number | IconType;
  position: Position;
  duration: number | 'short' | 'long' | any;
}

export class Toast extends Overlay {
  private static ToastView = ToastView;

  private static defaultDuration = 'short';

  private static defaultPosition: any = 'center';

  private static messageDefaultDuration: any = 'short';

  private static messageDefaultPosition: any = 'bottom';

  static show(options: ToastOptions) {
    let { duration } = options || {};

    const key = super.show(<this.ToastView {...options} />);
    if (typeof duration !== 'number') {
      switch (duration) {
        case 'long':
          duration = 3700;
          break;
        default:
          duration = 2000;
          break;
      }
    }
    setTimeout(() => this.hide(key, false), duration);

    return key;
  }

  static message(
    text: any,
    duration = this.messageDefaultDuration,
    position: Position = this.messageDefaultPosition,
  ) {
    return this.show({ text, duration, position });
  }

  static success(
    text: string,
    duration = this.defaultDuration,
    position: Position = this.defaultPosition,
  ) {
    return this.show({
      text,
      duration,
      position,
      icon: (
        <Icon
          type="antdesign"
          name="checkcircleo"
          size={37}
          color="#fff"
        />
      ),
    });
  }

  static fail(
    text: any,
    duration = this.defaultDuration,
    position: Position = this.defaultPosition,
  ) {
    return this.show({
      text,
      duration,
      position,
      icon: (
        <Icon
          type="antdesign"
          name="closecircleo"
          size={37}
          color="#fff"
        />
      ),
    });
  }

  static smile(
    text: any,
    duration = this.defaultDuration,
    position: Position = this.defaultPosition,
  ) {
    return this.show({
      text,
      duration,
      position,
      icon: (
        <Icon
          type="simple-line-icon"
          name="emotsmile"
          size={40}
          color="#fff"
        />
      ),
    });
  }

  static sad(
    text: any,
    duration = this.defaultDuration,
    position: Position = this.defaultPosition,
  ) {
    return this.show({
      text,
      duration,
      position,
      icon: <Icon type="entypo" name="emoji-sad" size={37} color="#fff" />,
    });
  }

  static info(
    text: string,
    duration = this.defaultDuration,
    position: Position = this.defaultPosition,
  ) {
    return this.show({
      text,
      duration,
      position,
      icon: (
        <Icon
          type="antdesign"
          name="exclamationcircleo"
          size={38}
          color="#fff"
        />
      ),
    });
  }

  static stop(
    text: any,
    duration = this.defaultDuration,
    position: Position = this.defaultPosition,
  ) {
    return this.show({
      text,
      duration,
      position,
      icon: (
        <Icon type="feather" name="stop-circle" size={38} color="#fff" />
      ),
    });
  }

  static loading(
    defaultText,
    duration: string | number = 9999999999999,
    position: Position = this.defaultPosition,
  ) {
    const t = getTFunction();
    const { ToastView } = this;
    let setText: any = null;

    const LoadingView = () => {
      const [text, _setText] = useState(defaultText ?? t`wait a minute`);
      setText = _setText;

      return (
        <ToastView
          text={text}
          position={position}
          icon={<ActivityIndicator color="#fff" size="large" />}
        />
      );
    };

    return super.show(<LoadingView />);
  }

  static progress(defaultText: ReactNode) {
    const t = getTFunction();
    const { ToastView } = this;
    let setText: any = null;

    const LoadingView = () => {
      const [text, _setText] = useState(defaultText ?? t`wait a minute`);
      setText = _setText;

      return (
        <ToastView
          text={text}
          position={this.defaultPosition}
          icon={<ActivityIndicator color="#fff" size="large" />}
        />
      );
    };

    const key = super.show(<LoadingView />);

    return {
      hide: () => super.hide(key),
      setProgress: (p: number) => setText?.(`${p}%`),
    };
  }
}

const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export async function WrapLoading<T>(
  func: ((...args: any) => Promise<T>) | Promise<T>,
  timeout?: number,
  { message } = {},
): Promise<T> {
  const t = getTFunction();
  const key = Toast.loading(message ?? t`wait a minute`);
  let res;
  if (isFunction(func)) {
    res = await func();
  } else {
    res = await func;
  }

  await delay(timeout ?? 500);
  Toast.hide(key, true);
  return res;
}
