import { TokenInputCtx as Ctx } from './context';
import {
  ChangeMode,
  CurrentValueCoin,
  CurrentValueUSD,
  Input,
  Slider,
  TokenIcon,
  TokenName,
} from './parts';

export const TokenInput = Object.assign(Ctx, {
  TokenIcon,
  Input,
  ChangeMode,
  Slider,
  CurrentValueCoin,
  CurrentValueUSD,
  TokenName,
});
