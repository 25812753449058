import { LinearView, styled } from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import { StyleProp, ViewStyle } from 'react-native';

const { colorProgressBarLeftBg } = globalToken.ProgressBar;

export const ProgressBar = ({
  pct,
  style,
}: {
  pct: number;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <LinearView style={style} disabled h={8}>
      <InnerBar pct={pct} />
    </LinearView>
  );
};

const InnerBar = styled(LinearView)<{ pct: number }>`
  border-radius: 4px;
  width: ${p => `${p.pct}%`};
  height: 100%;
  color: ${colorProgressBarLeftBg};
`;
