import { globalToken } from '@aries/ui-theming';
import React, { FC } from 'react';
import styled from 'styled-components/native';
import { Header, HeaderProps } from './Header';

export * from './Header';

export const FirstPageWrapper: FC<{} & HeaderProps> = ({
  children,
  headerLeft,
  headerRight,
  headerStyle,
  hasAccount,
}) => {
  return (
    <Container>
      <Header
        headerRight={headerRight}
        headerLeft={headerLeft}
        headerStyle={headerStyle}
        hasAccount={hasAccount}
      />
      <Content>{children}</Content>
    </Container>
  );
};

const { colorBgBase, padding } = globalToken;
const Container = styled.View`
  flex: 1;
  background-color: ${colorBgBase};
`;

const Content = styled.View`
  flex: 1;
  padding-left: ${padding};
  padding-right: ${padding};
`;
