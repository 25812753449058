import { useWallet } from '@aries/lending-fe-hooks';
import {
  ActionSheet,
  CenterRow,
  Txt,
} from '@aries/mobile-core/components';
import { openModal, Toast } from '@aries/mobile-core/Smoothy';
import { useTranslation } from '@aries/shared/locale';
import { shortenAddress } from '@aries/shared/utils';
import { colorConfig, useThemeMode } from '@aries/ui-theming';
import React, { FC } from 'react';
import { Clipboard, Image, TouchableOpacity } from 'react-native';
import { IconSecondDown } from '~/components';
import { Card } from './common';

const { colorTextCritical } = colorConfig;

export const WalletSelect: FC<{ close: () => void }> = ({
  close: closeOuter,
}) => {
  const { t } = useTranslation();
  const walletCtx = useWallet();
  const { mode } = useThemeMode();

  return (
    <TouchableOpacity
      onPress={() => {
        openModal({
          renderContent: ({ close }) => (
            <ActionSheet
              close={close}
              closeOuter={closeOuter}
              cancelText={t`Cancel`}
              btns={[
                {
                  title: t`Copy Address`,
                  onPress: () => {
                    Clipboard.setString(walletCtx.walletAddress!);
                    Toast.success(t`Copied To The Clipboard!`);
                  },
                  type: 'primary',
                },
                {
                  title: t`Change Wallet`,
                  onPress: () => walletCtx.connect(),
                },
                {
                  title: t`Disconnect`,
                  onPress: () => walletCtx.disconnect(),
                  color: colorTextCritical[mode],
                },
              ]}
            />
          ),
          showHeader: false,
          bodyStyle: { height: 300 },
        });
      }}
    >
      <Card
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CenterRow>
          {walletCtx.walletIcon ? (
            <Image
              source={{ uri: walletCtx.walletIcon }}
              style={{ height: 25, width: 25, borderRadius: 5 }}
            />
          ) : null}
          <Txt ml={5}>
            {walletCtx.walletAddress
              ? shortenAddress(walletCtx.walletAddress)
              : t`Please connect your wallet`}
          </Txt>
        </CenterRow>

        <IconSecondDown
          style={{ transform: [{ rotate: '-90deg' }] }}
          size={10}
          mr={4}
        />
      </Card>
    </TouchableOpacity>
  );
};
