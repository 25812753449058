import { useMemo, useState } from 'react';

export const useSearchList = <T>(
  list: T[],
  getKeywords: (item: T) => string[],
) => {
  const [search, setSearch] = useState('');

  const filteredList = useMemo(() => {
    if (search === '') return list;

    const s = search.toLowerCase();

    return list.filter(item =>
      getKeywords(item).some(keyword => {
        const k = keyword.toLowerCase();
        return k.includes(s) || s.includes(k);
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, search]);

  return {
    filteredList,
    search,
    setSearch,
  };
};
