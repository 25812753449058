import { noop } from 'lodash';
import React, { useContext } from 'react';
import {
  DashboardDefine,
  useDashboard as useDashboardImpl,
} from '../adapter';

const DashboardCtx = React.createContext<DashboardDefine>({
  networth: {
    valueUSD: '$0.00',
    valueUSDNum: 0,
  },
  summary: {
    apy: 0,
    borrowedValueUSD: '-',
    riskFactor: 0,
  },
  histories: [],
  reservesProfits: [],
  profitTotal: {
    valueUSD: '$0.00',
    isPositive: true,
  },
  profitsLoading: true,
  actionsGroupByCoin: {},
  profile: {
    initialized: false,
    initMainProfile: () => Promise.resolve(false),
    list: [],
    profileTotals: [],
    currentProfileLoading: false,
    reserves: [],
    reservesLoading: false,
    curProfileTotal: {
      valueUSD: '$0.00',
      isPositive: true,
    },
    currentProfile: undefined,
    changeProfile: noop,
    liquidationHistories: [],
    liquidationHistoriesLoading: true,
    liquidationsTotal: '$.00',
    actionHistories: [],
    actionHistoriesLoading: true,
  },
  tokens: [],
  tokensTotal: {
    valueUSD: '$0.00',
    valueUSDNum: 0,
  },
  lpTokens: [],
  lpTokensTotal: {
    valueUSD: '$0.00',
    valueUSDNum: 0,
  },
  lpPlatformsMap: {},
  farms: [],
  farmsTotal: {
    valueUSD: '$0.00',
    valueUSDNum: 0,
  },
  depositsAndLoans: { platforms: [], totalValueUSD: '$0.00' },
  farmsPlatformsMap: {},
  walletAddress: '',
});

export const DashboardProvider: React.FC<{
  walletAddress: string | undefined;
}> = ({ walletAddress, children }) => {
  const val = useDashboardImpl(walletAddress);

  return (
    <DashboardCtx.Provider value={val}>{children}</DashboardCtx.Provider>
  );
};

const useDashboard = () => {
  return useContext(DashboardCtx);
};

export const useTotalAmount = () => {
  const {
    tokensTotal,
    lpTokensTotal,
    farmsTotal,
    depositsAndLoans: { totalValueUSD: platformBalance },
  } = useDashboard();

  return {
    tokensTotal,
    platformBalance,
    lpTokensTotal,
    farmsTotal,
  };
};

export const useUserSummary = () => {
  const { walletAddress, summary, networth } = useDashboard();

  return {
    networth,
    summary,
    walletAddress,
  };
};

export const useProfileHistories = () => {
  const {
    histories,
    profile: { list },
  } = useDashboard();
  return {
    histories,
    profileList: list,
  };
};

export const useReservesProfits = () => {
  const {
    reservesProfits,
    profitsLoading,
    profitTotal,
    actionsGroupByCoin,
  } = useDashboard();

  return {
    reservesProfits,
    profitsLoading,
    profitTotal,
    actionsGroupByCoin,
  };
};

export const useProfileState = () => useDashboard().profile;

export const useProfileLendings = () => {
  const {
    profile: {
      reserves,
      reservesLoading,
      currentProfileLoading: profileLoading,
      curProfileTotal,
      initialized,
      initMainProfile,
    },
  } = useDashboard();

  return {
    reserves,
    reservesLoading,
    profileLoading,
    curProfileTotal,
    initialized,
    initMainProfile,
  };
};
export const useProfileLiquidations = () => {
  const {
    liquidationHistories,
    liquidationsTotal,
    liquidationHistoriesLoading,
  } = useDashboard().profile;

  return {
    liquidationHistories,
    liquidationsTotal,
    liquidationHistoriesLoading,
  };
};

export const useActionHistories = () => {
  const {
    profile: {
      actionHistories: histories,
      actionHistoriesLoading: isLoading,
    },
  } = useDashboard();

  return {
    histories,
    isLoading,
  };
};

export const useTokens = () => {
  const { tokens, tokensTotal } = useDashboard();

  return {
    tokens,
    tokensTotal,
  };
};

export const useLPTokens = () => {
  const {
    lpTokens,
    lpPlatformsMap: platformsMap,
    lpTokensTotal,
  } = useDashboard();
  return { lpTokens, platformsMap, lpTokensTotal };
};

export const useFarms = () => {
  const { farms, farmsPlatformsMap, farmsTotal } = useDashboard();
  return { farms, farmsPlatformsMap, farmsTotal };
};

export const usePlatforms = () => {
  const {
    depositsAndLoans: { platforms, totalValueUSD },
  } = useDashboard();
  return {
    platforms,
    platformsTotalUSD: totalValueUSD,
  };
};

export const useBalanceCompositions = () => {
  const {
    networth,
    tokensTotal,
    lpTokensTotal,
    farmsTotal,
    profile: { profileTotals },
  } = useDashboard();

  return {
    networth,
    tokensTotal,
    lpTokensTotal,
    farmsTotal,
    profileTotals,
  };
};
