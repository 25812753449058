import { useLocalStorageRef } from '@aries/shared/utils';
import axios from 'axios';
import { compact, mapValues, uniq } from 'lodash';
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';

const PRICE_RECORD_KEY = 'stale_price_map_1';
export const useTokenPrice = (
  coins: { address: string; coingeckoId?: string }[],
) => {
  const res = useTokenPricesUSD(compact(coins.map(c => c.coingeckoId)));
  const [stalePriceMapRef, setStalePriceMap] =
    useLocalStorageRef<Record<string, number>>(PRICE_RECORD_KEY);

  const priceMap = useMemo(() => {
    const map: Record<string, number> = {};
    coins.forEach(({ address, coingeckoId }) => {
      map[address] =
        res[coingeckoId ?? ''] ?? stalePriceMapRef.current?.[address] ?? 0;
    });

    return map;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  useEffect(() => {
    setStalePriceMap({ ...stalePriceMapRef.current, ...priceMap });
  }, [priceMap, setStalePriceMap, stalePriceMapRef]);

  return { priceMap };
};

export const useTokenPricesUSD = (
  keys: string[],
): Record<string, number | undefined> => {
  const { data } = useSWR(
    ['TokenPrice', ...uniq(keys)],
    async () => {
      const res = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?precision=6&ids=${uniq(
          keys,
        ).join(',')}&vs_currencies=usd`,
      );
      if (res.status === 200) {
        const reqData = res.data as { [k: string]: { usd: number } };
        return mapValues(reqData, v => v.usd);
      }

      return {};
    },
    {
      refreshInterval: 80000,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return data ?? {};
};
