import { setTheme } from '%/../ui-theming/src';

const colorPopPicker = {
  dark: '#f8f8f8',
  light: '#f8f8f8',
};

const colorPopPickerShadow = {
  dark: '#333',
  light: '#333',
};
const colorPopPickerItem = {
  dark: '#fff',
  light: '#000',
};
const colorPopPickerItemTitle = {
  dark: '#ccc',
  light: '#ccc',
};
const colorPopPickerItemSeparator = {
  dark: '#ccc',
  light: '#ccc',
};
const colorPopPickerAccessoryCheck = {
  dark: '#007aff',
  light: '#007aff',
};

export const PopoverPickerToken = {
  minWidthPopPicker: 120,
  maxWidthPopPicker: 260,
  minHeightPopPicker: 44,
  maxHeightPopPicker: 246,
  directionInsetsPopPicker: 4,
  paddingLeftPopPickerItem: 12,
  paddingRightPopPickerItem: 12,
  paddingTopPopPickerItem: 8,
  paddingBottomPopPickerItem: 8,
  fontSizePopPickerItem: 14,
  widthPopPickerAccessory: 10,
  heightPopPickerAccessory: 10,
  paddingLeftPopPickerAccessory: 8,
  colorPopPicker: setTheme(colorPopPicker),
  colorPopPickerShadow: setTheme(colorPopPickerShadow),
  colorPopPickerItem: setTheme(colorPopPickerItem),
  colorPopPickerItemTitle: setTheme(colorPopPickerItemTitle),
  colorPopPickerItemSeparator: setTheme(colorPopPickerItemSeparator),
  colorPopPickerAccessoryCheck: setTheme(colorPopPickerAccessoryCheck),
};

export const PopoverPickerConfig = {
  colorPopPicker,
  colorPopPickerShadow,
  colorPopPickerItem,
  colorPopPickerItemTitle,
  colorPopPickerItemSeparator,
  colorPopPickerAccessoryCheck,
};
