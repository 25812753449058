import { createGlobalStore } from '@aries/shared/deps';
import { eventEmitter } from '@aries/shared/utils';
import { useEffect, useRef, useState } from 'react';

export enum Network {
  Devnet = 'devnet',
  Testnet = 'testnet',
  Mainnet = 'mainnet',
}

const NETWORK_CHANGE_KEY = 'AptosProviderChange';

export const createProviderHub = <
  Meta extends { network: Network; name: string },
  Provider,
>(
  envList: Meta[],
  factory: (meta: Meta) => Provider,
) => {
  const onProviderChange = (
    callback: (network: typeof envList[number]) => void,
  ) => {
    return eventEmitter.addListener(NETWORK_CHANGE_KEY, network => {
      callback(network);
    });
  };

  const [useProviderHub, getProviderHub] = createGlobalStore(() => {
    const getProvider = (name: string) => {
      const meta = envList.find(v => v.name === name)!;
      const provider = factory(meta);

      return { provider, meta };
    };

    const [{ provider, meta: currentNetwork }, setProvider] = useState(
      () => getProvider(envList[0].name),
    );

    // To make we can get the newest provider immediately
    const providerRef = useRef(provider);
    providerRef.current = provider;

    const changeNetwork = (name: string) => {
      setProvider(getProvider(name));
    };

    const env = {
      isDevnet: currentNetwork.network === Network.Devnet,
      isTestnet: currentNetwork.network === Network.Testnet,
      isMainnet: currentNetwork.network === Network.Mainnet,
    };

    useEffect(() => {
      eventEmitter.emit(NETWORK_CHANGE_KEY, currentNetwork);
    }, [currentNetwork]);

    return {
      provider: providerRef.current,
      currentNetwork,
      list: envList,
      changeNetwork,
      env,
    };
  });

  return {
    useProviderHub,
    getProviderHub,
    onProviderChange,
  };
};
