import { createGlobalStore } from '@aries/shared/deps';
import { type Adapter } from '@aries/solana-defi/wallet';
import { Transaction } from '@solana/web3.js';
import { useWallet as useWalletImpl } from './hook';

export { useConnection, useWallet } from '@solana/wallet-adapter-react';
export { useWalletModal } from '@solana/wallet-adapter-react-ui';

export const [useWalletAdapter, getWalletAdapter] =
  createGlobalStore<Adapter>(() => {
    const { walletCtx, walletCtxRef, setWalletModalShow, disconnect } =
      useWalletImpl();

    const getAddressWithNotify = () => {
      const address = walletCtxRef.current?.address;

      if (!address) {
        throw new Error('Please connect your wallet');
      }

      return address;
    };

    return {
      walletAddress: walletCtx?.address,
      walletIcon: walletCtx?.adapter.wallet?.adapter.icon,
      signTransaction: (tx: Transaction) => {
        const adapter = walletCtxRef.current;
        if (!adapter) {
          throw new Error('Please connect your wallet');
        }

        return adapter.adapter.signTransaction?.(tx)!;
      },
      signAllTransactions: (txns: Transaction[]) => {
        const adapter = walletCtxRef.current;
        if (!adapter) {
          throw new Error('Please connect your wallet');
        }

        return adapter.adapter.signAllTransactions?.(txns)!;
      },
      getAddressWithNotify,
      getAddress: () => {
        return walletCtxRef.current?.address;
      },
      connect: () => setWalletModalShow(true),
      disconnect,
    };
  });
