import { createGlobalStore } from '@aries/shared/deps';
import { Asset } from '@port.finance/port-sdk';
import { SolanaProvider } from '@saberhq/solana-contrib';
import { deserializeAccount } from '@saberhq/token-utils';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  PublicKey,
  PublicKeyInitData,
  SystemProgram,
} from '@solana/web3.js';
import Big from 'big.js';
import { compact, keyBy, mapValues } from 'lodash';
import useSWR from 'swr';
import { useWallet } from '../wallet/index';
import {
  getProviderHub,
  MAX_GAS_PER_TX,
  useProviderHub,
} from './provider';
import { useWatchPriceAndUpdate } from './token-info';

const emptyProxy = (
  rawMap: any,
): Record<
  string,
  {
    lamports: Big;
  }
> => {
  return new Proxy(rawMap, {
    get: (map, prop: string) => {
      return map[prop] ?? { lamports: Big(0) };
    },
  });
};

export const [useBalanceHub, getBalanceHub] = createGlobalStore(() => {
  useWatchPriceAndUpdate();
  const { walletAddress } = useWallet();
  const { provider } = useProviderHub();
  const walletPubkey = walletAddress ? new PublicKey(walletAddress) : null;

  const { data: balanceMap = emptyProxy({}), mutate } = useSWR(
    ['BalanceHub', walletAddress, provider],
    async () => {
      // Will not return empty value.
      if (walletAddress) {
        const pubkey = new PublicKey(walletAddress);
        const [tokenAccounts, walletAccount] = await Promise.all([
          provider.connection.getTokenAccountsByOwner(pubkey, {
            programId: TOKEN_PROGRAM_ID,
          }),
          provider.connection.getAccountInfo(pubkey),
        ]);

        const map = mapValues(
          keyBy(
            tokenAccounts.value.map(rawAccount => {
              const accountData = deserializeAccount(
                rawAccount.account.data,
              );

              return {
                mintId: accountData.mint.toBase58(),
                lamports: Big(accountData.amount.toString()),
              };
            }),
            v => v.mintId as string,
          ),
          v => ({ lamports: v.lamports }),
        );

        map[NATIVE_MINT.toBase58()] = {
          lamports: Big(walletAccount?.lamports ?? 0),
        };

        return emptyProxy(map);
      }

      return emptyProxy({});
    },
    {
      refreshInterval: 60 * 1000,
      onError: err => {
        // eslint-disable-next-line no-console
        console.error('[FETCH BALANCE HUB ERR]', err);
      },
    },
  );

  const getBalance = (mint: PublicKeyInitData) => {
    const balance =
      balanceMap[new PublicKey(mint).toBase58()]?.lamports ?? Big(0);

    if (mint === NATIVE_MINT.toBase58()) {
      return balance.minus(Asset.MIN_NATIVE_LAMPORT.getRaw());
    }

    return balance;
  };

  const tryGetSplAccount = async (
    mint: PublicKeyInitData,
    minBalance?: Big,
  ) => {
    if (!walletPubkey) {
      throw new Error('Please connect wallet');
    }

    const mintId = new PublicKey(mint);
    const balance = getBalance(mintId);

    if (minBalance && balance.lt(minBalance)) {
      throw new Error('Insufficient balance');
    }

    if (mintId.equals(NATIVE_MINT)) {
      const { address: wrappedAddr, instruction: createWrappedIx } =
        await getOrCreateATA({
          provider,
          mint: NATIVE_MINT,
          owner: walletPubkey,
        });

      return {
        address: wrappedAddr,
        balance,
        preIxns: compact([
          createWrappedIx,
          minBalance &&
            SystemProgram.transfer({
              fromPubkey: walletPubkey,
              toPubkey: wrappedAddr,
              lamports: minBalance.toNumber(),
            }),
          minBalance &&
            // @ts-ignore this method is accidentally not exported by SPL library
            Token.createSyncNativeInstruction(
              TOKEN_PROGRAM_ID,
              wrappedAddr,
            ),
        ]),
      };
    }

    const { address: wrappedAddr, instruction: createWrappedIx } =
      await getOrCreateATA({
        provider,
        mint: mintId,
        owner: walletPubkey,
      });

    return {
      address: wrappedAddr,
      preIxns: compact([createWrappedIx]),
      balance: Big(0),
    };
  };

  return {
    balanceMap,
    refresh: () => mutate(v => v, true),
    getBalance,
    tryGetSplAccount,
  };
});

export const hasEnoughGasOrThrow = () => {
  const solBalance = getBalanceHub()?.getBalance(NATIVE_MINT) ?? Big(0);

  if (solBalance.lte(MAX_GAS_PER_TX)) {
    throw `You need to have ${
      MAX_GAS_PER_TX / 10 ** 9
    } SOL at least to send transaction. ${
      getProviderHub()?.env.isTestnet ? 'Go to Faucet to get Aptos!' : ''
    } ` as any;
  }
};

const getOrCreateATA = async ({
  provider,
  mint,
  owner,
}: {
  provider: SolanaProvider;
  mint: PublicKey;
  owner: PublicKey;
}) => {
  const address = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    mint,
    owner,
  );
  if (await provider.getAccountInfo(address)) {
    return { address, instruction: null };
  }
  return {
    address,
    instruction: Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      address,
      owner,
      owner,
    ),
  };
};
