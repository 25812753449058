import { createGlobalStore } from '@aries/shared/deps';
import { useProviderHub } from '@aries/solana-defi/common';
import { ReserveInfo, RESERVE_DATA_SIZE } from '@port.finance/port-sdk';
import { useLendingMarkets } from '../../market';
import { useProgramSubscription } from '../util';

export const [useReservesSubscription, getReservesSubscription] =
  createGlobalStore(() => {
    const { currentNetwork } = useProviderHub();
    const { activeMarketId } = useLendingMarkets();

    return useProgramSubscription(
      currentNetwork.programId.toBase58(),
      [
        {
          dataSize: RESERVE_DATA_SIZE,
        },
        {
          memcmp: {
            offset: 1 + 8 + 1,
            bytes: activeMarketId,
          },
        },
      ],
      ReserveInfo.fromRaw,
      {
        deps: [activeMarketId],
      },
    );
  });
