import { createGlobalStore } from '@aries/shared/deps';
import { compact, mapValues } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { Asset, BaseAsset, createAsset, EMPTY_ASSET } from '../types';
import { useTokenBaseInfo } from './base-info';
import { useTokenPrice } from './token-price';

export const createTokenHub = (fetchList: () => Promise<BaseAsset[]>) => {
  const [useTokenInfoHub, getTokenInfoHub] = createGlobalStore(() => {
    const [customizedPriceMap, setCustomizedPriceMap] = useState<
      Record<string, number>
    >({});

    const { tokenMap: tokenInfoMap, refresh } =
      useTokenBaseInfo(fetchList);

    const { priceMap } = useTokenPrice(
      Object.values(tokenInfoMap).map(({ id, coingeckoId }) => ({
        address: id,
        coingeckoId,
      })),
    );

    const { tokenMap, emptyableTokenMap } = useMemo(() => {
      const map = mapValues(tokenInfoMap, (baseInfo, key) =>
        createAsset(
          baseInfo,
          customizedPriceMap[key] ?? priceMap[key] ?? 1,
        ),
      );

      const tokenMap = new Proxy(map, {
        get: (target, key) => {
          return target[key as string] ?? EMPTY_TOKEN;
        },
      });

      return {
        emptyableTokenMap: map as Record<string, Asset | undefined>,
        tokenMap,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceMap, tokenInfoMap, customizedPriceMap]);

    const tokenMapRef = useRef(tokenMap);
    tokenMapRef.current = tokenMap;

    const tokenList: Asset[] = useMemo(
      () => compact(Object.values(emptyableTokenMap)),
      [emptyableTokenMap],
    );

    return {
      tokenMap,
      tokenMapRef,
      emptyableTokenMap,
      tokenList,
      refreshTokenList: refresh,
      setCustomizedPriceMap,
    };
  });

  return {
    useTokenInfoHub,
    getTokenInfoHub,
  };
};

export const EMPTY_TOKEN = {
  ...EMPTY_ASSET,
  address: '',
};
