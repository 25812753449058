import { useProviderHub } from '@aries/solana-defi/common/index';
import { PublicKey } from '@solana/web3.js';
import { useRef, useState } from 'react';

export const useAccountInfoHub = () => {
  const {
    provider: { connection },
    currentNetwork,
  } = useProviderHub();
  const [accountInfoMap, setAccountInfoMap] = useState<
    Record<string, Buffer>
  >({});
  const subscriptionIdsRef = useRef<string[]>([]);

  const subscribeAccountInfo = (publicKey: PublicKey) => {
    const cacheKey = `${currentNetwork.name}-${publicKey}}`;
    if (!publicKey || subscriptionIdsRef.current.includes(cacheKey)) {
      return;
    }

    let previousInfo = accountInfoMap[cacheKey] || null;
    connection.onAccountChange(publicKey, info => {
      if (!previousInfo || !previousInfo.equals(info.data)) {
        previousInfo = info.data;
        setAccountInfoMap(map => ({ ...map, [cacheKey]: info.data }));
      }
    });
    subscriptionIdsRef.current.push(cacheKey);
  };

  const getAccountInfo = (publicKey?: PublicKey) => {
    return publicKey
      ? accountInfoMap[`${currentNetwork.name}-${publicKey}}`]
      : null;
  };

  return { getAccountInfo, subscribeAccountInfo, accountInfoMap };
};
