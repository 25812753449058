/* eslint-disable  */
// ActionSheetItem.js
import { colorConfig, globalToken, ModeProps } from '@aries/ui-theming';
import React, { Component } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import { Theme } from '../../theme';

const { colorText, colorTextCritical, colorBgBase, colorBgContainer } =
  colorConfig;
export default class ActionSheetItem extends Component<any> {
  static contextType = ThemeContext;
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    type: 'default',
    topSeparator: 'none',
    bottomSeparator: 'none',
    disabled: false,
  };

  buildStyle() {
    const {
      paddingLeftAcSheetItem,
      paddingRightAcSheetItem,
      paddingBottomAcSheetItem,
      paddingTopAcSheetItem,
      minHeightAcSheetItem,
    } = globalToken.ActionSheet!;
    let { style } = this.props;
    const mode: ModeProps = this.context?.mode ?? 'dark';
    style = [
      {
        backgroundColor: colorBgContainer[mode],
        paddingLeft: paddingLeftAcSheetItem,
        paddingRight: paddingRightAcSheetItem,
        paddingTop: paddingTopAcSheetItem,
        paddingBottom: paddingBottomAcSheetItem,
        minHeight: minHeightAcSheetItem,
        overflow: 'hidden',
        justifyContent: 'center',
      },
    ].concat(style);
    return style;
  }

  renderSeparator(separator: any) {
    const { type } = this.props;
    const { paddingLeftAcSheetItem } = globalToken.ActionSheet!;
    const mode: ModeProps = this.context?.mode ?? 'dark';

    const indentViewStyle = {
      backgroundColor: 'rgba(0,0,0,0)',
      paddingLeft: paddingLeftAcSheetItem,
    };
    let separatorStyle;
    if (type === 'cancel') {
      separatorStyle = {
        backgroundColor: colorBgContainer[mode],
        height: Theme.pixelSize,
      };
    } else {
      separatorStyle = {
        backgroundColor: colorBgBase[mode],
        height: Theme.pixelSize,
      };
    }
    switch (separator) {
      case 'full':
        return <View style={separatorStyle} />;
      case 'indent':
        return (
          <View style={indentViewStyle}>
            <View style={separatorStyle} />
          </View>
        );
      default:
        return null;
    }
  }

  renderTitle() {
    const { type, title, disabled } = this.props;
    const {
      FontSizeAcSheetItem,
      fontSizeAcSheetCancelItem,
      opacityAcSheetItemDisabled,
    } = globalToken.ActionSheet!;
    const mode: ModeProps = this.context?.mode ?? 'dark';
    if (
      title === null ||
      title === undefined ||
      React.isValidElement(title)
    )
      return title;

    let textStyle;
    if (type === 'cancel') {
      textStyle = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: colorTextCritical[mode],
        fontSize: fontSizeAcSheetCancelItem,
        textAlign: 'center',
        opacity: disabled ? opacityAcSheetItemDisabled : 1,
        overflow: 'hidden',
      };
    } else {
      textStyle = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: colorText[mode],
        fontSize: FontSizeAcSheetItem,
        textAlign: 'center',
        opacity: disabled ? opacityAcSheetItemDisabled : 1,
        overflow: 'hidden',
      };
    }
    return (
      <Text style={textStyle as any} numberOfLines={1}>
        {title}
      </Text>
    );
  }

  render() {
    const {
      style,
      children,
      type,
      title,
      topSeparator,
      bottomSeparator,
      disabled,
      activeOpacity,
      ...others
    } = this.props as any;
    return (
      <View style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
        {this.renderSeparator(topSeparator)}
        <TouchableOpacity
          style={this.buildStyle()}
          activeOpacity={disabled ? 1 : 0.9}
          {...others}
        >
          {this.renderTitle()}
        </TouchableOpacity>
        {this.renderSeparator(bottomSeparator)}
      </View>
    );
  }
}
