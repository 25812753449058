/* eslint-disable prettier/prettier */
import './initialize';

import { initLendingAppImpl } from '@aries/lending-fe-hooks/adapter';
import { UIProvider } from '@aries/mobile-core/provider';
import { HoxRoot } from '@aries/shared/deps';
import {
  getSolanaLendingApp,
  JupiterProvider,
  useSolanaLendingApp,
  useSolanaTradingApp,
} from '@aries/solana-defi';
import { initWalletCtx } from '@aries/solana-defi/wallet';
import {
  getWalletAdapter,
  useWalletAdapter,
} from '@aries/solana-web-wallet';
import { WalletProvider } from '@aries/solana-web-wallet/provider/mobile-web';
import { initTradingAppImpl } from '@aries/trading-fe-hooks/adapter';
import React from 'react';
import { AppRoutes } from './routes';

initWalletCtx({ hook: useWalletAdapter, getter: getWalletAdapter });

initTradingAppImpl({
  hook: useSolanaTradingApp,
});

initLendingAppImpl({
  hook: useSolanaLendingApp,
  getter: getSolanaLendingApp,
});

export default function App() {
  return (
    <HoxRoot>
      <UIProvider>
        <JupiterProvider>
          <WalletProvider>
            <AppRoutes />
          </WalletProvider>
        </JupiterProvider>
      </UIProvider>
    </HoxRoot>
  );
}
