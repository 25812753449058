import { colorConfig, useThemeMode } from '@aries/ui-theming';
import { noop } from 'lodash';
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import {
  SceneMap,
  TabBar,
  TabView as ScrollableTabView,
} from 'react-native-tab-view';
import { CenterRow } from '../layout';
import { Txt } from '../txt';

const { colorBgBase, colorTextSecondary, colorText, colorInteractive } =
  colorConfig;
export interface TabViewProps {
  initialPage?: number;
  style?: ViewStyle;
  prerenderingSiblingsNumber?: number;
  tabbarStyle?: ViewStyle;
  tabStyle?: ViewStyle;
  tabLabelStyle?: TextStyle;
  pages: {
    title: string;
    view: React.ReactNode;
    indicatorColor?: string;
  }[];
  goToPageRef?: MutableRefObject<(index: number) => void>;
}

const TabContext = React.createContext({
  changeTab: noop as (index: number) => void,
  curPage: 0,
});

export const useTabViewCtx = () => useContext(TabContext);

export const TabView: React.FC<TabViewProps> = React.memo(props => {
  const {
    initialPage = 0,
    prerenderingSiblingsNumber,
    pages: data,
    goToPageRef: customGoToPageRef,
    style,
    tabStyle,
    tabLabelStyle,
    tabbarStyle,
  } = props;
  const [index, setIndex] = React.useState(initialPage);

  const [routes] = React.useState(
    data.map(v => {
      return { key: v.title, title: v.title };
    }),
  );
  const currentRoute = data[index];

  const { mode } = useThemeMode();

  const scene: Record<string, React.ComponentType<unknown>> = {};

  data.forEach(v => {
    scene[v.title] = () => v.view as any;
  });

  const renderScene = SceneMap(scene);

  const goToPageRef = useRef<(index: number) => void>(v => setIndex(v));

  useEffect(() => {
    if (customGoToPageRef) {
      customGoToPageRef.current = goToPageRef.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ctx = useMemo(
    () => ({ changeTab: goToPageRef.current, curPage: index }),
    [index],
  );

  return (
    <TabContext.Provider value={ctx}>
      <ScrollableTabView
        style={style}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={value => {
          return (
            <TabBar
              {...value}
              inactiveColor={colorTextSecondary[mode]}
              activeColor={colorText[mode]}
              indicatorStyle={{
                marginBottom: -2,
                backgroundColor:
                  currentRoute.indicatorColor ?? colorInteractive[mode],
              }}
              style={{
                backgroundColor: 'transparent',
                ...tabbarStyle,
              }}
              labelStyle={{
                fontSize: 10,
                ...tabLabelStyle,
              }}
              tabStyle={{
                backgroundColor: colorBgBase[mode],
                paddingTop: -5,
                height: 40,
                marginRight: -1,
                ...tabStyle,
              }}
              renderLabel={({ route }) => {
                const isActive =
                  routes.findIndex(r => r.title === route.title) === index;

                return (
                  <CenterRow flex={1}>
                    <Txt
                      c2={!isActive}
                      bold={isActive}
                      style={{ fontSize: 14 }}
                    >
                      {route.title}
                    </Txt>
                  </CenterRow>
                );
              }}
            />
          );
        }}
        onIndexChange={setIndex}
        lazyPreloadDistance={prerenderingSiblingsNumber}
      />
    </TabContext.Provider>
  );
});
