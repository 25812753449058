import {
  getLendingAppData,
  Reserve,
  useReserveById,
} from '@aries/lending-fe-hooks';
import { CenterRow, TabView, Txt } from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { useTranslation } from '@aries/shared/locale';
import { compact } from 'lodash';
import React, { FC } from 'react';
import { TokenIcon } from '~/components';
import { BorrowPanel } from './Borrow';
import { CopiableAddress, FAQs } from './common';
import { DepositPanel } from './Deposit';
import { RepayPanel } from './Repay';
import { WithdrawPanel } from './Withdraw';

export const openReserveAction = (assetId: string) => {
  const reserve = getLendingAppData()?.reserves.find(
    r => r.asset.id === assetId,
  );

  if (!reserve) return;
  openModal({
    renderHeader: () => (
      <CenterRow>
        <TokenIcon asset={reserve.asset} size={25} />
        <Txt ml={8} h3>
          {reserve.asset.name}
        </Txt>
      </CenterRow>
    ),
    hasContentMargin: false,
    renderContent: ({ close }) => (
      <ReserveAction reserveId={reserve.id} onClose={close} />
    ),
  });
};

const ReserveAction: FC<{
  onClose: () => void;
  reserveId: string;
}> = ({ onClose, reserveId }) => {
  const { reserve } = useReserveById(reserveId);
  const { t } = useTranslation();

  if (!reserve) return null;

  const moreInfo = <Footer reserve={reserve} />;

  return (
    <TabView
      pages={[
        {
          title: t`Deposit`,
          view: (
            <DepositPanel
              onClose={onClose}
              reserveId={reserveId}
              footerComponents={moreInfo}
            />
          ),
        },
        {
          title: t`Withdraw`,
          view: (
            <WithdrawPanel
              onClose={onClose}
              reserveId={reserveId}
              footerComponents={moreInfo}
            />
          ),
        },
        {
          title: t`Borrow`,
          view: (
            <BorrowPanel
              onClose={onClose}
              reserveId={reserveId}
              footerComponents={moreInfo}
            />
          ),
        },
        {
          title: t`Repay`,
          view: (
            <RepayPanel
              onClose={onClose}
              reserveId={reserveId}
              footerComponents={moreInfo}
            />
          ),
        },
      ]}
    />
  );
};

const Footer: FC<{ reserve: Reserve }> = ({ reserve }) => {
  const { t } = useTranslation();
  return (
    <FAQs
      style={{ marginTop: 16 }}
      data={compact([
        {
          question: t`Deposit Limit`,
          answer: reserve.meta.depositLimit ?? '-',
        },
        {
          question: t`Borrow Limit`,
          answer: reserve.meta.borrowLimit ?? '-',
        },
        {
          question: t`Maximum LTV`,
          answer: `${reserve.meta.loanToValue}`,
        },
        {
          question: t`Liquidation threshold`,
          answer: `${reserve.meta.liquidationThreshold}`,
        },
        {
          question: t`Coin Address`,
          answer: <CopiableAddress address={reserve.meta.coinAddress} />,
        },
        {
          question: t`LP Address`,
          answer: <CopiableAddress address={reserve.meta.lpCoinAddress} />,
        },
      ])}
      direction="horizontal"
      title={t`More Info`}
    />
  );
};
