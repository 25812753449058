import {
  getBalanceHub,
  getProviderHub,
  hasEnoughGasOrThrow,
} from '@aries/solana-defi/common';
import { getProfileHub } from '@aries/solana-defi/lending/data/profile';
import { withSendTxNotify } from '@aries/solana-defi/utils';
import { Lamport } from '@port.finance/port-sdk';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Keypair, Transaction } from '@solana/web3.js';
import Big from 'big.js';
import { bigToU64 } from './instructions/config';
import { repayIx } from './instructions/repay';
import { getReserveCtx } from './utils';

export const repayAsset = withSendTxNotify(
  async (reserveKey: string, lamports: Big, isMax: boolean) => {
    hasEnoughGasOrThrow();
    const { provider } = getProviderHub()!;

    const {
      walletPubkey,
      marketPubkey,
      profilePubkey,
      reserveId,
      assetMintId,
      reserveSDK,
      refreshProfileIxs,
      refreshReservesIxs,
    } = await getReserveCtx(reserveKey);

    const tx = new Transaction();

    const srcSplAccount = await getBalanceHub()!.tryGetSplAccount(
      assetMintId,
      lamports,
    );

    const transferAuthorityKeypair = Keypair.generate();

    const repayAmount = isMax ? Lamport.max().getRaw() : lamports;

    tx.add(
      ...srcSplAccount.preIxns,
      ...refreshReservesIxs,
      ...refreshProfileIxs,
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        srcSplAccount.address,
        transferAuthorityKeypair.publicKey,
        walletPubkey,
        [],
        bigToU64(repayAmount),
      ),
      repayIx({
        srcAssetSplAccount: srcSplAccount.address,
        reserveAssetSplAccount: reserveSDK.asset.getSplAccountId(),
        reserveId,
        transferAuthority: transferAuthorityKeypair.publicKey,
        amount: repayAmount,
        marketId: marketPubkey,
        profileId: profilePubkey,
      }),
      Token.createRevokeInstruction(
        TOKEN_PROGRAM_ID,
        srcSplAccount.address,
        walletPubkey,
        [],
      ),
    );

    const res = await provider.signAndSendTx(tx, [
      transferAuthorityKeypair,
    ]);

    getProfileHub()?.refresh();
    getBalanceHub()?.refresh();

    return res;
  },
);
