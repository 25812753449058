import { useRepay } from '@aries/lending-fe-hooks';
import {
  CenterRow,
  Col,
  ScrollView,
  useTabViewCtx,
} from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import Big from 'big.js';
import React, { FC, ReactNode } from 'react';
import { TokenInput } from '~/components';
import {
  ConfirmButton,
  Content,
  ExtraConfig,
  MaxValueRow,
} from './common';

export const RepayPanel: FC<{
  onClose: () => void;
  reserveId: string;
  footerComponents: ReactNode;
}> = ({ onClose, reserveId, footerComponents }) => {
  const { t } = useTranslation();
  const repay = useRepay(reserveId);
  const { curPage } = useTabViewCtx();

  if (!repay) return null;

  const {
    amount,
    loading,
    isMaxRef,
    reserve,
    borrowed,
    disableConfirm,
    disableInput,
    onChange,
    onConfirm,
  } = repay;

  return (
    <Content>
      <ScrollView>
        <Col pb={12}>
          <TokenInput
            tokenInfo={reserve.asset}
            value={amount}
            onAmountChange={v => onChange(v)}
            maxAmount={Big(borrowed ?? 0)}
          >
            <CenterRow>
              <TokenInput.Input
                focused={curPage === 3}
                disabled={disableInput}
                style={{ width: '100%' }}
              />
            </CenterRow>
            <MaxValueRow
              maxTitle={t`Borrowed`}
              asset={reserve.asset}
              onSetValue={(v, isMax) => {
                onChange(v);
                isMaxRef.current = isMax;
              }}
              max={borrowed ?? 0}
            />
            <TokenInput.Slider
              onPctChange={pct => {
                isMaxRef.current = pct === 100;
              }}
            />
          </TokenInput>

          <ExtraConfig reserveId={reserve.id} items={[]} />

          {footerComponents}
        </Col>
      </ScrollView>

      <ConfirmButton
        onClick={async () => {
          const success = await onConfirm();
          success && onClose();
        }}
        loading={loading}
        disabled={disableConfirm}
        title={t`Repay`}
      />
    </Content>
  );
};
