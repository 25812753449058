import { colorConfig, useThemeMode } from '@aries/ui-theming';
import { isString } from 'lodash';
import React from 'react';
import { Platform, StyleSheet, View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { CenterRow } from '../layout';
import { Txt } from '../txt';
import RNSlider from './Slider';

const { colorSliderThumb, colorSliderTrack } = colorConfig.Slider;
const { colorBgSpotlight } = colorConfig;
export const Slider: React.FunctionComponent<{
  step?: number;
  onChangeValue?: (v: number) => void;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  thumbSize?: number;
  style?: ViewStyle;
  sliderHeight?: number;
  marks?: { step: number; label: string | React.ReactNode }[];
  marksHeight?: number;
}> = props => {
  const {
    onChangeValue,
    value,
    step = 1,
    min = 0,
    max = 10,
    thumbSize = 16,
    disabled,
    style,
    marks,
    sliderHeight = 28,
    marksHeight = 18,
  } = props;
  const { mode } = useThemeMode();

  return (
    <View style={style}>
      <RNSlider
        value={value}
        // @ts-ignore;
        disabled={disabled}
        // @ts-ignore;
        onValueChange={(value: number) => {
          onChangeValue?.(Number(value));
        }}
        trackStyle={{
          height: 4,
          borderRadius: 5,
        }}
        thumbStyle={[
          styles.thumb,
          {
            width: thumbSize,
            height: thumbSize,
            backgroundColor: colorSliderThumb[mode],
          },
        ]}
        minimumTrackTintColor={colorSliderTrack[mode]}
        step={step}
        minimumValue={min}
        maximumValue={max}
        maximumTrackTintColor={colorBgSpotlight[mode]}
        style={{ height: sliderHeight }}
      />

      {marks ? (
        <CenterRow style={{ height: marksHeight }}>
          {marks.map((item, index) => {
            return (
              <View
                key={index}
                style={{
                  left: `${(item.step / max) * 100}%`,
                  position: 'absolute',
                }}
              >
                <View>
                  <Placeholder
                    style={{
                      top: -sliderHeight / 2 - PLACEHOLDER_SIZE / 2,
                      left: -(item.step / max) * 6,
                    }}
                  />
                </View>
                <View style={{ left: index === 0 ? '' : '-48%' }}>
                  {isString(item.label) ? (
                    <Txt h2 key={index}>
                      {item.label}
                    </Txt>
                  ) : (
                    item.label
                  )}
                </View>
              </View>
            );
          })}
        </CenterRow>
      ) : null}
    </View>
  );
};
const PLACEHOLDER_SIZE = 6;

const Placeholder = styled.View`
  width: ${PLACEHOLDER_SIZE}px;
  height: ${PLACEHOLDER_SIZE}px;
  border-radius: ${PLACEHOLDER_SIZE}px;
  background-color: #fff;
  position: absolute;
`;

const styles = StyleSheet.create({
  thumb: Platform.select({
    ios: {
      width: 30,
      height: 30,
      borderRadius: 30 / 2,
      backgroundColor: 'white',
      shadowColor: 'black',
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 2,
      shadowOpacity: 0.35,
    },
    android: {
      width: 30,
      height: 30,
      borderRadius: 30 / 2,
      backgroundColor: 'white',
      borderWidth: 1,
      borderColor: '#ccc',
      elevation: 4,
    },
  })!,
});
