import { colorConfig, ModeProps, useThemeMode } from '@aries/ui-theming';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { parseColor } from '../../utils';

type BaseProps = {
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;

  pd?: number;
  pt?: number;
  pr?: number;
  pl?: number;
  pb?: number;

  flex?: number;

  w?: number | string;
  h?: number | string;
};

export type LinearDefaultColorProps = {
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
};

export const LinearView: React.FC<
  {
    color?: string;
    style?: StyleProp<ViewStyle>;
    type?: keyof LinearDefaultColorProps;
  } & LinearDefaultColorProps &
    BaseProps
> = props => {
  const { mode } = useThemeMode();
  const {
    color: colors,
    style,
    children,
    disabled,
    loading,
    active,
  } = props;

  const { color, end, start } = parseColor(
    colors ?? getLinearConfig({ disabled, loading, active }, mode),
  );

  return (
    <Container
      {...props}
      colors={color}
      start={start}
      end={end}
      style={style}
    >
      {children}
    </Container>
  );
};

const getLinearConfig = (
  {
    active,
    disabled,
    loading,
  }: {
    disabled?: boolean;
    active?: boolean;
    loading?: boolean;
  },
  mode: ModeProps,
) => {
  const { colorPrimaryBg, colorPrimaryBgActive, colorPrimaryBgDisabled } =
    colorConfig;

  if (active) {
    return colorPrimaryBgActive[mode];
  }
  if (disabled) {
    return colorPrimaryBgDisabled[mode];
  }
  if (loading) {
    return colorPrimaryBg[mode];
  }

  return colorPrimaryBg[mode];
};

const Container = styled(LinearGradient)<{
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;

  pd?: number;
  pt?: number;
  pr?: number;
  pl?: number;
  pb?: number;

  flex?: number;

  w?: number | string;
  h?: number | string;
}>`
  ${p => (p.mg ? `margin:${p.mg}px` : '')};

  ${p => (p.ml ? `margin-left:${p.ml}px` : '')};
  ${p => (p.mr ? `margin-right:${p.mr}px` : '')};
  ${p => (p.mt ? `margin-top:${p.mt}px` : '')};
  ${p => (p.mb ? `margin-bottom:${p.mb}px` : '')};

  ${p => (p.pd ? `padding:${p.pd}px` : '')};
  ${p => (p.pl ? `padding-right:${p.pl}px` : '')};
  ${p => (p.pr ? `padding-right:${p.pr}px` : '')};
  ${p => (p.pt ? `padding-top:${p.pt}px` : '')};
  ${p => (p.pb ? `padding-bottom:${p.pb}px` : '')};

  ${p => (p.flex ? `flex:${p.flex}` : '')};
  ${p => (p.w ? `width:${p.w}px` : '')};
  ${p => (p.h ? `height:${p.h}px` : '')};
`;
