/* eslint-disable  */
// OverlayPopoverView.js

import React from 'react';
import { Dimensions, Platform } from 'react-native';

import { Popover } from '../Popover';
import OverlayView from './OverlayView';

export default class OverlayPopoverView extends OverlayView {
  static defaultProps = {
    ...OverlayView.defaultProps,
    overlayOpacity: 0,
    direction: 'down',
    autoDirection: true,
    align: 'end',
    showArrow: true,
  };

  constructor(props) {
    super(props);
    Object.assign(this.state, {
      fromBounds: props.fromBounds,
      popoverWidth: null,
      popoverHeight: null,
    });
    this.defaultDirectionInsets = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps &&
      super.UNSAFE_componentWillReceiveProps(nextProps);
    if (
      JSON.stringify(nextProps.fromBounds) !=
      JSON.stringify(this.state.fromBounds)
    ) {
      this.setState({ fromBounds: nextProps.fromBounds });
    }
  }

  updateFromBounds(bounds) {
    this.setState({ fromBounds: bounds });
  }

  onPopoverLayout(e) {
    if (
      Platform.OS === 'android' &&
      (this.state.popoverWidth !== null ||
        this.state.popoverHeight != null)
    ) {
      // android calls many times...
      return;
    }
    const { width, height } = e.nativeEvent.layout;
    if (
      width !== this.state.popoverWidth ||
      height !== this.state.popoverHeight
    ) {
      this.setState({ popoverWidth: width, popoverHeight: height });
    }
  }

  buildPopoverStyle() {
    const { fromBounds, popoverWidth, popoverHeight } = this.state;
    let {
      popoverStyle,
      direction,
      autoDirection,
      directionInsets,
      align,
      alignInsets,
      showArrow,
      arrow,
    } = this.props;
    if (popoverWidth === null || popoverHeight === null) {
      popoverStyle = []
        .concat(popoverStyle)
        .concat({ position: 'absolute', left: 0, top: 0, opacity: 0 });
      if (!showArrow) arrow = 'none';
      else {
        switch (direction) {
          case 'right':
            arrow = 'left';
            break;
          case 'left':
            arrow = 'right';
            break;
          case 'up':
            arrow = 'bottom';
            break;
          default:
            arrow = 'top';
            break;
        }
      }
      return { popoverStyle, arrow };
    }

    const screenWidth = Dimensions.get('window').width;
    const screenHeight = Dimensions.get('window').height;
    let { x, y, width, height } = fromBounds || {};

    if (!x && x !== 0) x = screenWidth / 2;
    if (!y && y !== 0) y = screenHeight / 2;
    if (!width) width = 0;
    if (!height) height = 0;
    if (!directionInsets && directionInsets !== 0)
      directionInsets = this.defaultDirectionInsets;
    if (!alignInsets) alignInsets = 0;

    // auto direction
    const ph = popoverHeight + directionInsets;
    const pw = popoverWidth + directionInsets;
    switch (direction) {
      case 'right':
        if (autoDirection && x + width + pw > screenWidth && x >= pw)
          direction = 'left';
        break;
      case 'left':
        if (autoDirection && x + width + pw <= screenWidth && x < pw)
          direction = 'right';
        break;
      case 'up':
        if (autoDirection && y + height + ph <= screenHeight && y < ph)
          direction = 'down';
        break;
      default:
        if (autoDirection && y + height + ph > screenHeight && y >= ph)
          direction = 'up';
        break;
    }

    // calculate popover top-left position and arrow type
    let px;
    let py;
    switch (direction) {
      case 'right':
        px = x + width + directionInsets;
        arrow = 'left';
        break;
      case 'left':
        px = x - popoverWidth - directionInsets;
        arrow = 'right';
        break;
      case 'up':
        py = y - popoverHeight - directionInsets;
        arrow = 'bottom';
        break;
      default:
        py = y + height + directionInsets;
        arrow = 'top';
        break;
    }
    if (direction == 'down' || direction == 'up') {
      switch (align) {
        case 'start':
          px = x - alignInsets;
          arrow += 'Left';
          break;
        case 'center':
          px = x + width / 2 - popoverWidth / 2;
          break;
        default:
          px = x + width - popoverWidth + alignInsets;
          arrow += 'Right';
          break;
      }
    } else if (direction == 'right' || direction == 'left') {
      switch (align) {
        case 'end':
          py = y + height - popoverHeight + alignInsets;
          arrow += 'Bottom';
          break;
        case 'center':
          py = y + height / 2 - popoverHeight / 2;
          break;
        default:
          py = y - alignInsets;
          arrow += 'Top';
          break;
      }
    }

    popoverStyle = [].concat(popoverStyle).concat({
      position: 'absolute',
      left: px,
      top: py,
      width: popoverWidth,
    });
    if (!showArrow) arrow = 'none';

    return { popoverStyle, arrow };
  }

  renderContent(content = null) {
    const { paddingCorner, children } = this.props;
    const { popoverStyle, arrow } = this.buildPopoverStyle();
    return (
      <Popover
        style={popoverStyle}
        arrow={arrow}
        paddingCorner={paddingCorner}
        onLayout={e => this.onPopoverLayout(e)}
      >
        {content || children}
      </Popover>
    );
  }
}
