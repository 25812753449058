import {
  CenterRow,
  Slider as SliderImpl,
  Txt,
  View,
} from '@aries/mobile-core/components';
import { Big } from 'big.js';
import { ViewStyle } from 'react-native';
import { IconCircleSwitch } from '../icons';
import { InputNumber } from '../InputNumber';
import { TokenIcon as TokenIconImpl } from '../TokenIcon';
import { useTokenInputContext } from './context';

import { colorConfig, useThemeMode } from '@aries/ui-theming';

export const Input: React.FC<{
  style?: ViewStyle;
  disabled?: boolean;
  suffix?: React.ReactNode;
  autoFocus?: boolean;
  focused?: boolean;
  placeholder?: string;
}> = ({ style, disabled, suffix, focused, autoFocus, placeholder }) => {
  const {
    maxAmount: maxValue,
    valueByCoin,
    valueByUSD,
    mode,
    tokenInfo,
    setValueByCoin,
    setValueByUSD,
  } = useTokenInputContext();

  const isUSD = mode === 'usd';

  const value = isUSD ? valueByUSD : valueByCoin;
  const onChange = isUSD ? setValueByUSD : setValueByCoin;
  const max = isUSD ? maxValue : maxValue;

  const size =
    Math.max((1.875 * (6 - value.toFixed(0).length)) / 5, 1.5) * 16;
  return (
    <InputNumber
      value={value}
      valueType="big"
      onChange={v => {
        onChange(v);
      }}
      min={Big(0)}
      max={max}
      disabled={disabled}
      focused={focused}
      autoFocus={autoFocus}
      placeholder={placeholder}
      keyboardType="decimal-pad"
      leftComponent={
        isUSD ? (
          <Txt style={{ fontSize: size, lineHeight: 36 }}>$</Txt>
        ) : undefined
      }
      rightComponent={
        suffix ??
        (isUSD ? undefined : (
          <CenterRow>
            {/* <TokenIconImpl asset={tokenInfo} size={25} /> */}
            <Txt
              c1
              style={{
                lineHeight: 36,
                fontSize: size,
              }}
              ml={5}
            >
              {tokenInfo?.symbol}
            </Txt>
          </CenterRow>
        ))
      }
      style={style ?? { width: '100%' }}
      inputStyle={{
        fontSize: size,
        overflow: 'visible',
        lineHeight: 36,
        width: `${
          ((value.eq(0) ? placeholder : value) ?? '000').toString()
            .length *
            0.8 +
          1
        }rem`,
      }}
    />
  );
};

const { colorBgSpotlight, colorIcon } = colorConfig;
export const Slider: React.FC<{
  onPctChange?: (pct: number) => void;
  value?: number;
}> = ({ onPctChange, value }) => {
  const { mode } = useThemeMode();
  const { pct: innerPct, setPct, maxAmount } = useTokenInputContext();
  const pct = value ?? innerPct;
  const marks: Parameters<typeof SliderImpl>[0]['marks'] = [
    { label: '0%', step: 0 },
    { label: '25%', step: 25 },
    { label: '50%', step: 50 },
    { label: '75%', step: 75 },
    { label: '100%', step: 100 },
  ];

  if (!marks.some(v => v.step === pct)) {
    marks.push({
      label: (
        <View
          pl={3}
          pr={3}
          style={{
            backgroundColor: colorBgSpotlight[mode],
            borderRadius: 3,
          }}
        >
          <Txt c1>{pct.toFixed(0)}%</Txt>
        </View>
      ),
      step: pct,
    });
  }

  return (
    <SliderImpl
      min={0}
      max={100}
      disabled={maxAmount.lte(0)}
      value={pct}
      onChangeValue={v => {
        setPct?.(v);
        onPctChange?.(v);
      }}
      marks={marks}
      style={{ marginLeft: '5%', marginTop: '20px', width: '90%' }}
    />
  );
};

export const TokenIcon: React.FC<{ size?: number }> = ({ size = 35 }) => {
  const { tokenInfo } = useTokenInputContext();

  return <TokenIconImpl size={size} asset={tokenInfo} />;
};

export const ChangeMode: React.FC<{
  size?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}> = ({ size = '40px', onClick, style }) => {
  const { mode: themeMode } = useThemeMode();
  const { changeMode, mode } = useTokenInputContext();

  return (
    <IconCircleSwitch
      onClick={() =>
        onClick ? onClick() : changeMode(mode === 'coin' ? 'usd' : 'coin')
      }
      style={{
        fontSize: size,
        color: colorIcon[themeMode],
        ...style,
      }}
    />
  );
};

export const TokenName = () => {
  const { tokenInfo } = useTokenInputContext();
  return tokenInfo?.name ?? 'Unknown';
};

export const CurrentValueUSD = () => {
  const { valueByUSD } = useTokenInputContext();
  return <>{valueByUSD.toString()}</>;
};

export const CurrentValueCoin = () => {
  const { valueByCoin } = useTokenInputContext();
  return <>{valueByCoin.toString()}</>;
};
