/* eslint-disable no-param-reassign */
import {
  createProviderHub,
  Network,
} from '@aries/defi-toolkit/provider-hub';
import { SolanaProvider } from '@saberhq/solana-contrib';
import {
  Connection,
  Keypair,
  Cluster,
  Transaction,
  PublicKey,
  Signer,
} from '@solana/web3.js';
import { noop } from 'lodash';
import { Environment } from '@port.finance/port-sdk';
import { getWalletCtx } from '@aries/solana-defi/wallet';
import { ENDPOINT_WHITE_LIST_DOMAINS } from '@aries/solana-defi/config';

const READONLY_WALLET = {
  signAllTransactions: noop as any,
  signTransaction: noop as any,
  publicKey: Keypair.generate().publicKey,
};

export const MAX_GAS_PER_TX = 100000;

export const { useProviderHub, getProviderHub, onProviderChange } =
  createProviderHub(
    [
      {
        name: 'Port Finance Node',
        desc: 'Stable version deployed on Dedicated Port Finance Node.',
        endpoint: `https://port-finance.rpcpool.com/${
          ENDPOINT_WHITE_LIST_DOMAINS.includes(window.location.hostname)
            ? ''
            : 'b20f8af6e479ad1c68937cf806a1'
        }`,
        network: Network.Mainnet,
        programId: Environment.forMainNet().getLendingProgramPk(),
        stakingProgramId: Environment.forMainNet().getStakingProgramPk()!,
        cluster: 'mainnet-beta' as Cluster,
      },
      {
        name: 'Solana Mainnet RPC',
        desc: 'Stable version deployed on Solana Mainnet.',
        endpoint: 'https://api.mainnet-beta.solana.com',
        network: Network.Mainnet,
        programId: Environment.forMainNet().getLendingProgramPk(),
        stakingProgramId: Environment.forMainNet().getStakingProgramPk()!,
        cluster: 'mainnet-beta' as Cluster,
      },
    ],
    meta => {
      const connection = new Connection(meta.endpoint, 'confirmed');
      const provider = SolanaProvider.init({
        connection,
        wallet: READONLY_WALLET,
      });

      const signAndSendTx = async (
        transaction: Transaction,
        signers: Signer[],
      ) => {
        const walletCtx = getWalletCtx();

        if (!walletCtx?.walletAddress) {
          throw new Error('Please connect your wallet');
        }

        const wallet = new PublicKey(walletCtx.walletAddress);
        const provider = SolanaProvider.init({
          connection,
          wallet: {
            signTransaction: walletCtx.signTransaction,
            signAllTransactions: walletCtx.signAllTransactions,
            publicKey: wallet,
          },
        });

        const confirmedHash = await connection.getLatestBlockhash(
          'confirmed',
        );

        transaction.recentBlockhash = confirmedHash.blockhash;
        transaction.feePayer = wallet;

        signers.forEach(s => transaction.partialSign(s));

        const signedTx = await walletCtx.signTransaction(transaction);

        const pendingTx = await provider.broadcaster.broadcast(signedTx, {
          skipPreflight: true,
        });

        await pendingTx.confirm({});

        const txId = pendingTx.signature;

        const tx = await provider.connection.getTransaction(txId);

        const success = !tx?.meta?.err;

        if (success) {
          return {
            success: true as const,
            rawTx: tx,
            txId,
            payload: transaction,
          };
        }
        return {
          success: false as const,
          rawTx: tx,
          txId,
          payload: transaction,
          message: tx.meta?.err?.toString() ?? '',
        };
      };

      return Object.assign(provider, { signAndSendTx });
    },
  );
