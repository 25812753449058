import { useWithdraw } from '@aries/lending-fe-hooks';
import {
  CenterRow,
  Col,
  ScrollView,
  useTabViewCtx,
} from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import React, { FC, ReactNode } from 'react';
import { TokenInput } from '~/components';
import {
  ConfirmButton,
  Content,
  ExtraConfig,
  MaxValueRow,
} from './common';

export const WithdrawPanel: FC<{
  reserveId: string;
  onClose: () => void;
  footerComponents: ReactNode;
}> = ({ onClose, reserveId, footerComponents }) => {
  const { t } = useTranslation();
  const withdraw = useWithdraw(reserveId);
  const { curPage } = useTabViewCtx();
  if (!withdraw) return null;

  const {
    reserve,
    loading,
    max,
    amount,
    disableInput,
    isMaxRef,
    disableConfirm,
    onConfirm,
    onChange,
  } = withdraw;

  return (
    <Content>
      <ScrollView>
        <Col pb={12}>
          <TokenInput
            tokenInfo={reserve.asset}
            value={amount}
            onAmountChange={v => {
              onChange(v);
              if (!v.eq(max)) {
                isMaxRef.current = false;
              }
            }}
            maxAmount={max}
          >
            <CenterRow>
              <TokenInput.Input
                focused={curPage === 1}
                disabled={disableInput}
                style={{ width: '100%' }}
              />
            </CenterRow>
            <MaxValueRow
              asset={reserve.asset}
              onSetValue={(v, isMax) => {
                isMaxRef.current = isMax;
                onChange(v);
              }}
              max={max.toNumber()}
            />
            <TokenInput.Slider
              onPctChange={pct => {
                isMaxRef.current = pct === 100;
              }}
            />
          </TokenInput>

          <ExtraConfig reserveId={reserve.id} items={[]} />
          {footerComponents}
        </Col>
      </ScrollView>

      <ConfirmButton
        onClick={async () => {
          const success = await onConfirm();
          success && onClose();
        }}
        loading={loading}
        title={t`Withdraw`}
        disabled={disableConfirm}
      />
    </Content>
  );
};
