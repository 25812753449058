import { useProviderHub } from '%/../solana-defi/common';
import {
  ActionSheet,
  CenterCol,
  Row,
  Txt,
} from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { useTranslation } from '@aries/shared/locale';
import React from 'react';
import { ViewStyle } from 'react-native';

export const openNetworkSelect = () => {
  openModal({
    showHeader: false,
    renderContent: ({ close }) => <NetworkSelect close={close} />,
    showClose: false,
    heightPercent: 0.3,
  });
};

export const NetworkSelect: React.FC<{ close: () => void }> = ({
  close,
}) => {
  const { t } = useTranslation();
  const { list, changeNetwork, currentNetwork } = useProviderHub();

  return (
    <ActionSheet
      close={close}
      btns={list.map(({ name, desc }) => ({
        title: (
          <CenterCol gap={4}>
            <Row>
              <Txt h3 bold>
                {name}
              </Txt>
            </Row>
            <Txt c3 h2>
              {desc}
            </Txt>
          </CenterCol>
        ),
        type: name === currentNetwork.name ? 'primary' : 'ghost',
        onPress: () => {
          changeNetwork(name);
        },
      }))}
      cancelText={t`Cancel`}
      style={{ gap: 6 } as ViewStyle}
    />
  );
};
