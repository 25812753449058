import { isIphoneX as _isIphoneX } from 'react-native-iphone-x-helper';

let cache: boolean | null = null;

export const isIphoneX = () => {
  if (cache !== null) return cache;

  cache = _isIphoneX();

  return cache;
};
