// @ts-nocheck
/* eslint-disable  */
// Drawer.js
import { ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import Overlay from '../Overlay/Overlay';

export class Drawer extends Overlay {
  static DrawerView = Overlay.PullView;

  static open(
    view: ReactElement,
    side: 'left' | 'right' | 'bottom' | 'top' = 'left',
    options?: {
      containerStyle?: ViewStyle;
      rootTransform?: 'none';
      disableCloseOnPressOverlay?: boolean;
    },
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let drawer;
    const key = super.show(
      <this.DrawerView
        side={side}
        modal={options?.disableCloseOnPressOverlay}
        rootTransform="none"
        {...options}
        ref={v => {
          drawer = v;
        }}
      >
        {view}
      </this.DrawerView>,
    );
    return {
      key,
      close(animated = true) {
        Overlay.hide(key, animated);
      },
    };
  }
}
