import { getProviderHub } from '@aries/solana-defi/common';
import { ReserveInfo } from '@port.finance/port-sdk';
import * as BufferLayout from '@solana/buffer-layout';
import {
  SYSVAR_CLOCK_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { LendingInstructionKey, toAccessKeys } from './config';

export const refreshReserveIx = (reserveInfo: ReserveInfo) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    { instruction: LendingInstructionKey.RefreshReserve },
    data,
  );

  return new TransactionInstruction({
    keys: toAccessKeys([
      { key: reserveInfo.getReserveId(), type: 'write' },
      { key: SYSVAR_CLOCK_PUBKEY, type: 'read' },
      reserveInfo.getOracleId() && {
        key: reserveInfo.getOracleId()!,
        type: 'read',
      },
    ]),
    data,
    programId: getProviderHub()!.currentNetwork.programId,
  });
};
