import { globalToken } from '@aries/ui-theming';
import React from 'react';
import { TextStyle } from 'react-native';
import styled from 'styled-components/native';

const h1Size = 11;
const h2Size = 13;
const h3Size = 15;
const h4Size = 18;
const h5Size = 21;

const {
  colorText,
  colorTextSecondary,
  colorTextTertiary,
  colorTextQuaternary,
  colorTextCritical,
  colorTextSuccess,
  colorTextWarning,
} = globalToken;

type TxtBaseProps = {
  mg?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;
  pd?: number;
  pt?: number;
  pr?: number;
  pl?: number;
  pb?: number;
  lh?: number;
  flex?: number;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  c1?: boolean;
  c2?: boolean;
  c3?: boolean;
  c4?: boolean;
  success?: boolean;
  critical?: boolean;
  warning?: boolean;
  bold?: boolean;
  color?: string;
  size?: number;
  sizeRank?: 1 | 2 | 3 | 4;
  colorRank?: 1 | 2 | 3 | 4 | 'success' | 'critical' | 'warning';
};

export type TxtProps = {
  style?: TextStyle;
  onPress?: () => void;
} & TxtBaseProps;

export const Txt: React.FC<TxtProps> = ({
  color,
  style,
  bold,
  children,
  onPress,
  h1,
  h2,
  h3,
  h4,
  h5,
  c1,
  c2,
  c3,
  c4,
  success,
  critical,
  warning,

  size,

  mg,
  mt,
  mr,
  ml,
  mb,
  pd,
  pt,
  pr,
  pl,
  pb,

  flex,
  lh,
  sizeRank,
  colorRank,
}) => {
  const content = (
    <Container
      style={style}
      size={size}
      color={color}
      bold={bold}
      mr={mr}
      mt={mt}
      mg={mg}
      ml={ml}
      mb={mb}
      pd={pd}
      pt={pt}
      pr={pr}
      pl={pl}
      pb={pb}
      flex={flex}
      h1={h1}
      h2={h2}
      h3={h3}
      h4={h4}
      h5={h5}
      lh={lh}
      onPress={onPress}
      c1={c1}
      c2={c2}
      c3={c3}
      c4={c4}
      success={success}
      critical={critical}
      warning={warning}
      sizeRank={sizeRank}
      colorRank={colorRank}
    >
      {children}
    </Container>
  );

  return content;
};

const Container = styled.Text<TxtBaseProps>`
  font-size: ${p => {
    if (p.size) {
      return `${p.size}px`;
    }
    if (p.sizeRank) {
      switch (p.sizeRank) {
        case 1:
          return h1Size;
        case 2:
          return h2Size;
        case 4:
          return h4Size;
        default:
          return h3Size;
      }
    }
    if (p.h1) {
      return h1Size;
    }
    if (p.h2) {
      return h2Size;
    }
    if (p.h4) {
      return h4Size;
    }

    if (p.h5) {
      return h5Size;
    }
    return h3Size;
  }}px;
  color: ${p => {
    if (p.color) {
      return p.color;
    }
    if (p.colorRank) {
      switch (p.colorRank) {
        case 2:
          return colorTextSecondary;
        case 3:
          return colorTextTertiary;
        case 4:
          return colorTextQuaternary;
        case 'success':
          return colorTextSuccess;
        case 'critical':
          return colorTextCritical;
        case 'warning':
          return colorTextWarning;
        default:
          return colorText;
      }
    }
    if (p.c2) {
      return colorTextSecondary;
    }
    if (p.c3) {
      return colorTextTertiary;
    }
    if (p.c4) {
      return colorTextQuaternary;
    }
    if (p.success) {
      return colorTextSuccess;
    }
    if (p.warning) {
      return colorTextWarning;
    }
    if (p.critical) {
      return colorTextCritical;
    }

    return colorText;
  }};

  ${p => (p.lh ? `line-height:${p.lh}px` : '')};

  ${p => (p.mg ? `margin:${p.mg}px` : '')};

  ${p => (p.ml ? `margin-left:${p.ml}px` : '')};
  ${p => (p.mr ? `margin-right:${p.mr}px` : '')};
  ${p => (p.mt ? `margin-top:${p.mt}px` : '')};
  ${p => (p.mb ? `margin-bottom:${p.mb}px` : '')};

  ${p => (p.pd ? `padding:${p.pd}px` : '')};
  ${p => (p.pl ? `padding-left:${p.pl}px` : '')};
  ${p => (p.pr ? `padding-right:${p.pr}px` : '')};
  ${p => (p.pt ? `padding-top:${p.pt}px` : '')};
  ${p => (p.pb ? `padding-bottom:${p.pb}px` : '')};

  ${p => (p.flex ? `flex:${p.flex}` : '')};

  font-family: ${p =>
    p.bold ? `${globalToken.fontFamily}Bold` : globalToken.fontFamily};
`;
