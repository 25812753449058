import { NotifyType } from '@aries/lending-fe-hooks';
import {
  HEDGE_MARKET_ID,
  HUBBLE_MARKET_ID,
  PORT_MARKET_ID,
} from './lending/market';

export const ReserveNotifyMap: Record<
  string,
  Record<string, { type: NotifyType; content: string }>
> = {
  [PORT_MARKET_ID.toBase58()]: {
    '4HVSvzUfQ3aP5wEDkCQRqgYMhNatenVRKPdbXUv8VvBa': {
      type: 'warning',
      content: 'UST is being deprecated.',
    },
    DSST29PMCVkxo8cf5ht9LxrPoMc8jAZt98t6nuJywz8p: {
      type: 'warning',
      content: 'soBTC is being deprecated.',
    },
    BXt3EhK5Tj81aKaVSBD27rLFd5w8A6wmGKDh47JWohEu: {
      type: 'warning',
      content: 'USDT-USDC is being deprecated.',
    },
    DSw99gXoGzvc4N7cNGU7TJ9bCWFq96NU2Cczi1TabDx2: {
      type: 'warning',
      content: 'PAI is being deprecated.',
    },
    '7dXHPrJtwBjQqU1pLKfkHbq9TjQAK9jTms3rnj1i3G77': {
      type: 'warning',
      content: 'SBR is being deprecated.',
    },
    ZgS3sv1tJAor2rbGMFLeJwxsEGDiHkcrR2ZaNHZUpyF: {
      type: 'warning',
      content: 'SRM is being deprecated.',
    },
    BnhsmYVvNjXK3TGDHLj1Yr1jBGCmD1gZMkAyCwoXsHwt: {
      type: 'warning',
      content: 'MER is being deprecated.',
    },
    Bten75q82AMWmrRp77DgsphtSbUjHYhL7Mx5bx6SR4iA: {
      type: 'warning',
      content: 'FIDA is being deprecated.',
    },
    GRJyCEezbZQibAEfBKCRAg5YoTPP2UcRSTC7RfzoMypy: {
      type: 'warning',
      content: 'pSOL is being deprecated.',
    },
  },
  [HUBBLE_MARKET_ID.toBase58()]: {
    HemB8zhh5dgE1vp9J1EfpLZ9Th6Nk3Sb8cGuiCM1WuA4: {
      type: 'warning',
      content: 'USDH is being deprecated.',
    },
    DPBWrkHmzGSjyNzVHxhPkE5d7uDXVPMXcAKuLdHX9aZa: {
      type: 'warning',
      content: 'soBTC is being deprecated.',
    },
  },
  [HEDGE_MARKET_ID.toBase58()]: {
    '5y3JRuMTJ9kGhto9iE2KWgmCxe8kscxcWwZgqKQ2LqRd': {
      type: 'warning',
      content: 'soBTC is being deprecated.',
    },
  },
};

export const USDT_REFERRAL_FEES_ADDRESS =
  '36xNNnTzAon74tE6vKii2PuaxEaRbqWyuBKKkzHAYGJt';
export const USDC_REFERRAL_FEES_ADDRESS =
  'HcpYtrjqbQkvfuZSN3cAGSan5gZ2ProMmd5ewGzcJQpQ';

export const ENDPOINT_WHITE_LIST_DOMAINS = [
  'mainnet.port.finance',
  'solana-app-mobile-port-fi.vercel.app',
  'mainnet-mobile.port.finance',
  'solana-app-mobile-git-fix-omit-port-token-port-fi.vercel.app',
];
