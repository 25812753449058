import { u64 } from '@saberhq/token-utils';
import {
  AccountMeta,
  PublicKey,
  PublicKeyInitData,
} from '@solana/web3.js';
import Big from 'big.js';
import { compact } from 'lodash';

export enum LendingInstructionKey {
  InitLendingMarket = 0,
  SetLendingMarketOwner = 1,
  InitReserve = 2,
  RefreshReserve = 3,
  DepositReserveLiquidity = 4,
  RedeemReserveCollateral = 5,
  InitObligation = 6,
  RefreshObligation = 7,
  DepositObligationCollateral = 8,
  WithdrawObligationCollateral = 9,
  BorrowObligationLiquidity = 10,
  RepayObligationLiquidity = 11,
  LiquidateObligation = 12,
  FlashLoan = 13,
  DepositReserveLiquidityAndAddCollateral = 14,
  WithdrawAndRedeemCollateral = 15,
}

export enum StakingInstructionKey {
  InitStakingPool = 0,
  CreateStakeAccount = 1,
  Deposit = 2,
  Withdraw = 3,
  ClaimReward = 4,
  UpdateEarliestRewardClaimTime = 5,
}

export const toAccessKeys = (
  arr: (
    | { key: PublicKeyInitData; type: 'read' | 'write' | 'signer' }
    | null
    | undefined
  )[],
) => {
  return compact(arr).map(({ key, type }) => withAccess(key, type));
};

export const withAccess = (
  key: PublicKeyInitData,
  type: 'read' | 'write' | 'signer',
): AccountMeta => {
  const pubkey = new PublicKey(key);
  if (type === 'write') {
    return {
      pubkey,
      isSigner: false,
      isWritable: true,
    };
  }
  if (type === 'signer') {
    return { pubkey, isSigner: true, isWritable: false };
  }

  return { pubkey, isSigner: false, isWritable: false };
};

export const bigToU64 = (val: Big) => {
  // eslint-disable-next-line new-cap
  return new u64(val.toFixed(0, 0));
};
