import { ModeProps } from '@aries/ui-theming';
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components/native';
import { DropdownProvider } from './Smoothy/components/notification';
import TopView from './Smoothy/components/Overlay/TopView';

// Integrate some UI logic into app
export const UIProvider: React.FC<{ children: React.ReactChild }> = ({
  children,
}) => {
  const [themeMode, setThemeMode] = useState<ModeProps>('dark');

  return (
    <ThemeProvider
      theme={{
        mode: themeMode,
        changeTheme: (v: ModeProps) => {
          setThemeMode(v);
        },
      }}
    >
      <DropdownProvider>
        <TopView>{children}</TopView>
      </DropdownProvider>
    </ThemeProvider>
  );
};
