import { Dimensions, ViewStyle } from 'react-native';
// eslint-disable-next-line import/no-named-as-default
import Overlay from '../Overlay/Overlay';
import { Modal } from './Modal';

const { width, height } = Dimensions.get('window');

export const openOverlay = ({
  renderContent,
  heightPercent = 0.5,
  widthPercent = 0.68,
  title,
  containerStyle,
  scrollable,
  showCloseIcon = true,
}: {
  renderContent: ({ close }: { close: () => void }) => {};
  heightPercent?: number;
  widthPercent?: number;
  title?: string;
  containerStyle?: ViewStyle;
  scrollable?: boolean;
  showCloseIcon?: boolean;
}) => {
  // eslint-disable-next-line prefer-const
  let key: any;
  const overlayView = (
    <Overlay.PopView
      modal
      style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
    >
      <Modal
        containerStyle={{
          width: widthPercent > 2 ? widthPercent : widthPercent * width,
          height:
            heightPercent > 2 ? heightPercent : heightPercent * height,
          ...containerStyle,
        }}
        title={title}
        onClose={() => {
          Overlay.hide(key, true);
        }}
        scrollable={scrollable}
        showClose={showCloseIcon}
        content={renderContent({
          close: () => {
            Overlay.hide(key, true);
          },
        })}
      />
    </Overlay.PopView>
  );
  // eslint-disable-next-line prefer-const
  key = Overlay.show(overlayView);

  return { close: () => Overlay.hide(key) };
};
