import { createGlobalStore } from '@aries/shared/deps';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { useRef, useState } from 'react';

export const [useWallet] = createGlobalStore(() => {
  const [walletModalShow, setWalletModalShow] = useState(false);
  const [walletCtx, setWalletCtx] = useState<{
    adapter: WalletContextState;
    address: string;
  } | null>(null);

  const walletCtxRef = useRef(walletCtx);
  walletCtxRef.current = walletCtx;

  const disconnect = () => {
    if (!walletCtx) return;

    walletCtx.adapter.disconnect();

    setWalletCtx(null);
  };

  return {
    walletModalShow,
    setWalletModalShow,
    walletCtx,
    walletCtxRef,
    setWalletCtx: (ctx: typeof walletCtx) => {
      walletCtxRef.current = ctx;
      setWalletCtx(ctx);
    },
    disconnect,
  };
});
