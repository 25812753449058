import { globalToken } from '@aries/ui-theming';
import React, { ReactNode } from 'react';
import styled from 'styled-components/native';
import { Header } from './Header';

export const Card: React.FC<{
  titleTags?: { label: string; color?: string; bckColor?: string }[];
  titleIcon: ReactNode;
  title: ReactNode;
  subTitle?: string;
  onPress?: () => void;
}> = ({ titleTags, titleIcon, children, title, subTitle, onPress }) => {
  return (
    <Container onPress={onPress} disabled={!onPress}>
      <Header
        icon={titleIcon}
        tags={titleTags}
        title={title}
        subTitle={subTitle}
      />
      {children}
    </Container>
  );
};

const {
  paddingXS,
  marginSM,
  borderRadiusLG,
  colorBgContainer,
  colorBorder,
} = globalToken;
const Container = styled.TouchableOpacity`
  padding: ${paddingXS}px;
  margin-bottom: ${marginSM}px;
  border-radius: ${borderRadiusLG}px;
  background-color: ${colorBgContainer};
  border-width: 1px;
  border-style: solid;
  border-color: ${colorBorder};
`;
