import { setTheme } from '@aries/ui-theming';

const colorBgToast = {
  dark: '',
  light: '',
};
const colorToastIconTint = {
  dark: '',
  light: '',
};
const colorTextToast = {
  dark: '',
  light: '',
};
export const toastToken = {
  paddingLeftToast: 12,
  paddingRightToast: 12,
  paddingTopToast: 8,
  paddingBottomToast: 8,
  borderRadiusToast: 7,
  widthToastIcon: 40,
  heightToastIcon: 40,
  paddingTopToastIcon: 8,
  paddingBottomToastIcon: 8,
  fontSizeToast: 16,
  paddingLeftToastScreen: 40,
  paddingRightToastScreen: 40,
  paddingTopToastScreen: 60,
  paddingBottomToastScreen: 80,
  heightToast: 100,
  widthToast: 100,
  colorBgToast: setTheme(colorBgToast),
  colorToastIconTint: setTheme(colorToastIconTint),
  colorTextToast: setTheme(colorTextToast),
};

export const toastConfig = {
  colorBgToast,
  colorToastIconTint,
  colorTextToast,
};
