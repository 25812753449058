import Big from 'big.js';
import { isArray, isPlainObject, mapValues } from 'lodash';

export const logAptosObject = (obj: any) => {
  const parsed = parseAptosObject(obj);
  // eslint-disable-next-line no-console
  console.log(parsed);
};

export const parseAptosObject = (obj: any): any => {
  return mapValues(obj, val => {
    if (isArray(val)) {
      return val.map(v => parseAptosObject(v));
    }
    if (isPlainObject(val)) {
      return parseAptosObject(val);
    }
    if (val instanceof Big) {
      return val.toNumber();
    }

    return val;
  });
};
