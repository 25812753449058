import Big, { BigSource } from 'big.js';
import { useMemo, useState } from 'react';

interface CurrentAmount {
  type: 'coin' | 'usd';
  amount: Big;
  isEmpty?: boolean;
}
/**
 * reusable token input manage hooks, zero dependence
 * @param walletAsset
 * @param tokenPrice
 */
export const useTokenAmountInput = (
  maxAmount: Big,
  tokenPrice: Big,
  tokenAmount?: Big,
  onTokenAmountChange?: (amount: Big) => void,
) => {
  const [mode, setMode] = useState<'coin' | 'usd'>('coin');
  const [localAmount, setLocalAmount] = useState<Big>(Big(0));
  const current: CurrentAmount = {
    type: mode,
    amount: tokenAmount
      ? mode === 'coin'
        ? tokenAmount
        : tokenAmount.mul(tokenPrice)
      : localAmount,
    isEmpty: (tokenAmount || localAmount).eq(0),
  };

  const setCurrent = ({ type, amount }: CurrentAmount) => {
    setMode(type);
    if (tokenAmount) {
      const newTokenAmount =
        type === 'coin'
          ? amount
          : tokenPrice.eq(0)
          ? Big(0)
          : amount.div(tokenPrice);
      onTokenAmountChange?.(newTokenAmount);
    } else {
      setLocalAmount(amount);
    }
  };

  const valueByCoin = useMemo(
    () =>
      current.type === 'coin'
        ? current.amount
        : tokenPrice.eq(0)
        ? Big(0)
        : current.amount.div(tokenPrice),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current.type, current.amount],
  );
  const valueByUSD = useMemo(
    () =>
      current.type === 'usd'
        ? current.amount
        : current.amount.mul(tokenPrice),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current.type, current.amount],
  );
  const maxAmountUSD = useMemo(
    () => maxAmount.mul(tokenPrice),
    [maxAmount, tokenPrice],
  );

  // 0-100
  const pct = Math.round(
    100 *
      (maxAmount.lte(0) || valueByCoin.lte(0)
        ? 0
        : valueByCoin.div(maxAmount).toNumber()),
  );

  /**
   * @param newPct 0-100 number
   */
  const setPct = (newPct: number) => {
    if (current.type === 'coin') {
      setCurrent({
        type: 'coin',
        amount: maxAmount.mul(newPct / 100),
      });
    } else {
      setCurrent({
        type: 'usd',
        amount: maxAmountUSD.mul(newPct / 100),
      });
    }
  };

  const setMax = () => {
    setPct(100);
  };

  const setValueByUSD = (amount: BigSource) => {
    setCurrent({
      type: 'usd',
      amount: new Big(amount),
    });
  };

  const setValueByCoin = (amount: BigSource) => {
    setCurrent({
      type: 'coin',
      amount: new Big(amount),
    });
  };

  const changeMode = (newMode: typeof current.type) => {
    if (newMode === 'coin') {
      setCurrent({ type: 'coin', amount: valueByCoin });
    } else {
      setCurrent({ type: 'usd', amount: valueByUSD });
    }
  };

  const clear = () => {
    setCurrent({ type: current.type, amount: new Big(0) });
  };

  return useMemo(
    () => ({
      mode: current.type,
      valueByCoin,
      valueByUSD,
      pct,
      setMax,
      setPct,
      setValueByCoin,
      setValueByUSD,
      changeMode,
      clear,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current, maxAmount, tokenPrice],
  );
};
