import {
  ColorConfig,
  GlobalToken,
  setGlobalTheme,
} from '@aries/ui-theming';
import { brandColorConfig, brandColorTokens } from './brand-color';
import {
  brandColorMapConfig,
  brandColorMapTokens,
} from './brand-colormap';
import {
  componentColorConfigMap,
  componentTokenMap,
} from './cmp-token-map';
import { commonToken } from './common-token';
import {
  functionalColorConfig,
  functionalColorToken,
} from './functional-color';
import { neutralColorConfig, neutralColorTokens } from './neutral-color';

const ariesTokens: GlobalToken = {
  ...neutralColorTokens,
  ...brandColorTokens,
  ...brandColorMapTokens,
  ...commonToken,
  ...componentTokenMap,
  ...functionalColorToken,
};
const ariesColors: ColorConfig = {
  ...neutralColorConfig,
  ...brandColorConfig,
  ...brandColorMapConfig,
  ...functionalColorConfig,
  ...componentColorConfigMap,
};

setGlobalTheme(ariesTokens, ariesColors);
