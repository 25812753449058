import { delay } from '@aries/shared/utils/index';
import { useProviderHub } from '@aries/solana-defi/common';
import { GetProgramAccountsFilter, PublicKey } from '@solana/web3.js';
import { useEffect, useState } from 'react';

export function useProgramSubscription<T>(
  programId: string,
  filters: GetProgramAccountsFilter[],
  parse: (raw: any) => T,
  options?: {
    disableFetch?: boolean;
    deps?: any[];
    delayLoad?: number;
  },
) {
  const { provider } = useProviderHub();

  const [results, setResults] = useState<{ pubkey: string; value: T }[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const load = async (filters: GetProgramAccountsFilter[]) => {
    if (options?.delayLoad) {
      await delay(options.delayLoad);
    }

    setIsLoading(true);
    const programIdPubkey = new PublicKey(programId);
    const { connection } = provider;

    const rawAccounts = await connection.getProgramAccounts(
      programIdPubkey,
      { filters },
    );

    const values = rawAccounts.map(raw => {
      const parsed = parse(raw);
      return {
        pubkey: raw.pubkey.toBase58(),
        value: parsed,
      };
    });

    setIsLoading(false);
    setResults(values);

    const sub = connection.onProgramAccountChange(
      programIdPubkey,
      info => {
        const pubkey = info.accountId;
        const raw = {
          pubkey,
          account: info.accountInfo,
        };
        const parsed = parse(raw);

        setResults(values => {
          const index = values.findIndex(
            v => v.pubkey === pubkey.toBase58(),
          );

          if (index > -1) {
            const newValues = [...values];
            newValues[index] = {
              pubkey: pubkey.toBase58(),
              value: parsed,
            };
            return newValues;
          }

          return values;
        });
      },
      'singleGossip',
      filters,
    );

    return {
      clear: () => {
        connection.removeProgramAccountChangeListener(sub);
      },
    };
  };

  const reload = () => {
    load(filters);
  };

  useEffect(() => {
    !options?.disableFetch && load(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.disableFetch, provider, ...(options?.deps ?? [])]);

  return { isLoading, results, reload };
}
