import { createIcon } from '@aries/mobile-core/components';

export const IconThirdClose = createIcon(
  require('%/assets/thirdColor/close.png'),
);

export const IconThirdInfo = createIcon(
  require('%/assets/thirdColor/info.png'),
);

export const IconwarningInfo = createIcon(
  require('%/assets/warning-info.png'),
);
