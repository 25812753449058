import { BetweenRow, Txt } from '@aries/mobile-core/components';
import { isString } from 'lodash';
import React, { ReactNode } from 'react';

export const InfoItem: React.FC<{
  title: string;
  subTitle: string | ReactNode;
}> = ({ title, subTitle }) => {
  return (
    <BetweenRow mt={5} mb={5}>
      <Txt mr={10} c2>
        {title}
      </Txt>

      {isString(subTitle) ? (
        <Txt style={{ textAlign: 'right' }} bold lh={15} flex={1}>
          {subTitle}
        </Txt>
      ) : (
        subTitle
      )}
    </BetweenRow>
  );
};
