/* eslint-disable  */
// ActionPopoverView.js

'use strict';
import { colorConfig, globalToken } from '@aries/ui-theming';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components/native';
import Overlay from '../Overlay/Overlay';
import ActionPopoverItem from './ActionPopoverItem';

export default class ActionPopoverView extends Overlay.PopoverView {
  static propTypes = {
    ...Overlay.PopoverView.propTypes,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        onPress: PropTypes.func,
      }),
    ).isRequired,
  };

  static defaultProps = {
    ...Overlay.PopoverView.defaultProps,
    direction: 'up',
    align: 'center',
    showArrow: true,
  };

  static Item = ActionPopoverItem;

  onItemPress(item) {
    this.close(false);
    item.onPress && item.onPress();
  }

  buildPopoverStyle() {
    const {
      paddingVerticalPAcpop,
      paddingHorizontalAcpop,
      directionInsetsAcpop,
      paddingVerticalAcPopItem,
      paddingHorizontalAcPopItem,
    } = globalToken.ActionPopover;

    const { borderRadiusLG } = globalToken;

    const { colorBgAcPop, colorTextAcPopItemTitle, colorSeparatorAcPop } =
      colorConfig.ActionPopover;

    const { mode } = this.context;

    this.defaultDirectionInsets = directionInsetsAcpop;
    let { popoverStyle, arrow } = super.buildPopoverStyle();
    popoverStyle = [
      {
        backgroundColor: colorBgAcPop[mode],
        paddingVertical: paddingVerticalPAcpop,
        paddingHorizontal: paddingHorizontalAcpop,
        borderRadius: borderRadiusLG,
        flexDirection: 'row',
      },
    ].concat(popoverStyle);
    return { popoverStyle, arrow };
  }

  renderContent() {
    const { items } = this.props;
    const list = [];
    for (let i = 0; items && i < items.length; ++i) {
      const item = items[i];
      list.push(
        <this.constructor.Item
          key={'item' + i}
          title={item.title}
          leftSeparator={i !== 0}
          onPress={() => this.onItemPress(item)}
        />,
      );
    }
    return super.renderContent(list);
  }
}

ActionPopoverView.contextType = ThemeProvider;
