import { createIcon } from '@aries/mobile-core/components';

export const IconHeaderSettings = createIcon(require('./settings.png'));
export const IconEarth = createIcon(require('./earth.svg'));
export const IconMenu = createIcon(require('./menu.png'));
export const IconLink = createIcon(require('./link.png'));
export const IconNetwork = createIcon(require('./network.png'));
export const IconQeustion = createIcon(require('./question.png'));
export const IconDiscord = createIcon(require('./discord.png'));
export const IconTelegram = createIcon(require('./telegram.png'));
export const IconTwtter = createIcon(require('./twitter.png'));
export const IconOtterSec = createIcon(require('./otterSec.png'));
