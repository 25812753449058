import {
  getBalanceHub,
  getProviderHub,
  hasEnoughGasOrThrow,
} from '@aries/solana-defi/common';
import { getProfileHub } from '@aries/solana-defi/lending/data/profile';
import { withSendTxNotify } from '@aries/solana-defi/utils';
import { Transaction } from '@solana/web3.js';
import Big from 'big.js';
import { borrowIx } from './instructions/borrow';
import { getReserveCtx } from './utils';

export const borrowAsset = withSendTxNotify(
  async (reserveKey: string, lamports: Big) => {
    hasEnoughGasOrThrow();

    const { provider } = getProviderHub()!;

    const {
      assetMintId,
      reserveSDK,
      refreshProfileIxs,
      walletPubkey,
      marketPubkey,
      profilePubkey,
      marketAuthority,
      reserveId,
      refreshReservesIxs,
    } = await getReserveCtx(reserveKey, { isBorrow: true });

    const tx = new Transaction();

    const destAssetSplAccount = await getBalanceHub()!.tryGetSplAccount(
      assetMintId,
    );

    tx.add(
      ...destAssetSplAccount.preIxns,
      ...refreshReservesIxs,
      ...refreshProfileIxs,
      borrowIx({
        amount: lamports,
        destAssetSplAccount: destAssetSplAccount.address,
        reserveId,
        marketAuthorityId: marketAuthority,
        reserveAssetSplAccount: reserveSDK.asset.getSplAccountId(),
        marketId: marketPubkey,
        reserveFeeSplAccount: reserveSDK.getFeeBalanceId(),
        profileId: profilePubkey,
        walletId: walletPubkey,
      }),
    );

    const res = await provider.signAndSendTx(tx, []);

    getProfileHub()?.refresh();
    getBalanceHub()?.refresh();

    return res;
  },
);
