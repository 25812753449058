import { createIcon } from '@aries/mobile-core/components';

export const IconSecondSearch = createIcon(
  require('%/assets/secondColor/search.png'),
);

export const IconSecondDown = createIcon(
  require('%/assets/secondColor/down.png'),
  18 / 32,
);

export const IconSecondSwap = createIcon(
  require('%/assets/secondColor/swap.png'),
);

export const IconSecondMenu = createIcon(
  require('%/assets/secondColor/menu.png'),
);
