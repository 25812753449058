import { EndCol, Txt } from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import { isString } from 'lodash';
import React, { FC, ReactNode } from 'react';
import { Image } from 'react-native';
import styled from 'styled-components/native';

export type Props = {
  image: string | ReactNode;
  name: string;
  subTitle: string;
  count: string;
  recentAmount: string;
  id: string;
};

export const Item: FC<{ data: Props; onPress: () => void }> = ({
  onPress,
  data: { image, name, subTitle, count, recentAmount },
}) => {
  return (
    <Container onPress={onPress}>
      {isString(image) ? <Image source={{ uri: image }} /> : image}
      <Content>
        <Txt h4 bold mb={4}>
          {name}
        </Txt>
        <Txt c3>{subTitle}</Txt>
      </Content>
      <EndCol>
        <Txt h4 bold mb={4}>
          {count}
        </Txt>
        <Txt success>{recentAmount}</Txt>
      </EndCol>
    </Container>
  );
};

const { marginSM, margin, colorBgSpotlight, borderRadiusLG } = globalToken;
const Container = styled.TouchableOpacity`
  padding: ${margin};
  background-color: ${colorBgSpotlight};
  align-items: center;
  border-radius: ${borderRadiusLG};
  flex-direction: row;
  margin-bottom: ${marginSM};
`;

const Content = styled.View`
  padding: 0px ${margin};
  flex: 1;
`;
