import { Button, Col, Row } from '@aries/mobile-core/components';
import { noop } from 'lodash';
import { getDefaultDataKey, SelectableProps } from '../common';
import { useSelectableKey } from '../common/hooks';

export type SegmentControlProps<T extends any[]> = SelectableProps<T>;

export function SegmentControl<T extends any[]>(
  props: SegmentControlProps<T>,
) {
  const {
    options,
    renderOption,
    keyExtractor = getDefaultDataKey,
  } = props;
  const [selectedKey, updateKey] = useSelectableKey(props);

  return (
    <Row flex={1}>
      {options.map(option => {
        const key = keyExtractor(option);
        return (
          <Col flex={1} key={key} mr={4}>
            <Button
              type="ghost"
              onPress={() => {
                updateKey(option, key);
              }}
              active={selectedKey === key}
              title={renderOption(option, {
                isOptionInList: true,
                closeSelect: noop,
              })}
              style={{ height: 32 }}
            />
          </Col>
        );
      })}
    </Row>
  );
}
