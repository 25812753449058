import {
  NeutralColorConfig,
  NeutralColorToken,
  setTheme,
} from '@aries/ui-theming';

const colorTextBase = {
  dark: '#e6e6ef',
  light: '#18192f',
};
const colorText = {
  dark: '#ffffff',
  light: '#000000',
};
const colorTextSecondary = {
  dark: '#B0B0B0',
  light: '#adb8db',
};
const colorTextTertiary = {
  dark: '#B0B0B0',
  light: '',
};
const colorTextQuaternary = {
  dark: '#737B94',
  light: '#000000',
};
const colorBgDefault = {
  dark: '#fff',
  light: '#000',
};
const colorBgBase = {
  dark: '#0B0B0C',
  light: '#f8f9fc',
};
const colorBg = {
  dark: '#19181D',
  light: '#e4e4eb',
};
const colorBgSecondary = {
  dark: '#19181D',
  light: '#d3d3db',
};
const colorBgTertiary = {
  dark: '#19181D',
  light: '',
};
const colorBgQuaternary = {
  dark: '#19181D',
  light: '#fff',
};
const colorBgContainer = {
  dark: '#19181D',
  light: '#e4e4eb',
};
const colorBgSpotlight = {
  dark: '#19181D',
  light: '#d3d3db',
};
const colorBgElevated = {
  dark: '#19181D',
  light: '',
};
const colorBgElevatedContent = {
  dark: '#19181D',
  light: '#14151c',
};
const colorBgMask = {
  dark: '#19181D',
  light: '#fff',
};
const colorBgOverlay = {
  dark: '#00000000',
  light: 'rgba(36, 43, 63, 0.8)',
};
const colorBgDisabled = {
  light: '#272744',
  dark: '#272744',
};
const colorBorder = {
  dark: '#2A2B30',
  light: '#141929',
};
const colorBorderTertiary = {
  dark: '#0a0a19',
  light: 'rgba(96, 113, 168, 0.35)',
};
const colorBorderAdditional = {
  dark: '#0a0a19',
  light: 'rgba(96, 113, 168, 0.8)',
};
const colorBorderDisabled = {
  dark: '#0a0a19',
  light: 'rgba(96, 113, 168, 0.35)',
};
const colorBorderSecondary = {
  dark: '',
  light: '',
};

export const neutralColorConfig: NeutralColorConfig = {
  colorTextBase,
  colorText,
  colorTextSecondary,
  colorTextTertiary,
  colorTextQuaternary,
  colorBgDefault,
  colorBgBase,
  colorBgContainer,
  colorBgSpotlight,
  colorBgElevated,
  colorBgElevatedContent,
  colorBg,
  colorBgSecondary,
  colorBgTertiary,
  colorBgQuaternary,
  colorBgMask,
  colorBgDisabled,
  colorBorder,
  colorBorderDisabled,
  colorBorderSecondary,
  colorBgOverlay,
  colorBorderTertiary,
  colorBorderAdditional,
};

export const neutralColorTokens: NeutralColorToken = {
  colorTextBase: setTheme(colorTextBase),
  colorText: setTheme(colorText),
  colorTextSecondary: setTheme(colorTextSecondary),
  colorTextTertiary: setTheme(colorTextTertiary),
  colorTextQuaternary: setTheme(colorTextQuaternary),
  colorBgDefault: setTheme(colorBgDefault),
  colorBgBase: setTheme(colorBgBase),
  colorBg: setTheme(colorBg),
  colorBgSecondary: setTheme(colorBgSecondary),
  colorBgTertiary: setTheme(colorBgTertiary),
  colorBgQuaternary: setTheme(colorBgQuaternary),
  colorBgContainer: setTheme(colorBgContainer),
  colorBgSpotlight: setTheme(colorBgSpotlight),
  colorBgElevated: setTheme(colorBgElevated),
  colorBgElevatedContent: setTheme(colorBgElevatedContent),
  colorBgMask: setTheme(colorBgMask),
  colorBgDisabled: setTheme(colorBgDisabled),
  colorBorder: setTheme(colorBorder),
  colorBorderDisabled: setTheme(colorBorderDisabled),
  colorBorderSecondary: setTheme(colorBorderSecondary),
  colorBgOverlay: setTheme(colorBgOverlay),
  colorBorderTertiary: setTheme(colorBorderTertiary),
  colorBorderAdditional: setTheme(colorBorderAdditional),
};
