import { isNumber } from 'lodash';

export const DefaultValue: {
  color: string[];
  start: { x: number; y: number };
  end: { x: number; y: number };
} = {
  color: ['#FFF', '#FFF'],
  start: { x: 0, y: 0.5 },
  end: { x: 1, y: 0.5 },
};

/**
 * @description
 * @param colors 'red,orange/0,1:1,1' or "#fff"
 * @param defaultValue theDefaultTypeCanBeExportedSettings
 */

export const parseColor = (
  colors: string | undefined,
  defaultValue?: {
    color?: string[];
    start?: { x: number; y: number };
    end?: { x: number; y: number };
  },
): {
  color: string[];
  start: { x: number; y: number };
  end: { x: number; y: number };
} => {
  try {
    if (colors?.includes('/')) {
      const [color, config] = colors.split('/');

      const linearColor = color
        ? color?.includes(',')
          ? color.split(',')
          : [color, color]
        : defaultValue?.color ?? DefaultValue.color;

      const [start, end] = config.split(':');

      return {
        color: linearColor,
        start:
          changeNumber(start) ?? defaultValue?.start ?? DefaultValue.start,
        end: changeNumber(end) ?? defaultValue?.end ?? DefaultValue.end,
      };
    }

    const linearColor = colors
      ? colors?.includes(',')
        ? colors.split(',')
        : [colors, colors]
      : defaultValue?.color ?? DefaultValue.color;

    return {
      color: linearColor as [string, string],
      start: defaultValue?.start ?? DefaultValue.start,
      end: defaultValue?.end ?? DefaultValue.end,
    };
  } catch (error) {
    return {
      color: defaultValue?.color ?? DefaultValue.color,
      start: defaultValue?.start ?? DefaultValue.start,
      end: defaultValue?.end ?? DefaultValue.end,
    };
  }
};

const changeNumber = (v: string) => {
  const value = v.split(',').map(o => {
    return Number(o);
  });

  return isNumber(value[0]) && isNumber(value[1])
    ? { x: value[0], y: value[1] }
    : null;
};
