import { getMax, getMin } from '@aries/defi-toolkit/utils';
import { createGlobalStore } from '@aries/shared/deps';
import { shortenAddress } from '@aries/shared/utils/index';
import {
  MAX_GAS_PER_TX,
  useProviderHub,
  useTokenInfoHub,
} from '@aries/solana-defi/common';
import { computeTotal } from '@aries/solana-defi/utils';
import { useWallet } from '@aries/solana-defi/wallet';
import {
  PortProfile,
  PORT_PROFILE_DATA_SIZE,
} from '@port.finance/port-sdk';
import Big from 'big.js';
import { compact, keyBy, sortBy, sumBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLendingMarkets } from '../market';
import { useReserves } from './reserves';
import { useProgramSubscription } from './util';

export const [useProfileHub, getProfileHub] = createGlobalStore(() => {
  const { reserveMap } = useReserves();
  const { tokenMap } = useTokenInfoHub();

  const {
    profiles: profileList,
    loading: isValidating,
    reload,
  } = useProfileList();

  const [currentProfileKey, setCurrentProfileKey] = useState<string>();

  const refreshCurrentProfile = () => {};

  const changeProfile = (id: string) => {
    const profile = profileList?.find(p => p.pubkey === id);
    if (!profile) return;

    setCurrentProfileKey(id);
  };

  const detailedProfileList = useMemo(
    () =>
      (profileList ?? []).map(({ pubkey, value }) => {
        const id = pubkey;
        const profileName = pubkey;
        const deposits = value.getCollaterals();
        const loans = value.getLoans();

        const depositList = sortBy(
          compact(
            deposits.map(collateral => {
              const collateralShare = collateral.getAmount().getRaw();
              const reserveId = collateral.getReserveId().toBase58();

              const reserve = reserveMap[reserveId];
              const coinAddress = reserve?.coinAddress ?? '';
              const tokenInfo = tokenMap[coinAddress];

              if (!reserve || !tokenInfo?.id) return null;

              const collateralAsset =
                reserve.shareToDeposit(collateralShare);

              const depositedValueUSD =
                tokenInfo.toUSDValue(collateralAsset);

              return {
                id: reserveId,
                asset: tokenInfo,
                lamports: collateralAsset,
                depositedValueUSD,
                amount: tokenInfo.toAmountStr(collateralAsset),
                valueUSDStr: tokenInfo.toUSDValueStr(collateralAsset),
                amountNum: tokenInfo.toAmount(collateralAsset).toNumber(),
                marginValueUSD: reserve.depositToMargin(depositedValueUSD),
                collateralThreshold:
                  reserve.depositToCollateralThreshold(depositedValueUSD),
              };
            }),
          ),
          v => -v.depositedValueUSD,
        );

        const loanList = sortBy(
          compact(
            loans.map(loan => {
              const borrowedShare = loan.getAmount().getRaw();
              const reserveId = loan.getReserveId().toBase58();

              const reserve = reserveMap[reserveId];
              const coinAddress = reserve?.coinAddress ?? '';
              const tokenInfo = tokenMap[coinAddress];

              if (!reserve || !tokenInfo?.id) return null;

              const shareAsset = reserve.shareToBorrow(borrowedShare);

              return {
                id: reserveId,
                asset: tokenInfo,
                lamports: shareAsset,
                amount: tokenInfo.toAmountStr(shareAsset),
                valueUSD: tokenInfo.toUSDValue(shareAsset),
                valueUSDStr: tokenInfo.toUSDValueStr(shareAsset),
              };
            }),
          ),
          v => -v.valueUSD,
        );

        const totalDepositedUSD = computeTotal(
          depositList,
          deposit => deposit.depositedValueUSD,
        );

        const totalLoanUSD = computeTotal(loanList, loan => loan.valueUSD);

        const totalMarginUSD = computeTotal(
          depositList,
          deposit => deposit.marginValueUSD,
        );

        const totalCollateralThreshold = computeTotal(
          depositList,
          deposit => deposit.collateralThreshold,
        );

        // Borrow power and risk
        const usedBorrowPowerPct = totalMarginUSD.eq(0)
          ? 100
          : totalLoanUSD.div(totalMarginUSD).mul(100).toNumber();

        const riskFactorPct = totalCollateralThreshold.eq(0)
          ? 0
          : totalLoanUSD.div(totalCollateralThreshold).toNumber();

        // APY
        const totalEarnPerYear = depositList.reduce((sum, cur) => {
          const reserve = reserveMap[cur.asset.id];
          if (!reserve) return sum;

          const apy = reserve.supplyApyPct.div(100).toNumber();
          return sum + cur.depositedValueUSD * apy;
        }, 0);

        const totalLoanInterest = loanList.reduce((sum, cur) => {
          const reserve = reserveMap[cur.asset.id];
          if (!reserve) return sum;

          const apy = reserve?.borrowApyPct.div(100).toNumber();
          return sum + cur.valueUSD * apy;
        }, 0);

        const netAsset = totalDepositedUSD.minus(totalLoanUSD).toNumber();

        const totalApyPct =
          (netAsset === 0
            ? 0
            : (totalEarnPerYear - totalLoanInterest) / netAsset) * 100;

        const getWithdrawableAmount = (reserveId: string) => {
          const deposit = depositList.find(d => d.id === reserveId);

          const reserve = reserveMap[reserveId];
          const tokenInfo = tokenMap[reserve?.coinAddress ?? ''];

          if (!deposit || !reserve || !tokenInfo?.id) return Big(0);

          const { maxWithdrableLamports, loanToValueRatio } = reserve;

          const minCollateral =
            loanToValueRatio === 0
              ? 0
              : totalLoanUSD
                  .sub(
                    sumBy(
                      depositList.filter(d => d.id !== reserveId),
                      d => d.marginValueUSD,
                    ),
                  )
                  .div(loanToValueRatio)
                  .toNumber();

          const withdrawableAssetUnsafe =
            minCollateral <= 0
              ? deposit.amountNum
              : Math.max(
                  0,
                  (deposit.amountNum -
                    minCollateral / tokenInfo.priceUSDNum) *
                    0.99999, // Avoid rounding problem
                );

          const withdrawableAmountUnsafe = tokenInfo.toLamports(
            withdrawableAssetUnsafe,
          );

          const withdrawable = getMin(
            withdrawableAmountUnsafe,
            maxWithdrableLamports,
          );

          return withdrawable;
        };

        const getBorrowableAmount = (reserveId: string) => {
          const reserve = reserveMap[reserveId];
          const tokenInfo = tokenMap[reserve?.coinAddress ?? ''];

          if (!reserve || !tokenInfo?.id) return Big(0);

          const { borrowFeePct, maxBorrowableLamports } = reserve;

          const availableMarginUSD = (depositList ?? [])
            .reduce((sum, cur) => sum.add(cur.marginValueUSD), Big(0))
            .sub(totalLoanUSD ?? Big(0))
            // Incase of round problem
            .sub(10 ** (5 - tokenInfo.decimals));

          const borrowFeeFactor = borrowFeePct.div(100).add(1);
          const priceAfterFee = tokenInfo.price.mul(borrowFeeFactor);

          const borrowableCoins = priceAfterFee.eq(0)
            ? Big(0)
            : availableMarginUSD.div(priceAfterFee) ?? Big(0);

          const willCost = borrowableCoins.mul(borrowFeeFactor);
          const cashAmount = tokenInfo.toAmount(maxBorrowableLamports);

          return getMax(
            Big(0),
            willCost.gte(cashAmount) && cashAmount.gt(0)
              ? cashAmount.div(borrowFeeFactor)
              : borrowableCoins,
          )
            .mul(0.97)
            .round(tokenInfo.decimals, Big.roundDown);
        };

        const getDepositableAmount = (
          reserveId: string,
          walletLamports: Big,
        ) => {
          const reserve = reserveMap[reserveId];
          const tokenInfo = tokenMap[reserve?.coinAddress ?? ''];

          if (!reserve || !tokenInfo?.id) return Big(0);

          const depositable = walletLamports.minus(
            tokenInfo.symbol === 'SUI' ? MAX_GAS_PER_TX : 0,
          );

          const { maxDepositableLamports } = reserve;

          return depositable.lt(maxDepositableLamports) &&
            !maxDepositableLamports.eq(0)
            ? tokenInfo.toAmount(reserve.maxDepositableLamports)
            : tokenInfo.toAmount(depositable);
        };

        const depositByCoin = keyBy(depositList, d => d.id);
        const loanByCoin = keyBy(loanList, d => d.id);

        const getDepositedLamports = (reserveId: string) => {
          return depositByCoin[reserveId]?.lamports ?? Big(0);
        };

        const getBorrowedLamports = (reserveId: string) => {
          return loanByCoin[reserveId]?.lamports ?? Big(0);
        };

        return {
          id,
          name: shortenAddress(profileName),
          depositList,
          loanList,
          totalLoanUSD,
          totalDepositedUSD,
          totalMarginUSD,
          availableMarginUSD: totalMarginUSD.sub(totalLoanUSD),
          networth: totalDepositedUSD.sub(totalLoanUSD),
          usedBorrowPowerPct: Number(usedBorrowPowerPct.toFixed(2)),
          borrowPower:
            usedBorrowPowerPct > 100
              ? 0
              : Number((100 - usedBorrowPowerPct).toFixed(2)),
          riskFactorPct: Number(
            ((riskFactorPct > 1 ? 1 : riskFactorPct) * 100).toFixed(2),
          ),
          totalApyPct,
          raw: value,
          getWithdrawableAmount,
          getBorrowableAmount,
          getDepositableAmount,
          getDepositedLamports,
          getBorrowedLamports,
        };
      }),
    [profileList, reserveMap, tokenMap],
  );

  const currentProfile = useMemo(() => {
    return (
      detailedProfileList?.find(i => i.id === currentProfileKey) ?? null
    );
  }, [currentProfileKey, detailedProfileList]);

  useEffect(() => {
    if (
      detailedProfileList &&
      detailedProfileList.length > 0 &&
      !detailedProfileList.find(p => p.id === currentProfileKey)
    ) {
      setCurrentProfileKey(detailedProfileList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileList, currentProfileKey]);

  return {
    hasInitialized: (profileList?.length ?? 0) > 0,
    profileList: detailedProfileList,
    profileNames: profileList?.map(profile => profile.pubkey) ?? [],
    profileLoading: isValidating,
    currentProfileKey,
    currentProfile,
    changeProfile,
    refreshCurrentProfile,
    refresh: reload,
  };
});

const useProfileList = () => {
  const { currentNetwork } = useProviderHub();
  const { walletAddress } = useWallet();
  const { activeMarketId } = useLendingMarkets();

  const {
    results: profiles,
    isLoading: loading,
    reload,
  } = useProgramSubscription(
    currentNetwork.programId.toBase58(),
    [
      {
        dataSize: PORT_PROFILE_DATA_SIZE,
      },
      {
        memcmp: {
          offset: 1 + 8 + 1 + 32,
          bytes: walletAddress!,
        },
      },
      {
        memcmp: {
          offset: 1 + 8 + 1,
          bytes: activeMarketId,
        },
      },
    ],
    PortProfile.fromRaw,
    { disableFetch: !walletAddress, deps: [activeMarketId] },
  );

  return {
    profiles,
    loading,
    reload,
  };
};

export const getCurrentProfile = () => {
  const profile = getProfileHub()?.currentProfile;
  if (!profile) {
    throw new Error('Get profile failed, please create your profile.');
  }

  return profile;
};
