import { getProviderHub } from '@aries/solana-defi/common';
import { uint64 } from '@port.finance/port-sdk';
import * as BufferLayout from '@solana/buffer-layout';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import Big from 'big.js';
import { compact } from 'lodash';
import { bigToU64, LendingInstructionKey, toAccessKeys } from './config';

/// Borrow liquidity from a reserve by depositing collateral tokens. Requires a refreshed
/// obligation and reserve.
///
/// Accounts expected by this instruction:
///
///   0. `[writable]` Source borrow reserve liquidity supply SPL Token account.
///   1. `[writable]` Destination liquidity token account.
///                     Minted by borrow reserve liquidity mint.
///   2. `[writable]` Borrow reserve account - refreshed.
///   3. `[writable]` Borrow reserve liquidity fee receiver account.
///                     Must be the fee account specified at InitReserve.
///   4. `[writable]` Obligation account - refreshed.
///   5. `[]` Lending market account.
///   6. `[]` Derived lending market authority.
///   7. `[signer]` Obligation owner.
///   8. `[]` Clock sysvar.
///   9. `[]` Token program id.
///   10 `[optional, writable]` Host fee receiver account.
export const repayIx = ({
  reserveAssetSplAccount,
  srcAssetSplAccount,
  reserveId,
  transferAuthority,
  amount,
  marketId,
  profileId,
}: {
  reserveAssetSplAccount: PublicKey;
  srcAssetSplAccount: PublicKey;
  reserveId: PublicKey;
  transferAuthority: PublicKey;
  profileId: PublicKey;
  marketId: PublicKey;
  amount: Big;
}) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
    uint64('liquidityAmount'),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: LendingInstructionKey.RepayObligationLiquidity,
      liquidityAmount: bigToU64(amount),
    },
    data,
  );

  return new TransactionInstruction({
    data,
    programId: getProviderHub()!.currentNetwork.programId,
    keys: toAccessKeys(
      compact([
        { key: srcAssetSplAccount, type: 'write' },
        { key: reserveAssetSplAccount, type: 'write' },
        { key: reserveId, type: 'write' },
        { key: profileId, type: 'write' },
        { key: marketId, type: 'read' },
        { key: transferAuthority, type: 'signer' },
        { key: SYSVAR_CLOCK_PUBKEY, type: 'read' },
        { key: TOKEN_PROGRAM_ID, type: 'read' },
      ]),
    ),
  });
};
