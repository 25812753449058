import { Asset } from '@aries/defi-toolkit/types';
import Big, { BigSource } from 'big.js';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTokenAmountInput } from './token-input';

export const TokenInputCtx: React.FC<{
  onAmountChange: (amount: Big) => void;
  value?: Big;
  maxAmount: BigSource;
  tokenInfo: Asset;
}> = ({
  children,
  onAmountChange,
  value: customValue,
  maxAmount: maxAmountSrc,
  tokenInfo,
}) => {
  const maxAmount = Big(maxAmountSrc);
  const inputCtx = useTokenAmountInput(
    maxAmount,
    Big(tokenInfo.priceUSDNum),
    customValue,
    onAmountChange,
  );

  useEffect(() => {
    onAmountChange?.(inputCtx.valueByCoin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCtx.valueByCoin.toString()]);

  const ctx = useMemo(
    () => ({
      ...inputCtx,
      tokenPrice: Big(tokenInfo.priceUSDNum),
      tokenInfo,
      maxAmount,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputCtx, tokenInfo.priceUSDNum, maxAmount],
  );

  return (
    <TokenInputContext.Provider value={ctx}>
      {children}
    </TokenInputContext.Provider>
  );
};

export const TokenInputContext = React.createContext<
  ReturnType<typeof useTokenAmountInput> & {
    tokenPrice: Big;
    tokenInfo: Asset;
    maxAmount: Big;
  }
>({} as any);

export const useTokenInputContext = () => useContext(TokenInputContext);
