import { getProviderHub } from '@aries/solana-defi/common';
import * as BufferLayout from '@solana/buffer-layout';
import {
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { LendingInstructionKey, toAccessKeys } from './config';

export const refreshProfileIx = ({
  profileId,
  reserves,
}: {
  profileId: PublicKey;
  reserves: PublicKey[];
}) => {
  const dataLayout = BufferLayout.struct<any>([
    BufferLayout.u8('instruction'),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    { instruction: LendingInstructionKey.RefreshObligation },
    data,
  );

  return new TransactionInstruction({
    keys: toAccessKeys([
      { key: profileId, type: 'write' },
      { key: SYSVAR_CLOCK_PUBKEY, type: 'read' },
      ...reserves.map(
        reserve =>
          ({
            key: reserve,
            type: 'read',
          } as const),
      ),
    ]),
    data,
    programId: getProviderHub()!.currentNetwork.programId,
  });
};
