import { colorConfig, useThemeMode } from '@aries/ui-theming';
import React from 'react';
import { ActivityIndicator, ViewStyle } from 'react-native';

const { colorInteractive } = colorConfig;
export const Spin: React.FC<{
  size?: number | 'small' | 'large' | undefined;
  color?: string;
  style?: ViewStyle;
}> = ({ size = 'small', color, style }) => {
  const { mode } = useThemeMode();
  return (
    <ActivityIndicator
      style={[style]}
      size={size}
      color={color ?? colorInteractive[mode]}
    />
  );
};
