import { Asset } from '@aries/defi-toolkit/types';
import React from 'react';
import { Image, ImageStyle } from 'react-native';

export function TokenIcon({
  asset,
  size = 20,
  style,
}: {
  asset: Pick<Asset, 'logoURI' | 'symbol'>;
  size?: number;
  style?: ImageStyle;
}) {
  if (asset) {
    return (
      <Image
        style={{
          width: size,
          height: size,
          backgroundColor: asset.symbol === 'APT' ? '#ccc' : undefined,
          borderRadius: '50%',
          ...(style as any),
        }}
        source={{ uri: asset.logoURI }}
      />
    );
  }

  return null;
}
