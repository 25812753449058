/* eslint-disable @typescript-eslint/no-unused-vars */
import { useBalanceHub } from '@aries/solana-defi/common';
import { useProfileHub } from '@aries/solana-defi/lending/data/profile';
import { useReserves } from '@aries/solana-defi/lending/data/reserves';
import { Asset, Big } from '@aries/defi-toolkit/types';
import { sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';

const commonCoin = [
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // USDC
  'So11111111111111111111111111111111111111112', // SOL
  'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', // USDT
  'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So', // mSOL
  '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj', // stSOL
  // '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs', // ETH
  // '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R', // RAY
  // 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM', // USDCet
];

export const usePairSelectOptions = (
  totalAssetList: Asset[],
  enableBorrow: boolean,
): {
  fromList: Asset[];
  toList: Asset[];
} => {
  const { balanceMap } = useBalanceHub();
  const { currentProfile } = useProfileHub();
  const { reserveMap } = useReserves();

  const [fromList, toList] = useMemo(() => {
    const list = (
      enableBorrow
        ? totalAssetList.filter(a => !!reserveMap[a.id])
        : totalAssetList
    ).map(v => ({ ...v, isCommon: commonCoin.includes(v.id) }));

    return [list, list];
  }, [totalAssetList, reserveMap, enableBorrow]);

  const fromListWithBalance = useMemo(() => {
    return sortBy(
      fromList.map(item => {
        const balance = balanceMap[item.id];
        const deposit = currentProfile?.getDepositedLamports(item.id);

        return {
          ...item,
          wallet: item.lamportsToBalance(balance.lamports ?? Big(0)),
          deposit: deposit ? item.lamportsToBalance(deposit) : undefined,
        };
      }),
      a => -(a.wallet.amountNum ?? 0),
    );
  }, [fromList, balanceMap, currentProfile]);

  const toListWithBalance = useMemo(() => {
    return sortBy(
      toList.map(item => {
        const balance = balanceMap[item.id];
        const deposit = currentProfile?.getDepositedLamports(item.id);

        return {
          ...item,
          wallet: item.lamportsToBalance(balance?.lamports ?? Big(0)),
          deposit: deposit ? item.lamportsToBalance(deposit) : undefined,
        };
      }),
      a => -(a.wallet.amountNum ?? 0),
    );
  }, [toList, balanceMap, currentProfile]);

  return {
    fromList: fromListWithBalance,
    toList: toListWithBalance,
  };
};
