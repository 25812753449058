import { ActionSheet } from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { LANGUAGE_LIST, useTranslation } from '@aries/shared/locale';
import React from 'react';

export const openLanguageSelect = () => {
  openModal({
    showHeader: false,
    renderContent: ({ close }) => <LanguageSelect close={close} />,
    showClose: false,
    heightPercent: 0.3,
  });
};

export const LanguageSelect: React.FC<{ close: () => void }> = ({
  close,
}) => {
  const { t, i18n } = useTranslation();
  const { language: currentLanguage } = i18n;
  const languages = getLanguages(currentLanguage);

  return (
    <ActionSheet
      close={close}
      btns={languages.map(({ code, title }) => ({
        title,
        type: code === currentLanguage ? 'primary' : 'ghost',
        onPress: () => i18n.changeLanguage(code),
      }))}
      cancelText={t`Cancel`}
    />
  );
};

const getLanguages = (curLanguage: string) => {
  const index = LANGUAGE_LIST.findIndex(
    language => language.code === curLanguage,
  );
  return index > -1
    ? [
        LANGUAGE_LIST[index],
        ...LANGUAGE_LIST.slice(0, index),
        ...LANGUAGE_LIST.slice(index + 1),
      ]
    : LANGUAGE_LIST;
};
