import { colorConfig, useThemeMode } from '@aries/ui-theming';
import React, { useState } from 'react';
import {
  RefreshControl,
  ScrollView as RawScrollView,
  ScrollViewProps,
} from 'react-native';

const { colorTextSecondary } = colorConfig;
export const ScrollView: React.FC<
  ScrollViewProps & {
    refresh?: () => Promise<unknown>;
  }
> = props => {
  const { refresh, style, children } = props;

  const { mode } = useThemeMode();

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = () => {
    setRefreshing(true);

    setTimeout(async () => {
      await refresh!();
      setRefreshing(false);
    }, 600);
  };

  return (
    <RawScrollView
      {...props}
      showsVerticalScrollIndicator={false}
      style={style}
      refreshControl={
        refresh && (
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={colorTextSecondary[mode]}
          />
        )
      }
    >
      {children}
    </RawScrollView>
  );
};
