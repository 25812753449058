import { TxnResult } from '@aries-markets/create-sdk';
import { noop } from 'lodash';

// eslint-disable-next-line import/no-mutable-exports
export let withSendTxNotify: <T extends any[]>(
  fn: (...params: T) => Promise<TxnResult>,
  options?: {
    sendingTitle?: string;
    sendSuccessTitle?: string;
    sendSuccessContent?: string;
  },
) => (...params: T) => Promise<boolean> = noop as any;

// eslint-disable-next-line import/no-mutable-exports
export let withLoadingNotify: <T>(
  promise: Promise<T>,
  options?: {
    sendingTitle?: string;
    sendSuccessTitle?: string;
    sendSuccessContent?: string;
  },
) => Promise<T | null> = noop as any;

export const initNotifyUtil = (
  sendTx: typeof withSendTxNotify,
  sendAsync: typeof withLoadingNotify,
) => {
  withSendTxNotify = sendTx;
  withLoadingNotify = sendAsync;
};
