import { CenterRow, Txt } from '@aries/mobile-core/components';
import { ScreenHeight, ScreenInset } from '@aries/mobile-core/utils';
import { globalToken } from '@aries/ui-theming';
import { isString } from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import {
  Dimensions,
  ScrollView,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import { IconClose } from '../common';
import { Drawer } from '../Drawer';

let MODAL_HEIGHT = ScreenHeight - ScreenInset.top - 45;

export const setModalHeight = (v: number) => {
  MODAL_HEIGHT = v;
};

type Props = {
  hasContentMargin?: boolean;
  contentStyle?: ViewStyle;
  scrollable?: boolean;

  headerStyle?: ViewStyle;
  title?: string | React.ReactNode;
  renderHeader?: ({ close }: { close: () => void }) => React.ReactNode;
  showHeader?: boolean;
  showClose?: boolean;

  heightPercent?: number;
  bodyStyle?: ViewStyle;
};

export const openModal = ({
  renderContent,
  heightPercent,
  title,
  renderHeader,
  scrollable,
  bodyStyle,
  showClose,
  showHeader,
  contentStyle,
  hasContentMargin = true,
  headerStyle,
  modalSide = 'bottom',
  onClose,
}: Props & {
  renderContent: ({ close }: { close: () => void }) => {};
  modalSide?: 'left' | 'right' | 'bottom' | 'top';
  onClose?: () => void;
}) => {
  const res = Drawer.open(
    <Container
      contentStyle={contentStyle}
      scrollable={scrollable}
      hasContentMargin={hasContentMargin}
      title={title}
      headerStyle={headerStyle}
      onClose={() => {
        res.close(true);
        onClose?.();
      }}
      renderHeader={renderHeader}
      showClose={showClose}
      showHeader={showHeader}
      bodyStyle={bodyStyle}
      heightPercent={heightPercent}
    >
      {renderContent({ close: () => res.close(true) })}
    </Container>,
    modalSide,
  );
  return res;
};

const Container: React.FC<Props & { onClose: () => void }> = ({
  onClose,
  children,
  heightPercent,
  title,
  renderHeader,
  scrollable,
  bodyStyle,
  showClose = true,
  showHeader = true,
  contentStyle,
  hasContentMargin,
  headerStyle,
}) => {
  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  const Content = scrollable ? ScrollView : View;

  return (
    <ContainerBox
      style={bodyStyle}
      height={heightPercent ? height * heightPercent : MODAL_HEIGHT}
    >
      {showHeader && (
        <HeaderBox>
          {renderHeader ? (
            renderHeader({ close: onClose })
          ) : isString(title) ? (
            <CenterRow style={[{ flex: 1 }, headerStyle]}>
              <Txt bold h4>
                {title}
              </Txt>
            </CenterRow>
          ) : (
            title
          )}

          {showClose ? (
            <TouchableOpacity
              onPress={onClose}
              style={{
                position: 'absolute',
                alignItems: 'center',
                right: 8,
              }}
            >
              <IconClose size={25} />
            </TouchableOpacity>
          ) : null}
        </HeaderBox>
      )}

      <Content
        style={[
          {
            paddingHorizontal: hasContentMargin ? 16 : 0,
            flex: 1,
          },
          contentStyle,
        ]}
      >
        {show ? children : null}
      </Content>
    </ContainerBox>
  );
};

const { width, height } = Dimensions.get('screen');

const { colorBgContainer, padding, colorBgBase } = globalToken;

const ContainerBox = styled.View<{ height: number }>`
  width: ${width}px;
  height: ${p => p.height}px;
  overflow: hidden;
  background-color: ${globalToken.Modal.colorModalBg};
`;

const HeaderBox = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 12px;
  padding-left: ${padding};
  padding-right: ${padding};
  background-color: ${colorBgBase};
  border-width: 1px;
  border-color: ${colorBgContainer};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;
