/* eslint-disable no-param-reassign */
import { Asset } from '@aries/defi-toolkit/types';
import { useLatest } from '@aries/shared/hooks';
import { Swap } from '@aries/trading-fe-hooks';
import axios from 'axios';
import { keyBy, mapValues } from 'lodash';
import useSWR from 'swr';

type CEXPricePluginRes = {
  name: string;
  uri: string;
  exchangeRate: number;
};
type GetCEXPricePlugin = (params: {
  fromAsset: Asset;
  toAsset: Asset;
}) => Promise<CEXPricePluginRes | null>;

export const useCEXPrices = (
  exchangePlugins: GetCEXPricePlugin[],
  fromAsset?: Asset,
  toAsset?: Asset,
  swapRate?: number,
): Swap['currentPair']['CEXPrices'] => {
  const pluginsRef = useLatest(exchangePlugins);

  const { data: CEXPrices = [] } = useSWR(
    ['CEXPrices', fromAsset, toAsset],
    async () => {
      if (!fromAsset || !toAsset) {
        return [];
      }
      const prices = await Promise.all(
        pluginsRef.current.map(plugin => plugin({ fromAsset, toAsset })),
      );

      return prices.filter(
        price => price !== null && price.exchangeRate,
      ) as CEXPricePluginRes[];
    },
    { refreshInterval: 3000 },
  );

  if (!swapRate) {
    return [];
  }

  return CEXPrices.map(({ name, uri, exchangeRate }) => {
    const comparePercentage = (1 / (swapRate * exchangeRate) - 1) * 100;
    return {
      name,
      uri,
      comparePercentage: Math.abs(comparePercentage),
      isPositive: comparePercentage > 0,
    };
  });
};

export const getCoinGeckoRatePlugin: GetCEXPricePlugin = async ({
  fromAsset,
  toAsset,
}) => {
  try {
    const fromSymbol = fromAsset.coingeckoId ?? '';
    const toSymbol = toAsset.coingeckoId ?? '';
    if (!fromSymbol || !toSymbol) {
      return null;
    }
    const uri = `https://api.coingecko.com/api/v3/simple/price?ids=${fromSymbol},${toSymbol}&vs_currencies=usd`;
    const fromPrice = fromAsset.priceUSDNum;
    const toPrice = toAsset.priceUSDNum;

    return {
      name: 'CoinGecko rate',
      uri,
      exchangeRate: toPrice / fromPrice,
    };
  } catch {
    return null;
  }
};

export const getBinanceRatePlugin: GetCEXPricePlugin = async ({
  fromAsset,
  toAsset,
}) => {
  const symbols = [];
  let fromSymbol = convertSymbol(fromAsset.symbol);
  let toSymbol = convertSymbol(toAsset.symbol);

  if (!fromSymbol || !toSymbol || fromSymbol === toSymbol) {
    return null;
  }
  if (/USDT|BUSD/.test(fromSymbol) && toSymbol !== 'USDT') {
    toSymbol = `${toSymbol}${fromSymbol}`;
    symbols.push(toSymbol);
  } else if (/USDT|BUSD/.test(toSymbol)) {
    fromSymbol = `${fromSymbol}${toSymbol}`;
    symbols.push(fromSymbol);
  } else {
    [fromSymbol, toSymbol] = [`${fromSymbol}BUSD`, `${toSymbol}BUSD`];
    symbols.push(fromSymbol, toSymbol);
  }

  try {
    const uri = `https://api.binance.com/api/v3/ticker/price?symbols=${JSON.stringify(
      symbols,
    )}`;
    const res = await axios.get(uri);
    const priceMap = mapValues(
      keyBy(res.data, i => i.symbol),
      v => Number(v.price),
    );
    const fromPrice = priceMap[fromSymbol] ?? 1;
    const toPrice = priceMap[toSymbol] ?? 1;

    return {
      name: 'Binance rate',
      uri,
      exchangeRate: toPrice / fromPrice,
    };
  } catch {
    return null;
  }
};

const convertSymbol = (symbol: string) =>
  /USDT/.test(symbol) ? 'USDT' : /USDC/.test(symbol) ? 'BUSD' : symbol;
