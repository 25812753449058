import {
  BetweenRow,
  CenterBetweenRow,
  CenterCol,
  Col,
  EndCol,
  Txt,
  View,
} from '@aries/mobile-core/components';
import { ScreenWidth } from '@aries/mobile-core/utils';
import { useCurrentMarket } from '@aries/trading-fe-hooks';
import { colorConfig, useThemeMode } from '@aries/ui-theming';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

const {
  colorTextSuccess,
  colorTextCritical,
  colorSuccessBg,
  colorErrorBg,
  colorSuccessBorder,
  colorErrorBorder,
} = colorConfig;

const ITEM_HEIGHT = 20;
const TOTAL_HEIGHT = ITEM_HEIGHT * 10 + 40 + 40;
export const Orderbook: React.FC<{
  marketId: string;
}> = () => {
  const { mode } = useThemeMode();
  const market = useCurrentMarket()!;
  const { bids, asks } = market.orderbook;

  return (
    <Col
      flex={1}
      mr={10}
      style={{
        width: ScreenWidth * 0.45,
        height: TOTAL_HEIGHT,
      }}
    >
      <BetweenRow style={{ height: 40 }}>
        <Col>
          <Txt h1 c2>
            Price
          </Txt>
          <Txt h1 c2 size={9}>
            ({market?.quoteAsset.symbol})
          </Txt>
        </Col>
        <EndCol>
          <Txt h1 c2>
            Amount
          </Txt>
          <Txt h1 c2 size={9}>
            ({market?.baseAsset.symbol})
          </Txt>
        </EndCol>
      </BetweenRow>

      <Col flex={1}>
        {bids.slice(0, 5).map(bid => {
          return (
            <OrderRow
              key={bid.price}
              {...bid}
              bgColor={colorSuccessBorder[mode]}
              flashColor={colorSuccessBg[mode]}
              txtColor={colorTextSuccess[mode]}
            />
          );
        })}
      </Col>
      <CenterCol style={{ height: 40, justifyContent: 'center' }}>
        <Txt color={colorTextSuccess[mode]} h2 bold>
          ￥12434
        </Txt>
        <Txt c2 h1>
          ≈$1243.3
        </Txt>
      </CenterCol>
      <Col flex={1}>
        {asks.slice(0, 5).map(ask => {
          return (
            <OrderRow
              key={ask.price}
              {...ask}
              bgColor={colorErrorBorder[mode]}
              flashColor={colorErrorBg[mode]}
              txtColor={colorTextCritical[mode]}
            />
          );
        })}
      </Col>
    </Col>
  );
};

const OrderRow: React.FC<{
  bgColor: string;
  flashColor: string;
  price: string;
  amount: number;
  depthPct: number;
  txtColor: string;
}> = ({
  bgColor,
  flashColor,
  depthPct,
  price,
  // priceNum,
  amount,
  txtColor,
}) => {
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setRefreshKey(v => v + 1);
  }, [price, amount]);

  return (
    <CenterBetweenRow
      style={{ paddingVertical: 2, height: ITEM_HEIGHT }}

      // onPress={() => clickOrderbook(isReverse ? 'bid' : 'ask', priceNum)}
    >
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: bgColor,
          width: `${depthPct}%`,
        }}
      />
      <FlashView key={refreshKey} color={flashColor} />

      <Txt h2 color={txtColor} pl={5}>
        {price}
      </Txt>
      <Txt h2 style={{ zIndex: 5 }} pr={5}>
        {amount}
      </Txt>
    </CenterBetweenRow>
  );
};

const FlashView: React.FC<{ color: string }> = ({ color }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 300);
  }, []);

  return (
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        backgroundColor: color ?? '',
        left: 0,
        opacity: show ? 1 : 0,
      }}
    />
  );
};
