import {
  getProviderHub,
  hasEnoughGasOrThrow,
} from '@aries/solana-defi/common';
import { getProfileHub } from '@aries/solana-defi/lending/data/profile';
import { withSendTxNotify } from '@aries/solana-defi/utils';
import { getWalletCtx } from '@aries/solana-defi/wallet';
import { PORT_PROFILE_DATA_SIZE } from '@port.finance/port-sdk';
import {
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
} from '@solana/web3.js';
import { getLendingMarkets } from '../market';
import { initProfileIx } from './instructions/init-profile';
import { createSeed } from './utils';

export const addProfile = withSendTxNotify(
  async (isInitCreate: boolean) => {
    hasEnoughGasOrThrow();
    const marketId = getLendingMarkets()!.activeMarketId;
    const wallet = getWalletCtx()?.walletAddress;
    const programId = getProviderHub()?.currentNetwork.programId!;

    if (!marketId) {
      throw new Error('Please select a market');
    }

    if (!wallet) {
      throw new Error('Please connect your wallet');
    }

    const { provider } = getProviderHub()!;
    const walletPubkey = new PublicKey(wallet);
    const marketPubkey = new PublicKey(marketId);
    const programPubkey = new PublicKey(programId);

    let pair = Keypair.generate();

    if (isInitCreate) {
      const seed = await createSeed(
        walletPubkey,
        marketPubkey,
        programPubkey,
      );
      pair = Keypair.fromSeed(seed);
    }

    const size = PORT_PROFILE_DATA_SIZE;
    const minBalance =
      await provider.connection.getMinimumBalanceForRentExemption(size);

    const tx = new Transaction();

    tx.add(
      SystemProgram.createAccount({
        fromPubkey: walletPubkey,
        newAccountPubkey: pair.publicKey,
        lamports: minBalance,
        space: size,
        programId: programPubkey,
      }),
      initProfileIx({
        marketId: marketPubkey,
        walletId: walletPubkey,
        profileId: pair.publicKey,
      }),
    );

    const res = await provider.signAndSendTx(tx, [pair]);

    getProfileHub()?.refresh();

    return res;
  },
);
