// Overlay.js

import React from 'react';

import { DeviceEventEmitter } from 'react-native';
import OverlayPopoverView from './OverlayPopoverView';
import OverlayPopView from './OverlayPopView';
import OverlayPullView from './OverlayPullView';
import OverlayView from './OverlayView';
import TopView from './TopView';

export const Overlay = _Overlay;
export default class _Overlay {
  static View = OverlayView;

  static PullView = OverlayPullView;

  static PopView = OverlayPopView;

  static PopoverView = OverlayPopoverView;

  static show(overlayView) {
    let key;
    const onDisappearCompletedSave =
      overlayView.props.onDisappearCompleted;
    const element = React.cloneElement(overlayView, {
      onDisappearCompleted: () => {
        TopView.remove(key);
        onDisappearCompletedSave && onDisappearCompletedSave();
      },
    });
    key = TopView.add(element);
    return key;
  }

  static hide(key, animate = false) {
    DeviceEventEmitter.emit('closeView', key, animate);
  }

  static transformRoot(transform, animated, animatesOnly = null) {
    TopView.transform(transform, animated, animatesOnly);
  }

  static restoreRoot(animated, animatesOnly = null) {
    TopView.restore(animated, animatesOnly);
  }
}
