import { Dimensions, PixelRatio, Platform, StatusBar } from 'react-native';
import { isIphoneX } from './env';

const PAD_WIDTH = 768;

const { width: D_WIDTH, height: D_HEIGHT } = Dimensions.get('window');

export const IsIphoneX = isIphoneX();

export const IsIpad = (() => {
  if (Platform.OS !== 'ios' || IsIphoneX) return false;

  // if portrait and width is smaller than iPad width
  if (D_HEIGHT > D_WIDTH && D_WIDTH < PAD_WIDTH) {
    return false;
  }

  // if landscape and height is smaller that iPad height
  if (D_WIDTH > D_HEIGHT && D_HEIGHT < PAD_WIDTH) {
    return false;
  }

  return true;
})();

// pixel size
export const HairLineWidth = (() => {
  const pixelRatio = PixelRatio.get();
  if (pixelRatio >= 3) return 0.333;
  if (pixelRatio >= 2) return 0.5;
  return 1;
})();

export const IsLandscape = (function isLandscape() {
  return Dimensions.get('window').width > Dimensions.get('window').height;
})();

const fitIPhoneX = true;

export const StatusBarHeight =
  (function statusBarHeight() {
    if (Platform.OS === 'web') return 0;

    if (Platform.OS === 'ios') {
      if (IsIphoneX) return IsLandscape ? 0 : fitIPhoneX ? 44 : 20;
      if (IsIpad) return 20;
    } else if (Platform.OS === 'android') {
      if (Platform.Version > 20) return StatusBar.currentHeight; // translucent StatusBar is required
      return 0;
    }
    return IsLandscape ? 0 : 20;
  })() ?? 0;

export const ScreenInset = {
  left: IsLandscape && IsIphoneX && fitIPhoneX ? 44 : 0,
  right: IsLandscape && IsIphoneX && fitIPhoneX ? 44 : 0,
  top: StatusBarHeight ?? 0,
  bottom: IsIphoneX && fitIPhoneX ? (IsLandscape ? 24 : 34) : 0,
};

export const ScreenWidth = D_WIDTH;
export const ScreenHeight = Platform.OS === 'ios' ? D_HEIGHT : D_HEIGHT;
