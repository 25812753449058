import { Notify } from '@aries/mobile-core/Smoothy';
import { useTranslation } from '@aries/shared/locale';
import { shortenAddress } from '@aries/shared/utils';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  useWallet as useWalletInner,
  WalletProvider as WalletProviderImpl,
} from '@solana/wallet-adapter-react';
import {
  useWalletModal,
  WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import {
  BackpackWalletAdapter,
  BraveWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolongWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import React, { useEffect, useMemo } from 'react';
import { ENDPOINT_WHITE_LIST_DOMAINS } from '../config';
import { useWallet } from '../hook';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

export const WalletProvider: React.FC = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(
    () =>
      `https://port-finance.rpcpool.com/${
        ENDPOINT_WHITE_LIST_DOMAINS.includes(window.location.hostname)
          ? ''
          : 'b20f8af6e479ad1c68937cf806a1'
      }`,
    [],
  );

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new BackpackWalletAdapter(),
      new BraveWalletAdapter(),
      new Coin98WalletAdapter(),
      new LedgerWalletAdapter(),
      new SolongWalletAdapter(),
      new SolflareWalletAdapter(),
      new MathWalletAdapter(),
      new CloverWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  );

  return (
    <ConnectionProvider
      endpoint={endpoint}
      config={{ commitment: 'confirmed' }}
    >
      <WalletProviderImpl wallets={wallets} autoConnect>
        <WalletModalProvider>
          {children}
          <WalletCtxSpy />
        </WalletModalProvider>
      </WalletProviderImpl>
    </ConnectionProvider>
  );
};

const WalletCtxSpy = () => {
  const { t } = useTranslation();
  const {
    setWalletCtx,
    walletModalShow,
    setWalletModalShow: setShowInner,
  } = useWallet();
  const ctx = useWalletInner();
  const { setVisible, visible } = useWalletModal();

  useEffect(() => {
    if (!visible) {
      setShowInner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (walletModalShow) {
      setVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletModalShow]);

  useEffect(() => {
    (async () => {
      if (ctx.connected && ctx.wallet) {
        const address = ctx.wallet.adapter.publicKey!.toBase58();
        const walletCtx = {
          adapter: ctx,
          address,
        };

        setWalletCtx(walletCtx);

        Notify.info(
          t`Wallet Connected!`,
          t`Address: ${shortenAddress(address, 6)}`,
        );
      } else {
        setWalletCtx(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.connected, ctx.wallet]);

  return null;
};

// const SelectWalletModal: React.FC<{}> = () => {
//   const { walletModalShow, setWalletModalShow } = useWallet();

//   const {
//     wallet,
//     disconnect,
//     select,
//     wallets: adapters,
//   } = useWalletInner();
//   const connectRef = useLatest(select);
//   const disconnectRef = useLatest(disconnect);

//   useEffect(() => {
//     if (walletModalShow) {
//       openModal({
//         title: 'Select Wallet',
//         renderContent: ({ close }) => {
//           return (
//             <>
//               <NotificationBase
//                 type="info"
//                 title={<Trans i18nkey="REMIND" />}
//                 style={{ paddingTop: 8, paddingBottom: 8 }}
//                 content={
//                   <Txt h2 c2>
//                     <Trans i18nkey="Please switch your wallet to" />{' '}
//                     <Txt h3 warning>
//                       <Trans i18nkey="CORRECT NETWORK" />
//                     </Txt>
//                     .
//                   </Txt>
//                 }
//               />
//               {adapters.map(i => (
//                 <TouchableOpacity
//                   key={i.name}
//                   onPress={async () => {
//                     await disconnectRef.current();
//                     setTimeout(async () => {
//                       try {
//                         await connectRef.current(i.name);
//                         close();
//                         // eslint-disable-next-line no-empty
//                       } catch {}
//                     }, 0);
//                   }}
//                 >
//                   <WalletItem
//                     disabled={false}
//                     isActive={wallet?.name === i.name}
//                     mt={10}
//                   >
//                     <Image
//                       style={{ height: 45, width: 45 }}
//                       source={{ uri: i.icon }}
//                     />
//                     <Col ml={15}>
//                       <Txt h3 bold>
//                         {i.name}
//                       </Txt>
//                       {/* <Txt c3={i.readyState !== 'Installed'} h2>
//                         {i.readyState}
//                       </Txt> */}
//                     </Col>
//                   </WalletItem>
//                 </TouchableOpacity>
//               ))}
//             </>
//           );
//         },
//       });
//       setWalletModalShow(false);
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [walletModalShow]);
//   return null;
// };

// // @ts-ignore
// const WalletItem = styled(CenterRow)<{
//   isActive: boolean;
//   disabled: boolean;
// }>`
//   padding-left: 15px;
//   background-color: ${p =>
//     p.disabled
//       ? BASE_CONFIG.disabledColor1
//       : p.isActive
//       ? BASE_CONFIG.bckColor6
//       : undefined};
//   cursor: pointer;
//   border-radius: 5px;
//   :hover {
//     background-color: ${BASE_CONFIG.bckColor5};
//   }
//   padding: 15px 12px;
//   width: 440px;
// `;
